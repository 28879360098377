import * as React from "react";
const SvgAnimalShelterIconReport = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 512 512"
    fill= "currentColor"
    {...props}
  >
    <path d="M326 77.2c-4.7 3.9-9.8 8-11.3 9.3l-2.9 2.2 2.7 3.1 2.7 3.2H151.5L77 189c-41 51.7-75 94.8-75.7 95.8-1.1 1.5.1 2.7 8.9 9.7 5.6 4.4 10.9 8.5 11.8 9.2 1.5 1 2.8-.3 10.1-9.5l8.4-10.7.3 79.3.2 79.2h432l.2-77.7.3-77.7 6.9 8.5c3.8 4.6 7.2 8.8 7.7 9.3.4.4 5.8-3.4 12.1-8.5 6.2-5.1 11.4-9.3 11.6-9.4.1-.1-31-38.6-69.3-85.4-38.2-46.8-69.5-85.4-69.5-85.7s4.8-6.4 10.6-13.6c9-10.9 10.4-13.2 9.3-14.3-2.8-2.7-21.4-17.5-21.9-17.4-.3 0-4.3 4.7-8.9 10.5-4.6 5.7-8.6 10.4-9 10.4-.3 0-4.4-4.7-9-10.5-4.7-5.8-8.7-10.5-9-10.5-.3.1-4.4 3.3-9.1 7.2m-44.7 102.4c-24.5 30-45.1 54.8-45.7 55s-36 .3-78.7.2l-77.6-.3 43.4-54.8 43.4-54.7h159.8zm117.1 15 44.6 54.6V412h-28V281H291v131h-28V249.5l44.7-54.8c24.6-30.1 45-54.7 45.5-54.7.4 0 20.7 24.6 45.2 54.6M211 265.4c0 .2-3.6 4.7-8 10.1s-8 10.1-8 10.5c0 .5 14.9 13.1 21.6 18.2.8.5 3.6-2.3 8.4-8.2 4-4.9 7.5-9 7.7-9 .1 0 .3 28.1.3 62.5V412H71V265h70c38.5 0 70 .2 70 .4m174 96.1V412h-63V311h63z" />
    <path d="M328 229v15h51v-30h-51z" />
  </svg>
);
export default SvgAnimalShelterIconReport;
