import * as React from "react";
const SvgHeatWaveDashboard = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    data-name="Layer 1"
    viewBox="0 0 24 24"
    width="1em"
    height="1em"
    fill= "currentColor"
    {...props}
  >
    <path  d="M7.692 14.038 2.009 17.39l-1.006-1.729 3.943-2.295a6.5 6.5 0 0 1-.363-1.367H0v-2h4.585q.111-.715.369-1.371L.998 6.326l1.006-1.729 3.957 2.304q.432-.528.964-.958L4.627 1.992 6.356.986l2.302 3.958a6.5 6.5 0 0 1 1.343-.359V0h2v4.58c.468.072.924.192 1.359.362L15.659.987l1.729 1.005-2.299 3.953q.524.427.957.961l3.966-2.309 1.006 1.729-3.968 2.31c.117.299.216.604.287.916a7.5 7.5 0 0 0-2.295-.532A4.5 4.5 0 0 0 6.5 11a4.42 4.42 0 0 0 1.192 3.038m11.717-.022a5.51 5.51 0 0 0-4.909-3.017 5.507 5.507 0 0 0-5.487 5.123A4.01 4.01 0 0 0 6 19.999c0 2.206 1.794 4 4 4h1.008a5 5 0 0 1-.902-2 2.01 2.01 0 0 1-2.105-2c0-1.103.897-2 2.027-2h1.113c-.026-.298-.147-1.212-.141-1.5 0-1.93 1.57-3.5 3.5-3.5 1.481 0 2.808.938 3.302 2.333l.235.667H19c1.654 0 3 1.346 3 3a3.005 3.005 0 0 1-2.082 2.855 4.95 4.95 0 0 1-.926 2.145H19c2.757 0 5-2.243 5-5a5.006 5.006 0 0 0-4.591-4.983m-2.296 4.854a3.005 3.005 0 0 1 .008 4.25c-.566.566-1.32.879-2.121.879s-1.555-.312-2.121-.879a3 3 0 0 1 0-4.242L15 16.802zm-1.406 1.422L15 19.601l-.715.699a1 1 0 0 0 .008 1.406 1.025 1.025 0 0 0 1.414 0 1 1 0 0 0 0-1.414" />
  </svg>
);
export default SvgHeatWaveDashboard;
