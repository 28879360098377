import React, { useEffect, useState } from "react";
import "./HumanDashboardCard.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { humanlossdashboardincidentcarddata } from "../../../../../Services/EndPoints";
import { getData } from "../../../../../Services/Services";
import {
  faPersonDrowning,
  faBolt,
  faRodSnake,
  faCarCrash,
  faShip,
  faThunderstorm,
  faTrain,
  faFire,
  faPlane,
  faBuilding,
  faSkull,
} from "@fortawesome/free-solid-svg-icons";

export default function HumanDashboardCard({
  data,
  config,
  clickedKey,
  setClickedKey,
  triggeredFunction,
  onIncidentSelect,
  fromDateNew,
  toDateNew,
  startDateStr,
  endDateStr,
  applyClicked,
  setApplyClicked,
  onApplyHandled,
  firstLoad,
  setLoader,
}) {
  const colors = ["#333c67"];
  const [cardData, setCardData] = useState({});
  const [totalDeaths, setTotalDeaths] = useState(0);
  const [incidentData, setIncidentData] = useState([]); // Store API response

  useEffect(() => {
    setCardData(data);
  }, [data]);

  useEffect(() => {
    refreshDashboard(fromDateNew, toDateNew);
  }, [firstLoad]);

  useEffect(() => {
    // Run only when Apply button is clicked
    if (applyClicked) {
      refreshDashboard(fromDateNew, toDateNew);
      onApplyHandled(); // Reset applyClicked after API call
    }
  }, [applyClicked]);

  const handleCardClick = (conf) => {
    if (conf.label === "All Incidents Data") {
      console.log("Selected: ALL");
      onIncidentSelect("ALL", conf.label); // Send "ALL"
      return;
    }
  
    // Find the clicked incident based on the label from config
    const clickedIncident = incidentData.find(
      (incident) => incident.incident_name === conf.label
    );
  
    if (clickedIncident) {
      console.log("Incident ID:", clickedIncident.incident_id);
      onIncidentSelect(
        clickedIncident.incident_id,
        clickedIncident.incident_name
      ); // Pass the incident_id
    } else {
      console.log("Incident ID not found for:", conf.label);
      onIncidentSelect(0, conf.label); // Send 0 as incident_id when not found
    }
  };
  

  // Refactored refreshDashboard to take fromDateNew and toDateNew dynamically
  const refreshDashboard = async (fromDate, toDate) => {
    // Make API calls dynamically using fromDate and toDate

    setLoader(true);
    const response = await getData(
      humanlossdashboardincidentcarddata
        .replace("FROMDATE", fromDate)
        .replace("TODATE", toDate)
    );

    setIncidentData(response); // Store API response for later use

    const mappedData = config?.data.reduce((acc, conf) => {
      const incident = response.find(
        (incident) => incident.incident_name === conf.label
      );
      if (incident) {
        acc[conf.key] = incident.count || 0;
      }
      return acc;
    }, {});

    const total = Object.values(mappedData).reduce(
      (sum, count) => sum + count,
      0
    );
    setTotalDeaths(total);
    setCardData(mappedData);
    setLoader(false);
  };

  return (
    <div className="dashboard_card">
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div>{config.title}</div>
        <div>Total Deaths: {totalDeaths}</div>
      </div>
      <div className="d-grid gap-2 mt-1">
        {config?.data.slice(0, 25).map((conf, index) => {
          return (
            <div
              key={index}
              className={`ds-single_card_cw cursor-pointer grid-container-cw ${
                clickedKey === conf.key ? "highlighted-card" : ""
              }`}
              onClick={() => {
                setClickedKey(conf.key);
                handleCardClick(conf); // Pass the config item to handleCardClick
              }}
              style={{ backgroundColor: colors[index % colors.length] }}
            >
              <div className="card-icon-humanloss">
                {conf.icon && <FontAwesomeIcon icon={conf.icon} size="3x" />}
              </div>
              <div className="card-label-humanloss">{conf.label}</div>
              {conf.label !== "All Incidents Data" && (
                <div className="card-data-humanloss">
                  {cardData && cardData[conf.key] !== undefined
                    ? cardData[conf.key]
                    : 0}
                </div>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
}
