import * as React from "react";
const SvgTaskManagementIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 512 512"
    fill="currentColor"
    {...props}
  >
    <path d="M52.3 41.7c-5.7 2-13.8 9.4-17.1 15.7l-2.7 5.1-.3 141.4c-.3 157.3-.7 147.1 6.6 156.6 2.7 3.6 5.7 6 10.6 8.5l6.9 3.5 52.4.3 52.4.3 2.9-3c1.7-1.6 3-3.7 3-4.6 0-2.9-3-7.5-5.3-8-1.2-.3-25-.6-52.8-.8l-50.6-.2-3.7-2.9c-7-5.3-6.6 3.4-6.6-132.1 0-67.4.3-122.5.7-122.5s45 25.3 99.2 56.2l98.5 56.3h16.1l98.2-56.3c54-30.9 98.5-56.2 98.8-56.2s.5 46.1.5 102.5v102.6l2.5 2.4c3.2 3.3 7.8 3.3 11.1 0l2.4-2.4-.2-120.8-.3-120.8-2.7-5.1c-1.5-2.8-5-7.2-7.8-9.7-9.3-8.4 10-7.7-211.4-7.6-172.3 0-197.6.2-201.3 1.6m400.8 16.7c5 3.4 7.1 8.2 6.7 15.7l-.3 6L359 137.7c-57.9 33.2-101.9 57.8-103.7 58.1-2.8.4-17-7.4-105.3-57.8L48 79.8v-6c0-7.1 2.5-12.7 7.2-15.7l3.3-2.1h391z" />
    <path d="M286 258.3c-11.9 3.3-19.4 12.2-20.6 24.5-.6 5.5 0 7.7 11.5 42.3 6.6 20.1 12.1 38 12.1 39.9s-5.4 19.7-12 39.5c-6.6 19.9-12 37.7-12 39.6 0 15.3 12.7 27.9 28.2 27.9h6.8l83.8-41.9c46.1-23 85.2-43.1 86.9-44.5 1.8-1.5 4.5-5.3 6-8.4 5.5-11.3 3-25-6.2-33.1-2.1-1.8-39.1-20.8-86.8-44.7-89.5-44.6-87.8-43.9-97.7-41.1m91.7 56.1c91.1 45.5 87.2 43.2 86 51.8-.3 2.2-1.4 4.9-2.4 6-2.9 3.2-164.8 83.8-168.3 83.8-6.4 0-12-5.3-12-11.2 0-1.3 5.2-17.9 11.6-37l11.6-34.8h35.3c35.3 0 35.4 0 38-2.3 3.5-2.9 3.7-7.8.5-11.2l-2.3-2.5h-71.2l-11.8-35.3c-6.4-19.4-11.7-36.1-11.7-37.2 0-5.1 6.7-11.3 12.2-11.5 1 0 39 18.6 84.5 41.4M206.5 307.5c-2.7 2.6-3.2 7-1.2 9.6 2.7 3.5 5.4 3.9 24.7 3.9 18.9 0 19.1 0 21.5-2.5 3.5-3.4 3.4-8.6-.1-11.4-2.5-2-4-2.1-22.6-2.1-19.8 0-19.9 0-22.3 2.5M189.2 359.3c-3.2 3.4-3 8.9.5 11.7 2.6 2 3.4 2.1 31.6 1.8l28.9-.3 1.9-2.4c2.6-3.2 2.5-8.2-.4-10.9-2.3-2.2-2.9-2.2-31.3-2.2h-29.1zM206.5 411.5c-4.4 4.3-2.2 11.5 3.8 13.1 1.3.3 10.8.4 21.1.2 18.4-.3 18.8-.4 20.7-2.7 2.6-3.2 2.5-8.2-.4-10.9-2.2-2.1-3.2-2.2-22.6-2.2-20.1 0-20.2 0-22.6 2.5" />
  </svg>
);
export default SvgTaskManagementIcon;
