import React, { useEffect, useRef, useState } from "react";
import { GridStack } from "gridstack";
import GridItem from "../../../Components/UiComponents/GridItem/GridItem";
import RegionTable from "../RegionManagement/RegionList/RegionTable/RegionTable";
import RegionTree from "../RegionManagement/RegionList/RegionTree/RegionTree";
import "./Region.css";
import DMSHeader from "../DMSHeader/DMSHeader";
import { toast, ToastContainer } from "react-toastify";
import { useTranslation } from "react-i18next";

import { TableView, TreeView } from "../../../Components/IconComponents";
const RegionManagement = () => {
  const { t, i18n } = useTranslation();
  const [treeView, setTreeView] = useState("grid");
  const [selectedValue, setSelectedValue] = useState("grid");
  useEffect(() => {
    GridStack.init();
  }, []);

  function showCreateRegionScn() {
    setTreeView("tree");
    setSelectedValue("tree");
  }
  function refreshRegionList(msg) {
    toast.success(msg, {
      position: toast.POSITION.TOP_CENTER,
      autoClose: 2000, // Close after 2 seconds
    });
    setTreeView("grid");
  }
  function setTreeViewType(type) {
    setTreeView(type);
  }
  //   function GetRegionHedaer() {
  //     return (
  //       <div className="d-flex align-items-center">
  //         {/* <DMSHeader title={t("region_management_header")} /> */}
  //       </div>
  //     );
  //   }

  //   function GetView() {
  //     //setSelectedValue(treeView);
  //     return <RegionTree refreshRegionList={refreshRegionList} />;
  //   }
  const layoutData = [
    {
      x: 0,
      y: 0,
      width: 12,
      height: 1,
      id: 1,
      component: <DMSHeader title={t("region_management_header")} />,
      haveOverflowVisible: true,
    },

    {
      x: 0,
      y: 5,
      width: 12,
      height: 14,
      id: 2,
      component: (
        <div>
          <RegionTree refreshRegionList={refreshRegionList} />
        </div>
      ),
    },
  ];

  return (
    <div>
      <div className="dms_header" style={{height: "42px"}}>
        <DMSHeader title={t("region_management_header")} />
      </div>
      <div className="region_container">
        <RegionTree refreshRegionList={refreshRegionList} />
      </div>
    </div>
  );
};

export default RegionManagement;
