import React, { useState, useEffect, useRef, useContext } from "react";
// import "./DailyFloodReportCompTable.css";
import Moment from "react-moment";
import { useLocation, useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import govt_logo from "../../../../Assets/Images/bihar_logo.png";
import Loader from "react-spinner-loader";
import "./FireIncidentReportForm.css"
//import html2pdf from 'html2pdf.js';
// import "./Hindi_Font.js";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import SelectComponent from "../../../../Components/UiComponents/SelectComponent/SelectComponent";
import { postDataWithBody, getData, deleteDataById } from "../../../../Services/Services";
import {
  getChildRegionsById,
  findRegionsByUser,
  getDailyReportByDistrict,
  getDailyFloodReportListURL,
  cleardailyReportData,
  getFireReportData,
  delteFireReportData,
  deletRoleById,
} from "../../../../Services/EndPoints";
import { useTranslation } from "react-i18next";
import DatePicker from "../../Common/Form/DatePicker/DatePicker";
import { RoleContext } from "../../../../index.js";
import Confirmpopup from "../../Common/ConfirmPopup/Confirmpopup.js";

const FireIncidentReportForm = () => {
  const roleName = useContext(RoleContext);
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);
  const [dailyReport, setDailyReport] = useState(null);
  const [popUp, setPopUp] = useState(false);
  const [districtData, setDistrictData] = useState([]);
  const [clearId, setClearId] = useState(null);
  const [blockData, setBlockData] = useState([]);
  const [selectedDistrictName, setSelectedDistrictName] = useState(null);
  const [selectedDistrictId, setSelectedDistrictId] = useState(null);
  const [slctdBlkId, setSlctdBlkId] = useState(null);
  const [selectedBlockId, setSelectedBlockId] = useState(null);
  const pdfRef = useRef(null);
  const [selectedDate, setSelectedDate] = useState(
    new Date().toLocaleDateString('en-CA')
  ); // State to store selected date
  const rolName = roleName.roleName;
  const location = useLocation();
  const { state } = location;  // Destructure the state from the location object
  const [selectedDistrictDisplayName, setSelectedDistrictDisplayName] = useState("");

  // Check if 'updated' is true...
  const {updated, date, distName, block} = state || {};

  console.log("state",state)

  const getBlockIdListForReport = () => {
    if (slctdBlkId == 0) {
      let blockIdList = [];
      blockData.forEach((v) => blockIdList.push(v.id));
      return blockIdList;
    } else {
      return [slctdBlkId];
    }
  };

  const editFloodData = async () => {
    if (slctdBlkId == 0) {
      toast.error("Please select a block to edit data.", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 2000, // Close after 2 seconds
      });
    } else {
      let slctdDate = document.getElementById("flood_date_picker").value;
      let inputobj = {
        region_id_list: [slctdBlkId],
        reported_on: slctdDate,
      };
      const data = await postDataWithBody(getFireReportData, inputobj, {});
      console.log("DATAAA", data);
  
      const addressObj = data.fire_report_address;
  
      // Check if fire_report array is empty or undefined
      if (!data.fire_report || data.fire_report.length === 0) {
        toast.error("No data exists for the selected date and block", {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 2000, // Close after 2 seconds
        });
      } else {
        navigate("/reports/fire-editdfr", {
          state: {
            blockId: slctdBlkId,
            slctd_dt: document.getElementById("flood_date_picker").value,
            id: data.fire_report[0]?.id, // Optional chaining to avoid errors
            addressObj: addressObj,
            distName: selectedDistrictId
          },
        });
      }
    }
  };
  


  const clearFloodData = async () => {
    if (slctdBlkId == 0) {
      toast.error("Please select a block to edit data.", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 2000, // Close after 2 seconds
      });
    } else {
      let slctdDate = document.getElementById("flood_date_picker").value;
      let inputobj = {
        region_id_list: [slctdBlkId],
        reported_on: slctdDate,
      };
      const data = await postDataWithBody(getFireReportData, inputobj, {});
      if (!data.fire_report || data.fire_report.length === 0) {
        toast.error("No data exists for the selected date and block", {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 2000, // Close after 2 seconds
        });
      } else {
        setPopUp(true);
        setClearId(data?.fire_report[0]?.id);
      }
    }
  };

  
  // const getDailyReportData = async (blockListId) => {
  //   if (blockListId == null) {
  //     blockListId = getBlockIdListForReport();
  //   }
  
  //   let selectedDateStr = document.getElementById("flood_date_picker").value;
  //   if (selectedDateStr == "") {
  //     selectedDateStr = new Date().toISOString().split("T")[0];
  //   }
  //   setSelectedDate(selectedDateStr);
  //   setLoader(true);
  
  //   const index = blockListId.indexOf(0);
  //   if (index > -1) {
  //     blockListId.splice(index, 1);
  //   }
  
  //   let inputobj = {
  //     region_id_list: blockListId,
  //     reported_on: selectedDateStr,
  //   };
  
  //   let dailyReportData = await postDataWithBody(getFireReportData, inputobj, {});
  
  //   // **Transforming the response**
  //   let mergedReport = {};
  
  //   // Merge `fire_report`
  //   dailyReportData.fire_report.forEach((report) => {
  //     let regionId = report.region_id;
  //     if (!mergedReport[regionId]) {
  //       mergedReport[regionId] = { ...report, address: "" };
  //     } else {
  //       Object.assign(mergedReport[regionId], report);
  //     }
  //   });
  
  //   // Merge `fire_report_address`
  //   let addressMap = {};
  //   dailyReportData.fire_report_address.forEach((address) => {
  //     let regionId = address.region_id;
  //     if (!addressMap[regionId]) {
  //       addressMap[regionId] = [];
  //     }
  //     addressMap[regionId].push(address.address || ""); // Handle null or undefined
  //   });
  
  //   Object.keys(addressMap).forEach((regionId) => {
  //     if (mergedReport[regionId]) {
  //       mergedReport[regionId].address = addressMap[regionId].join(", ");
  //     }
  //   });
  
  //   // // Merge `fire_report_cum`
  //   // if (dailyReportData.fire_report_cum.length > 0 &&
  //   //     dailyReportData.fire_report.length === 0 &&
  //   //     dailyReportData.fire_report_address.length === 0) {
  //   //   // **If only `fire_report_cum` exists, use it directly**
  //   //   dailyReportData.fire_report_cum.forEach((cumReport) => {
  //   //     let regionId = cumReport.region_id;
  //   //     mergedReport[regionId] = { ...cumReport, address: "" };
  //   //   });
  //   // } else {
  //     // **Normal merging if all reports exist**
  //     dailyReportData.fire_report_cum.forEach((cumReport) => {
  //       let regionId = cumReport.region_id;
  //       if (mergedReport[regionId]) {
  //         Object.assign(mergedReport[regionId], cumReport);
  //       }
  //     });
  //   // }
  
  //   // **Ensure all properties in mergedReport have default values**
  //   Object.keys(mergedReport).forEach((regionId) => {
  //     Object.keys(mergedReport[regionId]).forEach((key) => {
  //       if (mergedReport[regionId][key] == null || mergedReport[regionId][key] === "") {
  //         mergedReport[regionId][key] = ""; // Replace null/undefined with an empty string
  //       }
  //     });
  //   });
  
  //   setDailyReport(Object.values(mergedReport)); // Convert object back to an array
  //   setLoader(false);
  
  //   var skillsSelect = document.getElementById("district_drop");
  //   var selectedText = skillsSelect.options[skillsSelect.selectedIndex].text;
  //   document.getElementById("selected_dist_name").innerHTML = selectedText;
  // };

  const getDailyReportData = async (blockListId, block = null, date = null) => {
    if (blockListId == null) {
        blockListId = getBlockIdListForReport();
    }

    let selectedDateStr = document.getElementById("flood_date_picker").value;
    if (selectedDateStr == "") {
        selectedDateStr = new Date().toLocaleDateString('en-CA');
    }
    setSelectedDate(selectedDateStr);
    setLoader(true);

    const index = blockListId.indexOf(0);
    if (index > -1) {
        blockListId.splice(index, 1);
    }
    if(block != null && date != null) {
      blockListId = [block];
      selectedDateStr = date;
      setSelectedDate(date);
      document.getElementById("flood_date_picker").valueAsDate = new Date(date);
      setTimeout(() => {
        document.getElementById("district_drop").value = distName
        document.getElementById("block_drop").value = block
      },1000)
    }
    let inputobj = {
        region_id_list: blockListId,
        reported_on: selectedDateStr,
    };

    let dailyReportData = await postDataWithBody(getFireReportData, inputobj, {});

    let mergedReport = {};

    // Process `fire_report` (Merge all data without comparing region_id)
    if (dailyReportData.fire_report.length > 0) {
        dailyReportData.fire_report.forEach((report) => {
            mergedReport = { ...mergedReport, ...report, address: "" };
        });
    }

    // Process `fire_report_cum` only if it exists
    if (dailyReportData.fire_report_cum.length > 0) {
        dailyReportData.fire_report_cum.forEach((cumReport) => {
            mergedReport = { ...mergedReport, ...cumReport };
        });
    }

    // Process `fire_report_address`
    if (dailyReportData.fire_report_address.length > 0) {
        let addressList = dailyReportData.fire_report_address.map((entry) => entry.address).join(", ");
        mergedReport.address = addressList;
    }

    // Ensure all properties have default values
    Object.keys(mergedReport).forEach((key) => {
        if (mergedReport[key] == null || mergedReport[key] === "") {
            mergedReport[key] = "";
        }
    });

    setDailyReport([mergedReport]);
    setLoader(false);

    var skillsSelect = document.getElementById("district_drop");
    var selectedText = skillsSelect.options[skillsSelect.selectedIndex]?.text || "";
    document.getElementById("selected_dist_name").innerHTML = selectedText;
};

  

  console.log("FIREREPORTDATA",dailyReport)
  async function addImageProcess() {
    return new Promise((resolve, reject) => {
      let img = new Image();
      img.src = govt_logo;
      img.onload = () => resolve(img);
      img.onerror = reject;
    });
  }
  const downloadPDF = async () => {
    let selectedDate = new Date(
        document.getElementById("flood_date_picker").value
    ).toLocaleDateString("en-GB");
    const content = pdfRef.current;
    const doc = new jsPDF("p", "pt", "a4");
    const image = await addImageProcess();

    let fontName = "Helvetica";
    if (i18n.language == "hi") {
        fontName = "Akshar Unicode";
    }
    doc.setFont(fontName);
    doc.setFontSize(13);
    doc.addImage(image, "png", 255, 25, 0, 0);
    var xOffset = doc.internal.pageSize.width / 2;
    doc.text(t("floodreport.govt_name"), xOffset, 120, { align: "center" });
    doc.text(t("floodreport.department_name"), xOffset, 140, { align: "center" });
    doc.text(
        t("Fire Daily Report") +
        " (" +
        t("floodreport.dated_on_lbl") +
        " " +
        selectedDate +
        ")",
        xOffset,
        160,
        { align: "center" }
    );

    doc.autoTable({
        html: "#report_table",
        startX: 10,
        startY: 190,
        theme: "grid",
        rowPageBreak: "avoid",
        bodyStyles: { lineColor: [0, 0, 0] },
        columnStyles: {
            2: { cellWidth: 300 },
            3: { cellWidth: 70 },
            4: { cellWidth: 70 },
            5: { cellWidth: 47 },
            6: { cellWidth: 47 },
            7: { cellWidth: 47 },
            8: { cellWidth: 47 },
            9: { cellWidth: 47 },
            10: { cellWidth: 47 },
            11: { cellWidth: 0 },
        },
        didParseCell: function (data) {
            if (data.cell.raw) {
                if (data.cell.raw.querySelector("b")) {
                    data.cell.styles.textColor = "red";
                }
                if (data.cell.raw.querySelector("span")) {
                    data.cell.styles.halign = "center";
                }
            } else {
                console.warn("data.cell.raw is undefined", data);
            }
        },
        styles: {
            font: fontName,
            fontSize: 9,
            cellWidth: "auto",
            halign: "left",
            cellPadding: 3,
        },
    });

    // Get the bottom of the page
    let pageHeight = doc.internal.pageSize.height;
    let footerY = pageHeight - 80; // Adjust as needed for spacing

    // District Name
    const distName = districtData?.find(x => x.id == selectedDistrictId)?.value || '';

    // Add footer content
    doc.setFontSize(10);
    doc.text(50, footerY, "Letter No.: ", { maxWidth: 300 });
    doc.text(50, footerY + 20, "Date: ", { maxWidth: 300 });
    doc.text(50, footerY + 40, "Copy: ", { maxWidth: 300 });

    doc.text(320, footerY ,"Signature of District Officers/ ", { maxWidth: 300 });
    doc.text(320, footerY + 20, "Additional District Magistrate(ADM)(Disaster Management)/", { maxWidth: 300 });
    doc.text(320, footerY + 40, `Officer in Charge(District Disaster Management)/`, { maxWidth: 300 });
    doc.setFont(undefined, "bold");
    doc.text(320, footerY + 60, `[${distName} DISTRICT]`, { maxWidth: 300 });
    doc.setFont(undefined, "normal"); // Reset font back to normal

    const now = new Date();
    now.setMinutes(now.getMinutes() + 330); // Convert UTC to IST (+5:30)

    const istDate = now.toISOString().split("T")[0]; // YYYY-MM-DD
    const istTime = now.toISOString().split("T")[1].split(".")[0].replace(/:/g, ""); // HHMMSS

    const timestamp = `${istDate}_${istTime}`;

    // Save file with timestamp in name
    doc.save(`fire_daily_report_${timestamp}.pdf`);
};

  const getDistrictData = async () => {
    try {
      const distList = await getData(findRegionsByUser);

      if(updated) {
        let blockdata = block;
        setSlctdBlkId(blockdata);
        getDailyReportData([blockdata], blockdata, date);
        setSelectedDistrictId(distName);
        setDistrictData(distList);
        getBlockListByDistId(distName);
        const selectedDistrict = distList.find(district => district.id === parseInt(distName));
      if (selectedDistrict) {
        setSelectedDistrictDisplayName(selectedDistrict.value);
      }
      } else if (Array.isArray(distList) && distList.length > 0) {
        let blockdata = await getBlockListByDistId(distList[0].id);
        setSlctdBlkId(0);
        let blockIdList = [];
        blockdata.forEach((v) => blockIdList.push(v.id));
        setDistrictData(distList);
        getDailyReportData(blockIdList);
        setSelectedDistrictId(distList[0].id);
        setSelectedDistrictDisplayName(distList[0].value);
      } else {
        console.error("Error: District data is empty or not an array");
      }
    } catch (error) {
      console.error("Error fetching district list:", error);
    }
  };

  useEffect(() => {
    document.getElementById("flood_date_picker").valueAsDate = new Date();
    getDistrictData();
  }, []);

  const getFormattedDate = (dateStr) => {
    return <Moment format="DD/MM/YYYY">{dateStr}</Moment>;
  };

  const getBlockListByDistId = async (distId) => {
    try {
      const blockList = await postDataWithBody(
        getChildRegionsById,
        [distId],
        {}
      );
      blockList.forEach(function (elem, index) {
        elem["value"] = elem["name"];
      });
      blockList.unshift({ id: 0, value: "All" });
      setBlockData(null);
      setTimeout(function () {
        setBlockData(blockList);
      }, 100);
      return blockList;
    } catch (error) {
      console.error("Error fetching block list:", error);
    }
  };
 // Modify the getDistrictDataByCode function to just update state
async function getDistrictDataByCode(event) {
  const districtId = event.target.value;
  setSelectedDistrictName(districtId);
  setSelectedDistrictId(districtId);

  
  // Find the district display name from the districtData array
  const selectedDistrict = districtData.find(district => district.id === parseInt(districtId));
  if (selectedDistrict) {
    setSelectedDistrictDisplayName(selectedDistrict.value);
  }
  // Just update block dropdown when district changes, don't fetch data yet
  await getBlockListByDistId(event.target.value);
  
  // Reset block selection when district changes
  setSlctdBlkId(0);
}

// Modify the setSelectedBlockIdVal function to just update state
function setSelectedBlockIdVal(event) {
  setSlctdBlkId(event.target.value);
}

// Update the Apply button click handler
const handleApplyClick = () => {
  // Reset dailyReport before fetching new data
  setDailyReport(null);
  
  // Fetch data based on current selections
  getDailyReportData(null);
}
  // const changeSelectedate = () => {
    /*if (selectedDistrictId && selectedDate) {
      getDailyReportData(selectedDistrictId, selectedDate);
    } else {
      console.error("Please select both district and date.");
    }*/
  // };

  const handleTogglePopup = () => {
    setPopUp(!popUp);
  };

  const onClickSucesshandler = async () => {
    const resData = await deleteDataById(delteFireReportData, clearId);
    if (resData.status === "success") {
      // Show the success toast
      toast.success(
        resData.msg,
        {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 2000,
        }
      ); 
    } else {
      toast.error(
        resData.msg || t("delete_failure_message"),
        {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 2000,
        }
      );
    }
    setPopUp(false);
    getDailyReportData();
  };

  const formatDate = (date) => {
    const d = new Date(date);
    const day = String(d.getDate()).padStart(2, '0');
    const month = String(d.getMonth() + 1).padStart(2, '0');
    const year = d.getFullYear();
    return `${day}-${month}-${year}`;
  };
  
  // Usage
  const selectedDateFormatted = formatDate(selectedDate);
  
  const isOlderThanThreeDays = (reportDate) => {
    const currentDate = new Date();
    const reportDateObj = new Date(reportDate);
    const diffInTime = currentDate.getTime() - reportDateObj.getTime();
    const diffInDays = diffInTime / (1000 * 3600 * 24); // Convert time difference to days
    return diffInDays > 3;
  };

  return (
    <div class="flood__report--container">
      {popUp && (
        <Confirmpopup
          show={popUp}
          handleToggle={handleTogglePopup}
          title="Confirmation"
          message="Are You sure to clear"
          cancel="No"
          success="Yes"
          onClickSucesshandler={onClickSucesshandler}
        />
      )}
      <Loader
        show={loader}
        type="body"
        stack="vertical"
        message="Loading Data"
      />
      <div className="ps-2 d-flex">
        <div className="me-2 region_type_container">
          <label className="mb-2">{t("floodreport.district")}</label>
          <div>
            <SelectComponent
              id="district_drop"
              value={selectedDistrictId}
              placeholder="All District"
              options={districtData}
              onChange={getDistrictDataByCode}
              getOptionLabel={(option) => option.label}
              getOptionValue={(option) => option.value}
            />
          </div>
        </div>

        <div className="me-2 region_type_container">
          <label className="mb-2">Block</label>
          <SelectComponent
            id="block_drop"
            value={selectedBlockId}
            onChange={setSelectedBlockIdVal}
            placeholder="All Blocks"
            options={blockData}
          />
        </div>

        <div>
          <label className="mb-2">{t("floodreport.reported_on")}</label>
          <div className="date_picker_flood_container me-1">
            <DatePicker
              selected={selectedDate}
              maxDate={new Date().toLocaleDateString('en-CA')}
              name="flood_date_picker"
              onChange={(date) => {
                console.log("Selected date:", date);
                setSelectedDate(date);
                // No automatic data fetch here
              }}
              className="form-control date-picker date_picker_flood"
            />
          </div>
        </div>
        <button
          className="btn btn-success ms-2 btn-sm flood__report--button"
          onClick={handleApplyClick}>
          {t("floodreport.buttonapply")}
        </button>
        <button
          className="btn btn-success btn-sm flood__report--button"
          onClick={downloadPDF}>
          {t("floodreport.download")}
        </button>
        <button
          className="btn btn-success btn-sm flood__report--button"
          onClick={() => editFloodData()}
          disabled={rolName !== process.env.REACT_APP_SUPER_ADMIN && isOlderThanThreeDays(selectedDate)}>
          {t("floodreport.edit_btl_lbl")}
        </button>
        <button
          className="btn btn-success btn-sm flood__report--button"
          onClick={() => clearFloodData()}
          disabled={rolName !== process.env.REACT_APP_SUPER_ADMIN}>
          {t("floodreport.clear_btl_lbl")}
        </button>
      </div>
      {/* <div className="pe-3" ref={pdfRef}> */}
        {/* <div className="text-center fw-bold d-none">
          Daily Flood Report Dated on ({getFormattedDate(selectedDate)})
        </div> */}
        {/* <h1>Incident Report Form</h1> */}

<table table id="report_table" class="flood__report--table" border="2" style={{marginLeft: "0px"}}>
  <colgroup>
  <col style={{width:"4%"}}/>
  <col style={{width:"20%"}}/>
  <col style={{width:"30%"}}/>
  <col style={{width:"23%"}}/>
  <col style={{width:"23%"}}/>
  </colgroup>
<tr>
<td colspan="4" className="table_header">Name of the District:   {dailyReport?.[0]?.dist_name || selectedDistrictDisplayName || "NA"}</td>
<td className="table_header">Date:     {selectedDateFormatted}</td>
</tr>
{/* <tr>
  <td className="table_header">S. No</td>
  <td colspan="4" className="table_header">Field Name</td>
</tr> */}
<tr>
  <td>1</td>
  <td>Block</td>
  <td colspan="3">{dailyReport?.[0]?.block_name || "NA"}</td>
</tr>
 
<tr>
  <td>2</td>
  <td colspan="3">Number of incidents (Today)</td>
  <td>{dailyReport?.[0]?.no_of_incidents ?? ""}</td>
  </tr>
<tr>
  <td>3</td>
  <td colspan="3">Number of incidents (Cumulative)</td>
  <td>{dailyReport?.[0]?.no_of_incidents_cum ?? ""}</td>
  </tr>
<tr>
  <td>3</td>
  <td>Addresses of the places of incidents</td>
  <td colspan="3">{dailyReport?.[0]?.address || "NA"}</td>
</tr>
<tr>
<td rowSpan="4">4</td>
<td colspan="2" className="table_header">Number of deaths</td>
<td className="table_header">Today</td>
<td className="table_header">Cumulative</td>
</tr>
<tr>
<td colspan="2">Human</td>
<td>{dailyReport?.[0]?.no_of_human_deaths_today ?? ""}</td>
<td>{dailyReport?.[0]?.no_of_human_deaths_cum ?? ""}</td>
</tr>
<tr>
<td colspan="2">Cattle</td>
<td>{dailyReport?.[0]?.no_of_cattle_deaths_today ?? ""}</td>
<td>{dailyReport?.[0]?.no_of_cattle_deaths_cum ?? ""}</td>
</tr>
<tr>
<td colspan="2">Poultry</td>
<td>{dailyReport?.[0]?.no_of_poultry_deaths_today ?? ""}</td>
<td>{dailyReport?.[0]?.no_of_poultry_deaths_cum ?? ""}</td>
</tr>
<tr>
<td rowSpan="4">5</td>
<td colspan="2" className="table_header">Number of injured</td>
<td className="table_header">Today</td>
<td className="table_header">Cumulative</td>
</tr>
<tr>
<td colspan="2">Human</td>
<td>{dailyReport?.[0]?.no_of_human_injured_today ?? ""}</td>
<td>{dailyReport?.[0]?.no_of_human_injured_cum ?? ""}</td>
</tr>
<tr>
<td colspan="2">Cattle</td>
<td>{dailyReport?.[0]?.no_of_cattle_injured_today ?? ""}</td>
<td>{dailyReport?.[0]?.no_of_cattle_injured_cum ?? ""}</td>
</tr>
<tr>
<td colspan="2">Poultry</td>
<td>{dailyReport?.[0]?.no_of_poultry_injured_today ?? ""}</td>
<td>{dailyReport?.[0]?.no_of_poultry_injured_cum ?? ""}</td>
</tr>
<tr>
<td rowSpan="4">6</td>
<td colspan="2" className="table_header">Ex-gratia details</td>
<td className="table_header">Today</td>
<td className="table_header">Cumulative</td>
</tr>
<tr>
<td colspan="2">Number of deceased for whom payment of ex-gratia done</td>
<td>{dailyReport?.[0]?.no_of_deceased_pay_exgra_done_today ?? ""}</td>
<td>{dailyReport?.[0]?.no_of_deceased_pay_exgra_done_cum ?? ""}</td>
</tr>
<tr>
<td colspan="2">Number of dead cattles for which payment done</td>
<td>{dailyReport?.[0]?.no_of_dead_cattles_pay_done_today ?? ""}</td>
<td>{dailyReport?.[0]?.no_of_dead_cattles_pay_done_cum ?? ""}</td>
</tr>
<tr>
<td colspan="2">Number of dead poultry for which payment done</td>
<td>{dailyReport?.[0]?.no_of_dead_poultry_pay_done_today ?? ""}</td>
<td>{dailyReport?.[0]?.no_of_dead_poultry_pay_done_cum ?? ""}</td>
</tr>
<tr>
<td rowSpan="7">7</td>
<td colspan="2" className="table_header">Number of damaged houses</td>
<td className="table_header">Today</td>
<td className="table_header">Cumulative</td>
</tr>
<tr>
<td rowSpan="2">Concrete</td>
<td>Complete</td>
<td>{dailyReport?.[0]?.concrete_complete ?? ""}</td>
<td>{dailyReport?.[0]?.concrete_complete_cum ?? ""}</td>
</tr>
<tr>
<td>Partial</td>
<td>{dailyReport?.[0]?.concrete_partial ?? ""}</td>
<td>{dailyReport?.[0]?.concrete_partial_cum ?? ""}</td>
</tr>
<tr>
<td rowSpan="2">Kutcha</td>
<td>Complete</td>
<td>{dailyReport?.[0]?.kutcha_complete ?? ""}</td>
<td>{dailyReport?.[0]?.kutcha_complete_cum ?? ""}</td>
</tr>
<tr>
<td>Partial</td>
<td>{dailyReport?.[0]?.kutcha_partial ?? ""}</td>
<td>{dailyReport?.[0]?.kutcha_partial_cum ?? ""}</td>
</tr>

<tr>
<td colspan="2">Cottage/Hut</td>
<td>{dailyReport?.[0]?.cottage_hut ?? ""}</td>
<td>{dailyReport?.[0]?.cottage_hut_cum ?? ""}</td>
</tr>
<tr>
<td colspan="2">Total number of damaged houses</td>
<td>{dailyReport?.[0]?.total ?? ""}</td>
<td>{dailyReport?.[0]?.total_cum ?? ""}</td>
</tr>
<tr>
<td rowSpan="7">8</td>
<td colspan="2" className="table_header">Number of damaged houses for which payment done</td>
<td className="table_header">Today</td>
<td className="table_header">Cumulative</td>
</tr>
<tr>
<td rowSpan="2">Concrete</td>
<td>Complete</td>
<td>{dailyReport?.[0]?.concrete_complete_pay_done_today ?? ""}</td>
<td>{dailyReport?.[0]?.concrete_complete_pay_done_cum ?? ""}</td>
</tr>
<tr>
<td>Partial</td>
<td>{dailyReport?.[0]?.concrete_partial_pay_done_today ?? ""}</td>
<td>{dailyReport?.[0]?.concrete_partial_pay_done_cum ?? ""}</td>
</tr>
<tr>
<td rowSpan="2">Kutcha</td>
<td>Complete</td>
<td>{dailyReport?.[0]?.kutcha_complete_pay_done_today ?? ""}</td>
<td>{dailyReport?.[0]?.kutcha_complete_pay_done_cum ?? ""}</td>
</tr>
<tr>
<td>Partial</td>
<td>{dailyReport?.[0]?.kutcha_partial_pay_done_today ?? ""}</td>
<td>{dailyReport?.[0]?.kutcha_partial_pay_done_cum ?? ""}</td>
</tr>
<tr>
<td colspan="2">Cottage/Hut</td>
<td>{dailyReport?.[0]?.cottage_hut_pay_done_today ?? ""}</td>
<td>{dailyReport?.[0]?.cottage_hut_pay_done_cum ?? ""}</td>
</tr>
<tr>
<td colspan="2">Total number of damaged houses for which payment done</td>
<td>{dailyReport?.[0]?.today_pay_done_today ?? ""}</td>
<td>{dailyReport?.[0]?.today_pay_done_cum ?? ""}</td>
</tr>
 
<tr>
  <td rowSpan="4">9</td>
  <td colspan="2" className="table_header">Cattle shed details and Compensation for damaged house/hut/cattle shed</td>
  <td className="table_header">Today</td>
  <td className="table_header">Cumulative</td>
  </tr>
  <tr>
  <td colspan="2">Number of damaged cattle sheds</td>
  <td>{dailyReport?.[0]?.no_of_dam_cattle_sheds_today ?? ""}</td>
  <td>{dailyReport?.[0]?.no_of_dam_cattle_sheds_cum ?? ""}</td>
  </tr>
  <tr>
  <td colspan="2">Number of damaged cattle sheds for which payment done</td>
  <td>{dailyReport?.[0]?.no_of_dam_cattle_sheds_pay_done_today ?? ""}</td>
  <td>{dailyReport?.[0]?.no_of_dam_cattle_sheds_pay_done_cum ?? ""}</td>
  </tr>
  <tr>
  <td colspan="2">Amount paid against damaged house/hut/cattle shed (Rs.)</td>
  <td>{dailyReport?.[0]?.amount_paid_dam_hous_hut_cattle_today ?? ""}</td>
  <td>{dailyReport?.[0]?.amount_paid_dam_hous_hut_cattle_cum ?? ""}</td>
  </tr>
 
  <tr>
    <td rowSpan="4">10</td>
    <td>Crop Type</td>
    <td colspan="3">{dailyReport?.[0]?.crop_type || "NA"}</td>
    </tr>

    <tr>
    <td colspan="2" className="table_header">Damaged Crop</td>
    <td className="table_header">Today</td>
    <td className="table_header">Cumulative</td>
    </tr>

    <tr>
    <td colspan="2">Area of damaged crop (in acre)</td>
    <td>{dailyReport?.[0]?.damaged_crop_area_affected ?? ""}</td>
    <td>{dailyReport?.[0]?.damaged_crop_area_affected_cum ?? ""}</td>
    </tr>
    <tr>
    <td colspan="2">Estimated value of damaged crops (Rs.)</td>
    <td>{dailyReport?.[0]?.estimated_value_of_damaged_crops_today ?? ""}</td>
    <td>{dailyReport?.[0]?.estimated_value_of_damaged_crops_cum ?? ""}</td>
    </tr>
   
    <tr>
      <td rowSpan="7">11</td>
      <td colspan="2" className="table_header">Relief Distribution</td>
      <td className="table_header">Today</td>
      <td className="table_header">Cumulative</td>
      </tr>
      <tr>
      <td colspan="2">Amount paid under the agriculture input subsidy (Rs)</td>
      <td>{dailyReport?.[0]?.amount_paid_agricultural_input_sub_today ?? ""}</td>
      <td>{dailyReport?.[0]?.amount_paid_agricultural_input_sub_cum ?? ""}</td>
      </tr>
      <tr>
      <td colspan="2">Area of crop for which agriculture input subsidy paid (in acre)</td>
      <td>{dailyReport?.[0]?.area_crop_agricultural_input_sub_paid_today ?? ""}</td>
      <td>{dailyReport?.[0]?.area_crop_agricultural_input_sub_paid_cum ?? ""}</td>
      </tr>
      <tr>
      <td colspan="2">Total amount of GR paid @Rs. 7000 per family</td>
      <td>{dailyReport?.[0]?.total_amount_gr_paid_7k_per_fam_today ?? ""}</td>
      <td>{dailyReport?.[0]?.total_amount_gr_paid_7k_per_fam_cum ?? ""}</td>
      </tr>
      <tr>
      <td colspan="2">Total amount paid for cloth/utensils etc., @5000 per family (Rs)</td>
      <td>{dailyReport?.[0]?.total_amount_cloth_paid_5k_per_fam_today ?? ""}</td>
      <td>{dailyReport?.[0]?.total_amount_cloth_paid_5k_per_fam_cum ?? ""}</td>
      </tr>
      <tr>
      <td colspan="2">Number of polythene sheets distributed</td>
      <td>{dailyReport?.[0]?.number_of_polythene_sheets_distributed_today ?? ""}</td>
      <td>{dailyReport?.[0]?.number_of_polythene_sheets_distributed_cum ?? ""}</td>
      </tr>
      <tr>
      <td colspan="2">Quantity of Dry ration distributed (Kg.)</td>
      <td>{dailyReport?.[0]?.quantity_dry_ration_distributed_today ?? ""}</td>
      <td>{dailyReport?.[0]?.quantity_dry_ration_distributed_cum ?? ""}</td>
      </tr>
      <tr>
        <td>12</td>
        <td>Remarks</td>
        <td colspan="3">{dailyReport?.[0]?.remarks || "NA"}</td>
      </tr>
 
</table>
      </div>
  );
};

export default FireIncidentReportForm;