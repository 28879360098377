import React, { useEffect, useState } from 'react';
import { Deleteicon } from '../../../../../Components/IconComponents';
import { deleteAddressFieldData } from '../../../../../Services/EndPoints';
import { deleteDataById } from '../../../../../Services/Services';
import Confirmpopup from '../../ConfirmPopup/Confirmpopup';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

const InputWithDynamicFields = ({ 
  name, 
  label, 
  register,
  setValue, // Add setValue from react-hook-form
  errors,
  mandatory = false, 
  maxLength = 100,
  type = 'text',
  disabled = false,
  style,
  addresses = []
}) => {
  const [fields, setFields] = useState([{ value: "", id: null }]);
  const [isdelete, setIsdelete] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    if (addresses.length > 0) {
      const newFields = addresses.map(address => ({ value: address.address, id: address.id }));
      setFields(newFields);
      // Set initial form values
      newFields.forEach((field, index) => {
        setValue(`${name}_${index}`, field.value);
        setValue(`${name}_hidden_${index}`, field.id || "");
      });
    }
  }, [addresses, setValue, name]);

  
  const addField = () => {
    const newFields = [...fields, { value: "", id: null }];
    console.log("NEWADDFIELDS",newFields);
    setFields(newFields);
  
    const newIndex = newFields.length - 1;
    setValue(`${name}_${newIndex}`, "");
    setValue(`${name}_hidden_${newIndex}`, ""); // Ensure ID is cleared
  };
  

  const removeField = (index) => {
    const field = fields[index];
    
    if (field.id) {
      deletePopUp(field.id);
    } else {
      const updatedFields = [...fields];
      updatedFields.splice(index, 1);
  
      // Remove all related field names
      setValue(`${name}_${index}`, ""); 
      setValue(`${name}_hidden_${index}`, ""); 
      setValue(`${name}_${index}_id`, ""); 
  
      // Re-index remaining fields
      updatedFields.forEach((field, idx) => {
        setValue(`${name}_${idx}`, field.value);
        setValue(`${name}_hidden_${idx}`, field.id || "");
        setValue(`${name}_${idx}_id`, field.id || "");
      });
  
      setFields(updatedFields);
    }
  };
  
  

  const deletePopUp = (id) => {
    setSelectedId(id);
    setIsdelete(!isdelete);
  };

  const deleteTableDataById = async () => {
    try {
      const response = await deleteDataById(deleteAddressFieldData, selectedId);
      if (response?.status === "success") {
        const indexToRemove = fields.findIndex(field => field.id === selectedId);
        if (indexToRemove !== -1) {
          const updatedFields = [...fields];
          updatedFields.splice(indexToRemove, 1);
  
          // Remove all related field names
          setValue(`${name}_${indexToRemove}`, ""); 
          setValue(`${name}_hidden_${indexToRemove}`, ""); 
          setValue(`${name}_${indexToRemove}_id`, ""); 
  
          // Re-index remaining fields
          updatedFields.forEach((field, idx) => {
            setValue(`${name}_${idx}`, field.value);
            setValue(`${name}_hidden_${idx}`, field.id || "");
            setValue(`${name}_${idx}_id`, field.id || "");
          });
  
          console.log("UPDATEDFIELDS", updatedFields);
          setFields(updatedFields);
        }
  
        toast.success(response.msg, {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 2000,
        });
      } else {
        toast.error(response.msg || t("delete_failure_message"), {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 2000,
        });
      }
    } catch (error) {
      toast.error(t("delete_error_message"), {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 2000,
      });
      console.error("Delete error:", error);
    } finally {
      setIsdelete(false);
      setSelectedId(null);
    }
  };
  
  


  const handleInputChange = (index, newValue) => {
    const updatedFields = [...fields];
    
    // If a new address is being typed, ensure ID is reset
    updatedFields[index] = { value: newValue, id: fields[index].id ? null : null };
  
    setFields(updatedFields);
    setValue(`${name}_${index}`, newValue);
    setValue(`${name}_hidden_${index}`, ""); // Ensure ID is reset
  };
  

  return (
    <div className="dynamic-input-container">
      <div className="input_text-form flexbox">
        <label>
          {label} 
          {mandatory && <span className="asterisk">*</span>}
        </label>
      </div>

      {isdelete && (
        <Confirmpopup
          className="DeltePopup"
          show={isdelete}
          title={t("deletepopuptext")}
          message={t("deletepopupmessage")}
          handleToggle={deletePopUp}
          deleteRow={deleteTableDataById}
          cancel={t("cancelbutton")}
          action={t("actionbutton")}
        />
      )}

      <table className="dynamic-table">
        <thead>
          <tr>
            <th>{label}</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {fields.map((field, index) => (
            <tr key={index}>
              <td>
                <input
                  {...register(`${name}_${index}`)}
                  type={type}
                  maxLength={maxLength}
                  className={`form-control ${errors[`${name}_${index}`] ? "error__border--color" : "form__input"}`}
                  disabled={disabled}
                  style={{
                    ...style,
                    padding: "6px",
                    margin: "2px 0",
                  }}
                  value={field.value}
                  onChange={(e) => handleInputChange(index, e.target.value)}
                />
                <input
                  type="hidden"
                  {...register(`${name}_hidden_${index}`)}
                  value={field.id || ""}
                />
              </td>
              <td>
                <button
                  type="button"
                  onClick={() => removeField(index)}
                  className="btn btn-danger btn-sm"
                  style={{
                    padding: "2px 5px",
                    fontSize: "12px",
                    height: "22px",
                    minWidth: "28px",
                    width: "28px",
                  }}
                >
                  <Deleteicon />
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <button
        type="button"
        onClick={addField}
        className="btn btn-success btn-sm"
        style={{
          padding: "6px 10px",
          fontSize: "14px",
          height: "30px",
          minWidth: "100px",
          width: "auto",
          marginTop: "10px",
        }}
      >
        Add Address
      </button>
    </div>
  );
};

export default InputWithDynamicFields;