import React, { useEffect } from "react";
import DMSHeader from "../../DMSHeader/DMSHeader";
//import DailyFloodReportCompTable from "./DailyFloodReportCompTable";
import { useTranslation } from "react-i18next";
import DailyColdWaveReportForm from "./DailyColdWaveReportForm";

const DailyColdWaveReport = () => {
  const { t } = useTranslation();

  return (
    <div>
      <div className="widget-container">
        <DMSHeader title="Cold wave casualty and relief distribution daily report" />
      </div>
      <div className="widget-container p-1">
        <DailyColdWaveReportForm />
      </div>
    </div>
  );
};

export default DailyColdWaveReport;
