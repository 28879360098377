import {
  faPerson,
  faCow,
  faKiwiBird,
  faSkullCrossbones,
  faUserInjured,
  faHouseDamage,
  faBuilding,
  faHouseChimney,
  faHome,
  faWheatAlt,
  faSheetPlastic,
  faBowlRice,
  faHouseChimneyWindow,
  faWarehouse
} from "@fortawesome/free-solid-svg-icons";

export const dashboardCardConfigForFire = [
  {
    title: "",
    data: [
      {
        label: "Number of deaths",
        key: "no_of_deaths_count",
        icon: faSkullCrossbones,
        subData: [
          { label: "Human", key: "no_of_human_deaths_today", icon: faPerson },
          { label: "Cattle", key: "no_of_cattle_deaths_today", icon: faCow },
          { label: "Poultry", key: "no_of_poultry_deaths_today", icon: faKiwiBird },
        ],
      },
      {
        label: "Number of Injured",
        key: "no_of_injured_count",
        icon: faUserInjured,
        subData: [
          { label: "Human", key: "no_of_human_injured_today", icon: faPerson },
          { label: "Cattle", key: "no_of_cattle_injured_today", icon: faCow },
          { label: "Poultry", key: "no_of_poultry_injured_today", icon: faKiwiBird },
        ],
      },
      {
        label: "Number of Damaged Houses",
        key: "no_of_damaged_houses",
        icon: faHouseDamage,
        subData: [
          {
            label: "Concrete",
            key: "concrete_count",
            icon: faBuilding,
            subData: [
              { label: "Complete", key: "concrete_complete" },
              { label: "Partial", key: "concrete_partial" },
            ],
          },
          {
            label: "Kutcha",
            key: "kutcha_count",
            icon: faHouseChimney,
            subData: [
              { label: "Complete", key: "kutcha_complete" },
              { label: "Partial", key: "kutcha_partial" },
            ],
          },
          {
            label: "Cottage/Hut",
            key: "cottage_hut",
            icon: faHome,
          },
        ],
      },
      {
        label: "Number of damaged cattle sheds ",
        key: "no_of_dam_cattle_sheds_today",
        icon: faWarehouse,
      },
      {
        label: "Area of damaged crop (in acre)",
        key: "damaged_crop_area_affected",
        icon: faWheatAlt,
      },
      {
        label: "Number of polythene sheets distributed",
        key: "number_of_polythene_sheets_distributed_today",
        icon: faSheetPlastic,
      },
      {
        label: "Quantity of Dry ration distributed (kg)",
        key: "quantity_dry_ration_distributed_today",
        icon: faBowlRice,
      },
      {
        label: "Number of Kitchen Running",
        key: "no_of_kitchens_run",
        icon: faHouseChimneyWindow,
      },
    ],
  },
];
