import React, { useState, useEffect, useContext } from "react";
import { useForm, useWatch } from "react-hook-form";
import { useNavigate, useLocation } from "react-router-dom";
import { Button } from "react-bootstrap";
import FormHandler from "../../Common/Form/FormHandler/FormHandler";
import  "./FireCommunityKitchenRegForm.css";
import { useKeycloak } from "@react-keycloak/web";
import { toast, ToastContainer } from "react-toastify";
import * as Yup from "yup";
import {
  postDataWithBody,
  getData,
  
  putDataWithBody,
  postDataWithBodyFrGIS,
} from "../../../../Services/Services";
import {
  getUsersFromApp,
  getAllRole,
  createTaskandAssign,
  createNewTask,
  updateColdWaveDetails,
  updateFireCommunityKitchenForm
} from "../../../../Services/EndPoints";
import { yupResolver } from "@hookform/resolvers/yup";
import { HeatWaveFormBConfig } from "../../../../JsonData/HeatWaveFormBConfig";
import moment from "moment";
import { RoleContext } from "../../../..";
import { Fire } from "react-bootstrap-icons";
import {FireCommunityKitchenFormConfig } from "../../../../JsonData/FireCommunityKitchenConfig";

const validationSchema = Yup.object().shape({
  date: Yup.string().required("Date is required"),
  district_name: Yup.string().required("District Name is required"),
  block_name: Yup.string().required("Block Name is required"),

  today_number_of_kitchen_running: Yup.number()
    .typeError("Must be a number")
    .required("Number of kitchens is required")
    // .min(0, "Cannot be negative")
    .integer("Must be a whole number"),
  
    lunch_today: Yup.number()
    .typeError("Must be a number")
    .required("Morning food quantity is required")
    // .positive("Must be a positive number")
    .integer("Must be a whole number"),
  
    dinner_today: Yup.number()
    .typeError("Must be a number")
    .required("Evening food quantity is required")
    // .positive("Must be a positive number")
    .integer("Must be a whole number"),
  
 

});

const FireCommunityKitchenRegForm = () => {
    const { roleName } = useContext(RoleContext);
  const { keycloak } = useKeycloak();
  const [activeTab, setActiveTab] = useState(0);
  const [loader, setLoader] = useState(false);
//   const [dailyColdWaveFormConfig, setDailyColdWaveFormConfig] = useState(
//     dailyColdWaveFormConfig
//   );
  const navigate = useNavigate();
  const { state } = useLocation();
  const location = useLocation();
  const {isTextFieldDisable } = location.state || {};
  let dataFromTable = state?.data;

  let date = state?.date;

  let districtNameValue = state?.districtNameValue;

  console.log("HeatWaveFormB Table",dataFromTable);
  console.log("HeatWaveFormBDate",date);
  console.log("DistrictNameValue",districtNameValue);

  const [lgShow, setLgShow] = useState(false);

 
  const {
    register,
    handleSubmit,
    setValue,
    getValues,

    reset,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
    mode: "all",
  });
  const assign_type = useWatch({
    name: "assign_type",
    control,
  });

  // Near the top of your component, after your useForm setup, add this:
const lunch_today = useWatch({
  control,
  name: "lunch_today",
  defaultValue: 0
});

const dinner_today = useWatch({
  control,
  name: "dinner_today",
  defaultValue: 0
});
  

// Add this effect to calculate and update total
useEffect(() => {
  const morningValue = Number(lunch_today) || 0;
  const eveningValue = Number(dinner_today) || 0;
  setValue('total_today', morningValue + eveningValue);
}, [lunch_today, dinner_today, setValue]);

 // Use setValue to reset form data
 useEffect(() => {


  const formatDate = (timestamp) => {
    if (!timestamp) return "Invalid Date"; // Handle null, undefined, or falsy timestamps
    
    const date = new Date(timestamp);
  
    // Check if the date is invalid (e.g., `Invalid Date`)
    if (isNaN(date.getTime())) return "Invalid Date";
  
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
  
    return `${year}-${month}-${day}`;
  };
  
  const formattedDate = formatDate(dataFromTable?.reported_on);
  console.log("FORMATTED2025",formattedDate)


  if (dataFromTable) {
    setValue("date", formattedDate); // Assuming `date` is already calculated elsewhere
    setValue("district_name", dataFromTable?.dist_name); // Assuming `districtNameValue` is calculated elsewhere
    setValue("block_name", dataFromTable?.block_name);




    // Map the remaining fields
    setValue(
      "today_number_of_kitchen_running",
      dataFromTable?.today_no_of_kitchens_run === null
    ? "NA"
    : dataFromTable?.today_no_of_kitchens_run ?? 0
    );
    setValue(
      "lunch_today",
      dataFromTable?.today_mrng_food ?? 0
    );
    setValue(
      "dinner_today",
      dataFromTable?.today_evng_food ?? 0
    );
    // setValue(
    //   "total_today",
    //   calculatedTodayTotal || ""
    // );
    setValue(
      "lunch_cumulative",
      dataFromTable?.till_mrng_food ?? 0
    );
    setValue(
      "dinner_cumulative",
      dataFromTable?.till_eveng_food ?? 0
    );
    setValue(
      "total_cumulative",
      dataFromTable?.till_today_total ?? 0
    );
  }
}, [dataFromTable, setValue, date, districtNameValue]);

  const GenerateForm = (fields, register, errors, control) => {
    return (
      <div>
        
          <FormHandler
            register={register}
            errors={errors}
            fields={fields}
            control={control}
            columns={2}
            getValues={getValues}
            isTextFieldDisable={isTextFieldDisable}
            roleName={roleName}
          />
     
      </div>
    );
  };

  

  const generateTabsData = (tabWiseData) => {
    tabWiseData?.forEach((element) => {
      return (element.component = GenerateForm(
        element?.fields,
        register,
        errors,
        control
      ));
    });
    return tabWiseData;
  };

  
  const submitHandler = async (data, e) => {
    e.preventDefault(); // Prevent default form submission
    console.log("Fire CK Form Submit", data);
  
    setLoader(true); // Show loader
  
    // Transform the form data into the required API input format
    const input = {
      id: dataFromTable.id, // Assuming `id` is static or obtained elsewhere
      reported_date: data.date,
      distName: data.district_name,
      blockName: data.block_name,
      region_id: dataFromTable.region_id, // Assuming `region_id` is static or obtained elsewhere
      
      todayNoOfKitchensRun: data.today_number_of_kitchen_running,
      todayMrngFood: data.lunch_today,
      todayEvngFood: data.dinner_today,
      todayTotal: data.total_today,
      is_submitted: true
    };
  
    console.log("Formatted Input for API:", input);
  
    try {
      const res = await postDataWithBody(
        updateFireCommunityKitchenForm.replace("DATE", data.date), // Replace placeholder with actual date
        input,
        {}
      );
  
      if (res.status === "success") {
        toast.success(res.msg, {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 2000, // Close after 2 seconds
        });
        setLoader(false);
        navigate("/reports/fire-community-kitchen-report"); // Navigate to the specified route
      } else {
        toast.error(res.msg, {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 2000, // Close after 2 seconds
        });
        setLoader(false);
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      toast.error("An error occurred while submitting the form.", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 2000, // Close after 2 seconds
      });
      setLoader(false);
    }
  };
  
  
  
  

  const tabsData = generateTabsData(FireCommunityKitchenFormConfig);
  return (
    <div className="mt-4 facility-container">
    

      <div className="form-body">
        <div className="registration__form--body">
          <form
            onSubmit={handleSubmit(submitHandler)}
            name="CreateFacilityRegForm"
          >
            <div>
              {tabsData?.map((tab) => (
                <>
                  {tab.id === activeTab && (
                    <>
                      <div>{tab.component}</div>
                    
                    </>
                  )}
                </>
              ))}
            </div>
            <div className="d-flex justify-content-center">
              {tabsData.length - 1 === activeTab && (
                <Button type="submit" id="submitBtn" className="mx-2" variant="primary">
                  Update
                </Button>
              )}
            </div>
          </form>
        </div>
      </div>
      
    </div>
  );
};

export default FireCommunityKitchenRegForm;
