import * as React from "react";
const SvgTasksSopIcons = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    data-name="Layer 1"
    viewBox="0 0 24 24"
    width="1em"
    height="1em"
    fill="currentColor"
    {...props}
  >
    <path d="M16.5 18H16V4.5C16 2.019 13.981 0 11.5 0h-7A4.505 4.505 0 0 0 0 4.5v16A3.503 3.503 0 0 0 3.498 24H16c1.654 0 3-1.346 3-3v-.5c0-1.379-1.121-2.5-2.5-2.5M5 20.5c0 .827-.673 1.5-1.5 1.5S2 21.327 2 20.5v-16C2 3.121 3.121 2 4.5 2h7C12.879 2 14 3.121 14 4.5V18H7.5A2.5 2.5 0 0 0 5 20.5m12 .5a1 1 0 0 1-1 1H6.662c.217-.455.338-.964.338-1.5 0-.275.225-.5.5-.5h9c.275 0 .5.225.5.5zm4-21c-1.654 0-3 1.346-3 3v10.343c0 1.336.521 2.592 1.465 3.536l.828.828a.997.997 0 0 0 1.414 0l.828-.828A4.97 4.97 0 0 0 24 13.343V3c0-1.654-1.346-3-3-3m1 13.343c0 .802-.313 1.556-.879 2.122l-.121.121-.121-.121A2.98 2.98 0 0 1 20 13.343V3a1 1 0 0 1 2 0zM3.293 7.097a.999.999 0 1 1 1.414-1.414l1.288 1.289c.033.033.105.033.139 0l2.179-2.179a.999.999 0 1 1 1.414 1.414L7.548 8.386c-.409.409-.946.613-1.483.613s-1.074-.204-1.483-.613zm4.255 7.289c-.409.409-.946.613-1.483.613s-1.074-.204-1.483-.613l-1.288-1.289a.999.999 0 1 1 1.414-1.414l1.288 1.289c.033.033.105.033.139 0l2.179-2.179a.999.999 0 1 1 1.414 1.414z" />
  </svg>
);
export default SvgTasksSopIcons;
