import * as React from "react";
const SvgIncidentFacility = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 512 512"
    fill= "currentColor"
    {...props}
  >
    <path  d="M258.5 20.7c-1.6.2-7.7 1.5-13.4 2.9-49.9 12.3-87.4 53-95.7 103.8l-1.7 10.6H95.2l-3.1 2.6-3.1 2.6v77.5l2.6 2.7c4 3.9 10.3 3.7 13.8-.5l2.6-3.1V158h39.7l1.6 9.7c4.6 27.9 17.1 51.7 37.6 71.6 14.9 14.4 30.6 24 49.5 30.1 25.7 8.4 56.6 8 81.8-1 43.8-15.7 76.3-54.7 83.6-100.2l1.6-10.2H443v62.2l2.9 2.9c5.8 5.8 15.1 2.9 16.6-5.2.3-1.9.5-19.3.3-38.7l-.3-35.4-3.3-2.9-3.2-2.9h-52.5l-1.7-10.3C397.3 100.2 386 78 366 57.9c-19.7-19.9-42.2-31.7-69.4-36.4-8.1-1.4-30.1-1.9-38.1-.8m38.5 21c39 7.8 71.4 37.5 82.4 75.7 18.2 62.6-22.4 126.7-86.9 137.1-47.5 7.7-95.9-18.3-115.6-62.2-19-42.3-9.2-91 24.6-123.2C227 44.9 262.3 34.7 297 41.7" />
    <path  d="M250.7 80.1c-3.9 2.3-4.7 6.1-4.7 22.4V118h-15.5c-16.5 0-20.3.8-22.5 4.9-.6 1.2-1 11.2-1 25.1v23.2l2.9 2.9 2.9 2.9H246v33l2.9 3.2 2.9 3.3h47.4l2.9-3.3 2.9-3.2v-33h33l3.2-2.9 3.3-2.9.3-19.2c.4-22.3-.1-27-3.3-30.8l-2.3-2.7-17.1-.3-17.1-.3V84.8l-2.9-2.9-2.9-2.9h-23.4c-13.9 0-24 .5-25.1 1.1m34.3 33.1c0 12.6.3 15.9 1.8 18.8 2.7 5.4 4.8 6 22.5 6h15.8l-.3 9.7-.3 9.8-17.1.5c-12.3.4-17.6.9-18.7 1.9-2.8 2.2-3.7 8-3.7 22.8V197h-19v-15.8c0-13.9-.2-16.1-2-19-1.1-1.7-2.1-3.3-2.2-3.4-.2-.1-8.2-.4-17.8-.7l-17.5-.6-.3-9.7-.3-9.7 17-.3 17.1-.3 2.7-2.8 2.8-2.7.3-17 .3-17H285zM442.5 269.3c-2.2.8-5.4 2.1-7 2.9-1.7.9-25.1 18.6-52 39.3l-49 37.6-1.1-4.4c-2.9-11.4-10.8-20.6-22.2-26l-6.7-3.2-83.2-.3-83.3-.3v-10.5c0-11.1-1.2-15.4-4.9-17.4-1.2-.6-12.7-1-30-1-27.6 0-28 0-30.6 2.3-2.4 2-5.1 11.4-27.6 96.7C31.3 437 20 481 20 482.8c0 2.4.8 4.2 2.9 6.3l2.9 2.9h52.7c34.2 0 53.3-.4 54.6-1 3.7-2 4.9-6.3 4.9-17.2v-10.4l6.3.4c70.2 4.1 161.6 8.8 163.1 8.4 2.4-.7 169.6-134.3 174.8-139.8 2-2.2 5-6.6 6.5-9.9 2.5-5.3 2.8-7.1 2.8-16s-.3-10.7-2.9-16.2c-3.5-7.7-11.3-15.4-19.3-19.1-6.9-3.1-20.2-4-26.8-1.9m20.8 21.1c8.5 4.6 11.7 16.6 6.8 25.2-1.8 3.2-20.8 18.9-85.5 70.7l-83.1 66.5-21.5-1.3c-11.8-.7-48.6-2.8-81.7-4.6l-60.3-3.3V335h81c77.7 0 81.2.1 84.8 1.9 14.5 7.4 14.9 27.1.7 34.9-2.7 1.5-9.6 1.8-62.2 2.2l-59.3.5-2.7 2.8c-3.8 3.8-3.8 9.6 0 13.4l2.7 2.8 56.8.3c61 .3 64.9.1 73.7-4.7 2.8-1.5 32.6-23.9 66.3-49.9 33.7-25.9 62.4-47.9 63.7-48.8 5.7-3.7 13.1-3.8 19.8 0M118 389v84H80.5c-34.2 0-37.5-.1-37.5-1.6 0-1 9.8-38.8 21.7-84L86.3 305H118z" />
    <path  d="M84.5 434.2c-5.8 3.2-7.3 10.6-3.1 15.5 2 2.3 3.2 2.8 7.1 2.8s5.1-.5 7.1-2.8c2.6-3 3.2-8.3 1.4-11.8-1.2-2.2-6.1-4.9-8.7-4.9-1 0-2.7.6-3.8 1.2" />
  </svg>
);
export default SvgIncidentFacility;
