import * as yup from "yup";

const decimalValidation = yup
  .number()
  .typeError("This field must be a valid number") // Custom error for non-numeric input
  .transform((value, originalValue) => {
    if (originalValue === "") return null; // Treat empty string as null
    return value;
  })
  .test("max-decimals", "Only up to 5 decimal places are allowed", (value) => {
    if (value === undefined || value === null) return true; // Allow if no value
    // Regex to check for up to 5 decimal places
    return /^(\d+(\.\d{1,5})?)?$/.test(value);
  })
  .nullable(); // Allow null values if needed



  // Number validation for integer-only fields
const numberValidation = yup
.number()
.typeError("This field must be a number")  // Ensure it's a number
.nullable(); // Allow null values if needed

const integerValidation = yup
  .string() // Keep it as a string so we can handle the raw input more easily
  .matches(/^\d+$/, 'This field must be an integer') // Regex to ensure only digits are allowed
  .test('is-integer', 'This field must be an integer', (value) => {
    // Allow empty or null values (for nullable fields)
    if (value === null || value === '' || value === undefined) return true;

    // Ensure value is a string before checking for a decimal point
    if (typeof value === 'string' && value.includes('.')) return false;

    // Check if it contains only numeric characters (no decimals or other non-numeric characters)
    return /^\d+$/.test(value);
  })
  .typeError('This field must be a number') // Triggered when non-numeric characters are typed
  .nullable(); // Allow null values if needed





export const schema = [
  yup.object({
    remarks: yup
      .string()
      .max(200, "Remarks must be 200 characters or less"), // Limit to 200 characters

      // Validation for the four fields
    quantity_wood_burnt_reporting: decimalValidation,
    quantity_wood_burnt_total: decimalValidation,
    amount_spent_updated: decimalValidation,
    blankets_distributed_total:numberValidation ,
    blankets_distributed_reporting:numberValidation ,
    people_in_shelter_total: numberValidation,
    people_in_shelter_reporting:numberValidation ,
    night_shelters_total: numberValidation,
    night_shelters_reporting:numberValidation ,
    deaths_total: numberValidation,
    deaths_reporting: numberValidation,
    population_affected_total: numberValidation,
    population_affected_reporting: numberValidation,
    number_of_bonfire_places_reporting: numberValidation,
    amount_allocated: decimalValidation,
    number_of_bonfire_places_reporting: integerValidation,
    deaths_reporting: integerValidation,
    night_shelters_reporting:integerValidation,
    people_in_shelter_reporting: integerValidation,
    people_in_shelter_reporting: integerValidation,
    population_affected_reporting: integerValidation,
    blankets_distributed_reporting: integerValidation
    
  }),
];

export const DailyColdWaveFormConfig = [
  {
    id: 0,
    name: "",
    heading: "",
    fields: [
        {
            name: "date",
            label: "Date",
            type: "date",
            disabled: true
          },
      {
            name: "district_name",
            label: "Name of District",
            type: "input",
            // options: [
            //   { value: "0", label: "Activity" },
            //   { value: "1", label: "Notification" },
            // ],
            disabled: true
      },
      {
            name: "block_name",
            label: "Block",
            type: "input",
            disabled: true
            // options: [
            //   { value: "0", label: "Activity" },
            //   { value: "1", label: "Notification" },
            // ],
      },
      {
        name: "number_of_bonfire_places_reporting",
        label: "No. of Places where bonfire lit (Today)",
        type: "input",
        // mandatory: "true",
      },
      {
        name: "number_of_bonfire_places_total",
        label: "No. of Places where bonfire lit (Cum)",
        type: "input",
        disabled: true
      },
      {
        name: "quantity_wood_burnt_reporting",
        label: "Qty. of wood burned in kg (Today)",
        type: "input",
      },
      {
        name: "quantity_wood_burnt_total",
        label: "Qty. of wood burned in kg till date (Cum)",
        type: "input",
        disabled: true
      },
      // {
      //   name: "population_affected_reporting",
      //   label: "Population affected by the cold wave (Today)",
      //   type: "input",
      // },
      // {
      //   name: "population_affected_total",
      //   label: "Population affected by the cold wave (Cum)",
      //   type: "input",
      //   disabled: true
      // },
      {
        name: "deaths_reporting",
        label: "Loss of Life (Today)",
        type: "input",
      },
      {
        name: "deaths_total",
        label: "Loss of Life (Cum)",
        type: "input",
        disabled: true
      },
      // {
      //   name: "amount_allocated",
      //   label: "Amount allocated by the department (in lakhs)",
      //   type: "input",
      //   disabled: ({ roleName }) => roleName !== process.env.REACT_APP_SUPER_ADMIN
      // },
      // {
      //   name: "amount_spent_updated",
      //   label: "Updated amount spent (in rupees)",
      //   type: "input",
      // },
      {
        name: "night_shelters_reporting",
        label: "No. of Night Shelters (Today)",
        type: "input",
      },
      {
        name: "night_shelters_total",
        label: "No. of Night Shelters till now (Cum)",
        type: "input",
        disabled: true
      },
      {
        name: "people_in_shelter_reporting",
        label: "No. of people taking shelter in night shelter (Today)",
        type: "input",
      },
      {
        name: "people_in_shelter_total",
        label: "No. of people taking shelter in night shelter till now (Cum)",
        type: "input",
        disabled: true
      },
      {
        name: "blankets_distributed_reporting",
        label: "No. of blankets distributed (Today)",
        type: "input",
      },
      {
        name: "blankets_distributed_total",
        label: "No. of blankets distributed till now (Cum)",
        type: "input",
        disabled: true
      },
      {
        name: "remarks",
        label: "Remarks",
        type: "text_area",
      },
     
    ],
  },
];

