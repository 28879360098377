export const misDistReportConfig = {
  colKey1: "",
  colKey2: "",
  colKey3: "block_name",
  colKey4: "no_of_incidents",
  colKey5: "no_of_incidents_cum",
  colKey6: "",
  colKey7: "no_of_human_deaths_today",
  colKey8: "no_of_cattle_deaths_today",
  colKey9: "no_of_poultry_deaths_today",
  colKey10: "no_of_human_deaths_cum",
  colKey11: "no_of_cattle_deaths_cum",
  colKey12: "no_of_poultry_deaths_cum",
  colKey13: "no_of_human_injured_today",
  colKey14: "no_of_cattle_injured_today",
  colKey15: "no_of_poultry_injured_today",
  colKey16: "no_of_human_injured_cum",
  colKey17: "no_of_cattle_injured_cum",
  colKey18: "no_of_poultry_injured_cum",
  colKey19: "no_of_deceased_pay_exgra_done_today",
  colKey20: "no_of_deceased_pay_exgra_done_cum",
  colKey21: "no_of_dead_cattles_pay_done_today",
  colKey22: "no_of_dead_cattles_pay_done_cum",
  colKey23: "no_of_dead_poultry_pay_done_today",
  colKey24: "no_of_dead_poultry_pay_done_cum",
  colKey25: "concrete_complete",
  colKey26: "concrete_complete_cum",
  colKey27: "concrete_partial",
  colKey28: "concrete_partial_cum",
  colKey29: "kutcha_complete",
  colKey30: "kutcha_complete_cum",
  colKey31: "kutcha_partial",
  colKey32: "kutcha_partial_cum",
  colKey33: "cottage_hut",
  colKey34: "cottage_hut_cum",
  colKey35: "total",
  colKey36: "total_cum",
  colKey37: "concrete_complete_pay_done_today",
  colKey38: "concrete_complete_pay_done_cum",
  colKey39: "concrete_partial_pay_done_today",
  colKey40: "concrete_partial_pay_done_cum",
  colKey41: "kutcha_complete_pay_done_today",
  colKey42: "kutcha_complete_pay_done_cum",
  colKey43: "kutcha_partial_pay_done_today",
  colKey44: "kutcha_partial_pay_done_cum",
  colKey45: "cottage_hut_pay_done_today",
  colKey46: "cottage_hut_pay_done_cum",
  colKey47: "today_pay_done_today",
  colKey48: "today_pay_done_cum",
  colKey49: "no_of_dam_cattle_sheds_today",
  colKey50: "no_of_dam_cattle_sheds_cum",
  colKey51: "no_of_dam_cattle_sheds_pay_done_today",
  colKey52: "no_of_dam_cattle_sheds_pay_done_cum",
  colKey53: "amount_paid_dam_hous_hut_cattle_today",
  colKey54: "amount_paid_dam_hous_hut_cattle_cum",
  colKey55: "",
  colKey56: "",
  colKey57: "damaged_crop_area_affected",
  colKey58: "damaged_crop_area_affected_cum",
  colKey59: "estimated_value_of_damaged_crops_today",
  colKey60: "estimated_value_of_damaged_crops_cum",
  colKey61: "amount_paid_agricultural_input_sub_today",
  colKey62: "amount_paid_agricultural_input_sub_cum",
  colKey63: "area_crop_agricultural_input_sub_paid_today",
  colKey64: "area_crop_agricultural_input_sub_paid_cum",
  colKey65: "total_amount_gr_paid_7k_per_fam_today",
  colKey66: "total_amount_gr_paid_7k_per_fam_cum",
  colKey67: "total_amount_cloth_paid_5k_per_fam_today",
  colKey68: "total_amount_cloth_paid_5k_per_fam_cum",
  colKey69: "number_of_polythene_sheets_distributed_today",
  colKey70: "number_of_polythene_sheets_distributed_cum",
  colKey71: "quantity_dry_ration_distributed_today",
  colKey72: "quantity_dry_ration_distributed_cum",
  colKey73: "",
};

export const misColumns = Object.entries(misDistReportConfig).map(
  ([key, value], index) => ({
    Header: `misdistfirereport.headercol${index + 1}`,
    accessor: value,
    disableSortBy: true,
    showInitially: true,
  })
);
