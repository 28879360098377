import React, { useState, useEffect } from "react";
import { useForm, useWatch } from "react-hook-form";
import { Button } from "react-bootstrap";
import { useKeycloak } from "@react-keycloak/web";
import { useTranslation } from "react-i18next";
import {
  FireFormInputData,
  schema,
} from "../../../../../JsonData/DailyFireReportSubFormConfig";
import FormHandler from "../../../Common/Form/FormHandler/FormHandler";
import { yupResolver } from "@hookform/resolvers/yup";

const DailyFireReportSubForm = ({ data, submitHandler, form_name }) => {
  const [reportData, setReportData] = useState(FireFormInputData[form_name]);
  const [activeTab, setActiveTab] = useState(0);
  const [addressFields, setAddressFields] = useState([]);

  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    getValues,
    trigger,
    reset,
    control,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema[activeTab]), mode: "all" });

  console.log("DATAForSubForm",data);

   // Use useWatch to monitor changes in the fields we want to total
   const fieldsToWatch = useWatch({
    control,
    name: [
      'concrete_complete_today',
      'concrete_partial_today',
      'kutcha_complete_today',
      'kutcha_partial_today',
      'cottage_hut_today'
    ],
  });

  // Use useWatch to monitor changes in the payment fields
  const paymentFields = useWatch({
    control,
    name: [
      'concrete_pay_complete_today',
      'concrete_pay_partial_today',
      'kutcha_pay_complete_today',
      'kutcha_pay_partial_today',
      'cottage_pay_hut_today'
    ],
  });

  // Update the total whenever any of the watched fields change
  useEffect(() => {
    // Convert each value to a number, defaulting to 0 if not provided or NaN
    const concreteComplete = parseFloat(fieldsToWatch[0]) || 0;
    const concretePartial = parseFloat(fieldsToWatch[1]) || 0;
    const kutchaComplete = parseFloat(fieldsToWatch[2]) || 0;
    const kutchaPartial = parseFloat(fieldsToWatch[3]) || 0;
    const cottageHut = parseFloat(fieldsToWatch[4]) || 0;

    // Calculate the total
    const total = concreteComplete + concretePartial + kutchaComplete + kutchaPartial + cottageHut;
    
    // Set the total value
    setValue('total_number_of_damaged_houses_today', total.toString());
  }, [fieldsToWatch, setValue]);

  useEffect(() => {
    // Convert each value to a number, defaulting to 0 if not provided or NaN
    const concretePayComplete = parseFloat(paymentFields[0]) || 0;
    const concretePayPartial = parseFloat(paymentFields[1]) || 0;
    const kutchaPayComplete = parseFloat(paymentFields[2]) || 0;
    const kutchaPayPartial = parseFloat(paymentFields[3]) || 0;
    const cottagePayHut = parseFloat(paymentFields[4]) || 0;

    // Calculate the total
    const paymentTotal = concretePayComplete + concretePayPartial + kutchaPayComplete + kutchaPayPartial + cottagePayHut;
    
    // Set the payment total value
    setValue('total_number_of_damaged_houses_for_which_payment_done_today', paymentTotal.toString());
  }, [paymentFields, setValue]);

  useEffect(() => {
    if (data && data.length > 0) {
      const report = data[0];
      
      const mapping = {
        // Number of Deaths
        human_today: "no_of_human_deaths_today",
        human_cumulative: "no_of_human_deaths_cum",
        cattle_today: "no_of_cattle_deaths_today",
        cattle_cumulative: "no_of_cattle_deaths_cum",
        poultry_today: "no_of_poultry_deaths_today",
        poultry_cumulative: "no_of_poultry_deaths_cum",
    
        // Number of Incidents
        number_of_incidents_today: "no_of_incidents",
        number_of_incidents_cumulative: "no_of_incidents_cum",
    
        // Address of Incidents
        addresses_of_places_of_incidents: "address",
    
        // Number of Injured
        human_injured_today: "no_of_human_injured_today",
        human_injured_cumulative: "no_of_human_injured_cum",
        cattle_injured_today: "no_of_cattle_injured_today",
        cattle_injured_cumulative: "no_of_cattle_injured_cum",
        poultry_injured_today: "no_of_poultry_injured_today",
        poultry_injured_cumulative: "no_of_poultry_injured_cum",
    
        // Ex Gratia Details
        number_of_deceased_for_whom_payment_of_ex_gratia_done_today: "no_of_deceased_pay_exgra_done_today",
        number_of_deceased_for_whom_payment_of_ex_gratia_done_cumulative: "no_of_deceased_pay_exgra_done_cum",
        number_of_dead_cattles_for_which_payment_done_today: "no_of_dead_cattles_pay_done_today",
        number_of_dead_cattles_for_which_payment_done_cumulative: "no_of_dead_cattles_pay_done_cum",
        number_of_dead_poultry_for_which_payment_done_today: "no_of_dead_poultry_pay_done_today",
        number_of_dead_poultry_for_which_payment_done_cumulative: "no_of_dead_poultry_pay_done_cum",
    
        // Damaged Houses
        concrete_complete_today: "concrete_complete",
        concrete_complete_cumulative: "concrete_complete_cum",
        concrete_partial_today: "concrete_partial",
        concrete_partial_cumulative: "concrete_partial_cum",
        kutcha_complete_today: "kutcha_complete",
        kutcha_complete_cumulative: "kutcha_complete_cum",
        kutcha_partial_today: "kutcha_partial",
        kutcha_partial_cumulative: "kutcha_partial_cum",
        cottage_hut_today: "cottage_hut",
        cottage_hut_cumulative: "cottage_hut_cum",
        total_number_of_damaged_houses_today: "total",
        total_number_of_damaged_houses_cumulative: "total_cum",
    
        // Damaged Houses Payment
        concrete_pay_complete_today: "concrete_complete_pay_done_today",
        concrete_pay_complete_cumulative: "concrete_complete_pay_done_cum",
        concrete_pay_partial_today: "concrete_partial_pay_done_today",
        concrete_pay_partial_cumulative: "concrete_partial_pay_done_cum",
        kutcha_pay_complete_today: "kutcha_complete_pay_done_today",
        kutcha_pay_complete_cumulative: "kutcha_complete_pay_done_cum",
        kutcha_pay_partial_today: "kutcha_partial_pay_done_today",
        kutcha_pay_partial_cumulative: "kutcha_partial_pay_done_cum",
        cottage_pay_hut_today: "cottage_hut_pay_done_today",
        cottage_pay_hut_cumulative: "cottage_hut_pay_done_cum",
        total_number_of_damaged_houses_for_which_payment_done_today: "today_pay_done_today",
        total_number_of_damaged_houses_for_which_payment_done_cumulative: "today_pay_done_cum",
    
        // Cattle Shed Details
        number_of_damaged_cattle_sheds_today: "no_of_dam_cattle_sheds_today",
        number_of_damaged_cattle_sheds_cumulative: "no_of_dam_cattle_sheds_cum",
        number_of_damaged_cattle_sheds_for_which_payment_done_today: "no_of_dam_cattle_sheds_pay_done_today",
        number_of_damaged_cattle_sheds_for_which_payment_done_cumulative: "no_of_dam_cattle_sheds_pay_done_cum",
        amount__paid_against_cattle_shed_rs_today: "amount_paid_dam_hous_hut_cattle_today",
        amount__paid_against_cattle_shed_rs_cumulative: "amount_paid_dam_hous_hut_cattle_cum",
    
        // Damaged Crop
        crop_type: "crop_type",
        area_of_damaged_crop_in_acre_today: "damaged_crop_area_affected",
        area_of_damaged_crop_in_acre_cumulative: "damaged_crop_area_affected_cum",
        estimated_value_of_damaged_crops_rs_today: "estimated_value_of_damaged_crops_today",
        estimated_value_of_damaged_crops_rs_cumulative: "estimated_value_of_damaged_crops_cum",
    
        // Relief Distribution
        amount_paid_under_the_agriculture_input_subsidy_rs_today: "amount_paid_agricultural_input_sub_today",
        amount_paid_under_the_agriculture_input_subsidy_rs_cumulative: "amount_paid_agricultural_input_sub_cum",
        area_of_crop_for_which_agriculture_input_subsidy_paid_in_acre_today: "area_crop_agricultural_input_sub_paid_today",
        area_of_crop_for_which_agriculture_input_subsidy_paid_in_acre_cumulative: "area_crop_agricultural_input_sub_paid_cum",
        total_amount_of_gr_paid_rs_7000_per_family_today: "total_amount_gr_paid_7k_per_fam_today",
        total_amount_of_gr_paid_rs_7000_per_family_cumulative: "total_amount_gr_paid_7k_per_fam_cum",
        total_amount_paid_for_cloth_utensils_5000_per_family_rs_today: "total_amount_cloth_paid_5k_per_fam_today",
        total_amount_paid_for_cloth_utensils_5000_per_family_rs_cumulative: "total_amount_cloth_paid_5k_per_fam_cum",
        number_of_polythene_sheets_distributed_today: "number_of_polythene_sheets_distributed_today",
        number_of_polythene_sheets_distributed_cumulative: "number_of_polythene_sheets_distributed_cum",
        quantity_of_Dry_ration_distributed_kg_today: "quantity_dry_ration_distributed_today",
        quantity_of_Dry_ration_distributed_kg_cumulative: "quantity_dry_ration_distributed_cum",
    
        // Remarks
        remarks: "remarks"
      };
  
      for (const formKey in mapping) {
        if (mapping.hasOwnProperty(formKey)) {
          let valueToSet = report[mapping[formKey]] ?? "";
  
          // Handle crop_type mapping from "kharif" and "rabi" to "1" and "0"
          if (formKey === "crop_type") {
            if (valueToSet.toLowerCase() === "kharif") {
              valueToSet = "1"; // Map "kharif" to "1"
            } else if (valueToSet.toLowerCase() === "rabi") {
              valueToSet = "0"; // Map "rabi" to "0"
            }
          }
  
          // Handle the 'addresses_of_places_of_incidents' field
        if (formKey === 'addresses_of_places_of_incidents') {
          if (Array.isArray(valueToSet)) {
            // Extract the address strings and IDs from the array of address objects
            const addressesWithIds = valueToSet.map((addr) => ({
              address: addr.address.trim(),
              id: addr.id
            }));

            setAddressFields(addressesWithIds); // Set the addresses and IDs in state if needed

            // Dynamically assign each address and its ID to a separate field
            addressesWithIds.forEach((addressData, index) => {
              setValue(`${formKey}_${index}`, addressData.address);
              setValue(`${formKey}_${index}_id`, addressData.id); // Set the corresponding ID for each address
            });
          }
        } else {
          setValue(formKey, valueToSet); // Standard handling for other fields
        }
           // Debugging line
        }
      }
    }
  }, [data, setValue]);

  const GenerateForm = (fields, register, errors, control) => {
    return (
      <div>
        <FormHandler
          register={register}
          errors={errors}
          fields={fields}
          control={control}
          columns={2}
          addresses={addressFields}
          setValueForInput={setValue}
        />
      </div>
    );
  };
  const generateTabsData = (tabWiseData) => {
    tabWiseData?.forEach((element) => {
      return (element.component = GenerateForm(
        element?.fields,
        register,
        errors,
        control
      ));
    });
    return tabWiseData;
  };

  const tabsData = generateTabsData(reportData);

  return (
    <div className="mt-4">
      <div className="form-body">
        <div className="registration__form--body">
          <form onSubmit={handleSubmit(submitHandler)} name={form_name}>
            <div>
              {tabsData?.map((tab) => (
                <>
                  {tab.id === activeTab && (
                    <>
                      <div>{tab.component}</div>
                    </>
                  )}
                </>
              ))}
            </div>
            <div className="d-flex justify-content-center">
              {tabsData?.length - 1 === activeTab && (
                <Button type="submit" className="mx-2" variant="primary">
                  {t("saveAndSubmit")}
                </Button>
              )}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
export default DailyFireReportSubForm;
