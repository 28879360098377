import * as React from "react";
const SvgMisReportsIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    data-name="Layer 1"
    viewBox="0 0 24 24"
    width="1em"
    height="1em"
    fill= "currentColor"
    {...props}
  >
    <path d="M21 0H8C6.346 0 5 1.346 5 3v3H0v14.5C0 22.43 1.57 24 3.5 24h17c1.93 0 3.5-1.57 3.5-3.5V3c0-1.654-1.346-3-3-3M5 20.5c0 .827-.673 1.5-1.5 1.5S2 21.327 2 20.5V8h3zm17 0c0 .827-.673 1.5-1.5 1.5H6.662c.216-.455.338-.963.338-1.5V3c0-.551.449-1 1-1h13c.551 0 1 .449 1 1zM15 6h5v2h-5zm-6 4h11v2H9zm0 4h11v2H9zm0 4h11v2H9zm4-10H9V4h4z" />
  </svg>
);
export default SvgMisReportsIcon;
