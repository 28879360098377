import * as React from "react";
const SvgFireDashboard = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    width="1em"
    height="1em"
    viewBox="0 0 512 512"
    fill= "currentColor"
    {...props}
  >
    <path stroke="currentColor" d="M349.485 86.766c-25.569-21.67-51.906-44.15-78.561-70.72L255.731.96l-14.915 15.107c-47.985 48.113-70.656 121.987-80.5 166.307a128.6 128.6 0 0 1-16.556-37.97l-9.013-34.795-26.208 24.632C62.619 177.345 32 220.11 32 288.592c-.882 101.634 67.488 190.839 165.859 216.401a236.2 236.2 0 0 0 43.915 6.392c4.864.352 18.793.703 21.18.554 120.685-3.63 216.608-102.565 216.507-223.305 0-91.494-60.236-142.633-129.976-201.868m-89.627 382.282c-2.231.193-4.259.213-6.516.192-39.844-1.218-71.54-33.819-71.637-73.681 0-27.002 14.481-40.461 47.254-68.357 8.262-7.027 17.249-14.672 26.704-23.425 8.284 7.517 16.333 14.332 23.765 20.656 32.922 27.939 50.107 43.804 50.107 71.062-.074 39.104-30.634 71.364-69.677 73.553m106.901-37.714-.426.298a116.1 116.1 0 0 0 5.792-36.074c0-48.233-31.645-75.108-65.12-103.537-11.861-10.051-24.106-20.443-36.329-32.667l-15.077-15.056-15.056 15.056c-13.799 13.778-27.3 25.277-39.204 35.414-33.391 28.408-62.246 52.961-62.246 100.79a115.9 115.9 0 0 0 6.389 37.543 181.33 181.33 0 0 1-70.891-144.679 141.34 141.34 0 0 1 40.226-100.087 165 165 0 0 0 15.929 25.959 38.33 38.33 0 0 0 39.524 14.502 39.52 39.52 0 0 0 30.09-30.452 348.8 348.8 0 0 1 56.56-136.289c22.466 21.295 44.72 40.141 64.801 57.199 66.696 56.645 114.994 97.574 114.994 169.296a179.97 179.97 0 0 1-69.976 142.784z" />
  </svg>
);
export default SvgFireDashboard;
