import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Table from "../../../../Components/UiComponents/Table/Table";
import {
  Editicon,
  Deleteicon,
  Claim,
  Complete,
  XCircleblack,
} from "../../../../Components/IconComponents";
import "./MyIndividualTaskList.css";
import { facilityTableData } from "../../../../JsonData/CreateFacilityConfig";
import TaskModalWithComment from "../../../../Components/UiComponents/TaskModalWithComment/TaskModalWithComment";
import {
  getData,
  postDataWithBody,
  postDataWithBodyFrGIS,
  putDataWithBody,
} from "../../../../Services/Services";
import {
  getMyTaskListURL,
  getMyIndividualTaskListURL,
  completeNotificationtask,
  getAllIndlTask,
  completeNotificationTypeTask,
  completeIndiTask,
  completeIndivTask,
} from "../../../../Services/EndPoints";
import Moment from "react-moment";
import { toast, ToastContainer } from "react-toastify";
import Confirmpopup from "../../Common/ConfirmPopup/Confirmpopup";
import { useKeycloak } from "@react-keycloak/web";
import StatusHandler from "../../Common/StatusHandler/StatusHandler";
import { useTranslation } from "react-i18next";
import { Button, Modal } from "react-bootstrap";

const MyIndividualTaskList = (props) => {
  const [data, setData] = useState([]);
  const { t } = useTranslation();
  const [selectedSopInstanceId, setSelectedSopInstanceId] = useState(null);
  const [taskData, setTaskData] = useState(null);
  const [showPop, setShowPop] = React.useState(false);
  const [rowCount, setRowCount] = useState(0);
  const navigate = useNavigate();

  const { keycloak, initialized } = useKeycloak();

  useEffect(() => {
    if (keycloak.authenticated) {
      getMyTaskList();
    }
  }, [keycloak.authenticated]);

  let taskServiceData = [];
  const getMyTaskList = async () => {
    var myTaskList = await postDataWithBody(
      getAllIndlTask,
      { email: getLoggedInuserName() },
      {}
    );
    myTaskList = myTaskList;
    taskServiceData = myTaskList;
    setRowCount(myTaskList.length);
    setData(myTaskList);
  };

  const getLoggedInuserName = () => {
    return keycloak.idTokenParsed.email;
  };
  const addNewHandler = () => {
    navigate("/facility-mgmt/create-facility");
    // localStorage.removeItem("DriverRegForm");
  };
  const showCompleteTaskPopup = (elem) => {
    setTaskData(elem);
    setShowPop(!showPop);
  };

  function refreshTaskTable() {
    getMyTaskList();
    setShowPop(false);
  }
  const columns = React.useMemo(
    () => [
      {
        Header: t("dailyoperationaltasktableheaders.taskname"),
        accessor: "name",
        disableSortBy: true,
        Cell: (props) => {
          if (props.value) {
            let descriptionText = props.value;
            // if (props.value.length > 10) {
            //   descriptionText = props.value.substring(0, 10) + "...";
            // }
            return <span title={props.value}>{descriptionText}</span>;
          } else {
            return "NA";
          }
        },
      },
      {
        Header: t("dailyoperationaltasktableheaders.description"),
        accessor: "description",
        disableSortBy: true,
        Cell: (props) => {
          const [showModal, setShowModal] = useState(false);
          const [fullDescription, setFullDescription] = useState("");
          const [highlightedDescription, setHighlightedDescription] =
            useState(""); // New state for highlighted description

          const handleShowModal = (description) => {
            setFullDescription(description);
            setHighlightedDescription(description); // Set the description being viewed to highlight
            setShowModal(true);
          };

          const handleCloseModal = () => {
            setShowModal(false);
            setHighlightedDescription(""); // Reset the highlighted description when the modal is closed
          };

          const descriptionText = props.value || "NA";
          const truncatedDescription =
            descriptionText.length > 25
              ? `${descriptionText.substring(0, 25)}...`
              : descriptionText;

          // Apply background color if the description is currently being viewed in the modal
          const isHighlighted = descriptionText === highlightedDescription;
          if (showModal) {
            setTimeout(() => {
              let el = document.querySelector(".modal-content");
              el.style.cssText =
                "background-color: #1c2038 !important; border-radius: 20px !important;";
            }, 0);
          }

          return (
            <span>
              <span
                title={descriptionText} // Tooltip with full description on hover
                style={{
                  cursor: "pointer", // Added pointer cursor to indicate clickability
                  backgroundColor: isHighlighted ? "#18A3A0" : "transparent", // Highlight background when viewed
                  padding: "2px 5px", // Add padding to give a little space around the text
                  borderRadius: "5px", // Rounded corners for the highlighted background
                }}
                onClick={() => handleShowModal(descriptionText)} // Open the modal with full description
              >
                {truncatedDescription}
              </span>

              {/* Modal to display the full description */}
              <Modal
                show={showModal}
                onHide={handleCloseModal}
                dialogClassName="custom-modal"
                centered
                style={{
                  borderRadius: "20px", // Smooth rounded edges
                  border: "none", // Remove border to avoid white edges
                  top: "20%",
                }}
              >
                <Modal.Header
                  style={{
                    backgroundColor: "#1c2038", // Dark blue background for header
                    color: "white", // White text in the header
                    borderBottom: "none", // Remove bottom border to prevent white edges
                  }}
                >
                  <Modal.Title style={{ color: "white" }}>
                    Full Description
                  </Modal.Title>
                  <XCircleblack
                    onClick={handleCloseModal}
                    width="1.5em"
                    height="1.5em"
                    className="cursor-pointer"
                  />
                </Modal.Header>
                <Modal.Body
                  style={{
                    backgroundColor: "#252b48", // Blue background for body
                    color: "white", // White text in the body
                    border: "none", // Remove border to prevent white edges
                    borderRadius: "0px", // Remove radius to make it seamless
                    padding: "20px", // Added padding for spacing
                    lineHeight: "1.8",
                  }}
                >
                  <p>{fullDescription}</p>
                </Modal.Body>
                <Modal.Footer
                  style={{
                    backgroundColor: "#252b48", // Darker blue footer
                    color: "white", // White text in footer
                    borderTop: "none", // Remove top border to prevent white edges
                  }}
                >
                  <Button
                    variant="secondary"
                    onClick={handleCloseModal}
                    style={{
                      backgroundColor: "#252b48", // Dark blue button
                      borderColor: "#003366", // Dark border for button
                      borderRadius: "5px", // Rounded button edges
                    }}
                  >
                    Close
                  </Button>
                </Modal.Footer>
              </Modal>
            </span>
          );
        },
      },
      {
        Header: t("dailyoperationaltasktableheaders.actionmessage"),
        accessor: "action_message",
        disableSortBy: true,
        Cell: (props) => {
          const [showModal, setShowModal] = useState(false);
          const [fullMessage, setFullMessage] = useState("");
          const [highlightedMessage, setHighlightedMessage] = useState(""); // New state for highlighted message
      
          const handleShowModal = (message) => {
            setFullMessage(message);
            setHighlightedMessage(message); // Highlight the message being viewed
            setShowModal(true);
          };
      
          const handleCloseModal = () => {
            setShowModal(false);
            setHighlightedMessage(""); // Reset highlighted message when modal closes
          };
      
          const messageText = props.value || "NA";
          const truncatedMessage =
            messageText.length > 25 ? `${messageText.substring(0, 25)}...` : messageText;
      
          const isHighlighted = messageText === highlightedMessage;
      
          if (showModal) {
            setTimeout(() => {
              let el = document.querySelector(".modal-content");
              el.style.cssText =
                "background-color: #1c2038 !important; border-radius: 20px !important;";
            }, 0);
          }
      
          return (
            <span>
              <span
                title={messageText} // Tooltip with full message on hover
                style={{
                  cursor: "pointer", // Pointer cursor to indicate clickability
                  backgroundColor: isHighlighted ? "#18A3A0" : "transparent", // Highlight background when viewed
                  padding: "2px 5px",
                  borderRadius: "5px",
                }}
                onClick={() => handleShowModal(messageText)} // Open the modal with full message
              >
                {truncatedMessage}
              </span>
      
              {/* Modal to display the full action message */}
              <Modal
                show={showModal}
                onHide={handleCloseModal}
                dialogClassName="custom-modal"
                centered
                style={{
                  borderRadius: "20px",
                  border: "none",
                  top: "20%",
                }}
              >
                <Modal.Header
                  style={{
                    backgroundColor: "#1c2038",
                    color: "white",
                    borderBottom: "none",
                  }}
                >
                  <Modal.Title style={{ color: "white" }}>Full Action Message</Modal.Title>
                  <XCircleblack
                    onClick={handleCloseModal}
                    width="1.5em"
                    height="1.5em"
                    className="cursor-pointer"
                  />
                </Modal.Header>
                <Modal.Body
                  style={{
                    backgroundColor: "#252b48",
                    color: "white",
                    border: "none",
                    padding: "20px",
                    lineHeight: "1.8",
                  }}
                >
                  <p>{fullMessage}</p>
                </Modal.Body>
                <Modal.Footer
                  style={{
                    backgroundColor: "#252b48",
                    color: "white",
                    borderTop: "none",
                  }}
                >
                  <Button
                    variant="secondary"
                    onClick={handleCloseModal}
                    style={{
                      backgroundColor: "#252b48",
                      borderColor: "#003366",
                      borderRadius: "5px",
                    }}
                  >
                    Close
                  </Button>
                </Modal.Footer>
              </Modal>
            </span>
          );
        },
      },

      {
        Header: t("dailyoperationaltasktableheaders.createdby"),
        accessor: "created_by",
        disableSortBy: true,
      },
      {
        Header: t("dailyoperationaltasktableheaders.tasktype"),
        accessor: "task_type",
        disableSortBy: true,
        Cell: (props) => {
          if (props.value == 1) {
            return "Notification";
          } else if (props.value == 0) {
            return "Activity";
          }
        },
      },
      {
        Header: t("dailyoperationaltasktableheaders.createdon"),
        accessor: "created_on",
        Cell: (props) => {
          if (props.value) {
            return <Moment format="DD/MM/YYYY, hh:mm a">{props.value}</Moment>;
          } else {
            return "NA";
          }
        },
        disableSortBy: true,
      },
      {
        Header: t("dailyoperationaltasktableheaders.updatedon"),
        accessor: "updated_on",
        Cell: (props) => {
          if (props.value) {
            return <Moment format="DD/MM/YYYY, hh:mm a">{props.value}</Moment>;
          } else {
            return "NA";
          }
        },
        disableSortBy: true,
      },
      {
        Header: t("dailyoperationaltasktableheaders.status"),
        accessor: "task_status_code",
        disableSortBy: true,
        Cell: (props) => {
          // Use the original task status directly from the row data
          // This ensures we always display the actual database value
          const originalStatus = props.row.original.task_status_code;
          const [showConfirmPopup, setShowConfirmPopup] = useState(false);
          const [showActivityConfirmPopup, setShowActivityConfirmPopup] = useState(false);
          
          const toggleConfirmPopup = () => {
            setShowConfirmPopup(!showConfirmPopup);
          };
          
          const toggleActivityConfirmPopup = () => {
            setShowActivityConfirmPopup(!showActivityConfirmPopup);
          };
          
          const handleStatusChange = (newStatus) => {
            const { id, task_type } = props.row.original;
            
            if (newStatus === "TASK_EXE_STATUS_INPROGRESS") {
              if (task_type === 0) {
                showCompleteTaskPopup(props.row.original);
              } else {
                toggleConfirmPopup();
              }
            } else if (newStatus === "TASK_EXE_STATUS_COMPLETED") {
              if (task_type === 1) {
                toggleConfirmPopup();
              } else {
                showCompleteTaskPopup(props.row.original);
              }
            }
          };
          
          const confirmTaskById = async (id) => {
            let input = {
              id: id,
              email: getLoggedInuserName(),
            };
            var res = await postDataWithBody(
              completeNotificationTypeTask,
              input,
              {}
            );
            if (res.status === "success") {
              getMyTaskList(); // Refresh the full table
            }
            toast.success(res.msg, {
              position: toast.POSITION.TOP_CENTER,
              autoClose: 2000,
            });
            toggleConfirmPopup();
          };
          
          const todayDate = new Date().toISOString().split("T")[0];
          
          const confirmTaskByIdActivity = async (id) => {
            let input = {
              id: id,
              taskCompletedDate: todayDate,
            };
            var res = await postDataWithBody(completeIndivTask, input, {});
            if (res.status === "success") {
              getMyTaskList(); // Refresh the full table
            }
            toast.success(res.msg, {
              position: toast.POSITION.TOP_CENTER,
              autoClose: 2000,
            });
            toggleActivityConfirmPopup();
          };
          
          const getDropdownColor = (status) => {
            switch (status) {
              case "TASK_EXE_STATUS_NEW_TASK":
                return "#e95060";
              case "TASK_EXE_STATUS_INPROGRESS":
                return "#ffaa22";
              case "TASK_EXE_STATUS_COMPLETED":
                return "#13845d";
              default:
                return "gray";
            }
          };
          
          // Conditionally render dropdown or message
          if (originalStatus === "TASK_EXE_STATUS_INPROGRESS_SOMEONE") {
            return (
              <div className="startedbysomeoneNew">
                <div>{t("Task started by someone")}</div>
              </div>
            );
          }
          
          return (
            <div>
              <select
                value={originalStatus} // Always use the original status from props
                onChange={(e) => handleStatusChange(e.target.value)}
                style={{
                  padding: "5px",
                  border: "1px solid #ddd",
                  borderRadius: "4px",
                  backgroundColor: getDropdownColor(originalStatus),
                  color: "white",
                  width: "120px",
                }}
              >
                <option
                  value="TASK_EXE_STATUS_NEW_TASK"
                  disabled={originalStatus !== "TASK_EXE_STATUS_NEW_TASK"}
                >
                  {props.row.original.task_type === 1 ? t("New Message") : t("New Task")}
                </option>
                
                {props.row.original.task_type !== 1 && (
                  <option
                    value="TASK_EXE_STATUS_INPROGRESS"
                    disabled={originalStatus === "TASK_EXE_STATUS_COMPLETED"}
                  >
                    {t("In-Progress")}
                  </option>
                )}
                
                <option
                  value="TASK_EXE_STATUS_COMPLETED"
                  disabled={
                    props.row.original.task_type === 1
                      ? originalStatus !== "TASK_EXE_STATUS_NEW_TASK"
                      : originalStatus !== "TASK_EXE_STATUS_INPROGRESS"
                  }
                >
                  {props.row.original.task_type === 1 ? t("Acknowledge") : t("Completed")}
                </option>
              </select>
              
              {showConfirmPopup && (
                <Confirmpopup
                  className="DeltePopup"
                  show={showConfirmPopup}
                  title={t("acknowldgtask")}
                  message={t("popupmsg")}
                  handleToggle={toggleConfirmPopup}
                  row={props.row}
                  name={props.row.original.task_name}
                  deleteRow={() => confirmTaskById(props.row.original.id)}
                  cancel={t("cancelbutton")}
                  action={t("confirm")}
                />
              )}
              
              {showActivityConfirmPopup && (
                <Confirmpopup
                  className="DeltePopup"
                  show={showActivityConfirmPopup}
                  title={t("acknowldgtask")}
                  message={"Are you sure you want to complete the task?"}
                  handleToggle={toggleActivityConfirmPopup}
                  row={props.row}
                  name={props.row.original.task_name}
                  deleteRow={() => confirmTaskByIdActivity(props.row.original.id)}
                  cancel={t("cancelbutton")}
                  action={t("confirm")}
                />
              )}
            </div>
          );
        }
      }
    ],
    [t]
  );

  return (
    <div>
      {showPop && (
        <TaskModalWithComment
          titleValue={t("actiontaken")}
          showSuccessMsg={refreshTaskTable}
          taskData={taskData}
          show={showPop}
          buttonLabel={t("popup.save")}
          handleToggle={showCompleteTaskPopup}
          getMyTaskList={getMyTaskList}
          setShowPop={setShowPop}
          {...props}
        />
      )}

      {facilityTableData && (
        <Table
          data={data}
          title=""
          columns={columns}
          numberOfRows={7}
          showExport={false}
          showReset={false}
          onExpand={false}
          showSearch={true}
          isHeaderFixed={true}
          rowCount={rowCount}
          headerTableHeadStyle={{ background: "#18a3a0", color: "#FFFFFF" }}
          tableStyle={{
            border: "1px solid  #f0f0f0",
            padding: "2px",
            borderRadius: "12px",
          }}
        />
      )}
    </div>
  );
};

export default MyIndividualTaskList;
