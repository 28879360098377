import React, { useEffect } from "react";
import DMSHeader from "../../DMSHeader/DMSHeader";
//import DailyFloodReportCompTable from "./DailyFloodReportCompTable";
import { useTranslation } from "react-i18next";
import FireCommunityKitchenReportForm from "./FireCommunityKitchenReportForm";

const FireCommunityKitchenReport = () => {
  const { t } = useTranslation();

  return (
    <div>
      <div className="widget-container">
        <DMSHeader title="Community Kitchen (Fire) Reporting Format" />
      </div>
      <div className="widget-container p-1">
        <FireCommunityKitchenReportForm />
      </div>
    </div>
  );
};

export default FireCommunityKitchenReport;
