import React, { useEffect } from "react";
import GridItem from "../../../../Components/UiComponents/GridItem/GridItem";
import { GridStack } from "gridstack";
import DMSHeader from "../../DMSHeader/DMSHeader";
import MISDistColdWaveReportTable from "./MISDistColdWaveReportTable";
import MISColdWaveReportTable from "./MISColdWaveReportTable";

const MISDistColdWaveReport = () => {
  useEffect(() => {
    GridStack.init();
  }, []);

  const layoutData = [
    {
      x: 0,
      y: 0,
      width: 12,
      height: 1,
      id: 1,
      component: (
        <DMSHeader
          title="MIS Cold Wave Report"
          fileName="MISReportStatus.csv"
          showdownload={false}
        />
      ),
      haveOverflowVisible: true,
    },
    {
      x: 0,
      y: 1,
      width: 12,
      height: 12,
      id: 2,
      component: <MISColdWaveReportTable />,
    },
  ];

  return (
    <div className="grid-stack grid-stack-instance-1 smartpole__header--transition">
      <GridItem layoutData={layoutData} />
    </div>
  );
};

export default MISDistColdWaveReport;
