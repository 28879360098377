import * as React from "react";
const SvgDailyReportsIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    data-name="Layer 1"
    viewBox="0 0 24 24"
    fill= "currentColor"
    {...props}
  >
    <path d="M19.5 2H18V.5a.5.5 0 0 0-1 0V2H7V.5a.5.5 0 0 0-1 0V2H4.5A4.505 4.505 0 0 0 0 6.5v13C0 21.981 2.019 24 4.5 24h4a.5.5 0 0 0 0-1h-4C2.57 23 1 21.43 1 19.5V9h22.5a.5.5 0 0 0 .5-.5v-2C24 4.019 21.981 2 19.5 2M23 8H1V6.5C1 4.57 2.57 3 4.5 3h15C21.43 3 23 4.57 23 6.5zm-3.268 3.732-7.707 7.707A3.48 3.48 0 0 0 11 21.914V23.5a.5.5 0 0 0 .5.5h1.586c.935 0 1.814-.364 2.475-1.025l7.707-7.707c.472-.472.732-1.1.732-1.768s-.26-1.296-.732-1.768c-.943-.944-2.592-.944-3.535 0Zm2.828 2.828-7.707 7.707c-.472.472-1.1.732-1.768.732h-1.086v-1.086c0-.668.26-1.295.732-1.768l7.707-7.707c.566-.566 1.555-.566 2.121 0 .283.283.439.66.439 1.061s-.156.777-.439 1.061ZM4.999 13.499a.5.5 0 0 1 .5-.5H14.5a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5Zm5 5a.5.5 0 0 1-.5.5H5.5a.5.5 0 0 1 0-1h4a.5.5 0 0 1 .5.5Z" />
  </svg>
);
export default SvgDailyReportsIcon;
