import React, { useEffect, useState } from "react";
import "./ColdwavedashboardCard.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { coldwavecardsdata } from "../../../../Services/EndPoints";
import { getData } from "../../../../Services/ProfileServices";

export default function ColdWaveDashboardcard({
  data,
  config,
  clickedKey,
  setClickedKey,
  triggeredFunction,
  onIncidentSelect,
  startDateStr,
  endDateStr,
}) {
  const colors = ["#333c67"];
  const [cardData, setCardData] = useState({});
  const [selectedCard, setSelectedCard] = useState(null);


  console.log("ColdWaveDashboardcard", startDateStr, endDateStr);
  const fetchColdWaveCardData = async () => {
    try {
      const response = await getData(
        coldwavecardsdata
          .replace("FROMDATE", startDateStr)
          .replace("TODATE", endDateStr)
      );
      setCardData(response[0] || {});
    } catch (error) {
      console.error("Error fetching cold wave card data:", error);
    }
  };

  useEffect(() => {
    fetchColdWaveCardData();
  }, [startDateStr, endDateStr]);

  const formatCardData = (value) => {
    if (typeof value === "string") {
      const splitValue = value.split("(");
      return (
        <>
          {splitValue[0]} <br />{splitValue[1]}
        </>
      );
    }
    return value || 0;
  };

  const handleCardClick = (conf) => {
    console.log("Card clicked: ", conf.key);
    setSelectedCard(conf.key);
    
    if (onIncidentSelect) {
      onIncidentSelect(conf.key);
    }
  };

  return (
    <div className="dashboard_card_Coldwave">
      <div>{config.title}</div>
      <div className="d-grid gap-2 mt-1">
        {config?.data.map((conf, index) => (
          <div
            key={index}
            className={`ds-single_card_cw cursor-pointer grid-container-cw ${
              selectedCard === conf.key ? "highlighted-card" : ""
            }`}
            onClick={() => handleCardClick(conf)}
            style={{ backgroundColor: colors[index % colors.length] }}
          >
            <div className="card-icon_coldwave">
              {conf.icon && <FontAwesomeIcon icon={conf.icon} size="3x" />}
            </div>
            <div className="card-label-coldwave">{conf.label}</div>
            <div className="card-data-coldwave">
              {formatCardData(cardData[conf.key])}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}