import React, { useEffect, useState, useRef, useLayoutEffect } from "react";
import ReactDatePicker from "react-datepicker";
import Loader from "react-spinner-loader";
import moment from "moment";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import { useTranslation } from "react-i18next";
import { Expand } from "../../../../Components/IconComponents";
import LMSButton from "../../Common/LMSButton/LMSButton";
import ColdWaveBarChart from "./ColdWaveBarChart";
import ColdWaveDashboardcard from "./ColdWaveDashboardcard";
import { dashboardCardConfigForColdwave } from "./DashboardConfigForColdwave";
import { getData } from "../../../../Services/Services";
import { getStateFloodCountData } from "../../../../Services/EndPoints";
import "react-datepicker/dist/react-datepicker.css";
import "./ColdwaveDashboard.css";

const ColdWaveDashboard = () => {
  // Hooks
  const { t } = useTranslation();
  const handle = useFullScreenHandle();

  // State variables
  const [loader, setLoader] = useState(false);
  const [error, setError] = useState(false);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [activeTab, setActiveTab] = useState("dss");
  const [cardData, setCardData] = useState(null);
  const [selectedIncident, setSelectedIncident] = useState("");
  
  // Date state variables
  const [internalFromDate, setInternalFromDate] = useState(new Date());
  const [internalToDate, setInternalToDate] = useState(new Date());
  const [appliedFromDate, setAppliedFromDate] = useState(new Date());
  const [appliedToDate, setAppliedToDate] = useState(new Date());
  const [isInitialLoad, setIsInitialLoad] = useState(true);

   useEffect(() => {
      if (internalFromDate && internalToDate && internalFromDate > internalToDate) {
        setError( "Select proper From Date and To Date");
      } else {
        setError(null);
      }
    }, [internalFromDate, internalToDate]);

  // Functions
  const toggleFullscreen = () => {
    if (isFullscreen) {
      handle.exit();
    } else {
      handle.enter();
    }
    setIsFullscreen(!isFullscreen);
  };

  const handleFromDateChange = (date) => {
    setInternalFromDate(date);
  };

  const handleToDateChange = (date) => {
    setInternalToDate(date);
  };

  // Data fetching function
  const refreshDashboardBtnClick = async () => {
    console.log("hi");
    // Update the applied dates when apply button is clicked
    setAppliedFromDate(internalFromDate);
    setAppliedToDate(internalToDate);
    
    const startDateStr = moment(internalFromDate).format("YYYY-MM-DD");
    const endDateStr = moment(internalToDate).format("YYYY-MM-DD");
    
    // setLoader(true);

    // try {
    //   const data = await getData(
    //     getStateFloodCountData
    //       .replace("FROMDATE", startDateStr)
    //       .replace("TODATE", endDateStr)
    //   );

    //   if (data && data[0]) {
    //     setCardData(data[0]);
    //   }
    // } catch (error) {
    //   console.error("Error fetching dashboard data:", error);
    //   setError(true);
    // } finally {
    //   setLoader(false);
    // }
  };

  // Initial data load
  useEffect(() => {
    if (isInitialLoad) {
      refreshDashboardBtnClick();
      setIsInitialLoad(false);
    }
  }, [isInitialLoad]);

  // Get the current applied dates for use in components
  const startDateStr = moment(appliedFromDate).format("YYYY-MM-DD");
  const endDateStr = moment(appliedToDate).format("YYYY-MM-DD");

  return (
    <FullScreen handle={handle}>
      <div 
        className={isFullscreen ? "state-dashboard_div fullscreen" : "state-dashboard_div"}
        style={{
          height: isFullscreen ? "100vh" : "auto",
          overflow: isFullscreen ? "auto" : "initial",
          padding: "10px",
        }}
      >
        <Loader
          show={loader}
          type="body"
          stack="vertical"
          message="Loading Data"
        />
        <div className="container-fluid">
          <div className="toprow--container">
            <div className="dash-title">Cold Wave Dashboard</div>
            <div className="d-flex gap-3">
              <div className="date-picker-container">
                <span className="ms-1">{t("flooddisaster.datetxtfrom")}</span>
                <ReactDatePicker
                  selected={internalFromDate}
                  onChange={handleFromDateChange}
                  dateFormat="dd/MM/yyyy"
                  isClearable
                  disabled={activeTab === "currentStatus"}
                  placeholderText={t("flooddisaster.dateplaceholder")}
                  className="custom-datepicker ms-2"
                  todayButton="Today"
                />
              </div>

              <div className="date-picker-container">
                <span>{t("flooddisaster.datetxtto")}</span>
                <ReactDatePicker
                  selected={internalToDate}
                  onChange={handleToDateChange}
                  dateFormat="dd/MM/yyyy"
                  isClearable
                  disabled={activeTab === "currentStatus"}
                  placeholderText={t("flooddisaster.dateplaceholder")}
                  className="custom-datepicker ms-2"
                  todayButton="Today"
                />
              </div>
            </div>

            <div 
              className="single-multiselect"
              style={{ display: "flex", alignItems: "center", gap: "10px" }}
            >
              <LMSButton
                size="btn_sm"
                label={t("flooddisaster.apply")}
                onClick={refreshDashboardBtnClick}
                disabled={!!error}
              />
              <span onClick={toggleFullscreen} className="cursor-pointer">
                <Expand
                  fill="var(--white3-color)"
                  width="1.1em"
                  height="1.1em"
                />
              </span>
            </div>
          </div>
          {error && <div className="error-message-coldwave">{error}</div>}


          <div className="dashboard-container mt-4">
            <div className="dashboard_cards">
              {dashboardCardConfigForColdwave?.map((card, index) => (
                <ColdWaveDashboardcard
                  key={index}
                  config={card}
                  onIncidentSelect={(incidentKey) => {
                    setSelectedIncident(incidentKey);
                  }}
                  startDateStr={startDateStr}
                  endDateStr={endDateStr}
                />
              ))}
            </div>
            <div className="weather_data_table">
              <ColdWaveBarChart
                fromDate={appliedFromDate}
                toDate={appliedToDate}
                startDateStr={startDateStr}
                endDateStr={endDateStr}
                selectedIncident={selectedIncident}
                title={selectedIncident}
              />
            </div>
          </div>
        </div>
      </div>
    </FullScreen>
  );
};

export default ColdWaveDashboard;