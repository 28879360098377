import React, { useContext } from "react";
import Loader from "react-spinner-loader";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import Table from "../../../../Components/UiComponents/Table/Table";
import FLCKTable from "../PopupContent/FLCKTable";
import HLTHCamp from "../PopupContent/HLTHCamp";

import {
  Deleteicon,
  PaperClipIcon,
  Viewicon,
  XCircleblack,
} from "../../../../Components/IconComponents";
import {
  postDataWithBody,
  putDataWithBody,
  getImageByFacility,
} from "../../../../Services/Services";
import {
  deleteCKById,
  getFloodReliefData,
  getMealsDetailsURL,
} from "../../../../Services/EndPoints";
import { Modal } from "react-bootstrap";
import { getExportedColumnsData } from "../../../../Components/UiComponents/utils";
import { columnsForExport } from "../../../../JsonData/FloodReliefCampConfig";
import { setTableData } from "../../../../CentralStore/ExportLMSTable/dataSlice";
import { RoleContext, ThemeContext } from "../../../..";
import { useTranslation } from "react-i18next";
import Confirmpopup from "../../Common/ConfirmPopup/Confirmpopup";
import { toast } from "react-toastify";
import TextEllipsis from "../../../../Components/UiComponents/TextEllipsis/TextEllipsis";
import CommunityKitchenBreakFastDetails from "../CommunityKitchen/CommunityKitchenBreakFastDetails";
import JSZip from "jszip";
import { saveAs } from "file-saver";

const FloodReliefCampTable = () => {
  const { roleName } = useContext(RoleContext);
  const [floodReliefCampData, setFloodReliefCampData] = useState([]);
  const [rowCount, setRowCount] = useState(0);
  const [categoryCd, setCategoryCd] = useState(null);
  const { t } = useTranslation();
  const [headerText, setHeaderText] = useState("");
  const [ckStatusOpen, setCKStatusOpen] = useState(false);
  const [mealTypeCd, setMealTypeCd] = useState(null);
  const [facilityReportId, setFacilityReportId] = useState(null);
  const [facilityreportId1, setFacilityReportId1] = useState(null);
  const [isHealthCampModal, setIsHealthCampModal] = useState(false);
  const [isShowModal, setIsShowModal] = useState(false);
  const { theme } = useContext(ThemeContext);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchData, setSearchData] = useState([]);
  const [isShowImageModal, setIsShowImageModal] = useState(false);
  const [imagetype, setimagetype] = useState([]);
  const [imageData, setImageData] = useState([]);
  const [loader, setLoader] = useState(false);
  useEffect(() => {
    getFloodReliefCampData();
  }, []);

  useEffect(() => {
    if (searchData && Array.isArray(searchData)) {
      const updatedData = searchData.map((item) => {
        if (item.close_date === "11/11/1111") {
          return { ...item, close_date: "" };
        }
        return item;
      });

      sendDataToCentralStore(updatedData);
    }
  }, [searchData]);

  const sendDataToCentralStore = (data) => {
    if (data.length > 0) {
      const columns = columnsForExport;
      dispatch(
        setTableData({
          columns,
          tableData: getExportedColumnsData(columns, data),
        })
      );
    }
  };

  const downloadFile = async (fileURL, fileName) => {
    let anchor = document.createElement("a");
    document.body.appendChild(anchor);
    let headers = new Headers();
    headers.append(
      "Authorization",
      "Bearer " + localStorage.getItem("auth_token")
    );
    fetch(fileURL, { headers })
      .then((response) => response.blob())
      .then((blobby) => {
        let objectUrl = window.URL.createObjectURL(blobby);
        anchor.href = objectUrl;
        anchor.download = fileName;
        anchor.click();
        window.URL.revokeObjectURL(objectUrl);
      });
  };
  const getFloodReliefCampData = async () => {
    setLoader(true);
    let input = { category_cd: "FLD_RLF_CMP" };
    let flListArr = [];
    var frList = await postDataWithBody(getFloodReliefData, input, {});

    Object.keys(frList)
      .map((i) => Number(i))
      .sort((a, b) => b - a)
      .forEach(function (key) {
        flListArr.push(frList[key]);
      });

    const result = Object.keys(frList).map((facilityId) => {
      const meals = frList[facilityId];
      const report = {
        facility_name: meals[0].facility_name,
        district_name: meals[0].district_name,
        block_name: meals[0].block_name,
        open_date: meals[0].open_date,
        operational_date: meals[0].operational_date,
        close_date: meals[0].close_date,
        breakfast: 0,
        lunch: 0,
        dinner: 0,
        total_meals: 0,
        attachment_path: meals[0].attachment_path,
        created_on: meals[0].created_on,
        open_status: meals[0].open_status,
        id: meals[0].id,
        facility_id: meals[0].facility_id,
        facility_type_id: meals[0].facility_type_id,
        open_date_format: `${meals[0].open_date_format} 00:00:00.0`,
        facility_report_id: facilityId,
      };

      meals.forEach((meal) => {
        if (meal.meal_type_cd === "BRKFST") {
          report.breakfast = meal.total_meals;
        } else if (meal.meal_type_cd === "LNCH") {
          report.lunch = meal.total_meals;
        } else if (meal.meal_type_cd === "DNR") {
          report.dinner = meal.total_meals;
        }
      });
      report.total_meals = report.breakfast + report.lunch + report.dinner;

      return report;
    });
    setRowCount(flListArr.length);
    setFloodReliefCampData(result);
    //sendDataToCentralStore(frList);
    setLoader(false);
  };

  const getMealsDetailsData = async (mealTypeCd, facilityreportId) => {
    let inputobj = {
      facilityReportIds: [facilityreportId],
      facilityOfferingTypeCd: mealTypeCd,
    };
    var mealdataarr = await postDataWithBody(getMealsDetailsURL, inputobj, {});
    let totalAmount = 0;
    mealdataarr.forEach(function (data, node) {
      if (
        data.facility_report_attribute_value &&
        !isNaN(data.facility_report_attribute_value)
      ) {
        totalAmount += parseInt(data.facility_report_attribute_value);
      }
    });
    mealdataarr.push({
      attribute: "Total",
      facility_report_attribute_value: totalAmount,
    });
    setTableData(mealdataarr);
  };

  const hideModal = () => {
    setIsShowModal(false);
  };

  const openPopUPForMealDetails = (mealTypeCd, facilityreportId) => {
    setIsShowModal(true);
    setIsHealthCampModal(false);
    setMealTypeCd(mealTypeCd);
    setFacilityReportId(facilityreportId);
  };

  const openPopUP = (categoryCd, facilityreportId, ckStat) => {
    setCKStatusOpen(ckStat);
    setIsHealthCampModal(true);
    setHeaderText(ckStat == true ? t("mealdetails") : t("healthcampdetails"));
    setCategoryCd(categoryCd);
    setFacilityReportId(facilityreportId);
    setIsShowModal(true);
    //getMealsDetailsData(mealTypeCd,facilityreportId);
  };

  const fetchImage = async (facilityId, typeid, opendate) => {
    setLoader(true);
    const response = await getImageByFacility(facilityId, typeid, opendate);

    // // Set the imagetype from the response
    // setimagetype(response);

    // Map through the response to extract the required fields
    const images = response.map((item) => ({
      image: `data:image/jpeg;base64,${item.image}`,
      id: item.id, // Assuming `id` is part of the response
      meal_type: item.meal_type, // Assuming `meal_type` is part of the response
      facility_report_id: item.facility_report_id,
    }));

    console.log(images, "images");

    // Set the images with id and meal_type
    setImageData(images);

    setLoader(false);
    setIsShowImageModal(true);
  };

  const handleClickViewImage = (
    facilityId,
    typeid,
    opendate,
    facilityReportId
  ) => {
    fetchImage(facilityId, typeid, opendate);
    setFacilityReportId1(facilityReportId); // Store the facility report ID here
    setIsShowImageModal(true);
  };

  // const handleClickViewImage = () =>{
  //   setIsShowImageModal(true);
  // }

  const hideImageModal = () => {
    setIsShowImageModal(false);
  };

  const downloadAllImages = async () => {
    if (!imageData || imageData.length === 0) {
      alert("No images available to download.");
      return;
    }

    const filteredImages = imageData.filter(
      (image) => image.facility_report_id == facilityreportId1 // Adjust based on your state variable
    );
    console.log(facilityreportId1, "''''''''''''''");
    if (filteredImages.length === 0) {
      alert("No images available for the selected facility.");
      return;
    }

    const zip = new JSZip();
    const folder = zip.folder("All_Images");

    for (let i = 0; i < filteredImages.length; i++) {
      const image = filteredImages[i];
      try {
        const response = await fetch(image.image);
        const blob = await response.blob();
        folder.file(`image_${i + 1}.jpg`, blob); // Add to ZIP with unique names
      } catch (error) {
        console.error(`Failed to fetch image: ${image.image}`, error);
      }
    }

    const zipContent = await zip.generateAsync({ type: "blob" });
    saveAs(zipContent, "all_images.zip");
  };

  const columns = React.useMemo(
    () => [
      {
        Header: t("floodreliefcamptableheaders.slno"),
        accessor: "sno",
        disableSortBy: false,
        Cell: (props) => <span>{props.row.index + 1}</span>,
      },
      {
        Header: t("communitykitchentableheaders.facility"),
        accessor: "facility_name",
        disableSortBy: false,
        Cell: (props) => {
          if (props.row) {
            return (
              <TextEllipsis
                text={props.row.original.facility_name}
                maxLength={30}
              />
            );
          } else {
            return "NA";
          }
        },
      },
      {
        Header: t("communitykitchentableheaders.district"),
        accessor: "district_name",
        Cell: (props) => {
          if (props.row) {
            return props.row.original.district_name;
          } else {
            return "NA";
          }
        },
        disableSortBy: false,
      },
      {
        Header: t("communitykitchentableheaders.block"),
        accessor: "block_name",
        Cell: (props) => {
          if (props.row) {
            return props.row.original.block_name;
          } else {
            return "NA";
          }
        },
        disableSortBy: false,
      },
      {
        Header: t("communitykitchentableheaders.opendate"),
        accessor: "open_date",
        Cell: (props) => {
          return props.row.original.open_date || "NA";
        },
        disableSortBy: false,
        sortType: (rowA, rowB) => {
          const parseDate = (dateStr) => {
            const [day, month, year] = dateStr.split("/");
            return new Date(year, month - 1, day);
          };
          const dateA = rowA.original.open_date
            ? parseDate(rowA.original.open_date)
            : new Date(-8640000000000000); // Default to a very old date for missing dates
          const dateB = rowB.original.open_date
            ? parseDate(rowB.original.open_date)
            : new Date(-8640000000000000);
          return dateA - dateB;
        },
      },
      {
        Header: t("communitykitchentableheaders.closedate"),
        accessor: "close_date",
        centerAlign: true, // Use true without quotes
        Cell: (props) => {
          const date = props.row.original.close_date;
          return date && date !== "11/11/1111" ? date : "-";
        },
        disableSortBy: false,
        sortType: (rowA, rowB) => {
          const parseDate = (dateStr) => {
            const [day, month, year] = dateStr.split("/");
            return new Date(year, month - 1, day);
          };

          const dateA = rowA.original.close_date
            ? parseDate(rowA.original.close_date)
            : new Date(-8640000000000000); // Default for missing dates
          const dateB = rowB.original.close_date
            ? parseDate(rowB.original.close_date)
            : new Date(-8640000000000000);

          return dateA - dateB;
        },
      },
      // {
      //   Header: t("floodreliefcamptableheaders.operationaldate"),
      //   accessor: "operational_date",
      //   Cell: (props) => {
      //     return props.row.original.operational_date || "NA";
      //   },
      //   disableSortBy: false,
      //   sortType: (rowA, rowB) => {
      //     const parseDate = (dateStr) => {
      //       const [day, month, year] = dateStr.split('/');
      //       return new Date(year, month - 1, day);
      //     };

      //     const dateA = rowA.original.operational_date ? parseDate(rowA.original.operational_date) : new Date(-8640000000000000); // Default to a very old date for missing dates
      //     const dateB = rowB.original.operational_date ? parseDate(rowB.original.operational_date) : new Date(-8640000000000000);

      //     return dateA - dateB;
      //   },
      // },

      // {
      //   Header: t("floodreliefcamptableheaders.operational"),
      //   accessor: "operational_attribute",
      //   disableSortBy: false,
      // },
      {
        Header: t("communitykitchentableheaders.breakfast"),
        accessor: "breakfast",
        Cell: (props) => {
          return (
            <div
              className="ms-3 value-style"
              onClick={() =>
                openPopUPForMealDetails(
                  "BRKFST",
                  props.row.original.facility_report_id
                )
              }
            >
              {props.value}
            </div>
          );
        },
        disableSortBy: false,
      },
      {
        Header: t("communitykitchentableheaders.lunch"),
        accessor: "lunch",
        Cell: (props) => {
          return (
            <div
              className="ms-3 value-style"
              onClick={() =>
                openPopUPForMealDetails(
                  "LNCH",
                  props.row.original.facility_report_id
                )
              }
            >
              {props.value}
            </div>
          );
        },
        disableSortBy: false,
      },
      {
        Header: t("communitykitchentableheaders.dinner"),
        accessor: "dinner",
        Cell: (props) => {
          return (
            <div
              className="ms-3 value-style"
              onClick={() =>
                openPopUPForMealDetails(
                  "DNR",
                  props.row.original.facility_report_id
                )
              }
            >
              {props.value}
            </div>
          );
        },

        disableSortBy: false,
      },
      {
        Header: t("floodreliefcamptableheaders.totalmeals"),
        accessor: "total_meals",
        disableSortBy: false,
      },
      {
        Header: t("floodreliefcamptableheaders.healthcamp"),
        Cell: (props) => {
          return (
            <div
              className="text-center value-style"
              onClick={() => openPopUP("FL", props.row.original.id, false)}
            >
              View
            </div>
          );
        },
        disableSortBy: true,
      },

      {
        Header: t("floodreliefcamptableheaders.action"),
        accessor: "attachment_path",
        disableSortBy: true,
        Cell: (props) => {
          const [isdelete, setIsdelete] = useState(false);
          const deletePopUp = () => {
            setIsdelete(!isdelete);
          };
          const deleteTableDataById = async (id) => {
            let data = { idVal: id };
            var res = await putDataWithBody(
              deleteCKById + "ck_delete",
              data,
              {}
            );
            if (res[0].status === "success") {
              getFloodReliefCampData();
            }
            toast.success(res.msg, {
              position: toast.POSITION.TOP_CENTER,
              autoClose: 2000, // Close after 2 seconds
            });
            setIsdelete(false);
          };
          let downloadAttachmentURL =
            process.env.REACT_APP_APP_SERVER_ADDRESS +
            "/" +
            process.env.REACT_APP_CORE_CONTEXT_PATH_NAME +
            "/download/download_attachments/?directoryPath=" +
            props.row.original.attachment_path +
            "&fileName=Flood_Relief_Camp.zip";
          return (
            <div className="text-center d-flex align-items-center">
              {/* {props.row.original &&
                props.row.original.attachment_path !== null && ( */}
              <span className="icon__position">
                <div
                  onClick={() =>
                    handleClickViewImage(
                      props.row.original.facility_id,
                      props.row.original.facility_type_id,
                      props.row.original.open_date_format,
                      props.row.original.facility_report_id
                    )
                  }
                >
                  <Viewicon />
                </div>
              </span>
              {/* )} */}
              {roleName === process.env.REACT_APP_SUPER_ADMIN && (
                <span onClick={deletePopUp} className="mx-2">
                  <Deleteicon
                    value={props.value}
                    className="cursor-pointer mr-1"
                  />
                </span>
              )}

              {isdelete && (
                <Confirmpopup
                  show={isdelete}
                  title="Confirm delete"
                  message="Are you sure you want to delete"
                  handleToggle={deletePopUp}
                  row={props.row}
                  name={props.row.original.activity}
                  deleteRow={() => deleteTableDataById(props.row.original.id)}
                  cancel="Cancel"
                  action="Delete"
                />
              )}
            </div>
          );
        },
      },
    ],
    [t, roleName]
  );

  return (
    <div>
      <div className="create-user">
        <Loader
          show={loader}
          type="body"
          stack="vertical"
          message="Loading Data"
        />
        <div className="datatable__container">
          {floodReliefCampData && (
            <Table
              data={floodReliefCampData}
              columns={columns}
              numberOfRows={10}
              setSearchData={setSearchData}
              showExport={false}
              showFilter={false}
              showReset={false}
              onExpand={false}
              rowCount={rowCount}
              headerTableHeadStyle={{ background: "#18a3a0", color: "#FFFFFF" }}
              tableStyle={{
                border: "1px solid  #f0f0f0",
                borderRadius: "12px",
              }}
            />
          )}
        </div>
      </div>
      {isHealthCampModal && (
        <Modal show={isShowModal} id={theme} size="lg">
          <Modal.Header>
            <Modal.Title>{headerText}</Modal.Title>
            <XCircleblack onClick={hideModal} width="1.5em" height="1.5em" />
          </Modal.Header>
          <Modal.Body>
            {ckStatusOpen == true ? (
              <FLCKTable
                categoryCd={categoryCd}
                facilityReportId={facilityReportId}
              />
            ) : (
              <HLTHCamp
                categoryCd={categoryCd}
                facilityReportId={facilityReportId}
              />
            )}
          </Modal.Body>
        </Modal>
      )}
      {!isHealthCampModal && (
        <Modal className="ckmodal" id={theme} show={isShowModal}>
          <Modal.Header>
            <Modal.Title>{t("communitykitchenpopup")}</Modal.Title>
            <XCircleblack
              onClick={hideModal}
              width="1.5em"
              height="1.5em"
              className="cursor-pointer"
            />
          </Modal.Header>
          <Modal.Body className="ckmodal-container">
            <div>
              <CommunityKitchenBreakFastDetails
                mealTypeCd={mealTypeCd}
                facilityreportId={facilityReportId}
              />
            </div>
          </Modal.Body>
        </Modal>
      )}
      <Modal className="ckmodal" id={theme} show={isShowImageModal}>
        <Modal.Header>
          <Modal.Title>{t("communitykitchenpopupForAttachments")}</Modal.Title>
          <XCircleblack
            onClick={hideImageModal}
            width="1.5em"
            height="1.5em"
            className="cursor-pointer"
          />
        </Modal.Header>
        <Modal.Body className="ckmodal-container">
          <div
            style={{ display: "flex", flexDirection: "column", gap: "20px" }}
          >
            {/* Render breakfast, lunch, and dinner first */}
            {["breakfast", "lunch", "dinner"].map((mealType) => {
              const filteredImages = imageData.filter(
                (image) =>
                  image.meal_type === mealType &&
                  image.facility_report_id == facilityreportId1
              );

              if (filteredImages.length > 0) {
                const title =
                  mealType.charAt(0).toUpperCase() + mealType.slice(1);
                return (
                  <div key={mealType}>
                    <h5 style={{ marginBottom: "10px" }}>{title}</h5>
                    <div
                      style={{
                        display: "flex",
                        flexWrap: "wrap",
                        gap: "10px",
                        justifyContent: "flex-start",
                      }}
                    >
                      {filteredImages.map((image, index) => (
                        <img
                          key={index}
                          src={image.image || "defaultImage.jpg"} // Provide a default image if loading fails
                          alt={`${title} ${index}`}
                          style={{
                            width: "calc(20% - 10px)",
                            height: "90px",
                            borderRadius: "8px",
                            objectFit: "cover",
                          }}
                          onError={(e) => {
                            e.target.src = "defaultImage.jpg"; // Set a default image on error
                          }}
                        />
                      ))}
                    </div>
                  </div>
                );
              }
              return null;
            })}

            {/* Render images with null meal_type after dinner */}
            {imageData.some(
              (image) =>
                image.meal_type === null &&
                image.facility_report_id == facilityreportId1
            ) && (
              <div>
                <h5 style={{ marginBottom: "10px" }}>Attachments</h5>
                <div
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    gap: "10px",
                    justifyContent: "flex-start",
                  }}
                >
                  {imageData
                    .filter(
                      (image) =>
                        image.meal_type === null &&
                        image.facility_report_id == facilityreportId1
                    )
                    .map((image, index) => (
                      <img
                        key={index}
                        src={image.image || "defaultImage.jpg"} // Provide a default image if loading fails
                        alt={`Attachment ${index}`}
                        style={{
                          width: "calc(20% - 10px)",
                          height: "90px",
                          borderRadius: "8px",
                          objectFit: "cover",
                        }}
                        onError={(e) => {
                          e.target.src = "defaultImage.jpg"; // Set a default image on error
                        }}
                      />
                    ))}
                </div>
              </div>
            )}
            {imageData.filter((image) =>
              ["breakfast", "lunch", "dinner"].some(
                (mealType) =>
                  image.meal_type === mealType &&
                  image.facility_report_id == facilityreportId1
              )
            ).length === 0 && (
              <p style={{ marginTop: "60px", textAlign: "center" }}>
                No images to show
              </p>
            )}

            {imageData.filter((image) =>
              ["breakfast", "lunch", "dinner"].some(
                (mealType) =>
                  image.meal_type === mealType &&
                  image.facility_report_id == facilityreportId1
              )
            ).length > 0 && (
              <button
                onClick={downloadAllImages}
                style={{
                  padding: "10px 20px",
                  backgroundColor: "#18A3A0",
                  color: "white",
                  border: "none",
                  borderRadius: "5px",
                  cursor: "pointer",
                  alignSelf: "flex-end", // Align the button to the right
                }}
              >
               Download
              </button>
            )}
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default FloodReliefCampTable;
