import React, { useState, useEffect } from "react";
import { useForm, useWatch } from "react-hook-form";
import { useNavigate, useLocation } from "react-router-dom";
import { Button } from "react-bootstrap";
import FormHandler from "../../../Common/Form/FormHandler/FormHandler";
import "./HumanLossForm.css"
import { useKeycloak } from "@react-keycloak/web";
import { toast, ToastContainer } from "react-toastify";
import {
  schema,
  HumanLossUserConfig
} from "../../../../../JsonData/HumanLossUserConfig";
import Loader from "react-spinner-loader";


import {
  postDataWithBody,
  getData,
  
  putDataWithBody,
  postDataWithBodyFrGIS,
} from "../../../../../Services/Services";


import {
  getUsersFromApp,
  getAllRole,
  createTaskandAssign,
  createHumanLossDetails,
  getPoliceStationDetails
} from "../../../../../Services/EndPoints";
import { yupResolver } from "@hookform/resolvers/yup";


const getCurrentDate = () => {
  const now = new Date();
  const day = String(now.getDate()).padStart(2, "0");
  const month = String(now.getMonth() + 1).padStart(2, "0"); // Months are 0-based
  const year = now.getFullYear();
  return `${year}-${month}-${day}`; // Return in yyyy-mm-dd format...
};



const HumanLossRegistrationForm = (props) => {
  const { keycloak } = useKeycloak();
  const [activeTab, setActiveTab] = useState(0);
  const [loader, setLoader] = useState(false);
  const [humanLossUserConfig, setHumanLossUserConfig] = useState(
    HumanLossUserConfig
  );
  const [policeStationOptions, setPoliceStationOptions] = useState([]);
  const navigate = useNavigate();
  const { state } = useLocation();

  
  let dataFromTable = state?.data;
  let districtNameForNavigate = state?.distName;
  let edit = state?.edit;
  const humanLossData = state?.humanLossData;
  const disasterData = state?.disasterData;
  const incidentDatesData = state?.incidentDatesData;

  const [lgShow, setLgShow] = useState(false);

 console.log("humanLossData",humanLossData);
 console.log("dataFromTable",dataFromTable);
  const {
    register,
    handleSubmit,
    setValue,
    getValues,

    reset,
    control,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema[activeTab]), mode: "all",defaultValues: {
    // Include incidentDate from dataFromTable
    incidentDate: dataFromTable?.incidentDate || "",
    // Other default values...
  }, });
  const paymentStatus = useWatch({
    name: "paymentStatus",
    control,
  });

  const typeCasualty = useWatch({
    name: "typeCasualty",
    control,
  });

  const hospitalized = useWatch({
    name: "hospitalized",
    control,
  });

  const isPoliceStation = useWatch({
    name: "reportedPoliceStation",
    control,
  });

  const nameOfDisaster = edit ? disasterData : dataFromTable?.disaster_name;

  console.log("NAMEOFSIASTER",nameOfDisaster);


useEffect(() => {
  if (isPoliceStation === "False") {
    const policeStationElements = document.getElementsByName("policeStationName");
    policeStationElements.forEach((element) => {
      element.classList.add("disabled_text");
      const selectContainer = element.closest(".input_text-form");
      if (selectContainer) {
        selectContainer.classList.add("disabled_text");
      }
    });

    // Explicitly set to null and trigger validation
    setValue("policeStationName", null, { shouldValidate: true });
  } else {
    const policeStationElements = document.getElementsByName("policeStationName");
    policeStationElements.forEach((element) => {
      element.classList.remove("disabled_text");
      const selectContainer = element.closest(".input_text-form");
      if (selectContainer) {
        selectContainer.classList.remove("disabled_text");
      }
    });

    // When switching to "Yes", set to empty string so validation doesn't fail
    setValue("policeStationName", "", { shouldValidate: false });
  }
}, [isPoliceStation, setValue]);


  // useEffect(() => {
  //   if (isPoliceStation === "False") {
  //     const policeStationElements = document.getElementsByName("policeStationName");
  //     policeStationElements.forEach(element => {
  //       element.classList.add('disabled_text');
  //       const selectContainer = element.closest('.input_text-form');
  //       if (selectContainer) {
  //         selectContainer.classList.add('disabled_text');
  //       }
  //     });
      
  //     // Explicitly set to null to trigger clearing
  //     setValue("policeStationName", null, { shouldValidate: true });
  //   } else {
  //     const policeStationElements = document.getElementsByName("policeStationName");
  //     policeStationElements.forEach(element => {
  //       element.classList.remove('disabled_text');
  //       const selectContainer = element.closest('.input_text-form');
  //       if (selectContainer) {
  //         selectContainer.classList.remove('disabled_text');
  //       }
  //     });
      
  //     // Restore the original police station name if editing existing data
  //     if (humanLossData && humanLossData.policeStationName) {
  //       const selectedPoliceStation = policeStationOptions.find(
  //         (option) => option.label === humanLossData.policeStationName
  //       );
  //       if (selectedPoliceStation) {
  //         setValue("policeStationName", selectedPoliceStation.value);
  //       }
  //     }
  //   }
  // }, [isPoliceStation, setValue, humanLossData, policeStationOptions]);
  //postMortem 
  
  useEffect(() => {
    const currentDate = getCurrentDate();
    setValue("entryDate", currentDate);
    setValue("paymentDate", currentDate);
  }, [setValue]);

  useEffect(() => {
    const occupationElement = document.getElementsByName("occupation")[0];
    const occupationContainer = occupationElement?.closest('.input_text-form');
    
    if (occupationElement && occupationContainer) {
      if (nameOfDisaster !== 'Heat Wave') {
        occupationElement.classList.add('disabled_text');
        occupationContainer.style.display = 'none';
        setValue("occupation", ""); // Clear the value when hidden
      } else {
        occupationElement.classList.remove('disabled_text');
        occupationContainer.style.display = 'block';
      }
    }
  }, [nameOfDisaster, setValue]);

   useEffect(() => {
    const selectElement = document.getElementsByName("death_due_to_heatstroke_has_been_certified")[0];
    const labelElement = selectElement?.previousElementSibling; // Assuming the label is the sibling element

    if (selectElement && labelElement) {
      if (nameOfDisaster !== "Heat Wave") {
        selectElement.classList.add("disabled_text");
        selectElement.style.display = "none"; // Hide the dropdown
        labelElement.style.display = "none"; // Hide the label
        setValue("death_due_to_heatstroke_has_been_certified", ""); // Clear value when hidden
      } else {
        selectElement.classList.remove("disabled_text");
        selectElement.style.display = "inline-block"; // Show the dropdown
        labelElement.style.display = "inline-block"; // Show the label
      }
    }
  }, [nameOfDisaster, setValue]);

  
 // In HumanLossRegistrationForm
useEffect(() => {
  if (nameOfDisaster === 'Road Accident') {
    setValue("paymentStatus", "2"); // Set to NA
    
    // Update form config to remove mandatory mark
    const updatedConfig = { ...humanLossUserConfig };
    updatedConfig[0].fields.forEach(field => {
      if (field.name === "paymentStatus" || field.name === "paymentDate") {
        field.mandatory = false;
      }
    });
    setHumanLossUserConfig(updatedConfig);
    
    // Add disabled attributes and class
    const paymentStatusElements = document.getElementsByName("paymentStatus");
    paymentStatusElements.forEach(element => {
      element.classList.add('disabled_text');
      element.setAttribute('disabled', 'disabled');
      element.setAttribute('tabindex', '-1');
      const selectContainer = element.closest('.input_text-form');
      if (selectContainer) {
        selectContainer.classList.add('disabled_text');
      }
    });
  } else {
    // Restore original config
    const updatedConfig = { ...humanLossUserConfig };
    updatedConfig[0].fields.forEach(field => {
      if (field.name === "paymentStatus") {
        field.mandatory = true;
      } else if (field.name === "paymentDate") {
        field.mandatory = (values) => values.paymentStatus === "0";
      }
    });
    setHumanLossUserConfig(updatedConfig);
    
    // Remove disabled attributes and class
    const paymentStatusElements = document.getElementsByName("paymentStatus");
    paymentStatusElements.forEach(element => {
      element.classList.remove('disabled_text');
      element.removeAttribute('disabled');
      element.setAttribute('tabindex', '0');
      const selectContainer = element.closest('.input_text-form');
      if (selectContainer) {
        selectContainer.classList.remove('disabled_text');
      }
    });
  }
}, [nameOfDisaster, setValue]);

// Modify your existing payment status effect
useEffect(() => {
  if (nameOfDisaster === 'Road Accident') {
    setValue("paymentStatus", "2"); // Always keep it as NA for Road Accident
    setValue("paymentDate", ""); // Clear payment date
  } else {
    // Original logic for other disaster types
    if (paymentStatus === "1" || paymentStatus === "2") {
      setValue("paymentDate", "");
    } else if (paymentStatus === "0") {
      setValue("paymentDate", getCurrentDate());
    }
  }
}, [paymentStatus, nameOfDisaster, setValue]);

  useEffect(() => {

    const getPoliceStationList = async () => {
      try {
        const policeStationDetails = await getData(getPoliceStationDetails);
        
        const policeStationOptions = policeStationDetails.map((station) => ({
          value: String(station.id || ""), // Convert to string, default to empty if null/undefined
          label: station.police_station || "", // Default to empty string if label is null/undefined
        }));

        setPoliceStationOptions(policeStationOptions);
        console.log(policeStationOptions,"POLICEstationOpts");
        // Update the HumanLossUserConfig dynamically
        const updatedConfig = { ...HumanLossUserConfig };  
        updatedConfig[0].fields.forEach(field => {
          if (field.name === "policeStationName") {
            field.options = policeStationOptions;
          }
        });

        setHumanLossUserConfig(updatedConfig);
      } catch (error) {
        console.error("Error fetching police station details:", error);
      }
    };

    getPoliceStationList();
  }, []);  // Empty dependency array to fetch data only once
 

  
  useEffect(() => {
    if (humanLossData && policeStationOptions.length > 0) {
      const selectedPoliceStation = policeStationOptions.find(
        (option) => option.label === humanLossData.policeStationName
      );
      if (selectedPoliceStation) {
        setValue("policeStationName", selectedPoliceStation.value);
      }
    }
  }, [humanLossData, policeStationOptions, setValue]);

   // Function to get the label from options based on the value
   const getSelectedLabel = (fieldName, value) => {
    const field = HumanLossUserConfig[0].fields.find((f) => f.name === fieldName);
    const selectedOption = field?.options.find((option) => option.value === value);
    return selectedOption ? selectedOption.label : value; // If no label found, return the value itself
  };
 
  // Payment date disable effect
useEffect(() => {
  const paymentDateElement = document.getElementById("paymentDate");
  if (paymentDateElement) {
    if (paymentStatus == 1 || paymentStatus == 2) {
      paymentDateElement.classList.add('disabled_text');
      paymentDateElement.setAttribute('disabled', 'disabled');
      paymentDateElement.setAttribute('tabindex', '-1');
    } else {
      paymentDateElement.classList.remove('disabled_text');
      paymentDateElement.removeAttribute('disabled');
      paymentDateElement.setAttribute('tabindex', '0');
    }
  }
}, [paymentStatus]);
 

  useEffect(() => {
    const postMortemElement = document.getElementById("postMortem");
    
    if (typeCasualty == 1) { // Injured
        if (postMortemElement) {
            postMortemElement.classList.add('disabled_text');
            postMortemElement.setAttribute('disabled', 'disabled'); // Add disabled attribute
            postMortemElement.setAttribute('tabindex', '-1'); // Prevent tabbing
        }
        setValue("postMortem", "False"); // Set to False when disabled
    } else { // Death
        if (postMortemElement) {
            postMortemElement.classList.remove('disabled_text');
            postMortemElement.removeAttribute('disabled'); // Remove disabled attribute
            postMortemElement.setAttribute('tabindex', '0'); // Allow tabbing
        }
        
        if (humanLossData && humanLossData.isPostMortem !== undefined) {
            // Restore the original value from humanLossData when switching back to Death
            setValue("postMortem", humanLossData.isPostMortem ? "True" : "False");
        } else {
            // If no initial data, clear the field
            setValue("postMortem", "");
        }
    }
}, [typeCasualty, setValue, humanLossData]);

  useEffect(() => {
    const hospitalElements = document.getElementsByName("hospitalName");
  
    hospitalElements.forEach((element) => {
      if (hospitalized === "False") {
        element.setAttribute("disabled", "true");
        element.classList.add("disabled_text");
        setValue("hospitalName", ""); // Clear the value
      } else {
        element.removeAttribute("disabled");
        element.classList.remove("disabled_text");
  
        // If this is initial form data and hospitalName exists, don't clear it
        if (humanLossData?.hospitalName) {
          setValue("hospitalName", humanLossData.hospitalName);
        }
      }
    });
  }, [hospitalized, humanLossData, setValue]);


console.log("humann",humanLossData);
  useEffect(() => {
    if (edit && humanLossData) {
        // Existing mapping objects
        const genderMapping = {
            Male: "0",
            Female: "1",
            Transgender: "2",
        };

        // Add payment status mapping
        const paymentStatusMapping = {
            "Yes": "0",
            "No": "1",
            "NA": "2"
        };

        const typeOfCasualityMap = {
          "Death": "0",
          "Injured": "1",
      };

       // Determine payment status based on disaster type and existing data
       let paymentStatusValue;
       if (nameOfDisaster === 'Road Accident') {
         paymentStatusValue = "2"; // Force NA for Road Accident
       } else {
         paymentStatusValue = paymentStatusMapping[humanLossData.paymentStatus] || "";
       }

        // Convert boolean postMortem to string "True"/"False"
        const postMortemValue = humanLossData.isPostMortem ? "True" : "False";
        
        // Map hospital and police station values to True/False
        const hospitalizedMapping = humanLossData.isHospitalized ? "True" : "False";
        const reportedToPSMapping = humanLossData.isReportedToPS ? "True" : "False";

        // Date formatting function
        const formatDate = (dateString) => {
            const [day, month, year] = dateString.split("-");
            return `${year}-${month}-${day}`;
        };

        const entryDateFormatted = humanLossData.entryDate ? formatDate(humanLossData.entryDate) : "";
        const paymentDateFormatted = humanLossData.paymentDate ? formatDate(humanLossData.paymentDate) : "";
         // Set default values when nameOfDisaster is not "Heat Wave"
         const occupationValue = nameOfDisaster === "Heat Wave" ? humanLossData.occupation || "" : "NA";
         const heatStrokeCertifiedValue = nameOfDisaster === "Heat Wave" 
             ? humanLossData.heatStrokeCertified === "Yes" ? "True" : "False" 
             : "NA";
             const incidentDateFormatted = incidentDatesData ? (incidentDatesData) : "";


        reset({
            id: humanLossData.id,
            typeCasualty: typeOfCasualityMap[humanLossData.typeOfCasualty],
            nameDeceased: humanLossData.personName,
            fathersName: humanLossData.personFamilyMemberName,
            age: humanLossData.age,
            sex: genderMapping[humanLossData.gender] || "",
            residentialAddress: humanLossData.personAddress,
            activityAccident: humanLossData.activityDuringAccident,
            postMortem: postMortemValue, // Use the converted boolean value
            hospitalized: hospitalizedMapping,
            hospitalName: humanLossData.hospitalName,
            reportedPoliceStation: reportedToPSMapping,
            policeStationName: getSelectedLabel(humanLossData.policeStationName),
            remarks: humanLossData.remarks,
            entryDate: entryDateFormatted,
            paymentStatus: paymentStatusValue, // Use the mapped payment status value
            paymentDate: nameOfDisaster === 'Road Accident' ? "" : paymentDateFormatted,

            occupation: occupationValue,  // Default to "NA" if not "Heat Wave"
            death_due_to_heatstroke_has_been_certified: heatStrokeCertifiedValue,
            incidentDate: incidentDateFormatted, // Include incidentDate in the form's default values
        });
    } else {
      // Ensuring default values for new entry (Create mode)
      reset({
          // typeCasualty: "0",
          // nameDeceased: "",
          // fathersName: "",
          // age: "",
          // sex: "0",
          // residentialAddress: "",
          // activityAccident: "",
          // postMortem: "True",
          // hospitalized: "True",
          // hospitalName: "",
          // reportedPoliceStation: "True",
          // policeStationName: "",
          // remarks: "",
          // entryDate: "",
          // paymentStatus: "0",
          // paymentDate: "",
          occupation: nameOfDisaster === "Heat Wave" ? "" : "NA", // Ensure default for create
          death_due_to_heatstroke_has_been_certified: nameOfDisaster === "Heat Wave" ? "False" : "NA", // Ensure default for create
      });
    }
}, [edit, humanLossData, reset, nameOfDisaster, incidentDatesData]);

  const GenerateForm = (fields, register, errors, control) => {
    return (
      <div>
        
          <FormHandler
            register={register}
            errors={errors}
            fields={fields}
            control={control}
            columns={2}
            getValues={getValues}
          />
     
      </div>
    );
  };

  

  const generateTabsData = (tabWiseData) => {
    tabWiseData?.forEach((element) => {
      return (element.component = GenerateForm(
        element?.fields,
        register,
        errors,
        control
      ));
    });
    return tabWiseData;
  };

  const submitHandler = async (data, e) => {
    e.preventDefault(); // Prevent default form submission
    console.log("DATA", data);
  
    setLoader(true); // Show loader
    // Function to convert "True"/"False" string values to boolean
    const convertToBoolean = (value) => {
      if (value === "True") return true;
      if (value === "False") return false;
      return value; // For non-boolean values, return them as is
    };
  
    // Function to get the current time in HH:mm:ss format
    const getCurrentTime = () => {
      const now = new Date();
      const hours = String(now.getHours()).padStart(2, "0"); // Ensure two digits for hours
      const minutes = String(now.getMinutes()).padStart(2, "0"); // Ensure two digits for minutes
      const seconds = String(now.getSeconds()).padStart(2, "0"); // Ensure two digits for seconds
      return `${hours}:${minutes}:${seconds}`; // Return the formatted time string
    };
  
    // Function to get the label from options based on the value
    const getSelectedLabel = (fieldName, value) => {
      const field = HumanLossUserConfig[0].fields.find(f => f.name === fieldName);
      const selectedOption = field?.options.find(option => option.value === value);
      return selectedOption ? selectedOption.label : value; // If no label found, return the value itself
    };
  
    // Your data object (array of objects)
    const formDataObject = [{
      ...(edit && { id: humanLossData.id }), 
      "human_loss_primary_id": edit ? humanLossData.human_loss_primary_id : dataFromTable.id, 
      "typeOfCasualty": getSelectedLabel("typeCasualty", data.typeCasualty),
      "personName": data.nameDeceased,
      "personFamilyMemberName": data.fathersName,
      "age": data.age,
      "gender": getSelectedLabel("sex", data.sex),
      "personAddress": data.residentialAddress,
      "activityDuringAccident": data.activityAccident,
      "isPostMortem": convertToBoolean(data.postMortem),  // Convert to boolean if necessary
      "isHospitalized": convertToBoolean(data.hospitalized), // Convert to boolean if necessary
      "hospitalName": data.hospitalName,
      "isReportedToPS": convertToBoolean(data.reportedPoliceStation), // Convert to boolean if necessary
      "policeStationName": getSelectedLabel("policeStationName", data.policeStationName) || "NA",
      "remarks": data.remarks,
      "entryDate": data.entryDate,
      "entryTime": getCurrentTime(),
      "paymentStatus": getSelectedLabel("paymentStatus", data.paymentStatus),
      "paymentDate": data.paymentDate,
       "occupation": nameOfDisaster === "Heat Wave" ? data.occupation : "NA",
        "heatStrokeCertified": nameOfDisaster === "Heat Wave"
            ? data.death_due_to_heatstroke_has_been_certified === "True" ? "Yes" : "No"
            : "NA"
    }];
  
    // Convert the array of objects into a JSON string to send in the body of the request
    const jsonData = JSON.stringify(formDataObject);
  
    console.log("Payload being sent: ", jsonData);  // Optional: See the payload in the console
  
    
  
    const headers = {
      "Content-Type": "application/json", // Set content type to application/json for sending JSON data
    };
  
    try {

       // If `edit` is true, update the data, else create new data
    const endpoint = edit
    ? createHumanLossDetails.replace("DATE", humanLossData.reported_on) // Assuming you're updating data
    : createHumanLossDetails.replace("DATE", dataFromTable.reported_on); // Endpoint for new data (you might need a different one for create)
  
      // Send the appropriate request based on the action
    const res = await postDataWithBody(endpoint, jsonData, headers);

  
      // Handle response
      if (res.status === "success") {
        toast.success(res.msg, {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 2000, // Close after 2 seconds
        });
        setLoader(false);
        navigate("/reports/human-loss-report",{
          state:{submitted: true,
            date: edit ? humanLossData.reported_on : dataFromTable.reported_on,
            distName: edit? districtNameForNavigate : dataFromTable.distName,
            viewFromForm:'Detailed'
        }
        });
      } else {
        toast.error(res.msg, {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 2000, // Close after 2 seconds
        });
        setLoader(false);

      }
    } catch (error) {
      console.error("Error submitting form data:", error);
      setLoader(false);
      toast.error("An error occurred while submitting the form.");
    }
  };
  

  

  const tabsData = generateTabsData(HumanLossUserConfig);
  return (
    <div className="mt-4">
      <Loader
        show={loader}
        type="body"
        stack="vertical"
        message="Loading Data"
      />
    <div className="mt-4 facility-container">
      <div className="form-body">
        <div className="registration__form--body">
          <form
            onSubmit={handleSubmit(submitHandler)}
            name="CreateHumanLossDetailedRegForm"
          >
            <input type="hidden" {...register("incidentDate")} value={dataFromTable?.incidentDate || ""} />
            <div>
              <h4 style={{marginLeft: '15px'}}>Detailed</h4>
            </div>
            <div>
              {tabsData?.map((tab) => (
                <>
                  {tab.id === activeTab && (
                    <>
                      <div>{tab.component}</div>
                    
                    </>
                  )}
                </>
              ))}
            </div>
            
            <div className="d-flex justify-content-center">
              {tabsData.length - 1 === activeTab && (
                <Button type="submit" id="submitBtn" className="mx-2" variant="primary">
                  Save & Submit
                </Button>
               )}
            </div>
          </form>
        </div>
      </div>
      
    </div>
    </div>
    
  );
};

export default HumanLossRegistrationForm;
