import React, { useState, useEffect, useContext } from "react";
import {
    useTable,
    useSortBy,
    usePagination,
    useGlobalFilter,
    useFilters,
} from "react-table";
import "bootstrap/dist/css/bootstrap.min.css";
import "./HumanLossTable.css";
import Sortdownarrow from "../../IconComponents/Sortdownarrow";
import Sortuparrow from "../../IconComponents/Sortuparrow";
import Sortingarrow from "../../IconComponents/Sortingarrow";
import TableHeader from "./TableHeader/TableHeader";
import { useTranslation } from "react-i18next";
import i18n from "i18next";
import { Deleteicon, Editicon } from "../../IconComponents";
import { deleteHumanLossDetailedDetail } from "../../../Services/EndPoints";
import { deleteDataById } from "../../../Services/Services";
import Confirmpopup from "../../../Pages/DMS/Common/ConfirmPopup/Confirmpopup";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { RoleContext } from "../../..";

// Default filter UI for a column filter
const DefaultColumnFilter = ({
    column: { filterValue, preFilteredRows, setFilter },
}) => {
    return (
        <input
            value={filterValue || ""}
            onChange={(e) => {
                setFilter(e.target.value || undefined); // undefined to remove the filter entirely
            }}
            placeholder={`Filter...`}
            style={{
                width: "100%",
                padding: "5px",
                border: "1px solid #ccc",
                borderRadius: "4px",
                marginTop: "5px",
            }}
        />
    );
};
const HumanLossTable = (props) => {
    const {
        columns,
        data,
        numberOfRows,
        headerTableHeadStyle = {},
        tableStyle = {},
        showRowCount = true,
        rowCount,
        tabChanger = false,
        activeTab,
        setActiveTab,
        searchData,
        setSearchData = () => { },
        enableColumnFilters = false, // New prop to control filter visibility
        disbalePaginationFilter = false,
        disbalePaginationFilter1 = false,
        disbalePaginationFilter2 = false,
        getTableData
    } = props;

    const [expandedRowIds, setExpandedRowIds] = useState({});
    const [isdelete, setIsdelete] = useState(false);
          const [selectedId, setSelectedId] = useState(null);
          const navigate = useNavigate();
          const roleName = useContext(RoleContext);
      
          

    const toggleRowExpansion = (rowId) => {
        setExpandedRowIds((prev) => {
            const isCurrentlyExpanded = !!prev[rowId];
            // If the row is currently expanded, collapse it; otherwise, expand it and collapse others
            return isCurrentlyExpanded ? {} : { [rowId]: true };
        });
    };

    console.log("Human loss ka data",data)
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        setGlobalFilter,
        rows,
        state: { pageIndex, globalFilter, pageSize },
    } = useTable(
        {
            columns,
            data,
            defaultColumn: { Filter: DefaultColumnFilter },
            initialState: { pageIndex: 0, pageSize: numberOfRows },
        },
        useFilters,
        useGlobalFilter,
        useSortBy,
        usePagination
    );

    const { t } = useTranslation();

    const translateNumber = (number) => {
        const numberString = number.toString();
        return i18n.t(`numbers.${numberString}`, { defaultValue: numberString });
    };
    let tempRowCount = globalFilter ? rows.length : rowCount || data.length;
    let tempPageIndex = pageIndex;
    let tempPageSize = pageSize;

    if (tempPageIndex === undefined || tempPageIndex === null) {
        tempPageIndex = 0;
    }
    if (tempRowCount === undefined || tempRowCount === null) {
        tempRowCount = 0;
    }
    if (tempPageSize === undefined || tempPageSize === null) {
        tempPageSize = 10;
    }

    const startIndex = tempPageIndex * tempPageSize + 1 || 1;
    const endIndex =
        Math.min((tempPageIndex + 1) * tempPageSize, tempRowCount) || tempRowCount;

    const translatedStartIndex = startIndex;
    const translatedEndIndex = endIndex;
    const translatedRowCount = tempRowCount;
    useEffect(() => {
        setPageSize(numberOfRows);
    }, [numberOfRows]); // Update page size if numberOfRows changes

    useEffect(() => {
        if (globalFilter) {
            const pageData = page?.map((row) => row.original);
            setSearchData(pageData ? pageData : []);
        } else {
            const pageData = rows?.map((row) => row.original);
            setSearchData(pageData ? pageData : []);
        }
    }, [globalFilter, rows]);


    const deletePopUp = (id) => {
        setSelectedId(id);
        setIsdelete(!isdelete);
      };

    const deleteTableDataById = async () => {
        try {
          const response = await deleteDataById(deleteHumanLossDetailedDetail, selectedId);
          if (response?.status === "success") {
            await getTableData()
            toast.success(
              response.msg || t("delete_success_message"),
              {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000,
              }
            );
          } else {
            toast.error(
              response.msg || t("delete_failure_message"),
              {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000,
              }
            );
          }
        } catch (error) {
          toast.error(t("delete_error_message"), {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 2000,
          });
          console.error("Delete error:", error);
        } finally {
          setIsdelete(false);
          setSelectedId(null);
        }
      };


    // Handle row expansion and collapse based on expandedRowIds
    const renderExpandedRow = (row) => {
        const userTaskMap = row.original.userTaskMapList || [];
        const casualtyData = row.original.casualtyData || [];
        const humanLossDetails = row.original.humanLossDetailsList || [];
        console.log("ROW",row);
        console.log("humanLossDetails",humanLossDetails);
        console.log("HumanLossCasualitydatafor delete", casualtyData);

        // Function to check if the report date is older than 3 days
  const isOlderThanThreeDays = (reportDate) => {
    const currentDate = new Date();
    const selectedDateObj = new Date(reportDate);
    const diffInTime = currentDate.getTime() - selectedDateObj.getTime();
    const diffInDays = diffInTime / (1000 * 3600 * 24);
    return diffInDays > 3;
  };

  const isSuperAdmin = roleName.roleName === process.env.REACT_APP_SUPER_ADMIN;
      const isDisabled = !isSuperAdmin && isOlderThanThreeDays(row.original.reported_on);

//   const isDisabled = isOlderThanThreeDays(row.original.reported_on); // Disable based on the report date
        return (
            <tr>
                <td colSpan="12">
                    <div className="human-loss-tbl-div" >
                    <table className="human-loss-tbl" id="detailed_report_table">
      <colgroup>
        <col style={{ width: "10%" }} />
        <col style={{ width: "15%" }} />
        <col style={{ width: "15%" }} />
        <col style={{ width: "5%" }} />
        <col style={{ width: "5%" }} />
        <col style={{ width: "20%" }} />
        <col style={{ width: "10%" }} />
        <col style={{ width: "5%" }} />
        <col style={{ width: "5%" }} />
        <col style={{ width: "10%" }} />
        <col style={{ width: "5%" }} />
        <col style={{ width: "10%" }} />
        <col style={{ width: "15%" }} />
        <col style={{ width: "10%" }} />
        <col style={{ width: "5%" }} />
        <col style={{ width: "10%" }} />
      </colgroup>
      <thead>
        <tr>
          <th style={{ verticalAlign: "top" }}>Type of casualty</th>
          <th style={{ verticalAlign: "top" }}>Name of deceased/ injured person</th>
          <th style={{ verticalAlign: "top" }}>Father's name / husband's name</th>
          <th style={{ verticalAlign: "top" }}>Age</th>
          <th style={{ verticalAlign: "top" }}>Gender</th>
          <th style={{ verticalAlign: "top" }}>Residential address</th>
          <th style={{ verticalAlign: "top" }}>Victim's activity</th>
          <th style={{ verticalAlign: "top" }}>Occupation</th>
          <th style={{ verticalAlign: "top" }}>Whether the death due to heatstroke has been certified by the competent authority/doctor</th>
          <th style={{ verticalAlign: "top" }}>Post mortem done</th>
          <th style={{ verticalAlign: "top" }}>Hospitalized</th>
          <th style={{ verticalAlign: "top" }}>Hospital name</th>
          <th style={{ verticalAlign: "top" }}>Reported to police station</th>
          <th style={{ verticalAlign: "top" }}>Police station name</th>
          <th style={{ verticalAlign: "top" }}>Remarks</th>
          <th style={{ verticalAlign: "top" }}>Entry date</th>
          <th style={{ verticalAlign: "top" }}>Payment status</th>
          <th style={{ verticalAlign: "top" }}>Payment date</th>
          <th style={{ verticalAlign: "top" }}>Actions</th>
        </tr>
      </thead>
      <tbody>
        {casualtyData.map((expandedRow, index) => (
          <tr key={index}>
            <td>{expandedRow.typeOfCasualty}</td>
            <td>{expandedRow.personName}</td>
            <td>{expandedRow.personFamilyMemberName}</td>
            <td>{expandedRow.age}</td>
            <td>{expandedRow.gender}</td>
            <td>{expandedRow.personAddress}</td>
            <td>{expandedRow.activityDuringAccident}</td>
            <td>{expandedRow.occupation || "NA"}</td>
            <td>{expandedRow.heatStrokeCertified || "NA"}</td>
            <td>{expandedRow.isPostMortem  ? "Yes" : "No"}</td>
            <td>{expandedRow.isHospitalized  ? "Yes" : "No"}</td>
            <td>{expandedRow.hospitalName || "NA"}</td>
            <td>{expandedRow.isReportedToPS  ? "Yes" : "No"}</td>
            <td>{expandedRow.policeStationName}</td>
            <td>{expandedRow.remarks}</td>
            <td>{expandedRow.entryDate}</td>
            <td>{expandedRow.paymentStatus}</td>
            <td>{expandedRow.paymentDate}</td>
            <td><span
       className="icon__position"
    onClick={() => {
        // if (!isDisabled) {
      navigate("/human-loss/registration-form", {
        state: {
          edit: true,  
          humanLossData: expandedRow, // Pass data to the next page
          disasterData: row.values.disaster_name,
          incidentDatesData: row.original.incidentDate
        },
      });
    // } 
    //   console.log("Human Loss Row Data:", props.row.original); // Log data after navigate
    }}
  >
    <Editicon value={props.value} className="cursor-pointer me-2" />
  </span>
                <span onClick={() => {
                    if (!isDisabled) {
                deletePopUp(expandedRow.id);
                    }
            }
                
                } style={{ cursor: isDisabled ? "not-allowed" : "pointer", marginRight: "10px" }}>
                <Deleteicon className={`cursor-pointer ${isDisabled ? 'disabled_icon' : ''}`} />
              </span></td>
          </tr>
        ))}
      </tbody>
    </table>
                    </div>
                </td>
            </tr>
        );
    };

    return (
        <div className="tabl_container">
            <TableHeader
                {...props}
                searchValue={globalFilter || ""}
                onSearchHandler={setGlobalFilter}
            />
            <div className="iccc__table--container" style={tableStyle}>
                <table {...getTableProps()} className="table iccc--table" id="detailed_report_table">
                    <thead>
                        <tr>
                            {/* Add a static "Expand" column header */}
                            <th
                                style={{
                                    width: "30px",
                                    textAlign: "center",
                                    color: "white",
                                    backgroundColor: "#18a3a0",
                                }}
                            >
                                {t("")}
                            </th>
                            {/* Render the other column headers */}
                            {headerGroups.map((headerGroup, index) => (
                                <React.Fragment key={index}>
                                    {headerGroup.headers.map((column, index) => (
                                        <th
                                            {...column.getHeaderProps(column.getSortByToggleProps())}
                                            style={headerTableHeadStyle}
                                            key={index}
                                        >
                                            {column.render("Header")}
                                            {!column.disableSortBy &&
                                                (column.isSorted ? (
                                                    column.isSortedDesc ? (
                                                        <Sortdownarrow />
                                                    ) : (
                                                        <Sortuparrow />
                                                    )
                                                ) : (
                                                    <Sortingarrow />
                                                ))}
                                            {enableColumnFilters && column.canFilter && (
                                                <div>{column.render("Filter")}</div>
                                            )}
                                        </th>
                                    ))}
                                </React.Fragment>
                            ))}
                        </tr>
                    </thead>
                    <tbody {...getTableBodyProps()}>
                        {page.length > 0 ? (
                            page.map((row, i) => {
                                prepareRow(row);
                                const isExpanded = expandedRowIds[row.id];

                                return (
                                    <React.Fragment key={row.id}>
                                        <tr {...row.getRowProps()}>
                                            {/* Add a static "Expand" cell in each row */}
                                            <td style={{ textAlign: "center" }}>
                                                <span
                                                    onClick={() => toggleRowExpansion(row.id)}
                                                    style={{ cursor: "pointer", fontSize: "18px" }}
                                                >
                                                    {isExpanded ? "-" : "+"}
                                                </span>
                                            </td>
                                            {row.cells.map((cell) => (
                                                <td {...cell.getCellProps()} key={cell.column.id}>
                                                    {cell.render("Cell")}
                                                </td>
                                            ))}
                                        </tr>
                                        {isExpanded && renderExpandedRow(row)}
                                    </React.Fragment>
                                );
                            })
                        ) : (
                            <tr>
                                <td colSpan={columns.length + 1} className="no-data-text">
                                    No data Found
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
            {isdelete && (
                <Confirmpopup
                  show={isdelete}
                  title={t("deletepopuptext")}
                  message={t("deletepopupmessage")}
                  handleToggle={() => deletePopUp(null)}
                  deleteRow={deleteTableDataById}
                  cancel={t("cancelbutton")}
                  action={t("actionbutton")}
                />
              )}

            <div className="d-flex justify-content-between align-items-center">
                {!disbalePaginationFilter2 && (
                    <div className="float-start m-2 mt-2 ms-3">
                        {showRowCount && (
                            <div className="float-start m-2 mt-1 ms-3 pagecount-style">
                                {t("showingentriestable.showing")} {translatedStartIndex}{" "}
                                {t("showingentriestable.to")} {translatedEndIndex}{" "}
                                {t("showingentriestable.of")} {translatedRowCount}{" "}
                                {t("showingentriestable.entries")}
                            </div>
                        )}
                        <div>
                            {/* <span>
Page{''}
<strong>
{pageIndex + 1} of {pageOptions.length}
</strong>{' '}
 
</span>*/}
                        </div>
                    </div>
                )}
                <div className="pagination">
                    {!disbalePaginationFilter && (
                        <div className="d-flex">
                            <span className="pagination_gotopage">
              Go to page:
              <input
                type="number"
                min="1"
                defaultValue={pageIndex + 1}
                onChange={(e) => {
                  let pageNumber = Number(e.target.value);
                  if (pageNumber < 1 || isNaN(pageNumber)) {
                    pageNumber = 1; // Ensures minimum value of 1
                  }
                  gotoPage(pageNumber - 1);
                }}
                className="pagination_gotopage-input"
              />
            </span>
                            <span>
                                <select
                                    className="pagination_select"
                                    value={pageSize}
                                    onChange={(e) => setPageSize(Number(e.target.value))}
                                >
                                    {[10, 50, 100, 500, 1000, 2000].map((pageSize) => (
                                        <option key={pageSize} value={pageSize}>
                                            show {pageSize}
                                        </option>
                                    ))}
                                </select>
                            </span>
                        </div>
                    )}
                    {!disbalePaginationFilter1 && (
                        <>
                            <button
                                className="pagebtn mx-2"
                                onClick={() => previousPage()}
                                disabled={!canPreviousPage}
                            >
                                {"<"}
                            </button>
                            <button className="pagination_page">
                                {translateNumber(pageIndex + 1)}{" "}
                            </button>
                            <button
                                className="pagebtn"
                                onClick={() => nextPage()}
                                disabled={!canNextPage}
                            >
                                {">"}
                            </button>{" "}
                            <span className="right_pageno">
                                {t("pagination.page_of", {
                                    page: translateNumber(pageIndex + 1),
                                    total: translateNumber(pageOptions.length),
                                })}
                            </span>
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};

export default HumanLossTable;