import * as React from "react";
const SvgHumanLoss = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    data-name="Layer 1"
    viewBox="0 0 24 24"
    width="1em"
    height="1em"
    fill="#fff"
    {...props}
  >
    <path stroke="currentColor" d="M14.152 16.359a.5.5 0 0 1 .696-.719L18 18.697V3.5a.5.5 0 0 1 1 0v15.198l3.152-3.057a.5.5 0 0 1 .696.719l-3.318 3.218c-.567.56-1.496.534-2.065-.005zM4 2.5C4 1.121 5.122 0 6.5 0S9 1.121 9 2.5 7.878 5 6.5 5 4 3.879 4 2.5m1 0C5 3.327 5.673 4 6.5 4S8 3.327 8 2.5 7.327 1 6.5 1 5 1.673 5 2.5m19 21a.5.5 0 0 1-.5.5H.5a.5.5 0 0 1 0-1H4v-6.351A3.5 3.5 0 0 1 2 13.5v-4C2 7.57 3.57 6 5.5 6h2C9.43 6 11 7.57 11 9.5v4a3.5 3.5 0 0 1-2 3.149V23h14.5a.5.5 0 0 1 .5.5M5.5 16h2c1.378 0 2.5-1.121 2.5-2.5v-4C10 8.121 8.878 7 7.5 7h-2A2.503 2.503 0 0 0 3 9.5v4C3 14.879 4.122 16 5.5 16m2.5.949c-.165.024-.329.051-.5.051h-2c-.171 0-.335-.027-.5-.051V23h3z" />
  </svg>
);
export default SvgHumanLoss;
