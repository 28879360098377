import {
  faPersonDrowning,
  faBolt,
  faPersonShelter,
  faSkullCrossbones,
  faBed,
  faThunderstorm,
  faTrain,
  faFire,
  faPlane,
  faBuilding,
  faSkull,
  faQuestion,
  faTents
} from "@fortawesome/free-solid-svg-icons";
export const dashboardCardConfigForColdwave = [{
  title: "",
  data: [
    {
      label: "Loss of life",
      key: "number_of_deaths",
      icon: faSkullCrossbones,
    },
    {
      label: "Number of places where bonfire lit",
      key: "number_of_bonfire_places",
      icon: faFire,
    },
    {
      label: "Number of night shelters",
      key: "number_of_night_shelters",
      icon: faTents,
    },
    {
      label: "Number of people taking night shelter",
      key: "number_of_ppl_taking_shelter_in_night_shelters",
      icon: faPersonShelter,
    },
    {
      label: "Number of blankets distributed",
      key: "number_of_blankets_distributed",
      icon: faBed,
    },
  ],
}];

//   export const currentCardConfig = {
//     title: "Facilities Report",
//     data: [
//       {
//         label: "Affected Blocks",
//         key: "curr_dss_affected_blocks",
//         apiKey: "curr_fs_blocks_affected",
//         apiKey2: "",
//       },
//       {
//         label: "Affected Panchayats",
//         key: "curr_dss_affected_gp",
//         apiKey: "curr_fs_affected_panchayats",
//         apiKey2: "",
//       },
//       {
//         label: "Affected Municipal Bodies",
//         key: "curr_dss_affected_mb",
//         apiKey: "curr_fs_affected_mc",
//         apiKey2: "",
//       },
//       {
//         label: "Affected Villages",
//         key: "curr_dss_affected_vill",
//         apiKey: "curr_fs_affected_vills",
//         apiKey2: "",
//       },
//       {
//         label: "Affected Wards",
//         key: "curr_dss_affected_wards",
//         apiKey: "curr_fs_affected_wards",
//         apiKey2: "",
//       },
//       {
//         label: "Affected Population",
//         key: "curr_dss_population_affected",
//         apiKey: "curr_fs_pop_affected",
//         apiKey2: "",
//       },
//       {
//         label: "No. of people in Relief Camps",
//         key: "curr_dss_relief_camps",
//         apiKey: "curr_fs_ppl_evacuated",
//         apiKey2: "",
//       },
//       {
//         label: "Community Kitchen Running",
//         key: "curr_dss_ckr",
//         apiKey: "curr_fs_total_ck",
//         apiKey2: "",
//       },
//       {
//         label: "No. of people having meals today",
//         key: "curr_dss_ppl_etng",
//         apiKey: "curr_fs_ppl_eating",
//         apiKey2: "",
//       },
//       {
//         label: "No of Boats plying today",
//         key: "curr_dss_totl_boats",
//         apiKey: "curr_fs_total_boats",
//         apiKey2: "",
//       },
//     ],
//   };
