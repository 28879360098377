import React, { useCallback, useEffect, useState } from "react";
import SelectComponent from "../../../../Components/UiComponents/SelectComponent/SelectComponent";
import DatePicker from "../../Common/Form/DatePicker/DatePicker";
import { useTranslation } from "react-i18next";
import "./DailyFloodReportStatusForm.css";
import Table from "../../../../Components/UiComponents/Table/Table";
import { getExportedColumnsData } from "../../../../Components/UiComponents/utils";
import { setTableData } from "../../../../CentralStore/ExportLMSTable/dataSlice";
import { useDispatch } from "react-redux";
import { postDataWithBody } from "../../../../Services/Services";
import { getForm1StatusData } from "../../../../Services/EndPoints";
import moment from "moment";
const DailyFloodReportStatusForm = ({ setSelectedDate, selectedDate }) => {
  const { t, i18n } = useTranslation();
  const [searchData, setSearchData] = useState();
  const [completeCount, setCompleteCount] = useState(0);
  const dispatch = useDispatch();
  const [reportStatusData, setReportStatusData] = useState([]);

  const columnsForExport = [
    { label: "Name", key: "dist_name" },
    { label: "Total Blocks", key: "total_block_count" },
    { label: "Affected Blocks", key: "affected" },
    { label: "Partially AffectedBlocks", key: "partially_affected" },
    { label: "NotAffected Blocks", key: "not_affected" },
    { label: "TotalMarked Blocks", key: "totalMarkedBlocks" },
  ];

  useEffect(() => {
    sendDataToCentralStore(searchData);
  }, [searchData]);

  const columns = React.useMemo(
    () => [
      {
        Header: "District",
        accessor: "dist_name",
        disableSortBy: true,
      },
      {
        Header: "Total Blocks",
        accessor: "total_block_count",
        disableSortBy: true,
        centerAlign: true,
      },
      {
        Header: "Affected Blocks",
        accessor: "affected",
        disableSortBy: true,
        centerAlign: true,
      },
      {
        Header: "PartiallyAffected Blocks",
        accessor: "partially_affected",
        disableSortBy: true,
        centerAlign: true,
      },
      {
        Header: "NotAffected Blocks",
        accessor: "not_affected",
        disableSortBy: true,
        centerAlign: true,
      },
      {
        Header: "Total Marked Blocks",
        accessor: "totalMarkedBlocks",
        disableSortBy: true,
        centerAlign: true,
      },
    ],
    []
  );

  const sendDataToCentralStore = (data) => {
    if (data?.length > 0) {
      const columns = columnsForExport;
      console.log(data);
      dispatch(
        setTableData({
          columns,
          tableData: getExportedColumnsData(columns, data),
        })
      );
    }
  };
  const getForm1StatusHandler = async () => {
    try {
      let data = await postDataWithBody(
        getForm1StatusData,
        { reportedOn: selectedDate },
        {}
      );

      // Add totalMarkedBlocks field to each object
      const updatedData = data.map((item) => ({
        ...item,
        totalMarkedBlocks:
          (item.not_affected || 0) +
          (item.partially_affected || 0) +
          (item.affected || 0),
      }));
      setReportStatusData(updatedData);
      sendDataToCentralStore(updatedData);
      const completedCount = updatedData.filter(
        (item) =>
          item.total_block_count === item.totalMarkedBlocks &&
          item.totalMarkedBlocks > 0
      ).length;
      setCompleteCount(completedCount);
    } catch (error) {
      console.error("Error fetching form status data:", error);
    }
  };

  return (
    <div className="report__status">
      <div className="flood__report--container">
        <div className="px-2 d-flex">
          <div>
            <label className="mb-2">Date</label>
            <div className="date_picker_flood_container me-1">
              <DatePicker
                selected={selectedDate}
                defaultDateValue={moment().format("YYYY-MM-DD")}
                maxDate={new Date().toISOString().split("T")[0]}
                name="flood_date_picker"
                setDate={setSelectedDate}
                className="form-control date-picker date_picker_flood"
              />
            </div>
          </div>

          <button
            className="btn btn-success ms-2 mt-1 btn-sm flood__statusreport--button"
            onClick={() => getForm1StatusHandler()}
          >
            {t("floodreport.buttonapply")}
          </button>
          <div className="me-2 region_type_container d-flex align-items-center">
            <div className="completed_count">
              Completed Count : {completeCount}
            </div>
          </div>
        </div>
      </div>

      <div className="p-2">
        <div className="datatable__container">
          {reportStatusData && (
            <Table
              data={reportStatusData}
              columns={columns}
              numberOfRows={7}
              showExport={false}
              showFilter={false}
              showReset={false}
              onExpand={false}
              // showRowCount={false}
              rowCount={reportStatusData?.length || 0}
              setSearchData={setSearchData}
              headerTableHeadStyle={{ background: "#18a3a0", color: "#FFFFFF" }}
              tableStyle={{
                border: "1px solid  #f0f0f0",
                padding: "0px 2px 2px 0px",
                borderRadius: "12px",
              }}
              applyTableHeaderCustomClass={true}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default DailyFloodReportStatusForm;
