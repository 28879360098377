import React, { useEffect, useState, useRef, useLayoutEffect } from "react";
import ReactDatePicker from "react-datepicker";
import Loader from "react-spinner-loader";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";
import { GridStack } from "gridstack";
import FamilyAffectedTrendView from "../FamilyAffectedTrendView";
import DoughnutChart from "../DoughnutChart";
import { getData, postDataWithBody } from "../../../../Services/Services";
import {
  getStateFloodCountData,
  getBlockWiseReport,
  getAffectedBlock,
  getAffectedDistrict,
  getAffectedNagarNigam,
  getAffectedPanchayat,
  getAffectedDrilldownView,
  getCurrStateFloodCountData,
  getCurrBarChartData,
} from "../../../../Services/EndPoints";
import "./HumanLossDashboard.css";
import { useTranslation } from "react-i18next";
import i18n from "i18next";
import { Expand, MapIcon } from "../../../../Components/IconComponents";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import HumanLossDashboardcar from "../StateDashboard/DashboardCard/DashboardCard";
import { currentCardConfig } from "../StateDashboard/StateDashboardConfig";
import {
  dashboardCardConfig,
  dashboardCardConfigHumanLoss,
} from "./HumanLossDamageConfig";
import LMSButton from "../../Common/LMSButton/LMSButton";
import HalfDoughnutChart from "../HalfDoughnutChart";
import HorizontalBarChart from "../HorizontalBarChart";
import CurrentDashboardCard from "../CurrentDashboardCard/CurrentDashboardCard";
import TabSwitch from "../TabSwitch/TabSwitch";
import { useNavigate } from "react-router-dom";
import WeatherDataTable from "../WeatherDataTable";
import ToggleViewBmsk from "../ToggleView/ToggleViewBmsk";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { Popover } from "react-bootstrap";
import HumanDashboardCard from "./DashboardCard/HumanDashboardCard";
import HumanLossDougnut from "./DashboardCard/HumanLossTableDougnut";
import FamilyAffectedTrendViewHumanLoss from "./DashboardCard/FamilyAffectedTrendViewHumanLoss";
import Multiselect from "multiselect-react-dropdown";

const HumanLossDashboard = () => {
  const handle = useFullScreenHandle();
  const [clickedKey, setClickedKey] = useState("");
  const [cardData, setCardData] = useState(null);
  const [currCardData, setCurrCardData] = useState(null);
  const [loader, setLoader] = useState(false);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [fromDate, setFromDate] = useState("2025-01-01");
  const [toDate, setToDate] = useState();
  const [horizontalChartTitle, setHorizontalChartTitle] = useState("");
  const [drildownAPIkey, setDrildownAPIkey] = useState("");
  const [drillDownData, setDrillDownData] = useState([]);
  const [trendData, setTrendData] = useState([]);
  const [affectedDistrictData, setAffectedDistrictData] = useState([]);
  const [affectedBlockData, setAffectedBlockData] = useState([]);
  const [affectedNagarNigamData, setAffectedNagarNigamData] = useState([]);
  const [affectedPanchayatData, setAffectedPanchayatData] = useState([]);
  const [activeTab, setActiveTab] = useState("dss");
  const [activeTabBmsk, setActiveTabBmsk] = useState("BMSK");
  const [activeKeyName, setactiveKeyName] = useState("");
  const [apiKeyName, setApiKeyName] = useState({});
  const [selectedYear, setSelectedYear] = useState(2025);
  const [error, setError] = useState(false);
  const [selectedIncident, setSelectedIncident] = useState(""); // State to store I
  const [applyClicked, setApplyClicked] = useState(false);
  const [firstLoad, setFirstLoad] = useState(true);
  

  const { t } = useTranslation();
  const navigate = useNavigate();

  const refreshCardDetailHoriChart = async (
    title,
    apiKey,
    key,
    apiKey2,
    keyName
  ) => {
    setApiKeyName({
      apiKey: apiKey,
      apiKey2: apiKey2,
      keyName: keyName,
      key: key,
    });
    setHorizontalChartTitle(title);
    setDrildownAPIkey(key);
  };

  useEffect(() => {
    const currentYear = 2025; // Set default year
    const today = new Date(); // Get today's date

    // Set default dates
    setSelectedYear(currentYear);
    setFromDate(new Date(currentYear, 0, 1)); // 01-01-2025
    setToDate(today); // Today's date
  }, []); // Run only on initial load

  useEffect(() => {
    if (fromDate && toDate && fromDate > toDate) {
      setError( "Select proper From Date and To Date");
    } else {
      setError(null);
    }
  }, [fromDate, toDate]);

  useEffect(() => {
    const timer = setTimeout(() => {
      window.location.reload();
    }, 600000); // 3 seconds

    return () => clearTimeout(timer); // Cleanup on unmount
  }, []);

  const toggleFullscreen = () => {
    if (isFullscreen) {
      handle.exit();
    } else {
      handle.enter();
    }
    setIsFullscreen(!isFullscreen);
  };
  const layoutData = [];
  const layoutDataBmsk = [];
  const layoutData1 = [];

  const girdoptions = {
    staticGrid: true,
    column: 24,
    margin: 5,
  };
  useLayoutEffect(() => {
    var grid = GridStack.init(girdoptions);
  }, []);

  const externalStyle1 = {
    multiselectContainer: {
      minWidth: "6rem",
      height: "1.5rem", // Increase height slightly for better balance
      backgroundColor: "#f0f0f0",
      borderRadius: "5px",
      display: "flex",
      alignItems: "center", // Center vertically
      justifyContent: "space-between", // Balance text and arrow
      padding: "0 0.5rem", // Add spacing inside the container
      boxSizing: "border-box",
      color: "#000",
      fontSize: "14px", // Adjust font size for better proportion
      lineHeight: "1", // Ensures text height is balanced
    },
    optionContainer: {
      // border: "1px solid #ccc",
      backgroundColor: "#fff",
      color: "#000",
      maxHeight: "200px",
      overflowY: "auto",
    },
  };

  const handleIncidentSelection = (incidentId, incidentName) => {
    setSelectedIncident({ id: incidentId, name: incidentName }); // Store both id and name
  };

  console.log(selectedIncident, "indash");

  const handleYearSelection = (selectedList) => {
    if (selectedList.length > 0) {
      const year = parseInt(selectedList[0].name, 10); // Get selected year
      setSelectedYear(year);

      // If the selected year is 2025, set today's date as the toDate
      const fromDate = new Date(year, 0, 1); // 01-01-[year]
      const toDate = year === 2025 ? new Date() : new Date(year, 11, 31); // 31-12-[year] for past years

      setFromDate(fromDate);
      setToDate(toDate);
    }
  };
  const fromDateNew = moment(fromDate).format("YYYY-MM-DD");
  const toDateNew = moment(toDate).format("YYYY-MM-DD");

  console.log("activeTab:", activeTab); // Log the value of activeTab
  console.log("activeTabBmsk:", activeTabBmsk); // Log the value of activeTabBmsk

  const layoutToDisplay = (() => {
    if (activeTab === "currentStatus") {
      return layoutData1;
    } else if (activeTabBmsk === "BMSK") {
      return layoutDataBmsk;
    } else if (activeTabBmsk === "Charts" || activeTab === "dss") {
      return layoutData;
    } else {
      return layoutData; // Default layout
    }
  })();

  const handleApplyClick = () =>{
    setLoader(true);
    setApplyClicked(true);
    setFirstLoad(false);
  }
  

  const onApplyHandled = () => {
    setApplyClicked(false); // ✅ Reset after API call is made
    setLoader(false)
  };

  return (
    <FullScreen handle={handle}>
      <div
        className={
          isFullscreen
            ? "state-dashboard_div fullscreen"
            : "state-dashboard_div"
        }
        style={{
          height: isFullscreen ? "100vh" : "auto", // Full height when fullscreen, else auto height
          overflow: isFullscreen ? "auto" : "initial", // Enable scrolling in fullscreen mode
          padding: "10px",
        }}
      >
        <Loader
          show={loader}
          type="body"
          stack="vertical"
          message="Loading Data"
        />
        <div className="container-fluid">
          <div className="toprow--container">
            <div className="dash-title-humanloss">
              Primary Incidents Report (Human Loss)
            </div>
            <div
              className="single-multiselect"
              style={{ display: "flex", alignItems: "center", gap: "10px" }}
            >
              <label
                htmlFor="entryType"
                style={{ fontSize: "14px", fontWeight: "500", color: "White" }}
              >
                Year
              </label>
              <Multiselect
                id="entryType"
                style={{ ...externalStyle1 }}
                className="custom-multiselect"
                // Assuming externalStyle1 is an object
                options={[{ name: "2024" }, { name: "2025" }]} // Static options
                displayValue="name"
                customArrow={true}
                placeholder="Select Fiscal Year"
                singleSelect={true}
                closeOnSelect={true}
                onSelect={handleYearSelection} // Calling the handler on selection
                selectedValues={
                  selectedYear ? [{ name: selectedYear.toString() }] : [] // Show the selected value if available
                }
                customCloseIcon={true}
              />
            </div>

            <div className="date-picker-container-humanloss d-flex gap-3">
              <div className="date-picker-container">
                <span className="ms-1">{t("flooddisaster.datetxtfrom")}</span>
                <ReactDatePicker
                  selected={fromDate}
                  onChange={setFromDate}
                  dateFormat="dd/MM/yyyy"
                  isClearable
                  disabled={activeTab === "currentStatus"}
                  minDate={new Date(selectedYear, 0, 1)} // Dynamic minDate
                  maxDate={new Date(selectedYear, 11, 31)} // Dynamic maxDate
                  placeholderText={t("flooddisaster.dateplaceholder")}
                  className="custom-datepicker ms-2"
                  todayButton="Today"
                />
              </div>

              <div className="date-picker-container">
                <span>{t("flooddisaster.datetxtto")}</span>
                <ReactDatePicker
                  selected={toDate}
                  onChange={setToDate}
                  dateFormat="dd/MM/yyyy"
                  minDate={new Date(selectedYear, 0, 1)} // Dynamic minDate
                  maxDate={new Date(selectedYear, 11, 31)} // Dynamic maxDate
                  isClearable
                  disabled={activeTab === "currentStatus"}
                  placeholderText={t("flooddisaster.dateplaceholder")}
                  className="custom-datepicker ms-2"
                  todayButton="Today"
                />
              </div>
            </div>
            <LMSButton
              size="btn_sm"
              label={t("flooddisaster.apply")}
              onClick={handleApplyClick}
              disabled={!!error}
              // className="apply-btn"
            />
            <span onClick={toggleFullscreen} className="cursor-pointer">
              <Expand fill="var(--white3-color)" width="1.1em" height="1.1em" />
            </span>
          </div>
          {error && <div className="error-message-humanloss">{error}</div>}
          {/* {activeTab === "dss" ? ( */}
          <div className="dashboard-container mt-4">
            <div className="dashboard_cards">
              {dashboardCardConfigHumanLoss?.map((card, index) => (
                <HumanDashboardCard
                  key={index}
                  config={card}
                  clickedKey={clickedKey}
                  setClickedKey={setClickedKey}
                  triggeredFunction={refreshCardDetailHoriChart}
                  onIncidentSelect={handleIncidentSelection} // Pass function to child
                  fromDateNew={fromDateNew}
                  toDateNew={toDateNew}
                  applyClicked={applyClicked}
                  onApplyHandled={onApplyHandled}
                  firstLoad={firstLoad}
                  setLoader={setLoader}
                />
              ))}
            </div>
            <div className="weather_data_table">
              <HumanLossDougnut
                fromDateNew={fromDateNew}
                toDateNew={toDateNew}
                applyClicked={applyClicked}
                onApplyHandled={onApplyHandled}
                firstLoad={firstLoad}
                title={selectedIncident.name}
                selectedIncident={selectedIncident} // Pass selected ID
              />
              <FamilyAffectedTrendViewHumanLoss
                // startDateStr={moment(fromDate).format("YYYY-MM-DD")}
                // endDateStr={moment(toDate).format("YYYY-MM-DD")}
                // chartData={trendData}
                // drillDownKey={drildownAPIkey}
                // data={drillDownData}
                title={selectedIncident.name}
                activeTab={activeTab}
                activeKeyName={activeKeyName}
                fromDateNew={fromDateNew}
                toDateNew={toDateNew}
                selectedIncident={selectedIncident} // Pass selected ID
                applyClicked={applyClicked}
                onApplyHandled={onApplyHandled}
                firstLoad={firstLoad}
                setLoader={setLoader}

              />
            </div>
          </div>

          <div></div>
          <div>
            <div
              className="grid-stack grid-stack-instance-1 mt-1"
              style={{
                overflowY: "auto", // Enable vertical scrolling
                maxHeight: isFullscreen ? "calc(100vh - 120px)" : "initial", // Adjust height based on fullscreen
              }}
            >
              {layoutToDisplay.map((gridItem) => (
                <div
                  className="grid-stack-item"
                  gs-x={gridItem.x}
                  gs-y={gridItem.y}
                  gs-w={gridItem.width}
                  gs-h={gridItem.height}
                  key={gridItem.id}
                >
                  <div
                    className={`grid-stack-item-content ${
                      gridItem?.haveNoBg ? " " : " grid-item"
                    } ${
                      gridItem?.haveOverflowVisible
                        ? " apply__overflow--visible"
                        : ""
                    }`}
                  >
                    {gridItem.component}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </FullScreen>
  );
};
export default HumanLossDashboard;
