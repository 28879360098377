import * as React from "react";
const SvgPartiallyAffectedRptIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    data-name="Layer 1"
    viewBox="0 0 24 24"
    width="1em"
    height="1em"
    fill= "currentColor"
    {...props}
  >
    <path d="M6 15a1 1 0 0 1 1 1v3a1 1 0 1 1-2 0v-3a1 1 0 0 1 1-1m4-4a1 1 0 0 0-1 1v7a1 1 0 1 0 2 0v-7a1 1 0 0 0-1-1m14 10.833C24 23.21 22.359 24 19.5 24s-4.5-.79-4.5-2.167V14.5c0-.883 1.85-1.5 4.5-1.5s4.5.617 4.5 1.5zM19.5 16c-.956 0-1.802-.083-2.5-.227v1.769c.249.165.996.458 2.5.458 1.48 0 2.237-.31 2.5-.489v-1.737c-.698.144-1.544.227-2.5.227zm2.5 5.607v-1.938c-.662.201-1.48.331-2.5.331-1.041 0-1.85-.127-2.5-.321v1.928c.296.16 1.114.393 2.5.393s2.204-.233 2.5-.393M13 10c-1.654 0-3-1.346-3-3V2.047C9.838 2.031 9.679 2 9.515 2H5C3.346 2 2 3.346 2 5v14c0 1.654 1.346 3 3 3h7a1 1 0 1 1 0 2H5c-2.757 0-5-2.243-5-5V5c0-2.757 2.243-5 5-5h4.515c1.869 0 3.627.728 4.95 2.05l3.484 3.486a7 7 0 0 1 2.028 4.383 1 1 0 0 1-.916 1.078c-.586.056-1.064-.418-1.092-.997h-4.97zm0-2h4.316a5 5 0 0 0-.781-1.05l-3.484-3.485A5 5 0 0 0 12 2.684V7c0 .551.448 1 1 1" />
  </svg>
);
export default SvgPartiallyAffectedRptIcon;
