import React, { useEffect, useState, useRef, useLayoutEffect } from "react";
import ReactDatePicker from "react-datepicker";
import Loader from "react-spinner-loader";
import moment from "moment";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import { useTranslation } from "react-i18next";
import { Expand } from "../../../../Components/IconComponents";
import LMSButton from "../../Common/LMSButton/LMSButton";
import FireDashboardcard from "./FireDashboardcard";
import { dashboardCardConfigForFire } from "./DashboardConfigForFire";
import { getData } from "../../../../Services/Services";
import {
  firedashboardcardsdata,
  getStateFloodCountData,
} from "../../../../Services/EndPoints";
import "react-datepicker/dist/react-datepicker.css";
import "./FireDashboard.css";
import Multiselect from "multiselect-react-dropdown";
import FireBarChart from "./FireBarChart";

const FireDashboard = () => {
  // Hooks
  const { t } = useTranslation();
  const handle = useFullScreenHandle();

  // State variables
  const [loader, setLoader] = useState(false);
  const [error, setError] = useState(false);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [activeTab, setActiveTab] = useState("dss");
  const [cardData, setCardData] = useState(null);
  const [selectedIncident, setSelectedIncident] = useState("");

  // Date state variables
  const [internalFromDate, setInternalFromDate] = useState(new Date());
  const [internalToDate, setInternalToDate] = useState(new Date());
  const [appliedFromDate, setAppliedFromDate] = useState(new Date());
  const [appliedToDate, setAppliedToDate] = useState(new Date());
  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const [fromDate, setFromDate] = useState("2025-01-01");
  const [toDate, setToDate] = useState(new Date());
  const [selectedYear, setSelectedYear] = useState(2025);
  const [applyClicked, setApplyClicked] = useState(false);
  const [firstLoad, setFirstLoad] = useState(true);

  // Functions
  const toggleFullscreen = () => {
    if (isFullscreen) {
      handle.exit();
    } else {
      handle.enter();
    }
    setIsFullscreen(!isFullscreen);
  };

  const handleFromDateChange = (date) => {
    setInternalFromDate(date);
  };

  const handleToDateChange = (date) => {
    setInternalToDate(date);
  };

  useEffect(() => {
    if (fromDate && toDate && fromDate > toDate) {
      setError("Select proper From Date and To Date");
    } else {
      setError(null);
    }
  }, [fromDate, toDate]);

  const externalStyle1 = {
    multiselectContainer: {
      minWidth: "6rem",
      height: "1.5rem", // Increase height slightly for better balance
      backgroundColor: "#f0f0f0",
      borderRadius: "5px",
      display: "flex",
      alignItems: "center", // Center vertically
      justifyContent: "space-between", // Balance text and arrow
      padding: "0 0.5rem", // Add spacing inside the container
      boxSizing: "border-box",
      color: "#000",
      fontSize: "14px", // Adjust font size for better proportion
      lineHeight: "1", // Ensures text height is balanced
    },
    optionContainer: {
      // border: "1px solid #ccc",
      backgroundColor: "#fff",
      color: "#000",
      maxHeight: "200px",
      overflowY: "auto",
    },
  };

  const handleYearSelection = (selectedList) => {
    if (selectedList.length > 0) {
      const year = parseInt(selectedList[0].name, 10); // Get the selected year
      setSelectedYear(year);

      // Set fromDate to June 1st of the selected year
      const fromDate = new Date(year, 0, 1); // June 1st

      // Get today's date
      const today = new Date();

      // Set toDate to today if today is between June 1st and October 31st of the selected year
      const toDate =
        today >= fromDate && today <= new Date(year, 9, 31)
          ? today
          : new Date(year, 9, 31); // October 31st

      setFromDate(fromDate);
      setToDate(toDate);
    }
  };

  const fromDateNew = moment(fromDate).format("YYYY-MM-DD");
  const toDateNew = moment(toDate).format("YYYY-MM-DD");

  useEffect(() => {
    refreshDashboardBtnClick();
  }, []);

  // Data fetching function
  const refreshDashboardBtnClick = async () => {
    setLoader(true);
    const response = await getData(
      firedashboardcardsdata
        .replace("FROMDATE", fromDateNew)
        .replace("TODATE", toDateNew)
    );

    setCardData(response);

    setApplyClicked(true);
    setFirstLoad(false);

    const startDateStr = moment(internalFromDate).format("YYYY-MM-DD");
    const endDateStr = moment(internalToDate).format("YYYY-MM-DD");

    // setLoader(true);

    // try {
    //   const data = await getData(
    //     getStateFloodCountData
    //       .replace("FROMDATE", startDateStr)
    //       .replace("TODATE", endDateStr)
    //   );

    //   if (data && data[0]) {
    //     setCardData(data[0]);
    //   }
    // } catch (error) {
    //   console.error("Error fetching dashboard data:", error);
    //   setError(true);
    // } finally {
    //   setLoader(false);
    // }

    setLoader(false);
  };

  const onApplyHandled = () => {
    setApplyClicked(false); // ✅ Reset after API call is made
    setLoader(false);
  };

  console.log(cardData, "cardData");

  // Initial data load
  useEffect(() => {
    if (isInitialLoad) {
      refreshDashboardBtnClick();
      setIsInitialLoad(false);
    }
  }, [isInitialLoad]);

  // Get the current applied dates for use in components
  const startDateStr = moment(appliedFromDate).format("YYYY-MM-DD");
  const endDateStr = moment(appliedToDate).format("YYYY-MM-DD");

  return (
    <FullScreen handle={handle}>
      <div
        className={
          isFullscreen
            ? "state-dashboard_div fullscreen"
            : "state-dashboard_div"
        }
        style={{
          height: isFullscreen ? "100vh" : "auto",
          overflow: isFullscreen ? "auto" : "initial",
          padding: "10px",
        }}
      >
        <Loader
          show={loader}
          type="body"
          stack="vertical"
          message="Loading Data"
        />
        <div className="container-fluid">
          <div className="toprow--container">
            <div className="dash-title">Fire Incident Dashboard</div>
            <div
              className="single-multiselect"
              style={{ display: "flex", alignItems: "center", gap: "10px" }}
            >
              <label
                htmlFor="entryType"
                style={{
                  fontSize: "14px",
                  fontWeight: "500",
                  color: "White",
                  marginLeft: "15px",
                }}
              >
                Year
              </label>
              <Multiselect
                id="entryType"
                style={{ ...externalStyle1 }}
                className="custom-multiselect"
                // Assuming externalStyle1 is an object
                options={[{ name: "2024" }, { name: "2025" }]} // Static options
                displayValue="name"
                customArrow={true}
                placeholder="Select Fiscal Year"
                singleSelect={true}
                closeOnSelect={true}
                onSelect={handleYearSelection} // Calling the handler on selection
                selectedValues={
                  selectedYear ? [{ name: selectedYear.toString() }] : [] // Show the selected value if available
                }
                customCloseIcon={true}
              />
            </div>
            <div className="d-flex gap-3">
              <div className="date-picker-container">
                <span className="ms-1">{t("flooddisaster.datetxtfrom")}</span>
                <ReactDatePicker
                  selected={fromDate}
                  onChange={setFromDate}
                  dateFormat="dd/MM/yyyy"
                  isClearable
                  disabled={activeTab === "currentStatus"}
                  minDate={new Date(selectedYear, 0, 1)} // Dynamic minDate
                  maxDate={new Date(selectedYear, 11, 31)} // Dynamic maxDate
                  placeholderText={t("flooddisaster.dateplaceholder")}
                  className="custom-datepicker ms-2"
                  todayButton="Today"
                />
              </div>

              <div className="date-picker-container">
                <span>{t("flooddisaster.datetxtto")}</span>
                <ReactDatePicker
                  selected={toDate}
                  onChange={setToDate}
                  dateFormat="dd/MM/yyyy"
                  minDate={new Date(selectedYear, 0, 1)} // Dynamic minDate
                  maxDate={new Date(selectedYear, 11, 31)} // Dynamic maxDate
                  isClearable
                  disabled={activeTab === "currentStatus"}
                  placeholderText={t("flooddisaster.dateplaceholder")}
                  className="custom-datepicker ms-2"
                  todayButton="Today"
                />
              </div>
            </div>

            <div
              className="single-multiselect"
              style={{ display: "flex", alignItems: "center", gap: "10px" }}
            >
              <LMSButton
                size="btn_sm"
                label={t("flooddisaster.apply")}
                onClick={refreshDashboardBtnClick}
                disabled={!!error}
              />
              <span onClick={toggleFullscreen} className="cursor-pointer">
                <Expand
                  fill="var(--white3-color)"
                  width="1.1em"
                  height="1.1em"
                />
              </span>
            </div>
          </div>
          {error && <div className="error-message-fire">{error}</div>}
          <div className="dashboard-container mt-4">
            <div className="dashboard_cards">
              {dashboardCardConfigForFire?.map((card, index) => (
                <FireDashboardcard
                  key={index}
                  config={card}
                  onIncidentSelect={(incidentKey) => {
                    setSelectedIncident(incidentKey);
                  }}
                  startDateStr={startDateStr}
                  endDateStr={endDateStr}
                  cardData={cardData}
                />
              ))}
            </div>
            <div className="weather_data_table">
              <FireBarChart
                fromDate={appliedFromDate}
                toDate={appliedToDate}
                fromDateNew={fromDateNew}
                toDateNew={toDateNew}
                selectedIncident={selectedIncident}
                title={selectedIncident}
                applyClicked={applyClicked}
                onApplyHandled={onApplyHandled}
                firstLoad={firstLoad}
              />
            </div>
          </div>
        </div>
      </div>
    </FullScreen>
  );
};

export default FireDashboard;
