import * as yup from "yup";

const decimalValidation = yup
  .number()
  .typeError("This field must be a valid number") // Custom error for non-numeric input
  .transform((value, originalValue) => {
    if (originalValue === "") return null; // Treat empty string as null
    return value;
  })
  .test("max-decimals", "Only up to 5 decimal places are allowed", (value) => {
    if (value === undefined || value === null) return true; // Allow if no value
    // Regex to check for up to 5 decimal places
    return /^(\d+(\.\d{1,5})?)?$/.test(value);
  })
  .nullable(); // Allow null values if needed



  // Number validation for integer-only fields
const numberValidation = yup
.number()
.typeError("This field must be a number")  // Ensure it's a number
.min(0, "Negative numbers are not allowed") // Disallow negative numbers
.nullable(); // Allow null values if needed


const integerValidation = yup
  .string() // Keep it as a string so we can handle the raw input more easily
  .matches(/^\d+$/, 'This field must be an integer') // Regex to ensure only digits are allowed
  .test('is-integer', 'This field must be an integer', (value) => {
    // Allow empty or null values (for nullable fields)
    if (value === null || value === '' || value === undefined) return true;

    // Ensure value is a string before checking for a decimal point
    if (typeof value === 'string' && value.includes('.')) return false;

    // Check if it contains only numeric characters (no decimals or other non-numeric characters)
    return /^\d+$/.test(value);
  })
  .typeError('This field must be a number') // Triggered when non-numeric characters are typed
  .nullable(); // Allow null values if needed





export const schema = [
  yup.object({
    remarks: yup
      .string()
      .max(200, "Remarks must be 200 characters or less"), // Limit to 200 characters

      // Validation for the four fields
      number_of_deaths_due_to_heatwave_today:numberValidation ,
      number_of_deceased_person_for_whom_exgratia_payment_done_today:numberValidation ,
      number_of_persons_hospitalized_today:numberValidation ,
      number_of_hospitalized_person_for_whom_payment_done_today:numberValidation ,
  }),
];

export const DailyHeatWaveFormConfig = [
  {
    id: 0,
    name: "",
    heading: "",
    fields: [
        {
            name: "date",
            label: "Date",
            type: "date",
            disabled: true
          },
      {
            name: "district_name",
            label: "Name of District",
            type: "input",
            // options: [
            //   { value: "0", label: "Activity" },
            //   { value: "1", label: "Notification" },
            // ],
            disabled: true
      },
      {
            name: "block_name",
            label: "Block",
            type: "input",
            disabled: true
            // options: [
            //   { value: "0", label: "Activity" },
            //   { value: "1", label: "Notification" },
            // ],
      },
      {
        name: "number_of_deaths_due_to_heatwave_today",
        label: "Number of Deaths due to Heat Wave (Today)",
        type: "input",
        // mandatory: "true",
      },
      {
        name: "number_of_deaths_cumulative",
        label: "Number of Deaths due to Heat Wave (Cumulative)",
        type: "input",
        disabled: true
      },
      {
        name: "number_of_deceased_person_for_whom_exgratia_payment_done_today",
        label: "Number of deceased person for whom ex-gratia payment done (Today)",
        type: "input",
      },
      {
        name: "number_of_deceased_person_for_whom_exgratia_payment_done_cumulative",
        label: "Number of deceased person for whom ex-gratia payment done (Cumulative)",
        type: "input",
        disabled: true
      },

      {
        name: "number_of_persons_hospitalized_today",
        label: "Number of persons hospitalized (Today)",
        type: "input",
      },
      {
        name: "number_of_persons_hospitalized_cumulative",
        label: "Number of persons hospitalized (Cumulative)",
        type: "input",
        disabled: true
      },
      {
        name: "number_of_hospitalized_person_for_whom_payment_done_today",
        label: "Number of hospitalized person for whom payment done (Today)",
        type: "input",
      },
      {
        name: "number_of_hospitalized_person_for_whom_payment_done_cumulative",
        label: "Number of hospitalized person for whom payment done (Cumulative)",
        type: "input",
        disabled: true
      },
      {
        name: "remarks",
        label: "Remarks",
        type: "text_area",
      },
     
    ],
  },
];

