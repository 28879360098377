import React, { useEffect, useState, useRef, useLayoutEffect } from "react";
import ReactDatePicker from "react-datepicker";
import Loader from "react-spinner-loader";
import moment from "moment";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import { useTranslation } from "react-i18next";
import { Expand } from "../../../../Components/IconComponents";
import LMSButton from "../../Common/LMSButton/LMSButton";
import HeatWaveBarChart from "./HeatWaveBarChart";
import HeatWaveDashboardcard from "./HeatWaveDashboardcard";
import { dashboardCardConfigForHeatwave } from "./DashboardConfigForHeatwave";
import { getData } from "../../../../Services/Services";
import { heatwavecardsdata } from "../../../../Services/EndPoints";
import "react-datepicker/dist/react-datepicker.css";
import "./HeatwaveDashboard.css";
import Multiselect from "multiselect-react-dropdown";

const HeatWaveDashboard = () => {
  // Hooks
  const { t } = useTranslation();
  const handle = useFullScreenHandle();

  // State variables
  const [loader, setLoader] = useState(false);
  const [error, setError] = useState(false);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [activeTab, setActiveTab] = useState("dss");
  const [cardData, setCardData] = useState(null);
  const [selectedIncident, setSelectedIncident] = useState("");

  // Date state variables
  const [internalFromDate, setInternalFromDate] = useState(new Date());
  const [internalToDate, setInternalToDate] = useState(new Date());
  const [appliedFromDate, setAppliedFromDate] = useState(new Date());
  const [appliedToDate, setAppliedToDate] = useState(new Date());
  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const [selectedYear, setSelectedYear] = useState(2025);


  // Functions
  const toggleFullscreen = () => {
    if (isFullscreen) {
      handle.exit();
    } else {
      handle.enter();
    }
    setIsFullscreen(!isFullscreen);
  };

  const handleFromDateChange = (date) => {
    setInternalFromDate(date);
  };

  const handleToDateChange = (date) => {
    setInternalToDate(date);
  };

  // Data fetching function
  const refreshDashboardBtnClick = async () => {
    setLoader(true);
    console.log("hi");
    // Update the applied dates when apply button is clicked
    setAppliedFromDate(internalFromDate);
    setAppliedToDate(internalToDate);

    const startDateStr = moment(internalFromDate).format("YYYY-MM-DD");
    const endDateStr = moment(internalToDate).format("YYYY-MM-DD");

    

    try {
      const data = await getData(
        heatwavecardsdata
          .replace("FROMDATE", startDateStr)
          .replace("TODATE", endDateStr)
      );

      if (data && data[0]) {
        setCardData(data[0]);
      }
    } catch (error) {
      console.error("Error fetching dashboard data:", error);
      setError(true);
    } finally {
      setLoader(false);
    }

  };

  const externalStyle1 = {
    multiselectContainer: {
      minWidth: "6rem",
      height: "1.5rem", // Increase height slightly for better balance
      backgroundColor: "#f0f0f0",
      borderRadius: "5px",
      display: "flex",
      alignItems: "center", // Center vertically
      justifyContent: "space-between", // Balance text and arrow
      padding: "0 0.5rem", // Add spacing inside the container
      boxSizing: "border-box",
      color: "#000",
      fontSize: "14px", // Adjust font size for better proportion
      lineHeight: "1", // Ensures text height is balanced
    },
    optionContainer: {
      // border: "1px solid #ccc",
      backgroundColor: "#fff",
      color: "#000",
      maxHeight: "200px",
      overflowY: "auto",
    },
  };

  const handleYearSelection = (selectedList) => {
    if (selectedList.length > 0) {
      const year = parseInt(selectedList[0].name, 10); // Get selected year
      setSelectedYear(year);

      // If the selected year is 2025, set today's date as the toDate
      const fromDate = new Date(year, 0, 1); // 01-01-[year]
      const toDate = year === 2025 ? new Date() : new Date(year, 11, 31); // 31-12-[year] for past years

      setInternalFromDate(fromDate);
      setInternalToDate(toDate);
    }
  };

  useEffect(() => {
    if (internalFromDate && internalToDate && internalFromDate > internalToDate) {
      setError("Select proper From Date and To Date");
    } else {
      setError(null);
    }
  }, [internalFromDate, internalToDate]);

  // Initial data load
  useEffect(() => {
    if (isInitialLoad) {
      refreshDashboardBtnClick();
      setIsInitialLoad(false);
    }
  }, [isInitialLoad]);

  // Get the current applied dates for use in components
  const startDateStr = moment(appliedFromDate).format("YYYY-MM-DD");
  const endDateStr = moment(appliedToDate).format("YYYY-MM-DD");

  return (
    <FullScreen handle={handle}>
      <div
        className={isFullscreen ? "state-dashboard_div fullscreen" : "state-dashboard_div"}
        style={{
          height: isFullscreen ? "100vh" : "auto",
          overflow: isFullscreen ? "auto" : "initial",
          padding: "10px",
        }}
      >
        <Loader
          show={loader}
          type="body"
          stack="vertical"
          message="Loading Data"
        />
        <div className="container-fluid">
          <div className="toprow--container">
            <div className="dash-title">Heat Wave Dashboard</div>
            <div className="d-flex gap-3">
              <div
                className="single-multiselect"
                style={{ display: "flex", alignItems: "center", gap: "10px" }}
              >
                <label
                  htmlFor="entryType"
                  style={{ fontSize: "14px", fontWeight: "500", color: "White" }}
                >
                  Year
                </label>
                <Multiselect
                  id="entryType"
                  style={{ ...externalStyle1 }}
                  className="custom-multiselect"
                  // Assuming externalStyle1 is an object
                  options={[{ name: "2024" }, { name: "2025" }]} // Static options
                  displayValue="name"
                  customArrow={true}
                  placeholder="Select Fiscal Year"
                  singleSelect={true}
                  closeOnSelect={true}
                  onSelect={handleYearSelection} // Calling the handler on selection
                  selectedValues={
                    selectedYear ? [{ name: selectedYear.toString() }] : [] // Show the selected value if available
                  }
                  customCloseIcon={true}
                />
              </div>
              <div className="date-picker-container">
                <span className="ms-1">{t("flooddisaster.datetxtfrom")}</span>
                <ReactDatePicker
                  selected={internalFromDate}
                  onChange={handleFromDateChange}
                  dateFormat="dd/MM/yyyy"
                  isClearable
                  disabled={activeTab === "currentStatus"}
                  placeholderText={t("flooddisaster.dateplaceholder")}
                  className="custom-datepicker ms-2"
                  todayButton="Today"
                />
              </div>

              <div className="date-picker-container">
                <span>{t("flooddisaster.datetxtto")}</span>
                <ReactDatePicker
                  selected={internalToDate}
                  onChange={handleToDateChange}
                  dateFormat="dd/MM/yyyy"
                  isClearable
                  disabled={activeTab === "currentStatus"}
                  placeholderText={t("flooddisaster.dateplaceholder")}
                  className="custom-datepicker ms-2"
                  todayButton="Today"
                />
              </div>
            </div>

            <div
              className="single-multiselect"
              style={{ display: "flex", alignItems: "center", gap: "10px" }}
            >
              <LMSButton
                size="btn_sm"
                label={t("flooddisaster.apply")}
                onClick={refreshDashboardBtnClick}
                disabled={!!error}
              />
              <span onClick={toggleFullscreen} className="cursor-pointer">
                <Expand
                  fill="var(--white3-color)"
                  width="1.1em"
                  height="1.1em"
                />
              </span>
            </div>
          </div>
          {error && <div className="error-message-heatwave">{error}</div>}
          <div className="dashboard-container mt-4">
            <div className="dashboard_cards">
              {dashboardCardConfigForHeatwave?.map((card, index) => (
                <HeatWaveDashboardcard
                  key={index}
                  config={card}
                  onIncidentSelect={(incidentKey) => {
                    setSelectedIncident(incidentKey);
                  }}
                  startDateStr={startDateStr}
                  endDateStr={endDateStr}
                />
              ))}
            </div>
            <div className="weather_data_table">
              <HeatWaveBarChart
                fromDate={appliedFromDate}
                toDate={appliedToDate}
                startDateStr={startDateStr}
                endDateStr={endDateStr}
                selectedIncident={selectedIncident}
                title={selectedIncident}
              />
            </div>
          </div>
        </div>
      </div>
    </FullScreen>
  );
};

export default HeatWaveDashboard;