import React, { useContext, useEffect, useRef, useState } from "react";
import * as echarts from "echarts";
import { useTranslation } from "react-i18next";
import { ThemeContext } from "../../../..";
import { Modal } from "react-bootstrap";
import { XCircleblack } from "../../../../Components/IconComponents";
import HeatWaveAffectedPopupData from "./HeatwavePopup";
import { heatwavechartdata, heatwavepopupdata } from "../../../../Services/EndPoints";
import { getData, postDataWithBody } from "../../../../Services/Services";
import "./HeatwaveDashboard.css";

const HeatWaveBarChart = ({ title, fromDate, toDate, selectedIncident, startDateStr, endDateStr }) => {
  const { theme } = useContext(ThemeContext);
  const echartsDom = useRef(null);
  const [chart, setChart] = useState(null);
  const [labelColor, setLabelColor] = useState("#fff");
  const [modalData, setModalData] = useState(null);
  const [isShowModal, setIsShowModal] = useState(false);
  const [cardData, setCardData] = useState([]);
  const [popUpData, setPopUpData] = useState([]);

  const { t } = useTranslation();

  useEffect(() => {
    if (theme === "dark") setLabelColor("#fff");
    else if (theme === "light") setLabelColor("#909097");
  }, [theme]);

  const hideModal = () => {
    setIsShowModal(false);
    setModalData(null);
    setPopUpData([]);
  };

  const handleCloseClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    hideModal();
  };

  const formatTitle = (barType) => {
    const titleMap = {
      no_of_deaths_today: "Number of deaths due to heat wave",
      no_of_persons_hospitalized_today: "Number of persons hospitalized",
      no_of_dec_ex_gratia_pay_done_today: "Number of deceased person for whom ex-gratia payment done",
      no_of_persons_hospitalized_pay_done_today: "Number of hospitalized person for whom payment done",
    };
  
    return titleMap[barType] || barType
      .split("_")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };

  const fetchHeatWaveBarChartData = async () => {
    try {
      const response = await getData(
        heatwavechartdata
          .replace("FROMDATE", startDateStr)
          .replace("TODATE", endDateStr)
          .replace("BARTYPE", selectedIncident)
      );
      setCardData(response || []);
    } catch (error) {
      console.error("Error fetching cold wave card data:", error);
    }
  };

  const fetchHeatWaveBlockData = async (regionIds) => {
    try {
      const input = {
        region_id_list: regionIds,
        from_date: fromDate,
        to_date: toDate,
        bar_type: selectedIncident,
      };

      const blockData = await postDataWithBody(heatwavepopupdata, input, {});
      setPopUpData(blockData || []);
    } catch (error) {
      console.error("Error fetching cold wave block data:", error);
    }
  };

  useEffect(() => {
    if (selectedIncident) {
      fetchHeatWaveBarChartData();
    }
  }, [selectedIncident, startDateStr, endDateStr]);

  const getChartOptions = () => {
    const filteredData = cardData.filter(item => item.count > 0); // Remove districts with count 0
    const sortedData = [...filteredData].sort((a, b) => a.count - b.count);
    
    return {
      title: {
        left: "center",
        textStyle: {
          color: "#333",
          fontSize: 12,
          fontWeight: "bold",
        },
      },
      tooltip: {
        trigger: "axis",
        confine: true,
      },
      grid: {
        left: "2%",
        top: "8%",
        right: "20%",
        bottom: "15%",
        containLabel: true,
      },
      xAxis: {
        type: "value",
        name: "Affected",
        nameLocation: "middle",
        nameTextStyle: {
          padding: [30, 0, 0, 0],
          color: "#909097",
          fontWeight: "bold",
        },
        axisLabel: {
          textStyle: {
            color: "#909097",
            fontWeight: "bold",
          },
        },
      },
      yAxis: {
        type: "category",
        data: sortedData.map((item) => item.dist_name),
        name: t("flooddisaster.barchartyaxis"),
        nameLocation: "end",
        axisLabel: {
          overflow: "truncate",
          ellipsis: "...",
          show: true,
          color: labelColor,
          fontWeight: "bold",
        },
        axisTick: {
          show: false,
        },
      },
      dataZoom: [
        {
          type: "slider",
          yAxisIndex: 0,
          start: 0,
          end: 100,
          orient: "vertical",
          zoomLock: false,
          handleSize: "100%",
          handleStyle: {
            color: "#E95060",
          },
        },
      ],
      series: [
        {
          type: "bar",
          barWidth: 25,
          data: sortedData.map((item) => item.count),
          itemStyle: {
            color: "#E95060",
            borderRadius: [10, 10, 10, 10],
          },
          label: {
            show: true,
            position: "right",
            distance: 10,
            formatter: (data) =>
              data.value > 0 ? `{valueStyle|${data.value}}` : "",
            rich: {
              valueStyle: {
                color: "#18a3a0",
                fontSize: 14,
                borderWidth: 2,
                borderColor: "#18a3a0",
                padding: [5, 10, 2, 10],
                fontWeight: "bold",
                borderRadius: 10,
              },
            },
          },
        },
      ],
    };
  };

  useEffect(() => {
    if (echartsDom.current) {
      const myChart = echarts.init(echartsDom.current);
      setChart(myChart);

      const handleResize = () => {
        myChart.resize();
      };
      window.addEventListener("resize", handleResize);

      return () => {
        window.removeEventListener("resize", handleResize);
        myChart.dispose();
      };
    }
  }, [selectedIncident, fromDate, toDate]);

  useEffect(() => {
    if (chart) {
      chart.clear();
      chart.setOption(getChartOptions());

      chart.on("click", (params) => {
        if (params.componentType === "series") {
          const clickedDistrict = cardData.find(
            (district) => district.dist_name === params.name
          );

          if (clickedDistrict) {
            const regionIds = clickedDistrict.region_id.split(",").map(Number);
            fetchHeatWaveBlockData(regionIds);
            setModalData({
              district: clickedDistrict.dist_name,
              affectedCount: clickedDistrict.count,
            });
            setIsShowModal(true);
          }
        }
      });
    }

    return () => {
      if (chart) {
        chart.off("click");
      }
    };
  }, [chart, labelColor, t, cardData]);

  return (
    <div className="card" style={{ width: "99%", height: "95%" }}>
      <div className="card-body" style={{ height: "100%" }}>
        <div className="card-title_new_cw">{formatTitle(title)}</div>

        {!selectedIncident ? (
          <div style={{ textAlign: "center", padding: "20px", fontSize: "16px", color: "#777" }}>
            Click on Incident cards to show the Data
          </div>
        ) : (
          <div ref={echartsDom} style={{ height: "625px"}} />
        )}

        <Modal
          className="ckmodal"
          id={theme}
          show={isShowModal}
          onHide={hideModal}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header>
            <Modal.Title
              style={{
                fontSize: "1rem",
                fontWeight: "bold",
                color: "#fff",
                textAlign: "center",
              }}
            >
              {modalData ? `District: ${modalData.district}` : "District Data"}
            </Modal.Title>
            <div 
              onClick={handleCloseClick}
              style={{ cursor: "pointer" }}
              role="button"
              aria-label="Close modal"
            >
              <XCircleblack
                width="1.5em"
                height="1.5em"
              />
            </div>
          </Modal.Header>
          <Modal.Body className="ckmodal-container-coldwave" style={{ marginTop: "0px" }}>
            <div>
              <HeatWaveAffectedPopupData blockData={popUpData} />
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </div>
  );
};

export default HeatWaveBarChart;