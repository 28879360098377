import React, { useEffect, useState } from "react";
import "./HumanLossTableDougnut.css";
import axios from "axios";
import DoughnutChart from "./DoughnutChartHumanLoss";
// import HalfDoughnutChart from "./HalfDoughnutChart";
// import HorizontalBarChart from "./HorizontalBarChart";
import { useTranslation } from "react-i18next";
import moment from "moment";
// import { getBMSKData } from "../../../Services/EndPoints";
// import { getData } from "../../../Services/Services";
// import { Expand } from "../../../Components/IconComponents";
import ToggleViewHumanLoss from "./ToggleViewHumanLoss";
import DoughnutChartHUmanLoss from "./DoughnutChartHumanLoss";
import HalfDoughnutChartLoss from "./HalfDoughnutChartHumanLoss";
import {
  humanlossdashboardincidenttableblocldata,
  humanlossdashboardincidenttabledata,
  humanlossdougnutchartdata,
} from "../../../../../Services/EndPoints";
import { getData } from "../../../../../Services/Services";

const HumanLossDougnut = ({
  affectedDistrictData,
  affectedBlockData,
  affectedNagarNigamData,
  affectedPanchayatData,
  fromDateNew,
  toDateNew,
  applyClicked,
  setApplyClicked,
  onApplyHandled,
  firstLoad,
  title,
  selectedIncident,
}) => {
  const [weatherApiData, setWeatherApiData] = useState([]);
  const [activeTabBmsk, setActiveTabBmsk] = useState("Charts"); // Set default to "Charts"
  const [expandedRows, setExpandedRows] = useState([]);
  const [cardData, setCardData] = useState({});
  const [BlockData, setBlockData] = useState({});
  const [selectedIncident1, setSelectedIncident1] = useState(null);


  const { t } = useTranslation();

  const formatDate = (date) => {
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${day}${month}${year}`;
  };

  const formatDates = (date) => {
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };

  useEffect(() => {
    refreshDashboard(fromDateNew, toDateNew);
    refreshDashboard1(fromDateNew, toDateNew);
  }, [firstLoad]);

  useEffect(() => {
    // Run only when Apply button is clicked
    if (applyClicked) {
      refreshDashboard(fromDateNew, toDateNew);
      refreshDashboard1(fromDateNew, toDateNew);
      onApplyHandled(); // Reset applyClicked after API call
    }
  }, [applyClicked]);

  
  

  const todayDate = formatDate(new Date());
  const todayDates = formatDates(new Date());

  const fromDate1 = "2025-01-02";
  const toDate1 = "2025-01-24";
  const distname = "BEGUSARAI";
  const handleRowClick = async (districtName, index) => {
    // Check if the clicked row is already expanded
    if (expandedRows.includes(index)) {
      // Collapse the row if it was already expanded
      setExpandedRows([]);
    } else {
      // Expand the clicked row and collapse any previously expanded rows
      setExpandedRows([index]);

      // Fetch the block data for the selected district
      await refreshDashboard(districtName);
    }
  };

  const refreshDashboard = async (districtName) => {

    const incidentId = selectedIncident?.id === 0 ? 0 : selectedIncident?.id || "ALL";
    const data = await getData(
      humanlossdashboardincidenttableblocldata
        .replace("FROMDATE", fromDateNew)
        .replace("TODATE", toDateNew)
        .replace("DISTNAME", districtName)
        .replace("ID", incidentId) // Replaces ID with "ALL" on initial load
    );

    // Assuming the API returns block data in the following format:
    setBlockData(data);
  };

  const idData = "1";

  const refreshDashboard1 = async () => {
    // startDateStr = moment(startDateStr).format("YYYY-MM-DD");
    // endDateStr = moment(endDateStr).format("YYYY-MM-DD");
    const incidentId = selectedIncident?.id === 0 ? 0 : selectedIncident?.id || "ALL";
    var data = await getData(
      humanlossdashboardincidenttabledata
        .replace("FROMDATE", fromDateNew)
        .replace("TODATE", toDateNew)
        .replace("ID", incidentId) // Replaces ID with "ALL" on initial load
    );
    setCardData(data);
  };

  useEffect(() => {
    // Reset expanded rows when selectedIncident changes
    setExpandedRows([]);
  }, [selectedIncident]);

  const handleCardClick = (incidentId, incidentName) => {
    setSelectedIncident1({ id: incidentId, name: incidentName }); // Store both ID and Name
  };

  useEffect(() => {
    if (selectedIncident) {
      refreshDashboard();
      refreshDashboard1();
    }
  }, [selectedIncident]);

  const calculateTotals = (data) => {
    console.log(data, ".............data");

    return Object.values(data).reduce(
      (totals, row) => {
        totals.incident += row.incident_count || 0;
        totals.deaths += row.deaths_count || 0;
        totals.injured += row.injured_count || 0;
        return totals;
      },
      { incident: 0, deaths: 0, injured: 0 }
    );
  };

  const totals = calculateTotals(cardData);

  const toggleRow = (index) => {
    setExpandedRows((prev) =>
      prev.includes(index)
        ? prev.filter((rowIndex) => rowIndex !== index)
        : [...prev, index]
    );
  };

  const data = [
    {
      id: 1,
      district: "District 1",
      incident: 2,
      deaths: 3,
      injured: 5,
      blocks: [
        { block: "Block A1", incidents: 1, deaths: 1, injured: 3 },
        { block: "Block A2", incidents: 1, deaths: 2, injured: 2 },
      ],
    },
    {
      id: 2,
      district: "District 2",
      incident: 3,
      deaths: 4,
      injured: 9,
      blocks: [
        { block: "Block B1", incidents: 2, deaths: 2, injured: 5 },
        { block: "Block B2", incidents: 1, deaths: 2, injured: 4 },
      ],
    },
    {
      id: 3,
      district: "District 3",
      incident: 5,
      deaths: 8,
      injured: 13,
      blocks: [
        { block: "Block C1", incidents: 3, deaths: 5, injured: 7 },
        { block: "Block C2", incidents: 2, deaths: 3, injured: 6 },
      ],
    },
  ];

  // const totals = data.reduce(
  //   (acc, row) => {
  //     acc.incident += row.incident;
  //     acc.deaths += row.deaths;
  //     acc.injured += row.injured;
  //     return acc;
  //   },
  //   { incident: 0, deaths: 0, injured: 0 }
  // );

  console.log(todayDate, "todaydate");

  //   useEffect(() => {
  //     const fetchWeatherData = async () => {
  //       try {
  //         const response = await getData(
  //           getBMSKData.replace("FROMDATE", todayDate)
  //         );
  //         setWeatherApiData(response.path);
  //         console.log(response.path);
  //       } catch (error) {
  //         console.error("Error fetching weather data:", error);
  //       }
  //     };

  //     fetchWeatherData();
  //   }, [todayDate]);
  const layoutData = [
    {
      x: 0,
      y: 0,
      width: 4,
      height: 7,
      id: 7,
      component: (
        <HalfDoughnutChartLoss
          title="Incidents"
          dataKey="incident_count" // Key for incidents
          fromDateNew={fromDateNew}
          toDateNew={toDateNew}
          applyClicked={applyClicked}
          onApplyHandled={onApplyHandled}
          firstLoad={firstLoad}
          activeTabBmsk={activeTabBmsk}
          selectedIncident={selectedIncident} // Pass selected ID

          // showViewIcon={true}
        />
      ),
    },
    {
      x: 4,
      y: 0,
      width: 4,
      height: 7,
      id: 8,
      component: (
        <HalfDoughnutChartLoss
          title="Deaths"
          dataKey="deaths_count" // Key for deaths
          fromDateNew={fromDateNew}
          toDateNew={toDateNew}
          applyClicked={applyClicked}
          onApplyHandled={onApplyHandled}
          firstLoad={firstLoad}
          activeTabBmsk={activeTabBmsk}
          selectedIncident={selectedIncident} // Pass selected ID
          // showViewIcon={true}
        />
      ),
    },
    {
      x: 8,
      y: 0,
      width: 4,
      height: 7,
      id: 10,
      component: (
        <HalfDoughnutChartLoss
          title="Injured"
          dataKey="injured_count" // Key for injured
          fromDateNew={fromDateNew}
          toDateNew={toDateNew}
          applyClicked={applyClicked}
          onApplyHandled={onApplyHandled}
          firstLoad={firstLoad}
          activeTabBmsk={activeTabBmsk}
          selectedIncident={selectedIncident} // Pass selected ID
          // showViewIcon={true}
        />
      ),
    },
  ];

  return (
    <div  onClick={() => handleCardClick(selectedIncident.id)}>
      <div
        className={
          activeTabBmsk === "Charts"
            ? "header-container"
            : "header-container-chart"
        }
      >
        {activeTabBmsk === "Charts" && (title || "All Incidents Data")}
        <div className="toggle-container">
          <ToggleViewHumanLoss
            className={
              activeTabBmsk === "Charts" ? "button-Charts" : "button-BMSK"
            }
            setActiveTabBmsk={setActiveTabBmsk}
            activeTabBmsk={activeTabBmsk}
          />
        </div>
      </div>

      <div className="charts-grid-wrapper">
        {activeTabBmsk === "Charts" ? (
          <div className="doughnut-container">
            {layoutData.map((gridItem) => (
              <div className="doughnut-item" key={gridItem.id}>
                {gridItem.component}
              </div>
            ))}
          </div>
        ) : (
          <div className="table-container">
            <div style={{ width: "90%", marginLeft: "40px" }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginBottom: "10px",
                }}
              >
                {/* <div style={{ display: 'flex', alignItems: 'center' }}>
          <input type="checkbox" id="incidentOccur" />
          <label htmlFor="incidentOccur" style={{ marginLeft: '5px' }}>
            Incident Occur
          </label>
        </div> */}
                {/* <button
          style={{
            padding: '5px 10px',
           border:'1px solid White',
            backgroundColor: '#f0f0f0',
            cursor: 'pointer',
          }}
        >
          Export
        </button> */}
              </div>
              <h5 style={{ textAlign: "center", marginBottom: "5px" }}>
                District Report
              </h5>
              <div className="hl-table-div">
                <table
                  // className="hl-table"
                  style={{
                    width: "100%",
                    borderCollapse: "collapse",
                    textAlign: "center",
                    margin: "0rem",
                  }}
                >
                  <thead>
                    <tr style={{ backgroundColor: "#23b1a4" }}>
                      <th className="hs-table-head hs-table-radius-left">
                        {/* Expand */}
                      </th>
                      <th className="hs-table-head">Sr No</th>
                      <th className="hs-table-head">District Name</th>
                      <th className="hs-table-head">Incident</th>
                      <th className="hs-table-head">Deaths</th>
                      <th className="hs-table-head hs-table-radius-right">
                        Injured
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {cardData.map((row, index) => (
                      <React.Fragment key={row.id}>
                        <tr>
                          <td className="hs-table-td">
                            <span
                              onClick={() =>
                                handleRowClick(row.dist_name, index)
                              }
                            >
                              {expandedRows.includes(index) ? "-" : "+"}
                            </span>
                          </td>
                          <td className="hs-table-td">{index + 1}</td>
                          <td className="hs-table-td">{row.dist_name}</td>
                          <td className="hs-table-td">{row.incident_count}</td>
                          <td className="hs-table-td">{row.deaths_count}</td>
                          <td className="hs-table-td">{row.injured_count}</td>
                        </tr>
                        {expandedRows.includes(index) && (
                          <tr>
                            <td colSpan="6" className="hs-table-td-footer">
                              <div className="hl-table-child-div">
                                <table className="h1-table-child">
                                  <thead>
                                    <tr>
                                      {/* Slightly lighter blue for the header */}
                                      <th className="hs-table-head hs-table-radius-left hs-table-tr">
                                        Block Name
                                      </th>
                                      <th className="hs-table-head hs-table-tr">
                                        Incidents
                                      </th>
                                      <th className="hs-table-head hs-table-tr">
                                        Deaths
                                      </th>
                                      <th className="hs-table-head hs-table-radius-right hs-table-tr">
                                        Injured
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {BlockData.map((block, blockIndex) => (
                                      <tr key={blockIndex}>
                                        <td className="hs-table-td">
                                          {block.block_name}
                                        </td>
                                        <td className="hs-table-td">
                                          {block.incident_count}
                                        </td>
                                        <td className="hs-table-td">
                                          {block.deaths_count}
                                        </td>
                                        <td className="hs-table-td">
                                          {block.injured_count}
                                        </td>
                                      </tr>
                                    ))}
                                  </tbody>
                                </table>
                              </div>
                            </td>
                          </tr>
                        )}
                      </React.Fragment>
                    ))}
                  </tbody>
                  <tfoot>
                    <tr style={{ fontWeight: "bold" }}>
                      <td
                        colSpan="3"
                        className="hs-table-td-footer hs-table-footer-left"
                      >
                        Total
                      </td>
                      <td className="hs-table-td-footer">{totals.incident}</td>
                      <td className="hs-table-td-footer">{totals.deaths}</td>
                      <td className="hs-table-td-footer hs-table-footer-right">
                        {totals.injured}
                      </td>
                    </tr>
                  </tfoot>
                </table>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default HumanLossDougnut;
