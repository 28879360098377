import React from "react";
import { useNavigate } from "react-router-dom";
import { useState, useEffect, forwardRef, useImperativeHandle } from "react";
import { toast, ToastContainer } from "react-toastify";
import api from "../../../../Services/api";
import {
  Editicon,
  Deleteicon,
  Viewicon,
  XCircleblack,
} from "../../../../Components/IconComponents";
import { useKeycloak } from "@react-keycloak/web";
import Loader from "react-spinner-loader";
import {
  keycloakbaseurl,
  getRoleDataByRoleNameURL,
  getallRolesByRealm,
  getRoleById,
  deleteRole,
  getAllRole,
  deletRoleById,
  getAllPermissions,
} from "../../../../Services/EndPoints";
import { getData, putDataWithBody } from "../../../../Services/Services";
import Table from "../../../../Components/UiComponents/Table/Table";
import Moment from "react-moment";
import { Modal, Button } from "react-bootstrap";
import Confirmpopup from "../../Common/ConfirmPopup/Confirmpopup";
import StatusHandler from "../../Common/StatusHandler/StatusHandler";
import { roleTableDatacolumnsForExport } from "../../../../JsonData/RoleDetails";
import { useDispatch } from "react-redux";
import { setTableData } from "../../../../CentralStore/ExportLMSTable/dataSlice";
import { getExportedColumnsData } from "../../../../Components/UiComponents/utils";
import { useTranslation } from "react-i18next";

const RoleTable = forwardRef((props, ref) => {
  let roleDataArr = [];
  const dispatch = useDispatch();
  const { keycloak } = useKeycloak();
  const [userdata, setUserdata] = useState([]);
  const qs = require("qs");
  const [roleData, setRoleData] = useState([]);
  const [selectedRoleId, setSelectedRoleId] = useState(null);
  const [isShow, invokeModal] = React.useState(false);
  const [loader, setLoader] = useState(false);
  const [rowCount, setRowCount] = useState(0);
  const [permissionDataFrmService, setPermissionDataFrmService] = useState({});
  const { t, i18n } = useTranslation();
  const [searchData, setSearchData] = useState([]);
  const navigate = useNavigate();
  const addNewHandler = () => {
    navigate("/users/create-role");
  };
  useImperativeHandle(ref, () => ({
    refreshRoleTbl() {
      fetchAllRoles();
    },
  }));

  useEffect(() => {
    fetchAllRoles();
    getAllUserPermission();
  }, []);

  const formatSearchData = (data) => {
    return data.map(role => {
        // Create a string of comma-separated permission names
        const formattedPermissions = role.role_permission.map(permission => {
            return permissionDataFrmService[permission.permission_id] || "Unknown Permission";
        }).join(", "); // Join the permission names with a comma and space

        // Return the role with formatted permissions as a string...
        return {
            ...role,
            role_permission: formattedPermissions, // Replace array with the formatted string
        };
    });
};

useEffect(() => {
  if (searchData.length > 0) {
      const formattedData = formatSearchData(searchData);
      console.log(formattedData,"formatteddata");
      sendDataToCentralStore(formattedData);
      console.log(formattedData, "formatted search data");
  }
}, [searchData]);

  async function getAllUserPermission() {
    let permissionData = await getData(getAllPermissions);
    permissionData.forEach(function (permission) {
      permissionDataFrmService[permission.id] = permission.name;
    });
    setPermissionDataFrmService(permissionDataFrmService);
    console.log(permissionDataFrmService, "permdata");
  }
  const getLoggedInUserAuthToken = async () => {
    return keycloak.token;
  };
  const getSuperAdminUserAuthToken = async () => {
    return process.env.REACT_APP_URM_SUPERADMIN_AUTH_TOKEN;
  };
  const showUserDeleteModal = (roleId) => {
    setSelectedRoleId(roleId);
    invokeModal(true);
  };
  const populatRoleDataFrEdt = async (roleData) => {
    // const userData = await getRoleDataByRoleId(roleId);
    props.invokePrntComponentRoleDataUpdteFun(roleData);
  };
  const hideModal = () => {
    invokeModal(false);
  };

  const getURMHeader = async () => {
    const authToknObj = await getSuperAdminUserAuthToken();
    let headers = {
      "content-type": "application/json",
      Authorization: "Bearer " + authToknObj,
    };
    return headers;
  };

  const deleteRoleByRoleId = async (id) => {
    /*let data = { id: id };
    var res = await putDataWithBody(deleteRole, data, {});
    if (res.status == "success") {
      toast.success(res.msg, {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 2000, // Close after 2 seconds
      });
      setIsdelete(false);
      fetchAllRoles();
    }
    
   let url =
      keycloakbaseurl +
      deletRoleById + selectedRoleId
    const authToknObj = await getLoggedInUserAuthToken();
    let headers = {
      "content-type": "application/json",
      Authorization: "Bearer " + authToknObj,
    };
    const response = await api.deleteData(url, headers);

    if (response.status == 204) {
      fetchAllRoles();
      invokeModal(false);
    }*/
  };

  const getURMRoleIdByAppRoleID = (id) => {
    let roleURMId = null;
    roleDataArr.forEach(function (roleObj) {
      if (roleObj.id == id) {
        roleURMId = roleObj.urm_role_id;
      }
    });
    return roleURMId;
  };
  const fetchAllRoles = async () => {
    setLoader(true);
    let roleArrFiltered = [];
    let rolearr = await getData(getAllRole);
    rolearr.forEach(function (roleObj) {
      if (roleObj.is_deleted != true) {
        roleArrFiltered.push(roleObj);
      }
    });
    setLoader(false);
    roleDataArr = roleArrFiltered;
    let sortedList = roleArrFiltered.sort((a, b) => {
      return b.createdOn - a.createdOn;
    });
    setRoleData(sortedList);
    setRowCount(rolearr.length);
  };

  const sendDataToCentralStore = (data) => {
    if (data.length > 0) {
      const columns = roleTableDatacolumnsForExport;
      dispatch(
        setTableData({
          columns,
          tableData: getExportedColumnsData(columns, data),
        })
      );
    }
  };

  const columns = React.useMemo(
    () => [
      {
        Header: t("roletableheaders.rolename"),
        accessor: "name",
        disableSortBy: true,
      },
      // {
      //   Header: t("roletableheaders.description"),
      //   accessor: "description",
      //   disableSortBy: true,
      // },

      // {
      //   Header: t("roletableheaders.createdon"),
      //   accessor: "createdOn",
      //   disableSortBy: true,
      //   Cell: (props) => {
      //     if (props.value) {
      //       return <Moment format="DD/MM/YYYY, HH:mm ">{props.value}</Moment>;
      //     } else {
      //       return "NA";
      //     }
      //   },
      // },
      {
        Header: t("roletableheaders.description"),
        accessor: "description",
        disableSortBy: true,
        // width: 200,
        Cell: (props) => {
          const [showModal, setShowModal] = useState(false);
          const [fullDescription, setFullDescription] = useState("");
          const [highlightedDescription, setHighlightedDescription] = useState(""); // New state for highlighted description
      
          const handleShowModal = (description) => {
            setFullDescription(description);
            setHighlightedDescription(description); // Set the description being viewed to highlight
            setShowModal(true);
          };
      
          const handleCloseModal = () => {
            setShowModal(false);
            setHighlightedDescription(""); // Reset the highlighted description when the modal is closed
          };
      
          const descriptionText = props.value || "NA";
          const truncatedDescription = descriptionText.length > 60 ? `${descriptionText.substring(0, 60)}...` : descriptionText;
      
          // Apply background color if the description is currently being viewed in the modal
          const isHighlighted = descriptionText === highlightedDescription;
          if (showModal) {
            setTimeout(() => {
              let el = document.querySelector('.modal-content');
              el.style.cssText = 'background-color: #1c2038 !important; border-radius: 20px !important;';
            }, 0);
          }
      
          return (
            <span>
              <div
                style={{
                  whiteSpace: "normal",
                  wordWrap: "break-word",
                  // width: "500px",
                  cursor: 'pointer',  // Pointer cursor to indicate clickability
                  backgroundColor: isHighlighted ? '#18A3A0' : 'transparent', // Highlight background when viewed
                  padding: '2px 5px', // Add padding to give a little space around the text
                  borderRadius: '5px', // Rounded corners for the highlighted background
                }}
                title={descriptionText} // Tooltip with full description on hover
                onClick={() => handleShowModal(descriptionText)} // Open the modal with full description
              >
                {truncatedDescription}
              </div>
      
              {/* Modal to display the full description */}
              <Modal
                show={showModal}
                onHide={handleCloseModal}
                dialogClassName="custom-modal"
                centered
                style={{
                  borderRadius: '20px',  // Smooth rounded edges
                  border: 'none',        // Remove border to avoid white edges
                  top: '20%',  
                }}
              >
                <Modal.Header
                  style={{
                    backgroundColor: '#1c2038', // Dark blue background for header
                    color: 'white',              // White text in the header
                    borderBottom: 'none',       // Remove bottom border to prevent white edges
                  }}
                >
                  <Modal.Title style={{ color: 'white' }}>Full Description</Modal.Title>
                  <XCircleblack
                    onClick={handleCloseModal}
                    width="1.5em"
                    height="1.5em"
                    className="cursor-pointer"
                  />
                </Modal.Header>
                <Modal.Body
                  style={{
                    backgroundColor: '#252b48', // Blue background for body
                    color: 'white',              // White text in the body
                    border: 'none',              // Remove border to prevent white edges
                    borderRadius: '0px',        // Remove radius to make it seamless
                    padding: '20px',             // Added padding for spacing
                    lineHeight: '1.8', 
                  }}
                >
                  <p>{fullDescription}</p>
                </Modal.Body>
                <Modal.Footer
                  style={{
                    backgroundColor: '#252b48', // Darker blue footer
                    color: 'white',              // White text in footer
                    borderTop: 'none',          // Remove top border to prevent white edges
                  }}
                >
                  <Button
                    variant="secondary"
                    onClick={handleCloseModal}
                    style={{
                      backgroundColor: '#252b48', // Dark blue button
                      borderColor: '#003366',     // Dark border for button
                      borderRadius: '5px',        // Rounded button edges
                    }}
                  >
                    Close
                  </Button>
                </Modal.Footer>
              </Modal>
            </span>
          );
        }
      },      
      {
        Header: t("roletableheaders.createdon"),
        accessor: "createdOn",
        disableSortBy: true,
        // width: 180,  // Adjust the width for the "Created On" column
        Cell: (props) => {
          if (props.value) {
            return <Moment format="DD/MM/YYYY, HH:mm">{props.value}</Moment>;
          } else {
            return "NA";
          }
        },
      },
  
      // {
      //   Header: t("roletableheaders.permission"),
      //   accessor: "role_permission",
      //   disableSortBy: true,
      //   Cell: (props) => {
      //     if (props.value && props.value.length > 0) {
      //       let permissionStr = "";
      //       let all_permissions = props.value;
      //       all_permissions.forEach(function (permission) {
      //         permissionStr +=
      //           permissionDataFrmService[permission["permission_id"]] + ",";
      //       });
      //       let permissionText = permissionStr;
      //       if (permissionText.length > 15) {
      //         permissionText = permissionText.substring(0, 15) + "...";
      //       }
      //       return <span title={permissionStr}>{permissionText}</span>;
      //     } else {
      //       return "NA";
      //     }
      //   },
      // },
      {
        Header: t("roletableheaders.permission"),
        accessor: "role_permission",
        disableSortBy: true,
        Cell: (props) => {
          const [showModal, setShowModal] = useState(false);
          const [fullPermissions, setFullPermissions] = useState("");
          const [highlightedPermissions, setHighlightedPermissions] = useState("");
      
          const handleShowModal = (permissions) => {
            setFullPermissions(permissions);
            setHighlightedPermissions(permissions); // Highlight the row
            setShowModal(true);
          };
      
          const handleCloseModal = () => {
            setShowModal(false);
            setHighlightedPermissions(""); // Reset highlight
          };
      
          if (props.value && props.value.length > 0) {
            let permissionStr = props.value
              .map((permission) => permissionDataFrmService[permission["permission_id"]])
              .join(", ");
            
            let truncatedText = permissionStr.length > 60 ? `${permissionStr.substring(0, 60)}...` : permissionStr;
      
            const isHighlighted = permissionStr === highlightedPermissions;
            if (showModal) {
              setTimeout(() => {
                let el = document.querySelector('.modal-content');
                el.style.cssText = 'background-color: #1c2038 !important; border-radius: 20px !important;';
              }, 0);
            }
      
            return (
              <span>
                <div
                  style={{
                    whiteSpace: "normal",
                    wordWrap: "break-word",
                    // maxWidth: "220px",
                    cursor: "pointer",
                    backgroundColor: isHighlighted ? "#18A3A0" : "transparent",
                    padding: "2px 5px",
                    borderRadius: "5px",
                  }}
                  title={permissionStr}
                  onClick={() => handleShowModal(permissionStr)}
                >
                  {truncatedText}
                </div>
      
                {/* Modal for full permissions */}
                <Modal
                  show={showModal}
                  onHide={handleCloseModal}
                  centered
                  dialogClassName="custom-modal"
                  style={{ borderRadius: "20px", border: "none", top: "20%" }}
                >
                  <Modal.Header
                    style={{
                      backgroundColor: "#1c2038",
                      color: "white",
                      borderBottom: "none",
                    }}
                  >
                    <Modal.Title style={{ color: "white" }}>Permissions</Modal.Title>
                    <XCircleblack
                      onClick={handleCloseModal}
                      width="1.5em"
                      height="1.5em"
                      className="cursor-pointer"
                    />
                  </Modal.Header>
                  <Modal.Body
                    style={{
                      backgroundColor: "#252b48",
                      color: "white",
                      border: "none",
                      borderRadius: "0px",
                      padding: "20px",
                      lineHeight: "1.8",
                    }}
                  >
                    <p>{fullPermissions}</p>
                  </Modal.Body>
                  <Modal.Footer
                    style={{
                      backgroundColor: "#252b48",
                      color: "white",
                      borderTop: "none",
                    }}
                  >
                    <Button
                      variant="secondary"
                      onClick={handleCloseModal}
                      style={{
                        backgroundColor: "#252b48",
                        borderColor: "#003366",
                        borderRadius: "5px",
                      }}
                    >
                      Close
                    </Button>
                  </Modal.Footer>
                </Modal>
              </span>
            );
          } else {
            return "NA";
          }
        },
      },
      
      // {
      //   Header: "Status  ",
      //   accessor: "status",
      //   disableSortBy: true,
      //   Cell: (props) => {
      //     return <StatusHandler props={props} />;
      //   },
      // },
      {
        Header: t("roletableheaders.action"),
        accessor: "action",
        disableSortBy: true,

        Cell: (props) => {
          const [isdelete, setIsdelete] = useState(false);
  const deletePopUp = () => {
    setIsdelete(!isdelete);
  };

  const deleteRoleByRoleId = async (id) => {
    // Delete logic
    let data = { id: id };
    var res = await putDataWithBody(deleteRole, data, {});
    if (res.status === "success") {
      toast.success(res.msg, {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 2000, // Close after 2 seconds
      });
      setIsdelete(false);
      fetchAllRoles();
    }
  };

  const isDisabled = props.row.original.name === "DEOC" || props.row.original.name === "SEOC" || props.row.original.name === "DashboardOnly" ;

  return (
    <div className="usertable__actionsicon">
      <span
        onClick={() =>
          !isDisabled && navigate("/users/create-role", {
            state: {
              data: props.row.original,
            },
          })
        }
      >
        <Editicon
          value={props.value}
          className={`cursor-pointer me-2 ${isDisabled ? 'text-muted' : ''}`}
          style={{ pointerEvents: isDisabled ? 'none' : 'auto', opacity: isDisabled ? 0.5 : 1 }}
        />
      </span>

      {isDisabled ? (
        <span>
          <Deleteicon
            value={props.value}
            className="me-1"
            style={{ color: 'lightgray', opacity: 0.5, pointerEvents: 'none' }} // Lighter effect
          />
        </span>
      ) : (
        <span onClick={deletePopUp}>
          <Deleteicon value={props.value} className="cursor-pointer mr-1" />
        </span>
      )}

      {isdelete && (
        <Confirmpopup
          className="DeltePopup"
          show={isdelete}
          title={t("deletepopuptext")}
          message={t("deletepopupmessage")}
          handleToggle={deletePopUp}
          row={props.row}
          name={props.row.original.name}
          deleteRow={deleteRoleByRoleId}
          cancel={t("cancelbutton")}
          action={t("actionbutton")}
        />
      )}
    </div>
  );
},    
      },
    ],
    [t]
  );
  console.log(roleData, "roledata");
  return (
    <div className="create-user">
      <Loader
        show={loader}
        type="body"
        stack="vertical"
        message="Loading Data"
      />
      <div className="datatable__container">
        
        {roleData && (
          <Table
            data={roleData}
            setSearchData={setSearchData}
            columns={columns}
            numberOfRows={10}
            addButtonLabel={t("createroleaddbuttonlabel")}
            addNewHandler={addNewHandler}
            showExport={false}
            showFilter={false}
            showReset={false}
            onExpand={false}
            rowCount={rowCount} // Pass rowCount to the Table component
            headerTableHeadStyle={{ background: "#18a3a0", color: "#FFFFFF" }}
            tableStyle={{
              border: "1px solid #f0f0f0",
              padding: "2px",
              borderRadius: "12px",
            }}
          />
        )}
      </div>

      <Modal show={isShow}>
        <Modal.Header closeButton onClick={hideModal}>
          <Modal.Title>Alert !</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure, you want to delete the role ?</Modal.Body>
        <Modal.Footer>
          <Button
            variant="danger"
            className="delete_user_btn"
            onClick={hideModal}>
            Cancel
          </Button>
          <Button
            onClick={deleteRoleByRoleId}
            variant="dark"
            className="create_user_btn">
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
});

export default RoleTable;
