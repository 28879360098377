import * as React from "react";
const SvgReportStatusIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 512 512"
    fill= "currentColor"
    {...props}
  >
    <path d="M93.5 2.5 91 4.9V56H22.8l-2.9 2.9c-1.6 1.6-2.9 3.8-2.9 4.9 0 2.9 72.8 437.1 74 441.4 2.1 7.4-15.2 6.8 202.9 6.6l196-.3 2.3-2.3 2.3-2.3.3-250.5.2-250.5-2.9-3-2.9-2.9H95.9zm384.3 253.2L478 494H108V17l184.8.2 184.7.3zM90.7 235c.1 91.6-.2 160.2-.7 158.5s-12.6-73.1-27-158.7c-14.3-85.6-26.3-157-26.6-158.7l-.7-3.1 27.4.2 27.4.3z" />
    <path d="M200.3 52.4c-3.7 3.2-3.9 9.9-.4 12.7 2.2 1.8 5.6 1.9 96.8 1.9 60.5 0 95.1-.4 96.3-1 2.6-1.4 4.3-7.1 3.1-10.3-2.3-6 3.4-5.7-99.3-5.7h-93.7zM260.8 107c-2.6 2.7-3 3.6-2.5 6.8 1.3 7.7-5.4 7.2 90.7 7.2h85.9l2-2.6c3-3.8 2.7-8-.8-11.5l-2.9-2.9H263.8zM195.6 132.1l-21.9 22.2-7.3-7.7c-6.2-6.5-7.8-7.6-10.6-7.6-6.4 0-10.7 5.4-8.7 10.7.5 1.6 6 7.9 12.2 14 16 16.1 12.4 17.2 43.3-13.7 13.7-13.8 25.5-26.2 26.2-27.6 2.8-5.4-1.7-12.4-8.2-12.4-2.7 0-5.7 2.6-25 22.1M242.5 160.5c-3.2 3.1-3.4 9.6-.5 12.5 2 2 3.3 2 97.4 2h95.5l2-2.6c3.5-4.4 2.2-11.1-2.6-13.3-1.7-.8-29.5-1.1-95.9-1.1h-93.5zM261 214c-1.1 1.1-2.2 3.1-2.6 4.5-.8 3.1 1.2 8.2 3.7 9.5 2 1.1 169.6 1.4 172.4.4 2.6-1 4.8-6.2 4-10-1.2-6.8 4.1-6.4-90.2-6.4-83.6 0-85.3 0-87.3 2M195.6 240.1l-21.9 22.1-5.9-6.7c-8.8-9.6-13-11.2-18.2-6.6-5.9 5.1-4 9.5 10.1 23.3 14.9 14.6 13.7 14.7 31.1-2.5 24.7-24.4 39.2-40.3 39.2-43.2 0-3.9-5-8.5-9.2-8.5-3 0-5.4 2-25.2 22.1M242.2 268.3c-3.3 3.5-3 9.9.4 12.6 2.7 2.1 3.1 2.1 96.5 2.1 51.5 0 94.5-.3 95.4-.6 2.6-1 4.8-6.2 4-10-1.2-6.8 5.1-6.4-99.5-6.4h-94.7zM233.9 317.5c-1.5.8-3.2 2.9-3.8 4.6-.8 2.2-1.1 21.9-1.1 63.1v59.9l-8.7-.3-8.8-.3-.3-35.7-.2-35.6-3.1-2.6-3.1-2.6H184c-20.6 0-20.9 0-23.7 2.4l-2.8 2.4-.5 35.9-.5 35.8-10.2.3c-9.6.3-10.3.4-12.3 2.9s-2.6 6.6-1.4 9.8c1.8 4.7-3.9 4.5 160.8 4.5h154.5l2.8-2.4c2.2-1.9 2.8-3.2 2.8-6.5 0-2.4-.7-4.7-1.6-5.7-2.2-2.1-8-3.3-16.1-3.4h-6.7l-.3-25.4c-.3-25.4-.3-25.5-2.7-28l-2.4-2.6h-44.4l-2.4 2.6c-2.4 2.6-2.4 2.6-2.7 28.5l-.3 25.9H356v-47c0-49.6-.2-51.7-4.5-53.4-.9-.3-11.3-.6-23-.6-19.5 0-21.5.2-23.6 1.9l-2.4 1.9-.5 48.4-.5 48.3-8.7.3-8.8.3-.2-62-.3-62-2.3-2.3c-2.2-2.2-2.9-2.3-23.5-2.5-17.1-.2-21.7 0-23.8 1.2m32.1 72V445h-19V334h19zm72.8 13.2-.3 41.8h-19l-.3-41.8-.2-41.7h20zM193.5 415v29.5h-19l-.3-28.5c-.1-15.7 0-29.1.3-29.8.3-.9 2.8-1.2 9.7-1l9.3.3zM411 425v20h-19v-40h19z" />
  </svg>
);
export default SvgReportStatusIcon;
