import React, { useEffect } from "react";
import DMSHeader from "../../DMSHeader/DMSHeader";
//import DailyFloodReportCompTable from "./DailyFloodReportCompTable";
import { useTranslation } from "react-i18next";
import FireIncidentReportForm from "./FireIncidentReportForm";

const FireIncidentReport = () => {
  const { t } = useTranslation();

  return (
    <div>
      <div className="widget-container">
        <DMSHeader title="Daily report of damage caused by Fire incidents and relief distribution" />
      </div>
      <div className="widget-container p-1">
        <FireIncidentReportForm />
      </div>
    </div>
  );
};

export default FireIncidentReport;
