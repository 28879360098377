import * as React from "react";
const SvgUserRoleManagementIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    data-name="Layer 1"
    viewBox="0 0 24 24"
    fill="currentColor"
    {...props}
  >
    <path d="M15 6c0-3.309-2.691-6-6-6S3 2.691 3 6s2.691 6 6 6 6-2.691 6-6m-6 4c-2.206 0-4-1.794-4-4s1.794-4 4-4 4 1.794 4 4-1.794 4-4 4m13 7c0-.552-.09-1.082-.256-1.579l1.82-1.049-.998-1.733-1.823 1.05A5 5 0 0 0 18 12.1V9.999h-2V12.1a5 5 0 0 0-2.743 1.589l-1.823-1.05-.998 1.733 1.82 1.049C12.09 15.918 12 16.448 12 17s.09 1.082.256 1.579l-1.82 1.049.998 1.733 1.823-1.05A5 5 0 0 0 16 21.9v2.101h2V21.9a5 5 0 0 0 2.743-1.589l1.823 1.05.998-1.733-1.82-1.049A5 5 0 0 0 22 17m-5 3c-1.654 0-3-1.346-3-3s1.346-3 3-3 3 1.346 3 3-1.346 3-3 3M5 14h3v2H5c-1.654 0-3 1.346-3 3v5H0v-5c0-2.757 2.243-5 5-5" />
  </svg>
);
export default SvgUserRoleManagementIcon;
