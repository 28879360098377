import React from "react";
import "./TimePicker.css";
 
const TimePicker = ({
  label = "",
  name = "",
  register = () => {},
  errors = null,
  mandatory = false,
  defaultTimeValue = null,
  applyTableCustomClass = false,
  setTimePicked = () => {},
  style = {},
  disabled,
}) => {
  const handleTimeChange = (event) => {
    const time = event.target.value;
    setTimePicked(time);
  };
 
  return (
    <div className="time__container">
      {label && (
        <label className="timepicker__label" htmlFor={name}>
          {label}
          {mandatory && <span className="asterisk">*</span>}
        </label>
      )}
      <div>
        <input
          type="time"
          disabled={disabled}
          style={style}
          className={`time_picker_control form-control
          ${disabled && "disabled__field"}
          ${applyTableCustomClass ? "custom-form-control" : ""} ${
            errors && errors[name] ? "error__border--color" : "form__input"
          }`}
          id={name}
          name={name}
          defaultValue={defaultTimeValue}
          {...register(name)}
          onChange={handleTimeChange}
        />
        {errors && errors[name] && (
          <p className="error__message">{errors[name].message}</p>
        )}
      </div>
    </div>
  );
};
 
export default TimePicker;