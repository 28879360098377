import React, { useState } from "react";
import "./FiredashboardCard.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function FireDashboardcard({
  data,
  config,
  clickedKey,
  setClickedKey,
  triggeredFunction,
  onIncidentSelect,
  startDateStr,
  endDateStr,
  cardData = [],
}) {
  const colors = ["#333c67"];
  const [selectedCard, setSelectedCard] = useState(null);

  const currentCardData = cardData && cardData.length > 0 ? cardData[0] : {};


  const formatCardData = (value) => {
    if (!value) return 0; // Ensure default value if undefined/null
    if (typeof value === "string") {
      const splitValue = value.split("(");
      return (
        <>
          {splitValue[0]} <br /> {splitValue[1]}
        </>
      );
    }
    return value;
  };

  console.log(cardData, "dashboardcard");

  const handleCardClick = (conf) => {
    setSelectedCard(conf.key);
    if (onIncidentSelect) {
      onIncidentSelect(conf.key);
    }
  };

  // Recursive function to render sub-data with proper key mappings
  const renderSubData = (subData) => {
    return (
      <div className="sub-data-container">
        {subData.map((sub, idx) => (
          <div
            key={idx}
            className={`sub-data-item ${
              selectedCard === sub.key ? "highlighted-card" : ""
            }`}
            onClick={(e) => {
              e.stopPropagation();
              handleCardClick(sub);
            }}
          >
            <FontAwesomeIcon icon={sub.icon} size="2x" />
            <div className="sub-label">{sub.label}</div>
            <div className="sub-value">
              {formatCardData(currentCardData?.[sub.key])}
            </div>
            {sub.subData && renderSubData(sub.subData)}
          </div>
        ))}
      </div>
    );
  };

  return (
    <div className="dashboard_card_fire">
      <div>{config.title}</div>
      <div className="d-grid gap-2 mt-1">
        {config?.data.map((conf, index) => (
          <div
            key={index}
            className={`ds-single_card_cw_fire cursor-pointer grid-container-cw-fire  ${
              selectedCard === conf.key ? "highlighted-card" : ""
            }`}
            onClick={() => handleCardClick(conf)}
            style={{ backgroundColor: colors[index % colors.length] }}
          >
            <div className="card-icon-fire">
              {conf.icon && <FontAwesomeIcon icon={conf.icon} size="3x" />}
            </div>
            <div
              className={`card-label-fire ${
                conf.subData
                  ? conf.subData.some((sub) => sub.subData)
                    ? "nested-sub-data"
                    : "has-sub-data"
                  : ""
              }`}
            >
              {conf.label}
            </div>

            <div
              className={`card-data-fire ${
                conf.subData
                  ? conf.subData.some((sub) => sub.subData)
                    ? "nested-card-data"
                    : "has-card-data"
                  : ""
              }`}
            >
              {formatCardData(currentCardData?.[conf.key])}
            </div>
            {conf.subData && renderSubData(conf.subData)}
          </div>
        ))}
      </div>
    </div>
  );
}
