import * as React from "react";
const SvgMyTaskList = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 512 512"
    fill="currentColor"
    {...props}
  >
    <path d="m141.9 13.9-2.9 2.9v36l-20.7.5c-15.9.3-22.1.8-26.3 2.2-18 5.6-31.1 18.8-36.7 37-1.7 5.7-1.8 14.1-1.8 185v179l2.2 7c5.5 17.6 20 31.3 38 36 4.9 1.3 26.9 1.5 162.3 1.5 137.4 0 157.3-.2 162.5-1.5 17.8-4.6 32.2-18.3 37.8-36l2.2-7v-179c0-170.9-.1-179.3-1.8-185-5.6-18.2-18.7-31.4-36.7-37-4.2-1.4-10.4-1.9-26.2-2.2l-20.8-.5v-36l-2.9-2.9c-4.8-4.7-13-3.7-16.4 2.1-1.4 2.3-1.7 5.7-1.7 19.9V53h-85V37c0-17.3-.9-21.4-5.1-24.4-3-2.1-8.8-2.1-11.8 0-4.2 3-5.1 7.1-5.1 24.4v16h-85V35.9c0-14.2-.3-17.6-1.7-19.9-3.4-5.8-11.6-6.8-16.4-2.1M139 93.1v18.1l2.9 2.9c4.8 4.7 13 3.7 16.4-2.1 1.4-2.3 1.7-5.7 1.7-19.9V75h85v16c0 17.3.9 21.4 5.1 24.4 3 2.1 8.8 2.1 11.8 0 4.2-3 5.1-7.1 5.1-24.4V75h85v17.1c0 14.2.3 17.6 1.7 19.9 3.4 5.8 11.6 6.8 16.4 2.1l2.9-2.9V75h18.9c15 0 20 .3 24.1 1.7 6.5 2.1 14.2 8.9 17.8 15.7l2.7 5.1v360l-2.3 4.3c-3.5 6.6-8.8 11.9-15 14.9l-5.7 2.8h-315l-5.7-2.8c-6.2-3-11.5-8.3-15-14.9l-2.3-4.3-.3-178.5C74.9 74.7 74 95 84.5 84.5c8.8-8.8 10.9-9.3 34.8-9.4L139 75z" />
    <path d="M186.5 161.9c-1.6 1-10.6 9.6-19.9 18.9l-17 17.1-8.2-7.8c-5.1-5-9.3-8.1-11.2-8.6-7-1.5-14.2 5.7-12.6 12.8.4 2.1 4.1 6.7 11.7 14.5 13.3 13.5 16.4 15.7 21.5 14.8 3.1-.5 6.9-3.9 26.9-23.8 15.1-15.1 23.7-24.4 24.4-26.5 2-6.1-3.5-13.3-10.3-13.3-1.3 0-3.6.9-5.3 1.9M230.3 182.5c-3.3 1.4-6.3 5.9-6.3 9.5 0 3.7 3 8.1 6.5 9.6 2.9 1.2 16 1.4 79.1 1.4 83.8 0 80.6.2 83.8-6.5.9-1.8 1.6-3.8 1.6-4.5s-.7-2.7-1.6-4.5c-3.2-6.8.1-6.5-84.1-6.5-62.3.1-76.4.3-79 1.5M168.5 286l-19.1 19-6.9-7.1c-10-10.1-13.4-11.5-19.6-8.3-4.1 2.1-6.4 7.5-4.9 11.8 1.2 3.9 24.3 27 28.4 28.6 2 .7 3.8.7 6 0 3.9-1.3 48-45.3 49.6-49.4 2.4-6.5-3-13.6-10.3-13.6-4 0-4.7.6-23.2 19M231 288.7c-8.1 3-9 15.7-1.4 19.2 1.8.8 25.1 1.1 80.3 1.1 76.5 0 77.9 0 80.4-2 3.8-3 5.2-8 3.3-12.3-2.9-7 1.1-6.7-84.3-6.6-42.2 0-77.5.3-78.3.6M187.5 374.6c-1.6.8-11 9.4-20.7 19.2l-17.7 17.6-8.4-8.2c-7.7-7.5-8.8-8.2-12.4-8.2-7.7 0-12.9 7.4-10 14.2.8 1.8 7.1 8.8 13.9 15.5C143.6 436 145 437 148.4 437c2 0 4.9-.6 6.4-1.4s12.7-11.5 24.8-23.8c16.7-16.8 22.3-23.1 22.8-25.5 1-4.4-1.7-9.7-5.9-11.7-4.1-2-5.2-2-9 0M229.3 396.2c-5.7 2.8-6.7 12.2-1.7 16.9l2.6 2.4 77.9.3c86.8.3 82.6.6 85.5-6.5 1.9-4.3.5-9.3-3.3-12.3-2.5-2-3.8-2-80.7-1.9-51.9 0-78.8.4-80.3 1.1" />
  </svg>
);
export default SvgMyTaskList;
