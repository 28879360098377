import * as React from "react";
const SvgCommunityKitchen = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    data-name="Layer 1"
    viewBox="0 0 24 24"
    width="1em"
    height="1em"
    fill= "currentColor"
    {...props}
  >
    <path d="M24 13h-4v-2h-5v2h-4v2h1v6.5c0 1.379 1.121 2.5 2.5 2.5h6c1.379 0 2.5-1.121 2.5-2.5V15h1zm-3 8.5c0 .275-.225.5-.5.5h-6a.5.5 0 0 1-.5-.5V15h7zM0 21h10a3 3 0 0 1-3 3H3a3 3 0 0 1-3-3m0-4h10a3 3 0 0 1-3 3H3a3 3 0 0 1-3-3m6.5-4c3.243 0 5.931-2.39 6.415-5.5H21v-2h-8.085C12.431 2.39 9.743 0 6.5 0 2.916 0 0 2.916 0 6.5S2.916 13 6.5 13m0-11C8.981 2 11 4.019 11 6.5S8.981 11 6.5 11 2 8.981 2 6.5 4.019 2 6.5 2" />
  </svg>
);
export default SvgCommunityKitchen;
