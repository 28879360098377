import React, { useContext, useEffect, useRef, useState } from "react";
import * as echarts from "echarts";
import moment from "moment";
import { useTranslation } from "react-i18next";
import {
  humanlossbarchartdata,
  humanlosstrendchartdata,
} from "../../../../../Services/EndPoints";
import { ThemeContext } from "../../../../..";
import NoData from "../../../../../Components/UiComponents/NoData/NoData";
import { getData } from "../../../../../Services/Services";
import ToggleView from "../../ToggleView/ToggleView";

const FamilyAffectedTrendViewHumanLoss = ({
  title,
  activeTab,
  selectedIncident,
  fromDateNew,
  toDateNew,
  status,
  applyClicked,
  setApplyClicked,
  onApplyHandled,
  firstLoad,
  setLoader
}) => {
  const { theme } = useContext(ThemeContext);
  const { t } = useTranslation();
  const echartsDom = useRef(null);
  const [chart, setChart] = useState(null);
  const [toggleChart, setToggleChart] = useState("bar");
  const [labelColor, setLabelColor] = useState("#fff");
  const [BarChartData, setBarChartData] = useState([]);
  const [trendChartData, setTrendChartData] = useState(null);
  const [selectedIncident1, setSelectedIncident1] = useState(null);

  // Set label color based on theme
  useEffect(() => {
    setLabelColor(theme === "dark" ? "#fff" : "#909097");
  }, [theme]);

  // Initialize and resize the chart
  useEffect(() => {
    if (echartsDom.current) {
      const myChart = echarts.init(echartsDom.current);
      setChart(myChart);

      const handleResize = () => {
        myChart.resize();
      };
      window.addEventListener("resize", handleResize);

      return () => {
        window.removeEventListener("resize", handleResize);
        myChart.dispose();
      };
    }
  }, [ selectedIncident]);

  const handleCardClick = (incidentId, incidentName) => {
    setSelectedIncident1({ id: incidentId, name: incidentName }); // Store both ID and Name
  };

  console.log(selectedIncident, selectedIncident1, "...................sss");

  
  const fetchData = async () => {

  setLoader(true)
    if (!selectedIncident) return;

    const fromDate = "2024-01-02";
    const toDate = "2025-01-24";

    try {
      const barChartData = await getData(
        humanlossbarchartdata
          .replace("FROMDATE", fromDateNew)
          .replace("TODATE", toDateNew)
          .replace("ID", selectedIncident.id)
      );
      setBarChartData(barChartData || []);

      const trendData = await getData(
        humanlosstrendchartdata
          .replace("FROMDATE", fromDateNew)
          .replace("TODATE", toDateNew)
          .replace("ID", selectedIncident.id)
      );
      setTrendChartData(trendData || {});
    } catch (error) {
      console.error("Error fetching data:", error);
    }
    setLoader(false)
  };

  useEffect(() => {
    if (firstLoad) {
      fetchData();
    }
  }, [firstLoad]); // Runs only when firstLoad is true

  // ✅ Fetch data when Apply button is clicked
  useEffect(() => {
    if (applyClicked) {
      fetchData();
      onApplyHandled(); // Reset applyClicked in parent after API call
    }
  }, [applyClicked]);

  useEffect(() => {
    if (selectedIncident) {
      fetchData();
    }
  }, [selectedIncident]);
  // Update chart options based on toggle and data
  useEffect(() => {
    if (chart) {
      chart.clear();
      if (toggleChart === "bar" && BarChartData.length > 0) {
        chart.setOption(getBarChartOptions());
      } else if (toggleChart === "trendChart" && trendChartData) {
        const dateArr = getDateArray(trendChartData); // Get dates dynamically
        const formattedData = mapTrendChartData(trendChartData, dateArr);
        chart.setOption(getTrendChartOptions(dateArr, formattedData));
      } else {
        chart.clear();
      }
    }
  }, [chart, toggleChart, BarChartData, trendChartData, selectedIncident]);
  
  // Helper to map trend chart data
  const mapTrendChartData = (data, dateArr) => {
    const seriesData = {
      injured: new Array(dateArr.length).fill(0),
      death: new Array(dateArr.length).fill(0),
      incident: new Array(dateArr.length).fill(0),
    };

    const formatDate = (timestamp) => moment(timestamp).format("DD-MM-YY");

    data?.injured?.forEach((item) => {
      const index = dateArr.indexOf(formatDate(item.reported_on));
      if (index !== -1) seriesData.injured[index] = item.injured_count || 0;
    });

    data?.death?.forEach((item) => {
      const index = dateArr.indexOf(formatDate(item.reported_on));
      if (index !== -1) seriesData.death[index] = item.deaths_count || 0;
    });

    data?.incident?.forEach((item) => {
      const index = dateArr.indexOf(formatDate(item.reported_on));
      if (index !== -1) seriesData.incident[index] = item.incident_count || 0;
    });

    return seriesData;
  };

  const getDateArray = (data) => {
  if (!data) return [];

  let allDates = [];

  // Collect all reported_on dates from each data type
  ["injured", "death", "incident"].forEach((key) => {
    if (data[key]) {
      allDates = allDates.concat(
        data[key].map((item) => moment(item.reported_on).format("YYYY-MM-DD"))
      );
    }
  });

  if (allDates.length === 0) return [];

  // Find min and max date from response
  const minDate = moment(Math.min(...allDates.map((d) => new Date(d))));
  const maxDate = moment(Math.max(...allDates.map((d) => new Date(d))));

  // Generate all dates in the range
  const dateRange = [];
  let currentDate = minDate.clone();

  while (currentDate.isSameOrBefore(maxDate)) {
    dateRange.push(currentDate.format("DD-MM-YY"));
    currentDate.add(1, "days"); // Move to the next day
  }

  return dateRange;
};

  

  // Bar chart options
  const getBarChartOptions = () => {
    const monthMapping = {
      1: "January",
      2: "February",
      3: "March",
      4: "April",
      5: "May",
      6: "June",
      7: "July",
      8: "August",
      9: "September",
      10: "October",
      11: "November",
      12: "December",
    };
  
    // Process data for chart
    const monthsData = BarChartData.map((item) => ({
      month: monthMapping[item.published_month] || "Unknown",
      deaths: item.deaths_count,
      incidents: item.incident_count,
      Injured: item.injured_count,
      monthIndex: item.published_month, // Keep track of month number for sorting
    }));
  
    // Sort the data by month order
    monthsData.sort((a, b) => a.monthIndex - b.monthIndex);
  
    // Extract data for chart
    const months = monthsData.map((item) => item.month);
    const deaths = monthsData.map((item) => item.deaths);
    const incidents = monthsData.map((item) => item.incidents);
    const Injured = monthsData.map((item) => item.Injured);
  
    // Define colors
    const colors = {
      deaths: "#FF7070", // Red
      incidents: "#FFEE70", // Gold
      Injured: "#FFB500", // Blue
    };
  
    return {
      title: {
        text: t(`Monthwise ${title} Report`),
        left: "center",
        textStyle: {
          color: labelColor,
        },
      },
      xAxis: {
        type: "category",
        data: months,
        axisLabel: {
          textStyle: {
            color: labelColor,
          },
        },
      },
      yAxis: {
        type: "value",
        name: t("Count"),
        nameLocation: "middle",
        nameGap: 30,
        axisLabel: {
          textStyle: {
            color: labelColor,
          },
        },
      },
      tooltip: {
        trigger: "item", // Show tooltip only for the hovered bar
        formatter: (params) => `${params.seriesName}: ${params.value}`,
      },
      legend: {
        data: [t("Incidents"),t("Deaths"), t("Injured")],
        bottom: 0,
        textStyle: {
          color: labelColor,
        },
      },
      series: [ 
        {
          name: t("Incidents"),
          data: incidents,
          type: "bar",
          itemStyle: { color: colors.incidents },
          barGap: "0%", // Keep bars close together
        },
        {
          name: t("Deaths"),
          data: deaths,
          type: "bar",
          itemStyle: { color: colors.deaths },
          barGap: "0%", // Keep bars close together
        },
        {
          name: t("Injured"),
          data: Injured,
          type: "bar",
          itemStyle: { color: colors.Injured },
          barGap: "0%", // Keep bars close together
        },
      ],
    };
  };
  
  
  
  

  // Trend chart options
  const getTrendChartOptions = (dateArr, seriesData) => {
    const generateColors = (numColors) => {
      const baseColors = [
        "#F32F0E",
        "#18A3A0",
        "#F69A06",
        "#83571c",
        "#F6F506",
        "#B3F606",
        "#36F606",
        "#06F6EE",
        "#069FF6",
        "#060BF6",
        "#BE06F6",
        "#F6069D",
        "#F60655",
        "#F378BA",
        "#470E3C",
      ];
      const extendedColors = [];

      for (let i = 0; i < numColors; i++) {
        extendedColors.push(baseColors[i % baseColors.length]);
      }

      return extendedColors;
    };

    const seriesCount = 3; // Since there are three series: Injured, Deaths, Incidents
    const lineColors = generateColors(seriesCount);

    // Helper function to apply ellipsis to legend names
    const applyEllipsis = (text, maxLength) => {
      return text?.length > maxLength ? `${text.slice(0, maxLength)}...` : text;
    };

    const seriesNames = ["Incidents", "Deaths", "Injured"];

    return {
      title: {
        // text: t("Trend of Affected Cases"),
        left: "center",
        textStyle: { color: labelColor },
      },
      tooltip: {
        trigger: "axis",
        formatter: (params) =>
          params
            .map((item) => `${item.seriesName}: ${item.value}`)
            .join("<br>"),
      },
      legend: {
        type: "scroll",
        orient: "vertical",
        right: "0",
        top: "5%",
        bottom: "10%",
        textStyle: {
          color: labelColor,
        },
        formatter: (name) => applyEllipsis(name, 10),
        data: seriesNames,
      },
      xAxis: {
        type: "category",
        data: dateArr,
        axisLabel: {
          rotate: 45,
          textStyle: { color: labelColor,fontSize: 10, },
        },
      },
      yAxis: {
        type: "value",
        name: t("Count"),
        nameLocation: "middle",
        nameGap: 40,
        axisLabel: { textStyle: { color: labelColor } },
      },
      dataZoom: [
        {
          type: "slider", // Use slider for zooming
          show: true, // Display the slider
          xAxisIndex: [0], // Apply to the x-axis
          start: 0, // Initial zoom range (start at the beginning)
          end: 100, // Initial zoom range (end at the full chart)
          handleSize: "100%",
          height:"4%",
          handleStyle: {
            color: "#E95060",
          },
          // top:10,
          bottom :10,
        },
        {
          type: "inside", // Allows zooming using mouse scroll
          xAxisIndex: [0], // Apply to the x-axis
          start: 0,
          end: 100,
        },
      ],
      series: [
        {
          name: t("Incidents"),
          type: "line",
          data: seriesData.incident,
          itemStyle: { color: lineColors[2] },
          smooth: true,
          symbolSize: 15,
          emphasis: { focus: "series" },
          lineStyle: { width: 3, color: lineColors[2] },
        },
        {
          name: t("Deaths"),
          type: "line",
          data: seriesData.death,
          itemStyle: { color: lineColors[1] },
          smooth: true,
          symbolSize: 15,
          emphasis: { focus: "series" },
          lineStyle: { width: 3, color: lineColors[1] },
        },
        {
          name: t("Injured"),
          type: "line",
          data: seriesData.injured,
          itemStyle: { color: lineColors[0] },
          smooth: true,
          symbolSize: 15,
          emphasis: { focus: "series" },
          lineStyle: { width: 3, color: lineColors[0] },
        },
        
      ],
    };
  };
  if (!selectedIncident) {
    return (
      <div className="card-body">
      <div style={{ textAlign: "center", padding: "20px", fontSize: "16px", color: "#777" }}>
        Click on Incident cards to show the Data
      </div>
      </div>
    );
  }

  if (selectedIncident.id === 0 || selectedIncident.id === "ALL" ) {
    return (
      <div className="card-body">
      <div style={{ textAlign: "center", padding: "20px", fontSize: "16px", color: "#777",  }}>
        No data avalible for the selected Incident
      </div>
      </div>
    );
  }

  return (
    <div className="card" style={{ width: "100%", height: "100%" }} onClick={() => handleCardClick(selectedIncident.id)}>
      <div className="card-body">
        <div className="d-flex justify-content-between mb-2">
          <div className="card-title">{title}</div>
          <div className="d-flex gap-2">
            {activeTab === "dss" && (
              <div>
                <ToggleView setActiveTab={setToggleChart} activeTab={toggleChart} />
              </div>
            )}
          </div>
        </div>
        <div ref={echartsDom} style={{ height: "422px", width: "100%" }} />
      </div>
    </div>
  );
};

export default FamilyAffectedTrendViewHumanLoss;
