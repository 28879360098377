import * as React from "react";
const SvgConsolidatedRptIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    data-name="Layer 1"
    viewBox="0 0 24 24"
    width="1em"
    height="1em"
    fill= "currentColor"
    {...props}
  >
    <path d="M19 1H5C2.243 1 0 3.243 0 6v12c0 2.757 2.243 5 5 5h14c2.757 0 5-2.243 5-5V6c0-2.757-2.243-5-5-5M5 3h14c1.654 0 3 1.346 3 3v2H2V6c0-1.654 1.346-3 3-3m14 18H5c-1.654 0-3-1.346-3-3v-8h20v8c0 1.654-1.346 3-3 3M3 5.5a1.5 1.5 0 1 1 3.001.001A1.5 1.5 0 0 1 3 5.5m4 0a1.5 1.5 0 1 1 3.001.001A1.5 1.5 0 0 1 7 5.5M9 16v2a1 1 0 1 1-2 0v-2a1 1 0 1 1 2 0m4-3v5a1 1 0 1 1-2 0v-5a1 1 0 1 1 2 0m4 2v3a1 1 0 1 1-2 0v-3a1 1 0 1 1 2 0" />
  </svg>
);
export default SvgConsolidatedRptIcon;
