import * as React from "react";
const SvgStateFloodIcons = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 512 512"
    fill= "currentColor"
    {...props}
  >
    <path d="M142.7 1.1c-5.7 1.3-13.4 5.6-18.3 10.1C120.3 15 113 27.4 113 30.6c0 1.1-1.7 1.4-6.8 1.4-10.2 0-17.8 1.7-26.8 6C64.2 45.3 52 61.2 49.1 77.4c-1.5 8.3-1.5 380.9 0 389.2 3.5 19.4 18.6 36.1 38.9 43 5.3 1.8 11.5 1.9 156 1.9s150.7-.1 156-1.9c18.3-6.2 30.8-18.4 37.1-36.3 1.5-4 2.3-9.3 2.6-17.4l.6-11.5 4.3-2.4c6.1-3.2 12-9.9 15.7-17.8l3.2-6.7v-387l-3.2-6.7c-4.4-9.3-10-15.1-19.1-19.6L433.6.5 290.1.3c-81.5 0-145.2.3-147.4.8m286.8 33.4 2.5 2.4v374.2l-2.5 2.4-2.4 2.5H148.9l-2.4-2.5-2.5-2.4V36.9l2.5-2.4 2.4-2.5h278.2zM112.2 240.8l.3 176.8 3.7 7.6c4.5 9.1 10.3 14.7 19.6 19.1l6.7 3.2 132.8.3 132.7.2v5.7c0 3-.5 7.4-1.2 9.7-1.6 5.5-9.9 13.8-15.4 15.4-6 1.7-288.8 1.7-294.8 0-5.5-1.6-13.8-9.9-15.4-15.4-1.8-6-1.8-376.8 0-382.8 1.5-5.1 9.9-13.7 14.8-15.3 1.9-.6 6.3-1.1 9.7-1.2l6.3-.1z" />
    <path d="M360 68.4c-25 5.2-24.2 4.9-27.9 9.1-5.8 6.6-5.4 15.1 1.1 22.2l4.2 4.4-12.7 12.7-12.7 12.7-24.2-24.2c-20.8-20.7-24.8-24.2-28-24.8-9.1-1.7-8.4-2.3-47 36.3s-38 37.9-36.3 46.9c.9 4.9 6.9 10.9 11.8 11.8 8.8 1.7 9 1.6 39.4-28.8l28.3-28.1 24.2 24.1c33.5 33.3 30.3 33.1 59.5 4l20.2-20 4.5 4.1c7 6.5 15.5 6.9 22.1 1.1 4.2-3.7 4.4-4.5 9.6-30.6 2.8-14.4 3.9-22.2 3.5-24.7-1.3-7-8.6-12.7-15.8-12.5-1.8.1-12.5 2-23.8 4.3M185.5 209.4c-6.7 2.9-10.5 10.6-9.1 18.1.9 4.7 5.9 10.2 10.4 11.5 2.5.8 35.3 1 103.4.8 98.9-.3 99.7-.3 102.4-2.4 5.3-3.9 6.9-7.1 6.9-13.4s-1.6-9.5-6.9-13.4c-2.7-2.1-3.5-2.1-103.4-2.3-82.2-.2-101.3 0-103.7 1.1M193.5 289.4c-6.7 2.9-10.5 10.6-9.1 18.1.9 4.7 5.9 10.2 10.4 11.5 2.5.8 34.1 1 99.4.8 94.8-.3 95.7-.3 98.4-2.4 5.3-3.9 6.9-7.1 6.9-13.4s-1.6-9.5-6.9-13.4c-2.7-2.1-3.5-2.1-99.4-2.3-78.9-.2-97.3 0-99.7 1.1M193.5 353.4c-6.7 2.9-10.5 10.6-9.1 18.1.9 4.7 5.9 10.2 10.4 11.5 2.5.8 20.9 1 55.4.8 50.4-.3 51.7-.4 54.4-2.4 5.3-3.9 6.9-7.1 6.9-13.4s-1.6-9.5-6.9-13.4c-2.7-2.1-4-2.1-55.4-2.3-42.3-.2-53.3 0-55.7 1.1" />
  </svg>
);
export default SvgStateFloodIcons;
