import React, { useEffect, useState } from "react";
import GridItem from "../../../../Components/UiComponents/GridItem/GridItem";
import { GridStack } from "gridstack";
import DMSHeader from "../../DMSHeader/DMSHeader";
import { useTranslation } from "react-i18next";
import "./DailyFireReportEditableForm.css";
import { toast, ToastContainer } from "react-toastify";
import Accordion from "react-bootstrap/Accordion";
// import MajorRivers from "./MajorRivers/MajorRivers";
import {
  getDailyFloodReportListURL,
  getFireReportData,
  misReportDataCumm,
  updateFireReportData,
  updateFloodReport,
} from "../../../../Services/EndPoints";
import { getData, postDataWithBody } from "../../../../Services/Services";
import { Button } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
// import DailyReportSubForm from "./DailyReportSubForm/DailyReportSubForm";
import DailyFireReportSubForm from "./DailyFireReportSubForm/DailyFireReportSubForm";

const DailyFireReportEditableForm = () => {
  const { t } = useTranslation();
  const { state } = useLocation();
  const [dailyFireReportData, setDailyFireReportData] = useState([]);
  const [riverList, setRiverList] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    GridStack.init();
  }, []);
  const layoutData = [
    {
      x: 0,
      y: 0,
      width: 12,
      height: 1,
      id: 1,
      component: (
        <DMSHeader showIconsForback={true} title={t("Edit Daily Fire Report")} />
      ),
      haveOverflowVisible: true,
    },
  ];
  useEffect(() => {
    getDailyFloodReportList();
  }, []);

  const getDailyFloodReportList = async () => {
    const region_id = state.blockId;
    const reported_on = state.slctd_dt;
    let inputobj = {
        region_id_list: [region_id],
        reported_on: reported_on,
    };
    const data = await postDataWithBody(getFireReportData, inputobj, {});
    console.log("FIRERESPONSE",data);

    let mergedReport = {};

    // Merge `fire_report`
    data.fire_report.forEach((report) => {
        let regionId = report.region_id;
        if (!mergedReport[regionId]) {
            mergedReport[regionId] = { ...report, address: [] };
        } else {
            Object.assign(mergedReport[regionId], report);
        }
    });

    // Merge `fire_report_address` (store entire address objects)
    let addressMap = {};
    data.fire_report_address.forEach((address) => {
        let regionId = address.region_id;
        if (!addressMap[regionId]) {
            addressMap[regionId] = [];
        }
        addressMap[regionId].push(address);
    });

    Object.keys(addressMap).forEach((regionId) => {
        if (mergedReport[regionId]) {
            mergedReport[regionId].address = addressMap[regionId]; // Set full address objects
        }
    });

    // Merge `fire_report_cum`
    if (data.fire_report_cum.length > 0 && 
        data.fire_report.length === 0 && 
        data.fire_report_address.length === 0) {
        // If only `fire_report_cum` exists, use it directly
        data.fire_report_cum.forEach((cumReport) => {
            let regionId = cumReport.region_id;
            mergedReport[regionId] = { ...cumReport, address: [] };
        });
    } else {
        // Normal merging if all reports exist
        data.fire_report_cum.forEach((cumReport) => {
            let regionId = cumReport.region_id;
            if (mergedReport[regionId]) {
                Object.assign(mergedReport[regionId], cumReport);
            }
        });
    }

    setDailyFireReportData(Object.values(mergedReport));
};






const submitHandler = async (data, e) => {
    console.log("SUBMITTED data", data);

    const id = state?.id;
    const addressObj = state?.addressObj || [];
    const block = state?.blockId;
    const date = state?.slctd_dt;
    const distName = state?.distName;

    // Extract address fields along with their corresponding IDs
    const addressFields = Object.keys(data)
        .filter(key => key.startsWith("addresses_of_places_of_incidents_") && !key.includes("_hidden") && !key.includes("_id"))
        .map((key) => {
            const index = key.match(/\d+/)?.[0]; // Extract numeric index from key
            const address = data[key] ? data[key].trim() : null;
            const addressId = data[`addresses_of_places_of_incidents_${index}_id`]; // Get corresponding ID
            return { address, addressId };
        })
        .filter(item => item.address); // Remove empty/null addresses

    console.log("Extracted addressFields", addressFields);

    // Map addresses based on their IDs
    const updatedAddressFields = addressFields.map(item => {
        const existingAddress = addressObj.find(addr => addr.id === item.addressId);

        return existingAddress
            ? { address: item.address, id: existingAddress.id }  // Prioritize updated address from data
            : { address: item.address };  // New address without ID
    });

    console.log("Final Address List", updatedAddressFields);

    const inputObj = {
        id: id,
        is_submitted: true,
        fire_daily_report: {
            patients_affected_report: {
                no_of_incidents: data.number_of_incidents_today,
                no_of_human_deaths_today: data.human_today,
                no_of_cattle_deaths_today: data.cattle_today,
                no_of_poultry_deaths_today: data.poultry_today,
                no_of_human_injured_today: data.human_injured_today,
                no_of_cattle_injured_today: data.cattle_injured_today,
                no_of_poultry_injured_today: data.poultry_injured_today,
                no_of_deceased_pay_exgra_done_today: data.number_of_deceased_for_whom_payment_of_ex_gratia_done_today,
                no_of_dead_cattles_pay_done_today: data.number_of_dead_cattles_for_which_payment_done_today,
                no_of_dead_poultry_pay_done_today: data.number_of_dead_poultry_for_which_payment_done_today,
            },
            damaged_house_report: {
                kutcha_complete: data.kutcha_complete_today,
                kutcha_partial: data.kutcha_partial_today,
                concrete_complete: data.concrete_complete_today,
                concrete_partial: data.concrete_partial_today,
                cottage_hut: data.cottage_hut_today,
                total: data.total_number_of_damaged_houses_today,
                kutcha_complete_pay_done_today: data.kutcha_pay_complete_today,
                kutcha_partial_pay_done_today: data.kutcha_pay_partial_today,
                concrete_complete_pay_done_today: data.concrete_pay_complete_today,
                concrete_partial_pay_done_today: data.concrete_pay_partial_today,
                cottage_hut_pay_done_today: data.cottage_pay_hut_today,
                today_pay_done_today: data.total_number_of_damaged_houses_for_which_payment_done_today,
                no_of_dam_cattle_sheds_today: data.number_of_damaged_cattle_sheds_today,
                no_of_dam_cattle_sheds_pay_done_today: data.number_of_damaged_cattle_sheds_for_which_payment_done_today,
                amount_paid_dam_hous_hut_cattle_today: data.amount__paid_against_cattle_shed_rs_today,
            },
            area_affected_report: {
                crop_type: data.crop_type === "" ? "" :  data.crop_type === "1" ? "Kharif" : "Rabi", // Mapping 1 to Kharif, 0 to Rabi
                damaged_crop_area_affected: data.area_of_damaged_crop_in_acre_today,
                estimated_value_of_damaged_crops_today: data.estimated_value_of_damaged_crops_rs_today,
                amount_paid_agricultural_input_sub_today: data.amount_paid_under_the_agriculture_input_subsidy_rs_today,
                area_crop_agricultural_input_sub_paid_today: data.area_of_crop_for_which_agriculture_input_subsidy_paid_in_acre_today,
                total_amount_GR_paid_7K_per_fam_today: data.total_amount_of_gr_paid_rs_7000_per_family_today,
                total_amount_cloth_paid_5K_per_fam_today: data.total_amount_paid_for_cloth_utensils_5000_per_family_rs_today,
                number_of_polythene_sheets_distributed_today: data.number_of_polythene_sheets_distributed_today,
                quantity_dry_ration_distributed_today: data.quantity_of_Dry_ration_distributed_kg_today,
                remarks: data.remarks,
            },
            fire_dr_address_list: updatedAddressFields, // Use updated addresses
        },
    };

    try {
        let res = await postDataWithBody(updateFireReportData, inputObj, {});
        if (res.status === "success") {
            toast.success(res.msg, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000,
            });
            setTimeout(() => {
                navigate("/reports/fireincident-report",{
                  state: {
                    updated: true,
                    date: date,
                    block: block,
                    distName: distName
                  },
                });
              }, 2000); // Delay to allow toast message to display
        } else {
            toast.error(res.msg, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000,
            });
        }
    } catch (error) {
        console.error("Error during form submission:", error);
        toast.error("An error occurred while submitting the form.", {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 2000,
        });
    }
};



  
  
  
  
 
console.log("dailyFireReportDataSendingfrEdit:",dailyFireReportData);
  return (
    <div>
      <div className="grid-stack grid-stack-instance-1 smartpole__header--transition">
        <GridItem layoutData={layoutData} />
      </div>
      <div style={{ border: "1px solid white", margin: "9px" }}>
        <Accordion defaultActiveKey="0" flush>
          <Accordion.Item eventKey="0">
            <Accordion.Header>{t("1. Number of incidents")}</Accordion.Header>
            <Accordion.Body className="accordion-body-style">
              <div>
                <DailyFireReportSubForm
                  data={dailyFireReportData}
                  submitHandler={submitHandler}
                  form_name={"number_of_incidents"}
                />
              </div>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="1">
            <Accordion.Header>{t("2. Addresses of the places of incidents")}</Accordion.Header>
            <Accordion.Body className="accordion-body-style">
              <div>
                <DailyFireReportSubForm
                  data={dailyFireReportData}
                  submitHandler={submitHandler}
                  form_name={"address_of_places_of_incidents"}
                />
              </div>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="2">
            <Accordion.Header>
              {t("3. Number of deaths")}
            </Accordion.Header>
            <Accordion.Body className="accordion-body-style">
              <DailyFireReportSubForm
                data={dailyFireReportData}
                submitHandler={submitHandler}
                form_name={"number_of_deaths"}
              />
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item eventKey="3">
            <Accordion.Header>{t("4. Number of injured")}</Accordion.Header>
            <Accordion.Body className="accordion-body-style">
              <div>
                <DailyFireReportSubForm
                  data={dailyFireReportData}
                  submitHandler={submitHandler}
                  form_name={"number_of_injured"}
                />
              </div>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="4">
            <Accordion.Header>{t("5. Ex-gratia details")}</Accordion.Header>
            <Accordion.Body className="accordion-body-style">
              <DailyFireReportSubForm
                data={dailyFireReportData}
                submitHandler={submitHandler}
                form_name={"ex_gratia_details"}
              />
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item eventKey="5">
            <Accordion.Header>{t("6. Number of damaged houses")}</Accordion.Header>
            <Accordion.Body className="accordion-body-style">
              <DailyFireReportSubForm
                data={dailyFireReportData}
                submitHandler={submitHandler}
                form_name={"number_of_damaged_houses"}
              />
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="6">
            <Accordion.Header>{t("7. Number of damaged houses for which payment done")}</Accordion.Header>
            <Accordion.Body className="accordion-body-style">
              <DailyFireReportSubForm
                data={dailyFireReportData}
                submitHandler={submitHandler}
                form_name={"number_of_damaged_houses_for_which_payment_done"}
              />
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="7">
            <Accordion.Header>{t("8. Cattle shed details and Compensation for damaged house/hut/cattle shed")}</Accordion.Header>
            <Accordion.Body className="accordion-body-style">
              <DailyFireReportSubForm
                data={dailyFireReportData}
                submitHandler={submitHandler}
                form_name={"cattle_shed_details"}
              />
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="8">
            <Accordion.Header>{t("9. Damaged Crop")}</Accordion.Header>
            <Accordion.Body className="accordion-body-style">
              <DailyFireReportSubForm
                data={dailyFireReportData}
                submitHandler={submitHandler}
                form_name={"damaged_crop"}
              />
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="9">
            <Accordion.Header>{t("10. Relief Distribution")}</Accordion.Header>
            <Accordion.Body className="accordion-body-style">
              <DailyFireReportSubForm
                data={dailyFireReportData}
                submitHandler={submitHandler}
                form_name={"relief_distribution"}
              />
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="10">
            <Accordion.Header>{t("11. Remarks")}</Accordion.Header>
            <Accordion.Body className="accordion-body-style">
              <DailyFireReportSubForm
                data={dailyFireReportData}
                submitHandler={submitHandler}
                form_name={"remarks"}
              />
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </div>
    </div>
  );
};

export default DailyFireReportEditableForm;
