import {
  faPersonDrowning,
  faBolt,
  faCarCrash,
  faRodSnake,
  faShip,
  faThunderstorm,
  faTrain,
  faFire,
  faPlane,
  faBuilding,
  faSkull,
  faQuestion,
  faCloudSun,
  faGasPump,
  faCloudShowersWater,
  faSnowflake,
  faBugs,
  faCloudRain,
  faCloudSunRain,
  faHouseFloodWater,
  faExplosion,
  faCloudMeatball
} from "@fortawesome/free-solid-svg-icons";

export const dashboardCardConfigHumanLoss = [
  {
    id: 0,
    title: "Deaths in incident",
    data: [
      {
        label: "All Incidents Data",
        key: "", // Update this to match the API response key
        // icon: faPersonDrowning,
        incidentId: "drowningId",
      },
      {
        label: "Drowning",
        key: "Drowning", // Update this to match the API response key
        icon: faPersonDrowning,
        incidentId: "drowningId",
      },
      {
        label: "Lightning",
        key: "Lightning", // Update this to match the API response key
        apiKey: "getPopEvacuationDetails",
        apiKey2: "trnd_population_evacuation",
        icon: faBolt,
      },
      {
        label: "Snakebite",
        key: "Snakebite", // Update this to match the API response key
        apiKey: "getFamilyAffectedDetails",
        apiKey2: "trnd_family_affected",
        icon: faRodSnake,
      },
      {
        label: "Road Accident",
        key: "Road Accident", // Update this to match the API response key
        icon: faCarCrash,
      },
      {
        label: "Boat Tragedy",
        key: "Boat Tragedy", // Update this to match the API response key
        apiKey: "getAnimalAffectedDetails",
        apiKey2: "trnd_animal_affected",
        icon: faShip,
      },
      {
        label: "Strong Wind (Andhi/Tufan)",
        key: "Strong Wind (Andhi/Tufan)", // Update this to match the API response key
        apiKey: "getAnimalAffectedDetails",
        apiKey2: "trnd_animal_affected",
        icon: faThunderstorm,
      },
      {
        label: "Rail Accident",
        key: "Rail Accident", // Update this to match the API response key
        apiKey: "getAnimalAffectedDetails",
        apiKey2: "trnd_animal_affected",
        icon: faTrain,
      },
      {
        label: "Fire",
        key: "Fire", // Update this to match the API response key
        // apiKey: "getAnimalAffectedDetails",
        // apiKey2: "trnd_animal_affected",
        icon: faFire,
      },
      {
        label: "Air Accident",
        key: "Air Accident", // Update this to match the API response key
        apiKey: "getAnimalAffectedDetails",
        apiKey2: "trnd_animal_affected",
        icon: faPlane,
      },
      {
        label: "Earthquake",
        key: "Earthquake", // Update this to match the API response key
        apiKey: "getAnimalAffectedDetails",
        apiKey2: "trnd_animal_affected",
        icon: faBuilding,
      },
      {
        label: "Heat Wave",
        key: "Heat Wave", // Update this to match the API response key
        apiKey: "getAnimalAffectedDetails",
        apiKey2: "trnd_animal_affected",
        icon: faCloudSun,
      },
      {
        label: "Gas Leakage",
        key: "Gas Leakage", // Update this to match the API response key
        apiKey: "getAnimalAffectedDetails",
        apiKey2: "trnd_animal_affected",
        icon: faExplosion,
      },
      {
        label: "Cold Wave",
        key: "Cold Wave", // Update this to match the API response key
        apiKey: "getAnimalAffectedDetails",
        apiKey2: "trnd_animal_affected",
        icon: faSnowflake,
      },
      {
        label: "Cloud Burst",
        key: "Cloud Burst", // Update this to match the API response key
        apiKey: "getAnimalAffectedDetails",
        apiKey2: "trnd_animal_affected",
        icon: faCloudShowersWater,
      },
      {
        label: "Hail Storm",
        key: "Hail Storm", // Update this to match the API response key
        apiKey: "getAnimalAffectedDetails",
        apiKey2: "trnd_animal_affected",
        icon: faCloudMeatball,
      },
      {
        label: "Pest Attack",
        key: "Pest Attack", // Update this to match the API response key
        apiKey: "getAnimalAffectedDetails",
        apiKey2: "trnd_animal_affected",
        icon: faBugs,
      },
      {
        label: "Excess Rainfall",
        key: "Excess Rainfall", // Update this to match the API response key
        apiKey: "getAnimalAffectedDetails",
        apiKey2: "trnd_animal_affected",
        icon: faCloudRain,
      },
      {
        label: "Unseasonal Heavy Rain",
        key: "Unseasonal Heavy Rain", // Update this to match the API response key
        apiKey: "getAnimalAffectedDetails",
        apiKey2: "trnd_animal_affected",
        icon: faCloudSunRain,
      },
      {
        label: "Cyclone",
        key: "Cyclone", // Update this to match the API response key
        apiKey: "getAnimalAffectedDetails",
        apiKey2: "trnd_animal_affected",
        icon: faHouseFloodWater,
      },
      {
        label: "Others",
        key: "Others", // Update this to match the API response key
        apiKey: "getAnimalAffectedDetails",
        apiKey2: "trnd_animal_affected",
        icon: faSkull,
      },
    ],
  },
];

