import React, { useEffect, useState } from "react";
import GridItem from "../../../../Components/UiComponents/GridItem/GridItem";
import { GridStack } from "gridstack";
import DMSHeader from "../../DMSHeader/DMSHeader";
import DailyFloodReportStatusForm from "./DailyFireWaveReportStatusForm";
import moment from "moment";
import DailyColdWaveReportStatusForm from "./DailyFireWaveReportStatusForm";
import DailyHeatWaveReportStatusForm from "./DailyFireWaveReportStatusForm";
import DailyFireWaveReportStatusForm from "./DailyFireWaveReportStatusForm";
//import DMSHeader from "../../DMSHeader/DMSHeader";

const DailyFireWaveReportStatus = () => {
  const [selectedDate, setSelectedDate] = useState(
    moment().format("YYYY-MM-DD")
  );
  useEffect(() => {
    GridStack.init();
  }, []);
  const fireWaveReportStatusfile = `DailyFireWaveReportStatus_${selectedDate}`;
  console.log("filename in reprotstatus=====", fireWaveReportStatusfile);

  const layoutData = [
    {
      x: 0,
      y: 0,
      width: 12,
      height: 1,
      id: 1,
      component: (
        <DMSHeader
          title="Fire Daily Report Status"
          fileName={`DailyFireWaveReportStatus`}
          showdownload={true}
        />
      ),
      haveOverflowVisible: true,
    },
    {
      x: 0,
      y: 1,
      width: 12,
      height: 10,
      id: 2,
      component: (
        <DailyFireWaveReportStatusForm
          setSelectedDate={setSelectedDate}
          selectedDate={selectedDate}
        />
      ),
    },
  ];

  return (
    <div className="grid-stack grid-stack-instance-1 smartpole__header--transition">
      <GridItem layoutData={layoutData} />
    </div>
  );
};

export default DailyFireWaveReportStatus;
