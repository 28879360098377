export const misDistReportConfig = {
  colKey1: "",
  colKey2: "",
  colKey3: "block_name",
  colKey4: "number_of_bonfire_places",
  colKey5: "number_of_bonfire_places_cum",
  colKey6: "quantity_of_wood_burnt",
  colKey7: "quantity_of_wood_burnt_till_now",
  colKey8: "number_of_deaths",
  colKey9: "number_of_deaths_cum",
  colKey10: "number_of_night_shelters",
  colKey11: "number_of_night_shelters_cum",
  colKey12: "number_of_ppl_taking_shelter_in_night_shelters",
  colKey13: "number_of_ppl_taking_shelter_in_night_shelters_cum",
  colKey14: "number_of_blankets_distributed",
  colKey15: "number_of_blankets_distributed_cum"
};

export const misColumns = Object.entries(misDistReportConfig).map(
  ([key, value], index) => ({
    Header: `misdistcoldwavereport.headercol${index + 1}`,
    accessor: value,
    disableSortBy: true,
    showInitially: true,
  })
);
