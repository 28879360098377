import * as React from "react";
const SvgFloodDashboardIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    data-name="Layer 1"
    viewBox="0 0 24 24"
    fill= "currentColor"
    {...props}
  >
    <path d="M21 23a4.38 4.38 0 0 1-3-1.225 4.336 4.336 0 0 1-6 0 4.336 4.336 0 0 1-6 0 4.186 4.186 0 0 1-5.668.2 1 1 0 1 1 1.335-1.489 2.2 2.2 0 0 0 3.39-.822A1 1 0 0 1 6 19a1 1 0 0 1 .943.667 2.278 2.278 0 0 0 4.114 0A1 1 0 0 1 12 19a1 1 0 0 1 .943.667 2.278 2.278 0 0 0 4.114 0A1 1 0 0 1 18 19a1 1 0 0 1 .943.667 2.2 2.2 0 0 0 3.389.823 1 1 0 0 1 1.336 1.487A3.98 3.98 0 0 1 21 23m0-6a4.38 4.38 0 0 1-3-1.225 4.336 4.336 0 0 1-6 0 4.336 4.336 0 0 1-6 0 4.186 4.186 0 0 1-5.668.2 1 1 0 1 1 1.335-1.489 2.2 2.2 0 0 0 3.39-.822A1 1 0 0 1 6 13a1 1 0 0 1 .943.667 2.278 2.278 0 0 0 4.114 0A1 1 0 0 1 12 13a1 1 0 0 1 .943.667 2.278 2.278 0 0 0 4.114 0A1 1 0 0 1 18 13a1 1 0 0 1 .943.667 2.2 2.2 0 0 0 3.389.823 1 1 0 0 1 1.336 1.487A3.98 3.98 0 0 1 21 17m0-6a4.38 4.38 0 0 1-3-1.225 4.336 4.336 0 0 1-6 0 4.336 4.336 0 0 1-6 0 4.186 4.186 0 0 1-5.668.2 1 1 0 1 1 1.335-1.486 2.2 2.2 0 0 0 3.39-.822A1 1 0 0 1 6 7a1 1 0 0 1 .943.667 2.278 2.278 0 0 0 4.114 0A1 1 0 0 1 12 7a1 1 0 0 1 .943.667 2.278 2.278 0 0 0 4.114 0A1 1 0 0 1 18 7a1 1 0 0 1 .943.667 2.2 2.2 0 0 0 3.389.823 1 1 0 0 1 1.336 1.487A3.98 3.98 0 0 1 21 11m0-6a4.38 4.38 0 0 1-3-1.225 4.336 4.336 0 0 1-6 0 4.336 4.336 0 0 1-6 0 4.186 4.186 0 0 1-5.668.2 1 1 0 1 1 1.335-1.486 2.2 2.2 0 0 0 3.39-.822A1 1 0 0 1 6 1a1 1 0 0 1 .943.667 2.278 2.278 0 0 0 4.114 0A1 1 0 0 1 12 1a1 1 0 0 1 .943.667 2.278 2.278 0 0 0 4.114 0A1 1 0 0 1 18 1a1 1 0 0 1 .943.667 2.2 2.2 0 0 0 3.389.823 1 1 0 1 1 1.336 1.487A3.98 3.98 0 0 1 21 5" />
  </svg>
);
export default SvgFloodDashboardIcon;
