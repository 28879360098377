export const misDistReportConfig = {
    colKey1: "",
    colKey2: "",
    colKey3: "reported_on",
    colKey4: "distName",
    colKey5: "blockName",
    colKey6: "nameOfDisaster",
    colKey7: "incidentId",
    colKey8: "latitude",
    colKey9: "longitude",
    colKey10: "typeOfCasualty",
    colKey11: "personName",
    colKey12: "personFamilyMemberName",
    colKey13: "age",
    colKey14: "gender",
    colKey15: "personAddress",
    colKey16: "activityDuringAccident",
    colKey17: "isPostMortem",
    colKey18: "occupation",
    colKey19: "heatStrokeCertified",
    colKey20: "isHospitalized",
    colKey21: "hospitalName",
    colKey22: "isReportedToPS",
    colKey23: "policeStationName",
    colKey24: "remarks",
    colKey25: "entryDate",
    colKey26: "paymentStatus",
    colKey27: "paymentDate",
  };
  
  export const misColumns = Object.entries(misDistReportConfig).map(
    ([key, value], index) => ({
      Header: `misdisthumanlossreport.headercol${index + 1}`,
      accessor: value,
      disableSortBy: true,
      showInitially: true,
    })
  );
  