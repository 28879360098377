import * as React from "react";
const SvgAffectedRptIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    data-name="Layer 1"
    viewBox="0 0 24 24"
    width="1em"
    height="1em"
    fill= "currentColor"
    {...props}
  >
    <path d="M22.204 1.162A5 5 0 0 0 18.106.081l-3.822.695A4 4 0 0 0 12 2.065 4 4 0 0 0 9.715.776L5.894.082A5 5 0 0 0 0 5.001v12.792a5 5 0 0 0 4.106 4.919l6.284 1.143a9 9 0 0 0 3.22 0l6.285-1.143A5 5 0 0 0 24 17.793V5.001a4.99 4.99 0 0 0-1.796-3.839M11 21.928c-.084-.012-6.536-1.184-6.536-1.184A3 3 0 0 1 2 17.792V5.001c0-.892.393-1.731 1.078-2.303a2.99 2.99 0 0 1 2.459-.648l3.821.695a2 2 0 0 1 1.643 1.968v17.216Zm11-4.135a3 3 0 0 1-2.463 2.952S13.085 21.916 13 21.929V4.712a2 2 0 0 1 1.642-1.968l3.821-.695a2.99 2.99 0 0 1 2.459.648A3 3 0 0 1 22 5zM8.984 6.224a1 1 0 0 1-.983.821c-.059 0-3.18-.562-3.18-.562a1 1 0 0 1 .358-1.968l3 .545a1 1 0 0 1 .805 1.163Zm0 3.955a1 1 0 0 1-.983.821c-.059 0-3.18-.562-3.18-.562a1 1 0 0 1 .358-1.968l3 .545a1 1 0 0 1 .805 1.163Zm0 4a1 1 0 0 1-.983.821c-.059 0-3.18-.562-3.18-.562a1 1 0 0 1 .358-1.968l3 .545a1 1 0 0 1 .805 1.163Zm11-8.857a1 1 0 0 1-.805 1.163s-3.121.562-3.18.562a1 1 0 0 1-.178-1.984l3-.545a1.003 1.003 0 0 1 1.163.805Zm0 3.955a1 1 0 0 1-.805 1.163s-3.121.562-3.18.562a1 1 0 0 1-.178-1.984l3-.545a1 1 0 0 1 1.163.805Zm0 4a1 1 0 0 1-.805 1.163s-3.121.562-3.18.562a1 1 0 0 1-.178-1.984l3-.545a1.003 1.003 0 0 1 1.163.805Zm-2 4.364a1 1 0 0 1-.805 1.163s-1.121.198-1.18.198a1 1 0 0 1-.178-1.984l1-.182a1 1 0 0 1 1.163.805m-11 .221a1 1 0 0 1-.983.821c-.059 0-1.18-.198-1.18-.198a1 1 0 0 1 .358-1.968l1 .182a1 1 0 0 1 .805 1.163" />
  </svg>
);
export default SvgAffectedRptIcon;
