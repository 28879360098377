import {
  faSkullCrossbones,
  faHospitalUser,
  faBedPulse,
  faPersonWalking,
  faMoneyBill,
  faMoneyCheck,
faMoneyCheckAlt,
faReceipt,
faHandHoldingDollar,faHandHoldingUsd,faDonate,faCoins
} from "@fortawesome/free-solid-svg-icons";
export const dashboardCardConfigForHeatwave = [{
  title: "",
  data: [
    {
      label: "Number of deaths due to heat wave",
      key: "no_of_deaths_today",
      icon: faSkullCrossbones,
    },
    {
      label: "Number of persons hospitalized",
      key: "no_of_persons_hospitalized_today",
      icon: faBedPulse,
    },
    {
      label: "Number of deceased person for whom ex-gratia payment done",
      key: "no_of_dec_ex_gratia_pay_done_today",
      icon: faDonate
    },
    {
      label: "Number of hospitalized person for whom payment done",
      key: "no_of_persons_hospitalized_pay_done_today",
      icon: faHandHoldingDollar
    },
  ],
}];
