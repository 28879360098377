import * as yup from 'yup';

const decimalValidation = yup
  .number()
  .typeError("This field must be a valid number") // Custom error for non-numeric input
  .transform((value, originalValue) => {
    if (originalValue === "") return null; // Treat empty string as null
    return value;
  })
  .test("max-decimals", "Only up to 5 decimal places are allowed", (value) => {
    if (value === undefined || value === null) return true; // Allow if no value
    // Regex to check for up to 5 decimal places
    return /^(\d+(\.\d{1,5})?)?$/.test(value);
  })
  .nullable(); // Allow null values if needed

  const integerValidation = yup
  .string()
  .matches(/^\d+$/, 'This field must be a whole number') // Ensures only digits (no negatives, no decimals)
  .test('is-integer', 'This field must be a whole number', (value) => {
    if (!value) return false; // Disallow empty or null values
    if (typeof value === 'string' && (value.includes('.') || value.startsWith('-'))) return false; // Disallow decimals and negatives
    return /^\d+$/.test(value); // Ensure only numeric characters
  })
  .required('This field is required'); // Ensure the field is mandatory

  const totalAmountClothUtensilsValidation = yup
  .number()
  .typeError("This field must be a number") // Ensure it's a number
  .test(
    "multiple-of-5",
    "Value must be a multiple of 5",
    (value) => value === undefined || value === null || value % 5 === 0
  )
  .nullable(); // Allow null values if needed


  const totalAmountValidation = yup
  .number()
  .typeError("This field must be a number") // Ensure it's a number
  .test(
    "multiple-of-7",
    "Value must be a multiple of 7",
    (value) => value === undefined || value === null || value % 7 === 0
  )
  .nullable(); // Allow null values if needed


export const schema = [
  yup.object({
    human_today: integerValidation,
    number_of_incidents_today: integerValidation,
    poultry_today: integerValidation,
    cattle_today: integerValidation,
    human_injured_today: integerValidation,
    cattle_injured_today: integerValidation,
    poultry_injured_today: integerValidation,
    number_of_dead_poultry_for_which_payment_done_today:integerValidation,
    number_of_dead_cattles_for_which_payment_done_today: integerValidation,
    number_of_deceased_for_whom_payment_of_ex_gratia_done_today: integerValidation,
    concrete_complete_today: integerValidation,
    concrete_partial_today: integerValidation,
    kutcha_complete_today: integerValidation,
    kutcha_partial_today: integerValidation,
    cottage_hut_today: integerValidation,
    total_number_of_damaged_houses_today: integerValidation,
    total_number_of_damaged_houses_for_which_payment_done_today: integerValidation,
    number_of_damaged_cattle_sheds_today: integerValidation,
    number_of_damaged_cattle_sheds_for_which_payment_done_today: integerValidation,
    amount__paid_against_cattle_shed_rs_today: integerValidation,
    number_of_polythene_sheets_distributed_today: integerValidation,
    estimated_value_of_damaged_crops_rs_today: integerValidation,
    area_of_damaged_crop_in_acre_today: decimalValidation,
    amount_paid_under_the_agriculture_input_subsidy_rs_today: decimalValidation,
    area_of_crop_for_which_agriculture_input_subsidy_paid_in_acre_today: decimalValidation,
    total_amount_of_gr_paid_rs_7000_per_family_today: totalAmountValidation,
    total_amount_paid_for_cloth_utensils_5000_per_family_rs_today: totalAmountClothUtensilsValidation,
    quantity_of_Dry_ration_distributed_kg_today: decimalValidation,
  })
];



export const FireFormInputData = {
  "number_of_deaths": [
    {
      id: 0,
      name: "",
      heading: "",
      fields: [
        {
          name: "human_today",
          label: "Human (Today)",
          maxLength: 60,
          type: "input",
          mandatory:true
        },
        {
          name: "human_cumulative",
          label: "Human (Cumulative)",
          maxLength: 60,
          type: "input",
          mandatory:true,
          "disabled": true
        },
        {
          name: "cattle_today",
          label: "Cattle (Today)",
          maxLength: 60,
          type: "input",
          mandatory:true
        },
        {
          name: "cattle_cumulative",
          label: "Cattle (Cumulative)",
          maxLength: 60,
          type: "input",
          mandatory:true,
          "disabled": true
        },
        {
          name: "poultry_today",
          label: "Poultry (Today)",
          maxLength: 60,
          type: "input",
          mandatory:true
        },
        {
          name: "poultry_cumulative",
          label: "Poultry (Cumulative)",
          maxLength: 60,
          type: "input",
          mandatory:true,
          "disabled": true
        },
      ],
    }],
  "number_of_incidents": [
    {
      id: 0,
      name: "",
      heading: "",
      fields: [
        {
          name: "number_of_incidents_today",
          label:"Number of incidents (Today)",
          maxLength: 60,
          type: "input",
          mandatory:true
        },
        {
          name: "number_of_incidents_cumulative",
          label:
            "Number of incidents (Cumulative)",
          maxLength: 60,
          type: "input",
          mandatory:true,
          "disabled": true
        },
      ],
    },
  ],
  "address_of_places_of_incidents": [
    {
      id: 0,
      name: "",
      heading: "",
      fields: [
        {
          name: "addresses_of_places_of_incidents",
          label: "Addresses of the places of incidents",
          maxLength: 200,
          type: "dynamic_input",
          mandatory:true,
        },
      ],
    },
  ],
  number_of_injured: [
    {
      id: 0,
      name: "",
      heading: "",
      fields: [
        {
          name: "human_injured_today",
          label: "Human (Today)",
          maxLength: 60,
          type: "input",
          mandatory:true
        },
        {
          name: "human_injured_cumulative",
          label: "Human (Cumulative)",
          maxLength: 60,
          type: "input",
          mandatory:true,
          "disabled": true
        },
        {
          name: "cattle_injured_today",
          label: "Cattle (Today)",
          maxLength: 60,
          type: "input",
          mandatory:true
        },
        {
          name: "cattle_injured_cumulative",
          label: "Cattle (Cumulative)",
          maxLength: 60,
          type: "input",
          mandatory:true,
          "disabled": true
        },
        {
          name: "poultry_injured_today",
          label: "Poultry (Today)",
          maxLength: 60,
          type: "input",
          mandatory:true
        },
        {
          name: "poultry_injured_cumulative",
          label: "Poultry (Cumulative)",
          maxLength: 60,
          type: "input",
          mandatory:true,
          "disabled": true
        },
      ],
    },
  ],
  ex_gratia_details: [
    {
      id: 0,
      name: "",
      heading: "",
      fields: [
        {
          name: "number_of_deceased_for_whom_payment_of_ex_gratia_done_today",
          label: "Number of deceased for whom payment of ex-gratia done (Today)",
          maxLength: 60,
          type: "input",
          mandatory: true
        },
        {
          name: "number_of_deceased_for_whom_payment_of_ex_gratia_done_cumulative",
          label: "Number of deceased for whom payment of ex-gratia done (Cumulative)",
          maxLength: 60,
          type: "input",
          mandatory: true,
          "disabled": true
        },
        {
          name: "number_of_dead_cattles_for_which_payment_done_today",
          label: "Number of dead cattles for which payment done (Today)",
          maxLength: 60,
          type: "input",
          mandatory: true
        },
        {
          name: "number_of_dead_cattles_for_which_payment_done_cumulative",
          label: "Number of dead cattles for which payment done (Cumulative)",
          maxLength: 60,
          type: "input",
          mandatory: true,
          "disabled": true
        },
        {
          name: "number_of_dead_poultry_for_which_payment_done_today",
          label: "Number of dead poultry for which payment done (Today)",
          maxLength: 60,
          type: "input",
          mandatory: true,
        },
        {
          name: "number_of_dead_poultry_for_which_payment_done_cumulative",
          label: "Number of dead poultry for which payment done (Cumulative)",
          maxLength: 60,
          type: "input",
          mandatory: true,
          "disabled": true
        },
      ],
    },
  ],
  "number_of_damaged_houses": [
    {
      id: 0,
      name: "",
      heading: "",
      fields: [
        {
          name: "concrete_complete_today",
          label: "Concrete complete (Today)",
          maxLength: 60,
          type: "input",
          mandatory: true
        },
        {
          name: "concrete_complete_cumulative",
          label: "Concrete complete (Cumulative)",
          maxLength: 60,
          type: "input",
          mandatory: true,
          "disabled": true
        },
        {
          name: "concrete_partial_today",
          label: "Concrete partial (Today)",
          maxLength: 60,
          type: "input",
          mandatory: true
        },
        {
          name: "concrete_partial_cumulative",
          label: "Concrete partial (Cumulative)",
          maxLength: 60,
          type: "input",
          mandatory: true,
          "disabled": true
        },
        {
          name: "kutcha_complete_today",
          label: "Kutcha complete (Today)",
          maxLength: 60,
          type: "input",
          mandatory: true
        },
        {
          name: "kutcha_complete_cumulative",
          label: "Kutcha complete (Cumulative)",
          maxLength: 60,
          type: "input",
          mandatory: true,
          "disabled": true
        },
        {
          name: "kutcha_partial_today",
          label: "Kutcha partial (Today)",
          maxLength: 60,
          type: "input",
          mandatory: true
        },
        {
          name: "kutcha_partial_cumulative",
          label: "Kutcha partial (Cumulative)",
          maxLength: 60,
          type: "input",
          mandatory: true,
          "disabled": true
        },
        {
          name: "cottage_hut_today",
          label: "Cottage/Hut (Today)",
          maxLength: 60,
          type: "input",
          mandatory: true
        },
        {
            name: "cottage_hut_cumulative",
            label: "Cottage/Hut (Cumulative)",
            maxLength: 60,
            type: "input",
            mandatory: true,
            "disabled": true
          },
          {
            name: "total_number_of_damaged_houses_today",
            label: "Total number of damaged houses (Today)",
            maxLength: 60,
            type: "input",
            mandatory: true,
            "disabled": true
          },
          {
            name: "total_number_of_damaged_houses_cumulative",
            label: "Total number of damaged houses (Cumulative)",
            maxLength: 60,
            type: "input",
            mandatory: true,
            "disabled": true
          },
      ],
    },
  ],
  "number_of_damaged_houses_for_which_payment_done": [
    {
      id: 0,
      name: "",
      heading: "",
      fields: [
        {
          name: "concrete_pay_complete_today",
          label: "Concrete complete (Today)",
          maxLength: 60,
          type: "input",
          mandatory: true
        },
        {
          name: "concrete_pay_complete_cumulative",
          label: "Concrete complete (Cumulative)",
          maxLength: 60,
          type: "input",
          mandatory: true,
          "disabled": true
        },
        {
          name: "concrete_pay_partial_today",
          label: "Concrete partial (Today)",
          maxLength: 60,
          type: "input",
          mandatory: true
        },
        {
          name: "concrete_pay_partial_cumulative",
          label: "Concrete partial (Cumulative)",
          maxLength: 60,
          type: "input",
          mandatory: true,
          "disabled": true
        },
        {
          name: "kutcha_pay_complete_today",
          label: "Kutcha complete (Today)",
          maxLength: 60,
          type: "input",
          mandatory: true
        },
        {
          name: "kutcha_pay_complete_cumulative",
          label: "Kutcha complete (Cumulative)",
          maxLength: 60,
          type: "input",
          mandatory: true,
          "disabled": true
        },
        {
          name: "kutcha_pay_partial_today",
          label: "Kutcha partial (Today)",
          maxLength: 60,
          type: "input",
          mandatory: true
        },
        {
          name: "kutcha_pay_partial_cumulative",
          label: "Kutcha partial (Cumulative)",
          maxLength: 60,
          type: "input",
          mandatory: true,
          "disabled": true
        },
        {
          name: "cottage_pay_hut_today",
          label: "Cottage/Hut (Today)",
          maxLength: 60,
          type: "input",
          mandatory: true
        },
        {
            name: "cottage_pay_hut_cumulative",
            label: "Cottage/Hut (Cumulative)",
            maxLength: 60,
            type: "input",
            mandatory: true,
            "disabled": true
          },
          {
            name: "total_number_of_damaged_houses_for_which_payment_done_today",
            label: "Total number of damaged houses for which payment done (Today)",
            maxLength: 60,
            type: "input",
            mandatory: true,
            "disabled": true
          },
          {
            name: "total_number_of_damaged_houses_for_which_payment_done_cumulative",
            label: "Total number of damaged houses for which payment done (Cumulative)",
            maxLength: 60,
            type: "input",
            mandatory: true,
            "disabled": true
          },
      ],
    },
  ],
  "cattle_shed_details": [
    {
      id: 0,
      name: "",
      heading: "",
      fields: [
        {
          name: "number_of_damaged_cattle_sheds_today",
          label: "Number of damaged cattle sheds (Today)",
          maxLength: 60,
          type: "input",
          mandatory: true
        },
        {
          name: "number_of_damaged_cattle_sheds_cumulative",
          label: "Number of damaged cattle sheds (Cumulative)",
          maxLength: 60,
          type: "input",
          mandatory: true,
          "disabled": true
        },
        {
          name: "number_of_damaged_cattle_sheds_for_which_payment_done_today",
          label: "Number of damaged cattle sheds for which payment done (Today)",
          maxLength: 60,
          type: "input",
          mandatory: true
        },
        {
          name: "number_of_damaged_cattle_sheds_for_which_payment_done_cumulative",
          label: "Number of damaged cattle sheds for which payment done (Cumulative)",
          maxLength: 60,
          type: "input",
          mandatory: true,
          "disabled": true
        },
        {
          name: "amount__paid_against_cattle_shed_rs_today",
          label: "Amount paid against damaged house/hut/cattle shed (Rs.) (Today)",
          maxLength: 60,
          type: "input",
          mandatory: true
        },
        {
          name: "amount__paid_against_cattle_shed_rs_cumulative",
          label: "Amount paid against damaged house/hut/cattle shed (Rs.) (Cumulative)",
          maxLength: 60,
          type: "input",
          mandatory: true,
          "disabled": true
        },
      ],
    },
  ],
  "damaged_crop": [
    {
      id: 0,
      name: "",
      heading: "",
      fields: [
        {
          name: "crop_type",
          label: "Crop Type",
          maxLength: 60,
          type: "select",
          options: [
              { value: "", label: "Select Option" },
              { value: "0", label: "Rabi" },
              { value: "1", label: "Kharif" },
            ],
          mandatory:true

        },
        {
          name: "area_of_damaged_crop_in_acre_today",
          label: "Area of damaged crop (in acre) (Today)",
          maxLength: 60,
          type: "input",
          mandatory:true
        },
        {
          name: "area_of_damaged_crop_in_acre_cumulative",
          label: "Area of damaged crop (in acre) (Cumulative)",
          maxLength: 60,
          type: "input",
          mandatory:true,
          "disabled": true
        },
        {
          name: "estimated_value_of_damaged_crops_rs_today",
          label: "Estimated value of damaged crops (Rs.) (Today)",
          maxLength: 60,
          type: "input",
          mandatory:true,
        },
        {
          name: "estimated_value_of_damaged_crops_rs_cumulative",
          label: "Estimated value of damaged crops (Rs.) (Cumulative)",
          maxLength: 60,
          type: "input",
          mandatory:true,
          "disabled": true
        },
      ],
    },
  ],
  "relief_distribution": [
    {
      id: 0,
      name: "",
      heading: "",
      fields: [
        {
          name: "amount_paid_under_the_agriculture_input_subsidy_rs_today",
          label: "Amount paid under the agriculture input subsidy (Rs) (Today)",
          maxLength: 60,
          type: "input",
          mandatory:true
        },
        {
          name: "amount_paid_under_the_agriculture_input_subsidy_rs_cumulative",
          label: "Amount paid under the agriculture input subsidy (Rs) (Cumulative)",
          maxLength: 60,
          type: "input",
          mandatory:true,
          "disabled": true
        },
        {
          name: "area_of_crop_for_which_agriculture_input_subsidy_paid_in_acre_today",
          label: "Area of crop for which agriculture input subsidy paid (in acre) (Today)",
          maxLength: 60,
          type: "input",
          mandatory:true
        },
        {
          name: "area_of_crop_for_which_agriculture_input_subsidy_paid_in_acre_cumulative",
          label: "Area of crop for which agriculture input subsidy paid (in acre) (Cumulative)",
          maxLength: 60,
          type: "input",
          mandatory:true,
          "disabled": true
        },
        {
          name: "total_amount_of_gr_paid_rs_7000_per_family_today",
          label: "Total amount of GR paid @Rs. 7000 per family (Today)",
          maxLength: 60,
          type: "input",
          mandatory:true
        },
        {
          name: "total_amount_of_gr_paid_rs_7000_per_family_cumulative",
          label: "Total amount of GR paid @Rs. 7000 per family (Cumulative)",
          maxLength: 60,
          type: "input",
          mandatory:true,
          "disabled": true
        },
        {
          name: "total_amount_paid_for_cloth_utensils_5000_per_family_rs_today",
          label: "Total amount paid for cloth/utensils etc., @5000 per family (Rs) (Today)",
          maxLength: 60,
          type: "input",
          mandatory:true
        },
        {
          name: "total_amount_paid_for_cloth_utensils_5000_per_family_rs_cumulative",
          label: "Total amount paid for cloth/utensils etc., @5000 per family (Rs) (Cumulative)",
          maxLength: 60,
          type: "input",
          mandatory:true,
          "disabled": true
        },
        {
          name: "number_of_polythene_sheets_distributed_today",
          label: "Number of polythene sheets distributed (Today)",
          maxLength: 60,
          type: "input",
          mandatory:true
        },
        {
          name: "number_of_polythene_sheets_distributed_cumulative",
          label: "Number of polythene sheets distributed (Cumulative)",
          maxLength: 60,
          type: "input",
          mandatory:true,
          "disabled": true
        },
        {
          name: "quantity_of_Dry_ration_distributed_kg_today",
          label: "Quantity of Dry ration distributed (Kg.) (Today)",
          maxLength: 60,
          type: "input",
          mandatory:true
        },
        {
          name: "quantity_of_Dry_ration_distributed_kg_cumulative",
          label: "Quantity of Dry ration distributed (Kg.) (Cumulative)",
          maxLength: 60,
          type: "input",
          mandatory:true,
          "disabled": true
        },
      ],
    },
  ],
  "remarks": [
    {
      id: 0,
      name: "",
      heading: "",
      fields: [
        {
          name: "remarks",
          label: "Remarks",
          type: "text_area",
          mandatory:true
        },
      ],
    },
  ],
};


