import * as yup from "yup";



export const schema = [
  yup.object({
    remarks: yup
      .string()
      .max(200, "Remarks must be 200 characters or less"), // Limit to 200 characters

      // Validation for the four fields
    
    
  }),
];

export const FireCommunityKitchenFormConfig = [
  {
    id: 0,
    name: "",
    heading: "",
    fields: [
        {
            name: "date",
            label: "Date",
            type: "date",
            disabled: true
          },
      {
            name: "district_name",
            label: "Name of District",
            type: "input",
            // options: [
            //   { value: "0", label: "Activity" },
            //   { value: "1", label: "Notification" },
            // ],
            disabled: true
      },
      {
            name: "block_name",
            label: "Block",
            type: "input",
            disabled: true
            // options: [
            //   { value: "0", label: "Activity" },
            //   { value: "1", label: "Notification" },
            // ],
      },
      {
        name: "today_number_of_kitchen_running",
        label: "Today Number of Kitchen Running",
        type: "input",
      },
      {
        name: "lunch_today",
        label: "Lunch (Today)",
        type: "input",
      },
      {
        name: "dinner_today",
        label: "Dinner (Today)",
        type: "input",
      },
      {
        name: "total_today",
        label: "Total (Today)",
        type: "input",
        disabled: true
      },
      {
        name: "lunch_cumulative",
        label: "Lunch (Cumulative)",
        type: "input",
        disabled: true
      },
      {
        name: "dinner_cumulative",
        label: "Dinner (Cumulative)",
        type: "input",
        disabled: true
      },
      {
        name: "total_cumulative",
        label: "Total (Cumulative)",
        type: "input",
        disabled: true
      },
      // {
      //   name: "remarks",
      //   label: "Remarks",
      //   type: "text_area",
      // },
     
    ],
  },
];

