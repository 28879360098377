import React, { useEffect } from "react";
import {
  AffectedRptIcon,
  AgencyManagement,
  AnimalShelterIconReport,
  Awareness,
  ColdWaveDashboard,
  CommunityKitchen,
  CommunityKitchenIcon,
  ConfigurationManagement,
  ConsolidatedRptIcon,
  ContactManagement,
  DailyFloodIcons,
  DailyReportsIcon,
  DashboardIcon,
  DevicesIcon,
  EventsIcon,
  Execution,
  FacilityManagement,
  FireDashboard,
  FloodDashboardIcon,
  FloodRelief,
  GisManagement,
  HeatWaveDashboard,
  Home,
  HumanLoss,
  IncidentDashboard,
  IncidentFacility,
  MasterResourceManagement,
  MisReportsIcon,
  MyTaskList,
  MyTasks,
  PartiallyAffectedRptIcon,
  PolicesIcon,
  RegionManagement,
  Relief,
  ReportsIcon,
  ReportStatusIcon,
  ResourceManagement,
  Resources,
  ResourcesIcons,
  RolesIcons,
  SopManagement,
  StateFloodIcons,
  TaskManagement,
  TaskManagementIcon,
  TasksList,
  TasksSopIcons,
  UserRoleManagement,
  UserRoleManagementIcon,
  UsersIcon,
  UsersIcons,
} from "../Components/IconComponents";

const height = "1.2em";
const width = "1.2em";

const navLinks = [
  {
    to: "dist-dashboard",
    icon: <DashboardIcon width={width} height={height} />,
    label: "navlinklabels.dashboard",
    children: [
      {
        to: "dist-dashboard",
        icon: <FloodDashboardIcon width={width} height={height} />,
        label: "navlinklabels.flooddashboard",
        children: [],
      },
      {
        to: "humanloss-dashboard",
        icon: <HumanLoss width={"2.2em"} height={"2.2em"} />,
        label: "Human Loss - Primary Information Dashboard",
        children: [],
      },
      {
        to: "fire-dashboard",
        icon: <FireDashboard width={"1.2em"} height={"1.2em"} />,
        label: "Fire Incident Dashboard",
        children: [],
      },
      {
        to: "heatwave-dashboard",
        icon: <HeatWaveDashboard width={width} height={height} />,
        label: "Heat Wave Dashboard",
        children: [],
      },
      {
        to: "coldwave-dashboard",
        icon: <ColdWaveDashboard width={width} height={height} />,
        label: "Cold Wave Dashboard",
        children: [],
      },
      {
        to: "dmsdashboard",
        icon: <IncidentFacility width={"1.5em"} height={"1.5em"} />,
        label: "navlinklabels.incidentdashboard",
        children: [],
      },
      {
        to: "incidentdashboard",
        icon: <IncidentDashboard width={width} height={height} />,
        label: "Incident Dashboard",
        children: [],
      },
    ],
  },
  {
    to: "reports",
    icon: <ReportsIcon width={width} height={height} />,
    label: "Report",
    children: [
      {
        to: "affected-floodreport",
        icon: <FloodDashboardIcon width={width} height={height} />,
        label: "Flood Reports",
        children: [
          {
            to: "../affected-floodreport",
            label: "navlinklabels.dailyreport",
            icon: <DailyFloodIcons width={width} height={height} />,
            children: [
              // {
              //   to: "../heatwave-dashboard",
              //   icon: "",
              //   label: "Heat Wave Dashboard",
              // },
              {
                to: "../affected-floodreport",
                icon: <DailyReportsIcon width={"1.5em"} height={"1.5em"} />,
                label: "Form 1 - Daily Report",
                children: [],
              },
              {
                to: "../partiallyaffected-floodreport",
                icon: (
                  <PartiallyAffectedRptIcon width={"1.7em"} height={"1.7em"} />
                ),
                label: "Partially Affected Report",
                children: [],
              },
              {
                to: "../dailyfloodreport",
                icon: <ConsolidatedRptIcon width={"1.7em"} height={"1.7em"} />,
                label: "Flood Report Consolidated",
                children: [],
              },
              {
                to: "../reporting-status",
                icon: <ReportStatusIcon width={"1.7em"} height={"1.7em"} />,
                label: "Form 1 - Reporting Status",
                children: [],
              },
            ],
          },
          {
            to: "../affected-report",
            label: "State Flood Report",
            icon: <StateFloodIcons width={width} height={height} />,
            children: [
              {
                to: "",
                icon: <AffectedRptIcon width={width} height={height} />,
                label: "Affected Report",
                children: [],
              },
              {
                to: "../partiallyaffected-report",
                icon: (
                  <PartiallyAffectedRptIcon width={"1.7em"} height={"1.7em"} />
                ),
                label: "Partially Affected Report",
                children: [],
              },
              {
                to: "../statefloodreport",
                icon: <ConsolidatedRptIcon width={"1.5em"} height={"1.5em"} />,
                label: "Consolidated Report",
                children: [],
              },
              // {
              //   to: "../misstate-report",
              //   icon: "",
              //   label: "MIS State Flood Report",
              // },
              // {
              //   to: "../form9",
              //   icon: "",
              //   label: "Form 9",
              //   children: [],
              // },
            ],
          },
          {
            to: "../misdist-report",
            icon: <MisReportsIcon width={width} height={height} />,
            label: "MIS Flood Report",
            children: [],
          },
        ],
      },
      {
        to: "human-loss-report",
        icon: <HumanLoss width={width} height={height} />,
        label: "Human Loss Reports",
        children: [
          {
            to: "../human-loss-report",
            icon: <DailyReportsIcon width={"3.0em"} height={"3.0em"} />,
            label: "Human Loss - Primay and detailed information Report",
            children: [],
          },
          {
            to: "../mis-human-loss-report",
            icon: <MisReportsIcon width={"1.5em"} height={"1.5em"} />,
            label: "Human Loss - MIS Report",
            children: [],
          },
        ],
      },
      {
        to: "fireincident-report",
        icon: <FireDashboard width={width} height={height} />,
        label: "Fire Reports",
        children: [
          {
            to: "../fireincident-report",
            icon: <DailyReportsIcon width={"2.0em"} height={"2.0em"} />,
            label: "Fire Incident - Daily Report Form",
            children: [],
          },
          {
            to: "../mis-fire-report",
            icon: <MisReportsIcon width={width} height={height} />,
            label: "Fire - MIS Report",
            children: [],
          },
          {
            to: "../fire-community-kitchen-report",
            icon: <CommunityKitchen width={"2.0em"} height={"2.0em"} />,
            label: "Fire - Community kitchen Daily Report",
            children: [],
          },
          {
            to: "../fire-wave-reporting-status",
            icon: <ReportStatusIcon width={"1.7em"} height={"1.7em"} />,
            label: "Fire Daily Report Status",
            children: [],
          },
        ],
      },
      {
        to: "heat-wave-report",
        icon: <HeatWaveDashboard width={width} height={height} />,
        label: "Heat Wave Reports",
        children: [
          {
            to: "../heat-wave-report",
            icon: <DailyReportsIcon width={"1.4em"} height={"1.4em"} />,
            label: "Heat Wave - Daily Report",
            children: [],
          },
          {
            to: "../heat-wave-misreport",
            icon: <MisReportsIcon width={"1.4em"} height={"1.4em"} />,
            label: "Heat Wave - MIS Report",
            children: [],
          },
          {
            to: "../heat-wave-reporting-status",
            icon: <ReportStatusIcon width={"1.7em"} height={"1.7em"} />,
            label: "Heat Wave Daily Report Status",
            children: [],
          },
        ],
      },
      {
        to: "dr-cold-wave",
        icon: <ColdWaveDashboard width={width} height={height} />,
        label: "Cold Wave Reports",
        children: [
          {
            to: "../dr-cold-wave",
            icon: <DailyReportsIcon width={"1.4em"} height={"1.4em"} />,
            label: "Cold Wave - Daily Report",
            children: [],
          },
          {
            to: "../misdist-coldwave-report",
            icon: <MisReportsIcon width={"1.4em"} height={"1.4em"} />,
            label: "Cold Wave - MIS Report",
            children: [],
          },
          {
            to: "../cold-wave-reporting-status",
            icon: <ReportStatusIcon width={"1.7em"} height={"1.7em"} />,
            label: "Cold Wave Daily Report Status",
            children: [],
          },
        ],
      },
      {
        to: "community-kitchen",
        icon: <IncidentFacility width={"1.5em"} height={"1.5em"} />,
        label: "Incident Facilities Reports",
        children: [
          {
            to: "../community-kitchen",
            icon: <CommunityKitchenIcon width={"1.5em"} height={"1.5em"} />,
            label: "navlinklabels.communitykitchen",
            children: [],
          },
          {
            to: "../fl-camp",
            icon: <FloodRelief width={"1.7em"} height={"1.7em"} />,
            label: "navlinklabels.floodcamp",
            children: [],
          },
          {
            to: "../animal-shelter",
            icon: <AnimalShelterIconReport width={"1.5em"} height={"1.5em"} />,
            label: "navlinklabels.animalshelter",
            children: [],
          },
        ],
      },

      // {
      //   to: "human-loss-details",
      //   icon: "",
      //   label: "Human Loss Details",
      // },
      // {
      //   to: "heat-wave-A-report",
      //   icon: "",
      //   label: "Heat Wave Format A",
      // },
      // {
      //   to: "heat-wave-B-report",
      //   icon: "",
      //   label: "Heat Wave Format B ",
      // },
      // {
      //   to: "heat-wave-C-report",
      //   icon: "",
      //   label: "Heat Wave Format C ",
      // },
      // {
      //   to: "hospital-situation-report",
      //   icon: "",
      //   label: "Hospital Situation Report",
      // },
      // {
      //   to: "heat-wave-B-edit",
      //   icon: "",
      //   label: "Heat Wave Edit FormB ",
      // },
      // {
      //   to: "heat-wave-C-report",
      //   icon: "",
      //   label: "Heat Wave Format C ",
      // },
      /*{
      to: "daily-flood-report",
      icon: <TasksList width={width} height={height} />,
      label: "navlinklabels.dailyreport",
    },*/
    ],
  },
  {
    to: "my-tasks",
    icon: <MyTasks width={width} height={height} />,
    label: "navlinklabels.mytask",
    children: [
      {
        to: "my-tasks",
        icon: <MyTaskList width={width} height={height} />,
        label: "navlinklabels.tasklist",
        children: [],
      },
      {
        to: "view-usertask",
        icon: <TaskManagementIcon width={width} height={height} />,
        label: "navlinklabels.taskmgmnt",
        children: [],
      },
    ],
  },
  // {
  //   icon: <ReportsIcon width={width} height={height} />,
  //   label: "navlinklabels.myforms",
  // },

  {
    to: "masterdata-table",
    // icon: <ReportsIcon width={width} height={height} />,
    icon: <UsersIcon />,
    allowedPermissionIds: [1],
    label: "navlinklabels.adminstrativeactivities",
    children: [
      {
        to: "masterdata-table",
        icon: <ConfigurationManagement width={width} height={height} />,
        label: "navlinklabels.configmgmnt",
        allowedPermissionIds: [2],
        children: [],
      },
      {
        to: "../region-list",
        icon: <RegionManagement width={width} height={height} />,
        label: "navlinklabels.regionmgmnt",
        allowedPermissionIds: [6],
        children: [],
      },
      {
        to: "../agency-list",
        icon: <AgencyManagement width={width} height={height} />,
        label: "navlinklabels.agencymgmnt",
        children: [],
        allowedPermissionIds: [10],
      },
      {
        to: "../users",
        icon: <UserRoleManagementIcon width={width} height={height} />,
        label: "navlinklabels.userrolemgmnt",
        allowedRoles: ["manage-users"],
        allowedPermissionIds: [37],
        children: [
          {
            to: "",
            icon: <UsersIcons width={"1.0em"} height={"1.0em"} />,
            label: "navlinklabels.users",
            children: [],
          },

          {
            to: "roles",
            icon: <RolesIcons width={"1.0em"} height={"1.0em"} />,
            label: "navlinklabels.roles",
            children: [],
          },
        ],
      },

      {
        to: "../master-resource",
        icon: <MasterResourceManagement width={width} height={height} />,
        label: "navlinklabels.masterresource",
        allowedPermissionIds: [18],
        children: [],
      },
      // {
      //   to: "../reportbuilder",
      //   icon: <GisManagement width={width} height={height} />,
      //   label: "navlinklabels.dashboard/reportbuilder",
      //   allowedPermissionIds: [38],
      //   children: [],
      // },
      // {
      //   to: "../reportmanagement",
      //   icon: <MasterResourceManagement width={width} height={height} />,
      //   label: "navlinklabels.adhocformbuilder",
      //   allowedPermissionIds: [39],
      //   children: [],
      // },
      {
        to: "../user-activity",
        icon: <UserRoleManagement width={width} height={height} />,
        label: "navlinklabels.useractivity",
        children: [],
      },
    ],
  },
  {
    to: "planning",
    icon: <DevicesIcon />,
    label: "navlinklabels.planning",
    allowedPermissionIds: [26],
    children: [
      {
        to: "view-contact",
        icon: <ContactManagement width={width} height={height} />,
        label: "navlinklabels.contactmgmnt",
        allowedPermissionIds: [22],
        children: [],
      },
      {
        to: "../facility-mgmt",
        icon: <FacilityManagement width={width} height={height} />,
        label: "navlinklabels.facilitymgmnt",
        allowedPermissionIds: [34],
        children: [],
      },
      {
        to: "../resource-list",
        icon: <Resources width={width} height={height} />,
        label: "navlinklabels.resourcemgmnt",
        allowedPermissionIds: [35],
        children: [],
      },
      {
        to: "view-sop",
        icon: <SopManagement width={width} height={height} />,
        label: "navlinklabels.sopmgmnt",
        allowedPermissionIds: [36],
        children: [
          {
            to: "../view-task",
            icon: <TasksSopIcons width={"1.0em"} height={"1.0em"} />,
            label: "navlinklabels.task",
            children: [],
          },
          {
            to: "../../resource-list",
            icon: <ResourcesIcons width={"1.0em"} height={"1.0em"} />,
            label: "navlinklabels.resource",
            children: [],
          },
          // {
          //   to: "../Sop-instance-list",
          //   icon: <ReportsIcon width={width} height={height} />,
          //   label: "navlinklabels.sopinstancelist",
          //   children: [],
          // },
          // {
          //   to: "../execute-sops",
          //   icon: <ReportsIcon width={width} height={height} />,
          //   label: "navlinklabels.execute-sops",
          //   children: [],
          // },
        ],
      },
    ],
  },
  {
    allowedPermissionIds: [27],
    to: "gismanagement",
    icon: <Awareness width={width} height={height} />,
    label: "navlinklabels.situationalawarness/gis",
    children: [],
  },
  {
    allowedPermissionIds: [31],
    to: "response-relief",
    icon: <Relief width={width} height={height} />,
    label: "navlinklabels.response&relief",
    children: [],
  },
];
// return navLinks;
// };

export default navLinks;
