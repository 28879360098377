import React from "react";
import Select from "react-select";
import { Controller } from "react-hook-form";
import { useTranslation } from 'react-i18next';
import "./SearchDropdown.css";

const SearchDropdown = ({
  name,
  label,
  errors,
  options,
  control,
  mandatory,
  onChange,
  style,
  disabled
}) => {
  const { t } = useTranslation();

 // Custom styles (same as multiselect)
 const customStyles = {
  option: (provided, state) => ({
    ...provided,
    color: state.isSelected ? "#fff" : "#fff",
    backgroundColor: state.isFocused ? '#2684ff' : provided.backgroundColor, 
  }),
  control: (base, state) => ({
    ...base,
    background: "#252b48",
    fontSize: "12px",
    borderColor: state.isFocused
      ? "#ffa100"
      : errors && errors[name]
      ? "#f4694c"
      : "#fff",
    boxShadow: state.isFocused ? null : null,
    "&:hover": {
      borderColor: state.isFocused ? "#ffa100" : "#fff",
    },
    // Apply custom styles if the select is disabled
    ...(disabled && {
      backgroundColor: "#7c8091", // Disabled background color
      color: "#7c8091", // Disabled text color
      cursor: "not-allowed", // Change cursor to indicate it's disabled
      borderColor: "#7c8091", // Light border color when disabled
    }),
    ...style
  }),
  menu: (provided) => ({
    ...provided,
    width: style?.width || "100%", // Match the width of the input field
  }),
  dropdownIndicator: (base) => ({
    ...base,
    color: "#17A3A0", // Custom colour
  }),
};


  return (
    <div className="input_text-form">
      <label>
        {t(label)}
        {mandatory && <span className="asterisk">*</span>}
      </label>
      <Controller
        control={control}
        name={name}
        render={({ field }) => {
          const isFieldEmpty = field.value === null || field.value === "";
          
          return (
            <Select
              {...field}  // Spread field props to pass value, onChange, etc.
              options={options || []}
              styles={customStyles}  // Apply custom hover styles here
              value={isFieldEmpty ? null : options ? options.find(option => option.value === field.value) : null}  // Clear label when empty
              onChange={(selectedOption) => {
                field.onChange(selectedOption ? selectedOption.value : "");  // Ensure value is set to empty string
                if (onChange) {
                  onChange(selectedOption);  // Custom callback if passed
                }
              }}
              isSearchable={true}  // Enable search functionality
              id={name}
              isClearable={true}  // Enable the clear button (X)
              classNamePrefix="single-select"
            />
          );
        }}
      />
      {errors[name] && <div className="error__message">{errors[name]?.message}</div>}
    </div>
  );
};

export default SearchDropdown;
