import React, { useMemo } from "react";

import { useState, useEffect } from "react";

import { useTranslation } from "react-i18next";

import Table from "../../../../Components/UiComponents/Table/Table";
import { getMealsDetailsURL } from "../../../../Services/EndPoints";
import { postDataWithBody } from "../../../../Services/Services";
import { text } from "@fortawesome/fontawesome-svg-core";
import "./FireDashboard.css";

const FireAffectedPopupData = ({ blockData }) => {
  // Calculate the total affected count
  const totalCount = blockData?.reduce(
    (sum, block) => sum + (block.count || 0),
    0
  );

  // Append a "Total" row to the block data
  const dataWithTotal = useMemo(() => {
    if (!blockData || blockData.length === 0) return [];
    return [
      ...blockData,
      {
        block_name: "TOTAL",
        count: totalCount,
      },
    ];
  }, [blockData, totalCount]);

  const columns = useMemo(
    () => [
      {
        Header: () => (
          <div style={{ textAlign: "left", marginLeft: "10pt" }}>
            Block Name
          </div>
        ),
        accessor: "block_name",
        disableSortBy: true,
        Cell: ({ value }) => (
          <div
            className="table-cell-left"
            style={{
              color: value === "TOTAL" ? "#17A3A0" : "inherit",
              fontWeight: value === "TOTAL" ? "bold" : "normal",
              fontSize: value === "TOTAL" ? "0.9rem" : "0.5",
            }}
          >
            {value}
          </div>
        ),
      },
      {
        Header: () => <div style={{ textAlign: "center" }}>Affected Count</div>,
        accessor: "count",
        disableSortBy: true,
        Cell: ({ value, row }) => (
          <div
            className="table-cell-center"
            style={{
              color:
                row.original.block_name === "TOTAL" ? "#17A3A0" : "inherit",
                fontWeight: row.original.block_name === "TOTAL" ? "bold" : "normal",
                fontSize: row.original.block_name === "TOTAL" ? "0.9rem" : "0.8rem",
            }}
          >
            {value}
          </div>
        ),
      },
    ],
    []
  );
  return (
    <div>
      <div className="container create-user">
        <div className="datatable__container">
          {dataWithTotal && dataWithTotal.length > 0 ? (
            <Table
              data={dataWithTotal}
              addButtonLabel="Create UserTask"
              columns={columns}
              numberOfRows={7}
              showExport={false}
              showFilter={false}
              showReset={false}
              onExpand={false}
              // rowCount={rowCount} // Pass rowCount to the Table component
              headerTableHeadStyle={{ background: "#18a3a0", color: "#FFFFFF" }}
              tableStyle={{
                border: "1px solid  #f0f0f0",
                padding: "2px",
                borderRadius: "12px",
              }}
              applyTableHeaderCustomClass={true}
              disbalePaginationFilter={true}
              getHeaderProps={(column) => ({
                style: { textAlign: column.id === "count" ? "center" : "left" },
              })}
              // headerTableHeadStyle={{ textAlign: "center" }} // Global center alignment for all headers
            />
          ) : (
            <p>No block-level data available.</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default FireAffectedPopupData;
