import React, { useEffect } from "react";
import DMSHeader from "../../DMSHeader/DMSHeader";
//import DailyFloodReportCompTable from "./DailyFloodReportCompTable";
import { useTranslation } from "react-i18next";
import DailyHeatWaveReportForm from "./DailyHeatWaveReportForm";

const DailyHeatWaveReport = () => {
  const { t } = useTranslation();

  return (
    <div>
      <div className="widget-container">
        <DMSHeader title="Heat Wave Daily Report" />
      </div>
      <div className="widget-container p-1">
        <DailyHeatWaveReportForm />
      </div>
    </div>
  );
};

export default DailyHeatWaveReport;
