import React, { useState, useEffect, useContext } from "react";
import { useForm, useWatch } from "react-hook-form";
import { useNavigate, useLocation } from "react-router-dom";
import { Button } from "react-bootstrap";
import FormHandler from "../../Common/Form/FormHandler/FormHandler";
import  "./DailyHeatWaveRegForm.css";
import { useKeycloak } from "@react-keycloak/web";
import { toast, ToastContainer } from "react-toastify";
import {
  postDataWithBody,
  getData,
  
  putDataWithBody,
  postDataWithBodyFrGIS,
} from "../../../../Services/Services";
import {
  getUsersFromApp,
  getAllRole,
  createTaskandAssign,
  createNewTask,
  updateHeatWaveDetails
} from "../../../../Services/EndPoints";
import { yupResolver } from "@hookform/resolvers/yup";
import { DailyHeatWaveFormConfig, schema } from "../../../../JsonData/DailyHeatWaveConfig";
import moment from "moment";
import { RoleContext } from "../../../..";

const DailyHeatWaveRegistrationForm = () => {
    const { roleName } = useContext(RoleContext);
  const { keycloak } = useKeycloak();
  const [activeTab, setActiveTab] = useState(0);
  const [loader, setLoader] = useState(false);
//   const [DailyHeatWaveFormConfig, setDailyHeatWaveFormConfig] = useState(
//     DailyHeatWaveFormConfig
//   );
  const navigate = useNavigate();
  const { state } = useLocation();
  const location = useLocation();
  const {isTextFieldDisable } = location.state || {};
  let dataFromTable = state?.data;

  let date = state?.date;

  let districtNameValue = state?.districtNameValue;

  console.log("Heat Wave DATA",dataFromTable);
  console.log("HEATWAVEDATE",date);
  console.log("DistrictNameValue",districtNameValue);

  const [lgShow, setLgShow] = useState(false);

 
  const {
    register,
    handleSubmit,
    setValue,
    getValues,

    reset,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema[activeTab]),
    mode: "all",
  });
  const assign_type = useWatch({
    name: "assign_type",
    control,
  });

 useEffect(() => {
    // const formattedDate = moment(date).format("DD-MM-YYYY");
    // console.log("FORMATTEDDate",formattedDate);
    const formatDate = (timestamp) => {
      if (!timestamp) return "Invalid Date"; // Handle null, undefined, or falsy timestamps
      
      const date = new Date(timestamp);
    
      // Check if the date is invalid (e.g., `Invalid Date`)
      if (isNaN(date.getTime())) return "Invalid Date";
    
      const day = String(date.getDate()).padStart(2, '0');
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const year = date.getFullYear();
    
      return `${year}-${month}-${day}`;
    };
    
  
    const formattedDate = formatDate(dataFromTable?.reported_on);
    console.log("FORMATTED2025",formattedDate)


    if (dataFromTable) {
      reset({
        date: formattedDate,
        district_name: dataFromTable?.dist_name,
        block_name: dataFromTable?.block_name,
        number_of_deaths_due_to_heatwave_today: dataFromTable?.no_of_deaths_today,
        number_of_deaths_cumulative: dataFromTable?.no_of_deaths_cum,
        number_of_deceased_person_for_whom_exgratia_payment_done_today: dataFromTable?.no_of_dec_ex_gratia_pay_done_today,
        number_of_deceased_person_for_whom_exgratia_payment_done_cumulative: dataFromTable?.no_of_dec_ex_gratia_pay_done_cum,
        number_of_persons_hospitalized_today: dataFromTable?.no_of_persons_hospitalized_today,
        number_of_persons_hospitalized_cumulative: dataFromTable?.no_of_persons_hospitalized_cum,
        number_of_hospitalized_person_for_whom_payment_done_today: dataFromTable?.no_of_persons_hospitalized_pay_done_today,
        number_of_hospitalized_person_for_whom_payment_done_cumulative: dataFromTable?.no_of_persons_hospitalized_pay_done_cum,
        remarks: dataFromTable?.remarks || "", // Ensure remarks are included
      });
    }
  }, [dataFromTable, reset]);
  

  const GenerateForm = (fields, register, errors, control) => {
    return (
      <div>
        
          <FormHandler
            register={register}
            errors={errors}
            fields={fields}
            control={control}
            columns={2}
            getValues={getValues}
            isTextFieldDisable={isTextFieldDisable}
            roleName={roleName}
          />
     
      </div>
    );
  };

  

  const generateTabsData = (tabWiseData) => {
    tabWiseData?.forEach((element) => {
      return (element.component = GenerateForm(
        element?.fields,
        register,
        errors,
        control
      ));
    });
    return tabWiseData;
  };

  
  const submitHandler = async (data, e) => {
    e.preventDefault(); // Prevent default form submission

    console.log("Heat wave edit form data", data);
  
    const formData = new FormData();
  
    setLoader(true); // Show loader
  
    const input = {
      id: dataFromTable?.id,
      reported_date: data.date,
      distName: data.district_name,
      blockName: data.block_name,
      region_id: dataFromTable.region_id,
      is_submitted: true,
      // Convert string values to numbers using parseInt or Number()
      no_of_deaths_today: Number(data?.number_of_deaths_due_to_heatwave_today),
      no_of_dec_ex_gratia_pay_done_today: Number(data?.number_of_deceased_person_for_whom_exgratia_payment_done_today),
      no_of_persons_hospitalized_today: Number(data?.number_of_persons_hospitalized_today),
      no_of_persons_hospitalized_pay_done_today: Number(data?.number_of_hospitalized_person_for_whom_payment_done_today),
      remarks: data?.remarks,
  };
  
    const res = await postDataWithBody(
        updateHeatWaveDetails.replace("DATE", data.date),
        input,
        {}
    );

    if (res.status === "success") {
        toast.success(res.msg, {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 2000, // Close after 2 seconds
        });
        setLoader(false);
        navigate("/reports/heat-wave-report");
    } else {
        toast.error(res.msg, {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 2000, // Close after 2 seconds
        });
        setLoader(false);
    }
};
  
  

  const tabsData = generateTabsData(DailyHeatWaveFormConfig);
  return (
    <div className="mt-4 facility-container">
    

      <div className="form-body">
        <div className="registration__form--body">
          <form
            onSubmit={handleSubmit(submitHandler)}
            name="CreateFacilityRegForm"
          >
            <div>
              {tabsData?.map((tab) => (
                <>
                  {tab.id === activeTab && (
                    <>
                      <div>{tab.component}</div>
                    
                    </>
                  )}
                </>
              ))}
            </div>
            <div className="d-flex justify-content-center">
              {tabsData.length - 1 === activeTab && (
                <Button type="submit" id="submitBtn" className="mx-2" variant="primary">
                  Update
                </Button>
              )}
            </div>
          </form>
        </div>
      </div>
      
    </div>
  );
};

export default DailyHeatWaveRegistrationForm;
