import React, { useState, useEffect, useRef, useContext } from "react";
import "../DailyFloodReportComp/Hindi_Font.js";
import "../DailyFloodReportComp/DailyFloodReportCompTable.css";
import Moment from "react-moment";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import govt_logo from "../../../../Assets/Images/bihar_logo.png";
import Loader from "react-spinner-loader";
import "./DailyColdWaveRegForm.css"
//import html2pdf from 'html2pdf.js';
import ExcelJS from "exceljs";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import SelectComponent from "../../../../Components/UiComponents/SelectComponent/SelectComponent.js";
import { postDataWithBody, getData, deleteDataById } from "../../../../Services/Services.js";
import {
  getChildRegionsById,
  findRegionsByUser,
  getDailyReportByDistrict,
  getDailyFloodReportListURL,
  cleardailyReportData,
  getDailyAffectedReportByDistrict,
  getDailyColdWaveReport,
  deleteColdWaveDetail,
} from "../../../../Services/EndPoints.js";
import { useTranslation } from "react-i18next";
import DatePicker from "../../Common/Form/DatePicker/DatePicker.js";
import { RoleContext } from "../../../../index.js";
import Confirmpopup from "../../Common/ConfirmPopup/Confirmpopup.js";
import { Deleteicon, Editicon } from "../../../../Components/IconComponents/index.js";
import Table from "../../../../Components/UiComponents/Table/Table.js";

const DailyColdWaveReportForm = () => {
  const roleName = useContext(RoleContext);
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);
  const [dailyReport, setDailyReport] = useState(null);
  const [popUp, setPopUp] = useState(false);
  const [districtData, setDistrictData] = useState([]);
  const [clearId, setClearId] = useState(null);
  const [blockData, setBlockData] = useState([]);
  const [selectedDistrictName, setSelectedDistrictName] = useState(null);
  const [selectedDistrictId, setSelectedDistrictId] = useState(null);
  const [slctdBlkId, setSlctdBlkId] = useState(null);
  const [selectedBlockId, setSelectedBlockId] = useState(null);
  const pdfRef = useRef(null);
  const [isdelete, setIsdelete] = useState(false);
          const [selectedId, setSelectedId] = useState(null);
  // const [districtName, setDistrictName] = useState(null);
  const [selectedDate, setSelectedDate] = useState(
    new Date().toLocaleDateString('en-CA')
  ); // State to store selected date;

  const getBlockIdListForReport = () => {
    if (slctdBlkId == 0) {
      let blockIdList = [];
      blockData.forEach((v) => blockIdList.push(v.id));
      return blockIdList;
    } else {
      return [slctdBlkId];
    }
  };

  //   const editFloodData = async () => {
  //     if (slctdBlkId == 0) {
  //       toast.error("Please select a block to edit data.", {
  //         position: toast.POSITION.TOP_CENTER,
  //         autoClose: 2000, // Close after 2 seconds
  //       });
  //     } else {
  //       let slctdDate = document.getElementById("flood_date_picker").value;
  //       const data = await getData(
  //         getDailyFloodReportListURL
  //           .replace("ID", slctdBlkId)
  //           .replace("DATE", slctdDate)
  //       );
  //       if (data.length == 0) {
  //         toast.error("No data exists for the selected date and block", {
  //           position: toast.POSITION.TOP_CENTER,
  //           autoClose: 2000, // Close after 2 seconds
  //         });
  //       } else {
  //         navigate("/dmsdashboard/editdfr", {
  //           state: {
  //             blockId: slctdBlkId,
  //             slctd_dt: document.getElementById("flood_date_picker").value,
  //           },
  //         });
  //       }
  //     }
  //   };
  //   const clearFloodData = async () => {
  //     if (slctdBlkId == 0) {
  //       toast.error("Please select a block to edit data.", {
  //         position: toast.POSITION.TOP_CENTER,
  //         autoClose: 2000, // Close after 2 seconds
  //       });
  //     } else {
  //       let slctdDate = document.getElementById("flood_date_picker").value;
  //       const data = await getData(
  //         getDailyFloodReportListURL
  //           .replace("ID", slctdBlkId)
  //           .replace("DATE", slctdDate)
  //       );
  //       if (data.length == 0) {
  //         toast.error("No data exists for the selected date and block", {
  //           position: toast.POSITION.TOP_CENTER,
  //           autoClose: 2000, // Close after 2 seconds
  //         });
  //       } else {
  //         setPopUp(true);
  //         setClearId(data[0].id);
  //       }
  //     }
  //   };
  
  const getDailyReportData = async (blockListId, onInit = false) => {
    if (blockListId == null) {
      blockListId = getBlockIdListForReport();
    }
    var selectedDateStr = document.getElementById("flood_date_picker").value;
    if (selectedDateStr == "") {
      selectedDateStr = new Date().toLocaleDateString('en-CA');
    }
    setSelectedDate(selectedDateStr);
    setLoader(true);
  
    const index = blockListId.indexOf(0);
    if (index > -1) {
      blockListId.splice(index, 1); // Remove 0 if it exists
    }
    let inputobj = {
      region_id_list: blockListId,
      reported_on: selectedDateStr,
    };
    let dailyReportColdWaveData = await postDataWithBody(
      getDailyColdWaveReport,
      inputobj,
      {}
    );
  
   // Merge `dr_cold_wave_Cumm` data into `dr_cold_wave`
dailyReportColdWaveData?.dr_cold_wave?.forEach((report) => {
  // Find matching cumulative data based on region_id
  const cumulativeData = dailyReportColdWaveData?.dr_cold_wave_Cumm?.find(
    (cumData) => cumData.region_id === report.region_id
  );

  if (cumulativeData) {
    // Add cumulative data to the report, ensuring 0 remains 0 and null is replaced with "NA"
    report.pop_affected_by_cold_wave_so_far =
      cumulativeData.pop_affected_by_cold_wave_so_far ?? "NA";
    report.number_of_night_shelters_so_far =
      cumulativeData.number_of_night_shelters_so_far ?? "NA";
    report.number_of_bonfire_places_so_far =
      cumulativeData.number_of_bonfire_places_so_far ?? "NA";
  }

  if (onInit) {
    // Fill in empty fields with a blank space, keeping 0 intact
    Object.keys(report).forEach((key) => {
      if (report[key] === null) {
        report[key] = "NA";
      } else if (report[key] === undefined || report[key] === "") {
        report[key] = " ";
      }
    });
  }
});

    setDailyReport(dailyReportColdWaveData);
    setLoader(false);
    var skillsSelect = document.getElementById("district_drop");
    var selectedText = skillsSelect.options[skillsSelect.selectedIndex].text;
    document.getElementById("selected_dist_name").innerHTML = selectedText;
  };
  

  async function addImageProcess() {
    return new Promise((resolve, reject) => {
      let img = new Image();
      img.src = govt_logo;
      img.onload = () => resolve(img);
      img.onerror = reject;
    });
  }
  const downloadPDF = async () => {
     // Get the table element
     const table = document.getElementById("report_table");
 // Convert the table data into a format suitable for autoTable
 const tableData = [];
 const tableHeader = [];
 const rows = table.rows;
 const numCols = rows[0].cells.length;

 // Get table header (excluding the Action column)
 for (let i = 0; i < numCols - 1; i++) {  // Skip the last column (Action column)
   tableHeader.push(rows[0].cells[i].textContent.trim());
 }

 // Get table data rows (excluding the Action column)
 for (let i = 1; i < rows.length; i++) {
   const rowData = [];
   for (let j = 0; j < numCols - 1; j++) {  // Skip the last column (Action column)
     rowData.push(rows[i].cells[j].textContent.trim());
   }
   tableData.push(rowData);
 }

    // Now generate the PDF without the "Actions" column
    const content = pdfRef.current;
    const doc = new jsPDF("l", "pt", "a4");  // Set the orientation to landscape ("l")
    const image = await addImageProcess();

    let fontName = "Helvetica";
    if (i18n.language == "hi") {
        fontName = "Akshar Unicode";
    }
    doc.setFont(fontName);
    doc.setFontSize(13);
    doc.addImage(image, "png", 380, 28, 0, 0);
    var xOffset = doc.internal.pageSize.width / 2;
    const selectedDate = new Date(document.getElementById("flood_date_picker").value).toLocaleDateString("en-GB");

    doc.text(t("floodreport.govt_name"), xOffset, 120, { align: "center" });
    doc.text(t("floodreport.department_name"), xOffset, 140, { align: "center" });
    doc.text(
        t("Cold wave Report") +
        " (" +
        t("floodreport.dated_on_lbl") +
        " " +
        selectedDate +
        ")",
        xOffset,
        160,
        { align: "center" }
    );
    doc.autoTable({
      head: [tableHeader],
      body: tableData,
        startX: 0,
        startY: 190,
        theme: "grid",
        rowPageBreak: "avoid",
        bodyStyles: { lineColor: [0, 0, 0] },
        styles: {
            font: fontName,
            fontSize: 2,
            cellPadding: 3,
            lineColor: [0, 0, 0], // Black lines
            lineWidth: 0.1, // Adjust thickness of lines
        },
        headStyles: {
            fillColor: [255, 255, 255], // White background for header
            textColor: [0, 0, 0],       // Black text for header
            fontStyle: "bold",          // Bold header text
            lineColor: [0, 0, 0],       // Ensure grid lines stay black for the header
            lineWidth: 0.1,             // Ensure lines are drawn in header
            halign: "center"
        },
        columnStyles: {
            0: { cellWidth: 29 }, // S.No
            1: { cellWidth: 70 }, // Block Name
            2: { cellWidth: 60 }, // Number of places...
            3: { cellWidth: 50 },
            4: { cellWidth: 50 },
            5: { cellWidth: 60 },
            6: { cellWidth: 40 },
            7: { cellWidth: 40 },
            8: { cellWidth: 40 },
            9: { cellWidth: 50 },
            10: { cellWidth: 60 },
            11: { cellWidth: 60 },
            12: { cellWidth: 60 },
            13: { cellWidth: 60 },
            14: { cellWidth: 60 },
            15: { cellWidth: 60 },
            16: { cellWidth: 60 },
            // Do not include the 17th column (Actions)
        },
        rowPageBreak: "auto",
        pageBreak: "auto",
        didParseCell: function (data) {
          if (data.cell.raw && data.cell.raw.nodeType === 1) {
            const cellElement = data.cell.raw;
                if (data.cell.raw.querySelector("b")) {
                    data.cell.styles.textColor = "red";
                }
                if (data.cell.raw.querySelector("span")) {
                    data.cell.styles.halign = "center";
                }
            } else {
                console.warn("data.cell.raw is undefined", data);
            }
        },
        styles: {
            font: fontName,
            fontSize: 9,
            cellWidth: "auto",
            halign: "left",
            cellPadding: 3,
        },
    });

    
    // Get the bottom of the page
    let pageHeight = doc.internal.pageSize.height;
    let footerY = pageHeight - 180; // Adjust as needed for spacing

    const distName = districtData?.find(x => x.id == selectedDistrictId)?.value || '';

    let finalY = doc.lastAutoTable.finalY; // The y position on the page
    doc.setFontSize(10);
    doc.text(550, footerY + 100,"Signature of District Officers/ ", { maxWidth: 300 });
    doc.text(550, footerY + 120, "Additional District Magistrate(ADM)(Disaster Management)/", { maxWidth: 300 });
    doc.text(550, footerY + 140, `Officer in Charge(District Disaster Management)/`, { maxWidth: 300 });
    doc.setFont(undefined, "bold");
    doc.text(550, footerY + 160, `[${distName} DISTRICT]`, { maxWidth: 300 });
    doc.setFont(undefined, "normal"); // Reset font back to normal

    doc.text(50, footerY + 100, "Letter No.: ", { maxWidth: 300 });
    doc.text(50, footerY + 120, "Date: ", { maxWidth: 300 });
    doc.text(50, footerY + 140, "Copy: ", { maxWidth: 300 });

    const now = new Date();
    now.setMinutes(now.getMinutes() + 330); // Convert UTC to IST (+5:30)

    const istDate = now.toISOString().split("T")[0]; // YYYY-MM-DD
    const istTime = now.toISOString().split("T")[1].split(".")[0].replace(/:/g, ""); // HHMMSS

    const timestamp = `${istDate}_${istTime}`;


    doc.save(`daily_cold_wave_report_${timestamp}.pdf`);
};



  // const downloadExcel = async (tableData, tableHeaders, fileName = "ColdWaveReport.xlsx") => {
  //   const workbook = new ExcelJS.Workbook();
  //   const worksheet = workbook.addWorksheet("Cold Wave Report");
  
  //   // Define columns based on tableHeaders
  //   worksheet.columns = tableHeaders.map((header) => ({
  //     header: header.label,
  //     key: header.key,
  //     width: 25, // Adjust as needed
  //   }));
  
  //   // Process data and replace null or undefined with "NA"
  //   const processedData = tableData.map((row, index) => {
  //     const processedRow = { slno: index + 1 }; // Add Sl. No dynamically
  
  //     // Replace null or undefined with "NA" for all other keys
  //     tableHeaders.forEach((header) => {
  //       if (header.key !== "slno") { // Skip slno column
  //         processedRow[header.key] = row[header.key] != null ? row[header.key] : "NA";
  //       }
  //     });
  //     return processedRow;
  //   });
  
  //   // Add rows to the worksheet
  //   processedData.forEach((row) => worksheet.addRow(row));
  
  //   // Style header row
  //   worksheet.getRow(1).eachCell((cell) => {
  //     cell.font = { bold: true };
  //     cell.alignment = { horizontal: "center", vertical: "middle" };
  //   });
  
  //   // Generate and save the Excel file
  //   try {
  //     const buffer = await workbook.xlsx.writeBuffer();
  //     const blob = new Blob([buffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
  //     const link = document.createElement("a");
  //     link.href = URL.createObjectURL(blob);
  //     link.download = fileName;
  //     document.body.appendChild(link);
  //     link.click();
  //     document.body.removeChild(link);
  //   } catch (error) {
  //     console.error("Failed to export Excel file:", error);
  //   }
  // };
  
  const deletePopUp = (id) => {
    setSelectedId(id);
    setIsdelete(!isdelete);
  };

  

  
  
  const getDistrictData = async () => {
    try {
      const distList = await getData(findRegionsByUser);

      if (Array.isArray(distList) && distList.length > 0) {
        let blockdata = await getBlockListByDistId(distList[0].id);
        setSlctdBlkId(0);
        let blockIdList = [];
        blockdata.forEach((v) => blockIdList.push(v.id));
        setDistrictData(distList);
        // if(sessionStorage.getItem("date") && sessionStorage.getItem("districtNameValue") ) {
        //   getDailyReportData(blockIdList,true);
        //   sessionStorage.removeItem("date");
        //   sessionStorage.removeItem("districtNameValue");
        // }
        setSelectedDistrictId(distList[0].id);
      } else {
        console.error("Error: District data is empty or not an array");
      }
    } catch (error) {
      console.error("Error fetching district list:", error);
    }
  };

  useEffect(() => {
    document.getElementById("flood_date_picker").valueAsDate = new Date();
    getDistrictData();
  }, []);

  const getFormattedDate = (dateStr) => {
    return <Moment format="DD/MM/YYYY">{dateStr}</Moment>;
  };

  const getBlockListByDistId = async (distId) => {
    try {
      const blockList = await postDataWithBody(
        getChildRegionsById,
        [distId],
        {}
      );
      blockList.forEach(function (elem, index) {
        elem["value"] = elem["name"];
      });
      blockList.unshift({ id: 0, value: "All" });
      setBlockData(null);
      setTimeout(function () {
        setBlockData(blockList);
      }, 100);
      return blockList;
    } catch (error) {
      console.error("Error fetching block list:", error);
    }
  };
  async function getDistrictDataByCode(event) {
    setSelectedDistrictName(event.target.value);
    setSelectedDistrictId(event.target.value);
    getBlockListByDistId(event.target.value);
  }
  function setSelectedBlockIdVal(event) {
    setSlctdBlkId(event.target.value);
  }
  const changeSelectedate = () => {
    /*if (selectedDistrictId && selectedDate) {
      getDailyReportData(selectedDistrictId, selectedDate);
    } else {
      console.error("Please select both district and date.");
    }*/
  };

  const handleTogglePopup = () => {
    setPopUp(!popUp);
  };

  const onClickSucesshandler = async () => {
    await postDataWithBody(cleardailyReportData + clearId, {}, {});
    setPopUp(false);
  };
  console.log("DISTRICT:",districtData);

  // const tableHeaders = [
  //   { label: "Sl. No", key: "slno" },
  //   { label: "District Name", key: "district" },
  //   { label: "Number of Bonfire Places (Today)", key: "number_of_bonfire_places_today" },
  //   { label: "Number of Bonfire Places (Total)", key: "number_of_bonfire_places" },
  //   { label: "Quantity of Wood Burnt (Today)", key: "quantity_of_wood_burnt_today" },
  //   { label: "Quantity of Wood Burnt (Total)", key: "quantity_of_wood_burnt" },
  //   { label: "Population Affected by Cold Wave (Today)", key: "pop_affected_by_cold_wave_today" },
  //   { label: "Population Affected by Cold Wave (Total)", key: "pop_affected_by_cold_wave" },
  //   { label: "Number of Deaths (Today)", key: "number_of_deaths_today" },
  //   { label: "Number of Deaths (Total)", key: "number_of_deaths" },
  //   { label: "Amount Allocated by Department", key: "amount_allocated_by_department" },
  //   { label: "Updated Amount Spent", key: "updated_amount_spent" },
  //   { label: "Number of Night Shelters (Today)", key: "number_of_night_shelters_today" },
  //   { label: "Number of Night Shelters (Total)", key: "number_of_night_shelters" },
  //   { label: "Number of People Taking Shelter in Night Shelters (Today)", key: "number_of_ppl_taking_shelter_in_night_shelters_today" },
  //   { label: "Number of People Taking Shelter in Night Shelters (Total)", key: "number_of_ppl_taking_shelter_in_night_shelters" },
  //   { label: "Number of Blankets Distributed (Today)", key: "number_of_blankets_distributed_today" },
  //   { label: "Number of Blankets Distributed (Total)", key: "number_of_blankets_distributed" },
  // ];
  
  console.log("SELECTEDDISCTRICT",selectedDistrictName);

  // const districtName = districtData?.find(x => x.id == selectedDistrictId)?.value || '';

  // console.log("DISTRICTNAME",districtName);

  
const coldWaveColumns = React.useMemo(
  () => [
    {
      Header: t("coldwavereport.slno"),
      accessor: "sno",
      disableSortBy: false,
      Cell: (props) => <span>{props.row.index + 1}</span>,
    },
    {
      Header: t("coldwavereport.block_name"),
      accessor: "block_name",
      disableSortBy: false,
      Cell: (props) => (props.row.original.block_name !== null ? props.row.original.block_name : "NA"),
    },
    {
      Header: t("coldwavereport.number_of_bonfire_places_reporting"),
      accessor: "number_of_bonfire_places",
      disableSortBy: true,
      Cell: (props) =>
        props.row.original.number_of_bonfire_places !== null ? props.row.original.number_of_bonfire_places : "NA",
    },
    {
      Header: t("coldwavereport.number_of_bonfire_places_total"),
      accessor: "number_of_bonfire_places_so_far",
      disableSortBy: true,
      Cell: (props) =>
        props.row.original.number_of_bonfire_places_so_far !== null ? props.row.original.number_of_bonfire_places_so_far : "NA",
    },
    {
      Header: t("coldwavereport.quantity_wood_burnt_reporting"),
      accessor: "quantity_of_wood_burnt",
      disableSortBy: true,
      Cell: (props) =>
        props.row.original.quantity_of_wood_burnt !== null ? props.row.original.quantity_of_wood_burnt : "NA",
    },
    {
      Header: t("coldwavereport.quantity_wood_burnt_total"),
      accessor: "quantity_of_wood_burnt_so_far",
      disableSortBy: true,
      Cell: (props) =>
        props.row.original.quantity_of_wood_burnt_so_far !== null ? props.row.original.quantity_of_wood_burnt_so_far : "NA",
    },
    // Uncomment if required
    // {
    //   Header: t("coldwavereport.population_affected_reporting"),
    //   accessor: "pop_affected_by_cold_wave",
    //   disableSortBy: true,
    //   Cell: (props) => props.row.original.pop_affected_by_cold_wave || "NA",
    // },
    // {
    //   Header: t("coldwavereport.population_affected_total"),
    //   accessor: "pop_affected_by_cold_wave_so_far",
    //   disableSortBy: true,
    //   Cell: (props) => props.row.original.pop_affected_by_cold_wave_so_far || "NA",
    // },
    {
      Header: t("coldwavereport.deaths_reporting"),
      accessor: "number_of_deaths",
      disableSortBy: true,
      Cell: (props) =>
        props.row.original.number_of_deaths !== null ? props.row.original.number_of_deaths : "NA",
    },
    {
      Header: t("coldwavereport.deaths_total"),
      accessor: "number_of_deaths_so_far",
      disableSortBy: true,
      Cell: (props) =>
        props.row.original.number_of_deaths_so_far !== null ? props.row.original.number_of_deaths_so_far : "NA",
    },
    // Uncomment if required
    // {
    //   Header: t("coldwavereport.amount_allocated"),
    //   accessor: "amount_allocated_by_department",
    //   disableSortBy: true,
    //   Cell: (props) => props.row.original.amount_allocated_by_department ? props.row.original.amount_allocated_by_department : "",
    // },
    // {
    //   Header: t("coldwavereport.amount_spent_updated"),
    //   accessor: "updated_amount_spent",
    //   disableSortBy: true,
    //   Cell: (props) => props.row.original.updated_amount_spent || "NA",
    // },
    {
      Header: t("coldwavereport.night_shelters_reporting"),
      accessor: "number_of_night_shelters",
      disableSortBy: true,
      Cell: (props) =>
        props.row.original.number_of_night_shelters !== null ? props.row.original.number_of_night_shelters : "NA",
    },
    {
      Header: t("coldwavereport.night_shelters_total"),
      accessor: "number_of_night_shelters_so_far",
      disableSortBy: true,
      Cell: (props) =>
        props.row.original.number_of_night_shelters_so_far !== null ? props.row.original.number_of_night_shelters_so_far : "NA",
    },
    {
      Header: t("coldwavereport.people_in_shelter_reporting"),
      accessor: "number_of_ppl_taking_shelter_in_night_shelters",
      disableSortBy: true,
      Cell: (props) =>
        props.row.original.number_of_ppl_taking_shelter_in_night_shelters !== null ? props.row.original.number_of_ppl_taking_shelter_in_night_shelters : "NA",
    },
    {
      Header: t("coldwavereport.people_in_shelter_total"),
      accessor: "number_of_ppl_taking_shelter_in_night_shelters_so_far",
      disableSortBy: true,
      Cell: (props) =>
        props.row.original.number_of_ppl_taking_shelter_in_night_shelters_so_far !== null
          ? props.row.original.number_of_ppl_taking_shelter_in_night_shelters_so_far
          : "NA",
    },
    {
      Header: t("coldwavereport.blankets_distributed_reporting"),
      accessor: "number_of_blankets_distributed",
      disableSortBy: true,
      Cell: (props) =>
        props.row.original.number_of_blankets_distributed !== null ? props.row.original.number_of_blankets_distributed : "NA",
    },
    {
      Header: t("coldwavereport.blankets_distributed_total"),
      accessor: "number_of_blankets_distributed_so_far",
      disableSortBy: true,
      Cell: (props) =>
        props.row.original.number_of_blankets_distributed_so_far !== null ? props.row.original.number_of_blankets_distributed_so_far : "NA",
    },
    {
      Header: t("coldwavereport.remarks"),
      accessor: "remarks",
      disableSortBy: true,
      Cell: (props) => (props.row.original.remarks !== null ? props.row.original.remarks : "NA"),
    },
    {
      Header: t("usertableheaders.action"),
      disableSortBy: true,
      Cell: (props) => {
        const [isdelete, setIsdelete] = useState(false);
        const [selectedId, setSelectedId] = useState(null);

      
          const deletePopUp = (id) => {
            setSelectedId(id);
            setIsdelete(!isdelete);
          };
        const deleteTableDataById = async () => {
          try {
            const response = await deleteDataById(deleteColdWaveDetail, selectedId);
            if (response?.status === "success") {
              toast.success(
                response.msg,
                {
                  position: toast.POSITION.TOP_CENTER,
                  autoClose: 2000,
                }
              ); 
              await getDailyReportData()
            } else {
              toast.error(
                response.msg || t("delete_failure_message"),
                {
                  position: toast.POSITION.TOP_CENTER,
                  autoClose: 2000,
                }
              );
            }
          } 
          // catch (error) {
          //   toast.error(t("delete_error_message"), {
          //     position: toast.POSITION.TOP_CENTER,
          //     autoClose: 2000,
          //   });
          //   console.error("Delete error:", error);
          // } 
          finally {
            setIsdelete(false);
            setSelectedId(null);
          }
        };
        // Calculate if the report is older than 3 days
const isOlderThanThreeDays = (reportDate) => {
  const currentDate = new Date();
  const reportDateObj = new Date(reportDate);
  const diffInTime = currentDate.getTime() - reportDateObj.getTime();
  const diffInDays = diffInTime / (1000 * 3600 * 24); // Convert time difference to days
  return diffInDays > 3;
};
  // console.log("ID", props.row.original);
  
const isSuperAdmin = roleName.roleName === process.env.REACT_APP_SUPER_ADMIN;
      const isDisabled = !isSuperAdmin && isOlderThanThreeDays(selectedDate); 
      console.log("Isdisabled",isDisabled);
        return (
          <div className="usertable__actionsicon">
            <span
            className={`icon__position ${isDisabled ? 'disabled' : ''}`} // Add class for disabled icon
              onClick={() => {
                if (!isDisabled) { // Allow click only if not disabled
                  // console.log("FORMATTEDdate",formattedDate);
                const distName = districtData?.find(x => x.id == selectedDistrictId)?.value || '';
                sessionStorage.setItem("date",JSON.stringify(selectedDate));
                sessionStorage.setItem("districtNameValue",JSON.stringify(distName));
                console.log("DISTNAME",distName);
                navigate("/daily-cold-wave/registration-form", {
                  state: { 
                    data: props.row.original, // Pass data to the next page
                    // date: formattedDate,
                    // districtNameValue: props.row.original.dist_name
                  },
                });
              }
              //   console.log("Human Loss Row Data:", props.row.original); // Log data after navigate
              }}
          >
              <Editicon className={`cursor-pointer me-2 ${isDisabled ? 'disabled_icon' : ''}`} style={{ fontSize: '14px' }} />
            </span>
            {roleName.roleName === process.env.REACT_APP_SUPER_ADMIN && <span className="mx-2" 
               onClick={() => {
                      deletePopUp(props.row.original.child_id); // Trigger delete action
                 }}>
                <Deleteicon style={{ fontSize: '14px' }} value={props.value}
                    className="cursor-pointer mr-1"/>
              </span>}
            {isdelete && (
              <Confirmpopup
                className="DeltePopup"
                show={isdelete}
                title={t("deletepopuptext")}
                message={t("deletepopupmessage")}
                handleToggle={deletePopUp}
                row={props.row}
                name={props.row.original.name}
                deleteRow={deleteTableDataById}
                cancel={t("cancelbutton")}
                action={t("actionbutton")}
              />
            )}
          </div>
        );
      },
    },
  ],
  [t, roleName]
);


  return (
    <div class="flood__report--container">
      {popUp && (
        <Confirmpopup
          show={popUp}
          handleToggle={handleTogglePopup}
          title="Confirmation"
          message="Are You sure to clear"
          cancel="No"
          success="Yes"
          onClickSucesshandler={onClickSucesshandler}
        />
      )}
      <Loader
        show={loader}
        type="body"
        stack="vertical"
        message="Loading Data"
      />
      <div className="ps-2 d-flex">
        <div className="me-2 region_type_container">
          <label className="mb-2">{t("floodreport.district")}</label>
          <div>
            <SelectComponent
              id="district_drop"
              value={selectedDistrictId}
              placeholder="All District"
              options={districtData}
              onChange={getDistrictDataByCode}
              getOptionLabel={(option) => option.label}
              getOptionValue={(option) => option.value}
            />
          </div>
        </div>

        {/* <div className="me-2 region_type_container">
          <label className="mb-2">Block</label>
          <SelectComponent
            id="block_drop"
            value={selectedBlockId}
            onChange={setSelectedBlockIdVal}
            placeholder="All Blocks"
            options={blockData}
          />
        </div> */}

        <div>
          <label className="mb-2">{t("floodreport.reported_on")}</label>
          <div className="date_picker_flood_container me-1">
            <DatePicker
              selected={selectedDate}
              maxDate={new Date().toLocaleDateString('en-CA')}
              name="flood_date_picker"
              onChange={(date) => {
                console.log("Selected date:", date);
                setSelectedDate(date);
              }}
              className="form-control date-picker date_picker_flood"
            />
          </div>
        </div>
        <button
          className="btn btn-success ms-2 btn-sm flood__report--button"
          onClick={() => getDailyReportData(null)}>
          {t("floodreport.buttonapply")}
        </button>
        <button
          className="btn btn-success btn-sm flood__report--button"
          onClick={downloadPDF}>
          {t("floodreport.download")}
        </button>
        {/* <button
          className="btn btn-success btn-sm flood__report--button"
          onClick={() => downloadExcel(dailyReport?.dr_cold_wave, tableHeaders)}>
          {"Export"}
        </button> */}
      </div>
      <div className="pe-3" ref={pdfRef}>
        <div className="text-center fw-bold d-none">
          Daily Flood Report Dated on ({getFormattedDate(selectedDate)})
        </div >


        <div className="datatable__container">
        <Table
  className="hl-primary-detailed-tbl"
  data={dailyReport?.dr_cold_wave || []}
  columns={coldWaveColumns}
  numberOfRows={7}
  showExport={false}
  showFilter={false}
  showReset={false}
  onExpand={false}
  scroll={true}
  rowCount={dailyReport?.dr_cold_wave?.length}
  headerTableHeadStyle={{ background: "#18a3a0", color: "#FFFFFF" }}
  tableStyle={{
    border: "1px solid  #f0f0f0",
    padding: "2px",
    borderRadius: "12px",
    // width: "150%"
  }}
/>
        </div>





      </div>
    </div>
  );
};

export default DailyColdWaveReportForm;
