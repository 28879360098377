import * as React from "react";
const SvgIncidentDashboard = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    data-name="Layer 1"
    viewBox="0 0 24 24"
    width="1em"
    height="1em"
    fill= "currentColor"
    {...props}
  >
    <path  d="M14 10a4.96 4.96 0 0 1-.832 2.754l-2.875-2.875A1 1 0 0 1 10 9.172V5.101A5 5 0 0 1 14 10m-6-.414V5.101a5.005 5.005 0 0 0-3.927 5.767c.325 1.934 1.82 3.543 3.729 3.992 1.47.345 2.86.033 3.952-.691L8.585 11a2 2 0 0 1-.586-1.414ZM19 5h-2a1 1 0 1 0 0 2h2a1 1 0 1 0 0-2m0 4h-2a1 1 0 1 0 0 2h2a1 1 0 1 0 0-2m0 4h-2a1 1 0 1 0 0 2h2a1 1 0 1 0 0-2m5-7v8c0 2.757-2.243 5-5 5h-6v2h4a1 1 0 1 1 0 2H7a1 1 0 1 1 0-2h4v-2H5c-2.757 0-5-2.243-5-5V6c0-2.757 2.243-5 5-5h14c2.757 0 5 2.243 5 5m-2 0c0-1.654-1.346-3-3-3H5C3.346 3 2 4.346 2 6v8c0 1.654 1.346 3 3 3h14c1.654 0 3-1.346 3-3z" />
  </svg>
);
export default SvgIncidentDashboard;
