import React, { useState, useEffect, useRef, useContext } from "react";
import * as echarts from "echarts";
import { useTranslation } from "react-i18next";
import {
  getBlockInfoFloodURL,
  humanlossdougnutchartdata,
} from "../../../../../Services/EndPoints";
import { Viewicon } from "../../../../../Components/IconComponents";
import { ThemeContext } from "../../../../..";
import { getData } from "../../../../../Services/Services";

const HalfDoughnutChartLoss = (props) => {
  const {
    fromDateNew,
    toDateNew,
    showViewIcon = false,
    title,
    dataKey,
    applyClicked,
    onApplyHandled,
    firstLoad,
    selectedIncident
  } = props;

  const { theme } = useContext(ThemeContext);
  const echartsDom1 = useRef(null);
  const { t } = useTranslation();
  const [DougnuCardData, setDougnuCardData] = useState({});
  const [selectedIncident1, setSelectedIncident1] = useState(null);
  

  const fetchChartData = async () => {
    try {
      const incidentId = selectedIncident?.id === 0 ? 0 : selectedIncident?.id || "ALL";
      // Send "ALL" on initial load if no incident is selected
  
      const data = await getData(
        humanlossdougnutchartdata
          .replace("FROMDATE", fromDateNew)
          .replace("TODATE", toDateNew)
          .replace("ID", incidentId) // Replaces ID with "ALL" on initial load
      );

      console.log(selectedIncident.id,incidentId,"incidentId")
  
      if (data && data.length > 0) {
        const { injured_count, incident_count, deaths_count } = data[0];
        setDougnuCardData({
          injured_count: injured_count || 0,
          incident_count: incident_count || 0,
          deaths_count: deaths_count || 0,
        });
      } else {
        setDougnuCardData({
          injured_count: 0,
          incident_count: 0,
          deaths_count: 0,
        });
      }
    } catch (error) {
      console.error("Error fetching doughnut chart data:", error);
    }
  };
  

  const handleCardClick = (incidentId, incidentName) => {
    setSelectedIncident1({ id: incidentId, name: incidentName }); // Store both ID and Name
  };

  // ✅ Run API on Initial Load
  useEffect(() => {
    if (firstLoad) {
      fetchChartData();
    }
  }, [firstLoad]); // Runs only when firstLoad is true

  // ✅ Run API when Apply button is clicked
  useEffect(() => {
    if (applyClicked) {
      fetchChartData();
      onApplyHandled(); // Reset applyClicked in parent after API call
    }
  }, [applyClicked]);

  useEffect(() => {
      if (selectedIncident) {
        fetchChartData();
      }
    }, [selectedIncident]);

  useEffect(() => {
    const myChart = echarts.init(echartsDom1.current);

    // Define colors based on the dataKey
    const getColorByDataKey = (key) => {
      switch (key) {
        case "deaths_count":
          return "#FF6666"; // Red for deaths
        case "incident_count":
          return "#FFD966"; // Yellow for incidents
        case "injured_count":
          return "#FFA500"; // Orange for injuries
        default:
          return "#CCCCCC"; // Gray for other cases
      }
    };

    const chartData = [
      {
        value: DougnuCardData[dataKey] || 0,
        name: title,
        itemStyle: { color: getColorByDataKey(dataKey) },
      },
    ];

    const options = {
      tooltip: { trigger: "item", confine: true },
      title: {
        show: true,
        text: DougnuCardData[dataKey]?.toString() || "0",
        textStyle: {
          fontSize: 20,
          color: theme === "light" ? "#000" : "#fff",
          fontWeight: "bold",
        },
        subtext: title,
        subtextStyle: {
          fontSize: 14,
          color: theme === "light" ? "#333" : "#ccc",
        },
        left: "center",
        top: "center",
      },
      series: [
        {
          type: "pie",
          radius: ["50%", "70%"],
          startAngle: 180,
          avoidLabelOverlap: false,
          label: { show: false },
          labelLine: { show: false },
          data: chartData,
        },
      ],
    };

    myChart.setOption(options);

    const handleResize = () => {
      myChart.resize();
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
      myChart.dispose();
    };
  }, [DougnuCardData, theme, title, dataKey]);

  return (
    <div className="card" style={{ width: "100%", height: "100%", overflow: "hidden"  }} onClick={() => handleCardClick(selectedIncident.id)}>
      <div className="card-body">
        <div className="card-title" style={{ display: "flex", justifyContent: "space-between" }}>
          <span>{title}</span>
          {showViewIcon && (
            <span className="cursor-pointer">
              <Viewicon />
            </span>
          )}
        </div>
        <div ref={echartsDom1} style={{ height: "100%", width: "100%" }} />
      </div>
    </div>
  );
};

export default HalfDoughnutChartLoss;
