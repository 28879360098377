export const misDistHeatWaveConfig = {
  colKey1: "",
  colKey2: "",
  colKey3: "block_name",
  colKey4: "no_of_deaths_today",
  colKey5: "no_of_deaths_cum",
  colKey6: "no_of_dec_ex_gratia_pay_done_today",
  colKey7: "no_of_dec_ex_gratia_pay_done_cum",
  colKey8: "no_of_persons_hospitalized_today",
  colKey9: "no_of_persons_hospitalized_cum",
  colKey10: "no_of_persons_hospitalized_pay_done_today",
  colKey11: "no_of_persons_hospitalized_pay_done_cum",
  colKey12: "",
};

export const misColumns = Object.entries(misDistHeatWaveConfig).map(
  ([key, value], index) => ({
    Header: `misdistheatwavereport.headercol${index + 1}`,
    accessor: value,
    disableSortBy: true,
    showInitially: true,
  })
);
