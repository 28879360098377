import React, { useState, useEffect, useRef, useContext } from "react";
import "../DailyFloodReportComp/Hindi_Font.js";
import "../DailyFloodReportComp/DailyFloodReportCompTable.css";
import Moment from "react-moment";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import govt_logo from "../../../../Assets/Images/bihar_logo.png";
import Loader from "react-spinner-loader";
import "./FireCommunityKitchenRegForm.css"
//import html2pdf from 'html2pdf.js';
import ExcelJS from "exceljs";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import SelectComponent from "../../../../Components/UiComponents/SelectComponent/SelectComponent.js";
import { postDataWithBody, getData, deleteDataById } from "../../../../Services/Services.js";
import {
  getChildRegionsById,
  findRegionsByUser,
  getDailyReportByDistrict,
  getDailyFloodReportListURL,
  cleardailyReportData,
  getDailyAffectedReportByDistrict,
  getFireCommunityKitchenReport,
  deletFireCommunityKitchenReport,
} from "../../../../Services/EndPoints.js";
import { useTranslation } from "react-i18next";
import DatePicker from "../../Common/Form/DatePicker/DatePicker.js";
import { RoleContext } from "../../../../index.js";
import Confirmpopup from "../../Common/ConfirmPopup/Confirmpopup.js";
import { Deleteicon, Editicon } from "../../../../Components/IconComponents/index.js";
import Table from "../../../../Components/UiComponents/Table/Table.js";
import "./FireCommunityKitchenRegForm.css";

const FireCommunityKitchenReportForm = () => {
  const roleName = useContext(RoleContext);
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);
  const [dailyReport, setDailyReport] = useState(null);
  const [popUp, setPopUp] = useState(false);
  const [districtData, setDistrictData] = useState([]);
  const [clearId, setClearId] = useState(null);
  const [blockData, setBlockData] = useState([]);
  const [selectedDistrictName, setSelectedDistrictName] = useState(null);
  const [selectedDistrictId, setSelectedDistrictId] = useState(null);
  const [slctdBlkId, setSlctdBlkId] = useState(null);
  const [selectedBlockId, setSelectedBlockId] = useState(null);
  const pdfRef = useRef(null);
  const [isdelete, setIsdelete] = useState(false);
          const [selectedId, setSelectedId] = useState(null);
  // const [districtName, setDistrictName] = useState(null);
  const [selectedDate, setSelectedDate] = useState(
    new Date().toLocaleDateString('en-CA')
  ); // State to store selected date;

  const getBlockIdListForReport = () => {
    if (slctdBlkId == 0) {
      let blockIdList = [];
      blockData.forEach((v) => blockIdList.push(v.id));
      return blockIdList;
    } else {
      return [slctdBlkId];
    }
  };

  const getDailyReportData = async (blockListId, onInit = false) => {
    if (blockListId == null) {
      blockListId = getBlockIdListForReport();
    }
    
    var selectedDateStr = document.getElementById("flood_date_picker").value;
    if (selectedDateStr == "") {
      selectedDateStr = new Date().toLocaleDateString('en-CA');
    }
    setSelectedDate(selectedDateStr);
    setLoader(true);
  
    const index = blockListId.indexOf(0);
    if (index > -1) {
      blockListId.splice(index, 1); // Remove 0 if it exists
    }
    
    let inputobj = {
      region_id_list: blockListId,
      reported_on: selectedDateStr,
    };
    
    try {
      let dailyReportData = await postDataWithBody(
        getFireCommunityKitchenReport,
        inputobj,
        {}
      );
  
      // Map and merge the cumulative data with daily data
      if (dailyReportData?.fire_ck) {
        dailyReportData.fire_ck = dailyReportData.fire_ck.map(report => {
          // Find matching cumulative data
          const cummData = dailyReportData?.fire_ck_cumm?.find(
            cumm => cumm.region_id === report.region_id
          );
  
           // Create merged report object
        const mergedReport = {
          ...report,
          // Add cumulative data while retaining 0 and replacing only null or undefined with "NA"
          till_mrng_food: cummData?.till_mrng_food ?? "NA",
          till_eveng_food: cummData?.till_eveng_food ?? "NA",
          till_today_total: cummData?.till_today_total ?? "NA"
        };

        // Ensure '0' is not replaced with "NA"
        Object.keys(mergedReport).forEach(key => {
          if (mergedReport[key] === null || mergedReport[key] === undefined) {
            mergedReport[key] = "NA";
          }
        });

        return mergedReport;
      });
    }
  
      setDailyReport(dailyReportData);
      console.log("Merged Daily Report Data:", dailyReportData);
      
      // Update district name in DOM
      var skillsSelect = document.getElementById("district_drop");
      if (skillsSelect) {
        var selectedText = skillsSelect.options[skillsSelect.selectedIndex].text;
        const distNameElement = document.getElementById("selected_dist_name");
        if (distNameElement) {
          distNameElement.innerHTML = selectedText;
        }
      }
  
    } catch (error) {
      console.error("Error fetching daily report data:", error);
      toast.error("Error loading report data");
    } finally {
      setLoader(false);
    }
  };
  
  

  async function addImageProcess() {
    return new Promise((resolve, reject) => {
      let img = new Image();
      img.src = govt_logo;
      img.onload = () => resolve(img);
      img.onerror = reject;
    });
  }
  const downloadPDF = async () => {
     // Get the table element
     const table = document.getElementById("report_table");
 // Convert the table data into a format suitable for autoTable
 const tableData = [];
 const tableHeader = [];
 const rows = table.rows;
 const numCols = rows[0].cells.length;

 // Get table header (excluding the Action column)
 for (let i = 0; i < numCols - 1; i++) {  // Skip the last column (Action column)
   tableHeader.push(rows[0].cells[i].textContent.trim());
 }

 // Get table data rows (excluding the Action column)
 for (let i = 1; i < rows.length; i++) {
   const rowData = [];
   for (let j = 0; j < numCols - 1; j++) {  // Skip the last column (Action column)
     rowData.push(rows[i].cells[j].textContent.trim());
   }
   tableData.push(rowData);
 }

    // Now generate the PDF without the "Actions" column
    const content = pdfRef.current;
    const doc = new jsPDF("l", "pt", "a4");  // Set the orientation to landscape ("l")
    const image = await addImageProcess();

    let fontName = "Helvetica";
    if (i18n.language == "hi") {
        fontName = "Akshar Unicode";
    }
    doc.setFont(fontName);
    doc.setFontSize(13);
    doc.addImage(image, "png", 380, 28, 0, 0);
    var xOffset = doc.internal.pageSize.width / 2;
    const selectedDate = new Date(document.getElementById("flood_date_picker").value).toLocaleDateString("en-GB");

    doc.text(t("floodreport.govt_name"), xOffset, 120, { align: "center" });
    doc.text(t("floodreport.department_name"), xOffset, 140, { align: "center" });
    doc.text(
        t("Fire Community Kitchen Report") +
        " (" +
        t("floodreport.dated_on_lbl") +
        " " +
        selectedDate +
        ")",
        xOffset,
        160,
        { align: "center" }
    );
    doc.autoTable({
      head: [tableHeader],
      body: tableData,
        startX: 0,
        startY: 190,
        theme: "grid",
        rowPageBreak: "avoid",
        bodyStyles: { lineColor: [0, 0, 0] },
        styles: {
            font: fontName,
            fontSize: 2,
            cellPadding: 3,
            lineColor: [0, 0, 0], // Black lines
            lineWidth: 0.1, // Adjust thickness of lines
            halign: "center"
        },
        headStyles: {
            fillColor: [255, 255, 255], // White background for header
            textColor: [0, 0, 0],       // Black text for header
            fontStyle: "bold",          // Bold header text
            lineColor: [0, 0, 0],       // Ensure grid lines stay black for the header
            lineWidth: 0.1,             // Ensure lines are drawn in header
            halign: "center"
        },
        columnStyles: {
            0: { cellWidth: 35 }, // S.No
            1: { cellWidth: 150 }, // Block Name
            2: { cellWidth: 80 }, // Number of places...
            3: { cellWidth: 80 },
            4: { cellWidth: 80 },
            5: { cellWidth: 80 },
            6: { cellWidth: 80 },
            7: { cellWidth: 80 },
            8: { cellWidth: 80 },

            // Do not include the 17th column (Actions)
        },
        rowPageBreak: "auto",
        pageBreak: "auto",
        didParseCell: function (data) {
          if (data.cell.raw && data.cell.raw.nodeType === 1) {
            const cellElement = data.cell.raw;
                if (data.cell.raw.querySelector("b")) {
                    data.cell.styles.textColor = "red";
                }
                if (data.cell.raw.querySelector("span")) {
                    data.cell.styles.halign = "center";
                }
            } else {
                console.warn("data.cell.raw is undefined", data);
            }
        },
        styles: {
            font: fontName,
            fontSize: 9,
            cellWidth: "auto",
            halign: "left",
            cellPadding: 3,
        },
    });

    
    // Get the bottom of the page
    let pageHeight = doc.internal.pageSize.height;
    let footerY = pageHeight - 180; // Adjust as needed for spacing

    const distName = districtData?.find(x => x.id == selectedDistrictId)?.value || '';

    let finalY = doc.lastAutoTable.finalY; // The y position on the page
    doc.setFontSize(10);
   

    doc.text(550, footerY + 100,"Signature of District Officers/ ", { maxWidth: 300 });
    doc.text(550, footerY + 120, "Additional District Magistrate(ADM)(Disaster Management)/", { maxWidth: 300 });
    doc.text(550, footerY + 140, `Officer in Charge(District Disaster Management)/`, { maxWidth: 300 });
    doc.setFont(undefined, "bold");
    doc.text(550, footerY + 160, `[${distName} DISTRICT]`, { maxWidth: 300 });
    doc.setFont(undefined, "normal"); // Reset font back to normal

    doc.text(50, footerY + 100, "Letter No.: ", { maxWidth: 300 });
    doc.text(50, footerY + 120, "Date: ", { maxWidth: 300 });
    doc.text(50, footerY + 140, "Copy: ", { maxWidth: 300 });

    const now = new Date();
    now.setMinutes(now.getMinutes() + 330); // Convert UTC to IST (+5:30)

    const istDate = now.toISOString().split("T")[0]; // YYYY-MM-DD
    const istTime = now.toISOString().split("T")[1].split(".")[0].replace(/:/g, ""); // HHMMSS

    const timestamp = `${istDate}_${istTime}`;

    doc.save(`fire_community_kitchen_report_${timestamp}.pdf`);
};


  const deletePopUp = (id) => {
    setSelectedId(id);
    setIsdelete(!isdelete);
  };

  

  
  
  const getDistrictData = async () => {
    try {
      const distList = await getData(findRegionsByUser);

      if (Array.isArray(distList) && distList.length > 0) {
        let blockdata = await getBlockListByDistId(distList[0].id);
        setSlctdBlkId(0);
        let blockIdList = [];
        blockdata.forEach((v) => blockIdList.push(v.id));
        setDistrictData(distList);
        if(sessionStorage.getItem("date") && sessionStorage.getItem("districtNameValue") ) {
          getDailyReportData(blockIdList,true);
          sessionStorage.removeItem("date");
          sessionStorage.removeItem("districtNameValue");
        }
        setSelectedDistrictId(distList[0].id);
      } else {
        console.error("Error: District data is empty or not an array");
      }
    } catch (error) {
      console.error("Error fetching district list:", error);
    }
  };

  useEffect(() => {
    document.getElementById("flood_date_picker").valueAsDate = new Date();
    getDistrictData();
  }, []);

  const getFormattedDate = (dateStr) => {
    return <Moment format="DD/MM/YYYY">{dateStr}</Moment>;
  };

  const getBlockListByDistId = async (distId) => {
    try {
      const blockList = await postDataWithBody(
        getChildRegionsById,
        [distId],
        {}
      );
      blockList.forEach(function (elem, index) {
        elem["value"] = elem["name"];
      });
      blockList.unshift({ id: 0, value: "All" });
      setBlockData(null);
      setTimeout(function () {
        setBlockData(blockList);
      }, 100);
      return blockList;
    } catch (error) {
      console.error("Error fetching block list:", error);
    }
  };
  async function getDistrictDataByCode(event) {
    setSelectedDistrictName(event.target.value);
    setSelectedDistrictId(event.target.value);
    getBlockListByDistId(event.target.value);
  }
  function setSelectedBlockIdVal(event) {
    setSlctdBlkId(event.target.value);
  }
  const changeSelectedate = () => {
    /*if (selectedDistrictId && selectedDate) {
      getDailyReportData(selectedDistrictId, selectedDate);
    } else {
      console.error("Please select both district and date.");
    }*/
  };

  const handleTogglePopup = () => {
    setPopUp(!popUp);
  };

  const onClickSucesshandler = async () => {
    await postDataWithBody(cleardailyReportData + clearId, {}, {});
    setPopUp(false);
  };
  console.log("DISTRICT:",districtData);

  console.log("SELECTEDDISCTRICT",selectedDistrictName);

  // const districtName = districtData?.find(x => x.id == selectedDistrictId)?.value || '';

  // console.log("DISTRICTNAME",districtName);

  
  const fireCKColumns = React.useMemo(
    () => [
      {
        Header: t("fireckreport.slno"),
        accessor: "sno",
        disableSortBy: false,
        Cell: (props) => {
          // For total row, span across two columns and show "Total"
          if (props.row.original.sno === "Total") {
            return null
          }
          // For normal rows, show row number
          return <span>{props.row.index + 1}</span>;
        }
      },
      {
        Header: t("fireckreport.block_name"),
        accessor: "block_name",
        disableSortBy: false,
        Cell: (props) => {
          // Hide content for total row as it's handled by sno column
          if (props.row.original.sno === "Total") {
            return <div style={{ fontWeight: "bold", textAlign: "center" }}>Total</div>;
          }
          return props.row.original.block_name || "NA";
        }
      },
      {
        Header: t("fireckreport.today_number_of_kitchen_running"),
        accessor: "today_no_of_kitchens_run",
        disableSortBy: true,
        Cell: (props) => (
          <div style={{ textAlign: "center", fontWeight: props.row.original.sno === "Total" ? "bold" : "normal" }}>
            {props.row.original.today_no_of_kitchens_run === null || props.row.original.today_no_of_kitchens_run === undefined ? "NA" : props.row.original.today_no_of_kitchens_run}
          </div>
        )
      },
      {
        Header: t("fireckreport.lunch_today"),
        accessor: "today_mrng_food",
        disableSortBy: true,
        Cell: (props) => (
          <div style={{ textAlign: "center", fontWeight: props.row.original.sno === "Total" ? "bold" : "normal" }}>
            {props.row.original.today_mrng_food === null || props.row.original.today_mrng_food === undefined ? "NA" : props.row.original.today_mrng_food}
          </div>
        )
      },
      {
        Header: t("fireckreport.dinner_today"),
        accessor: "today_evng_food",
        disableSortBy: true,
        Cell: (props) => (
          <div style={{ textAlign: "center", fontWeight: props.row.original.sno === "Total" ? "bold" : "normal" }}>
            {props.row.original.today_evng_food === null || props.row.original.today_evng_food === undefined ? "NA" : props.row.original.today_evng_food}
          </div>
        )
      },
      {
        Header: t("fireckreport.total_today"),
        accessor: "today_total",
        disableSortBy: true,
        Cell: (props) => (
          <div style={{ textAlign: "center", fontWeight: props.row.original.sno === "Total" ? "bold" : "normal" }}>
            {props.row.original.today_total !== null && props.row.original.today_total !== undefined 
              ? props.row.original.today_total 
              : "NA"}
          </div>
        )
      },
      {
        Header: t("fireckreport.lunch_cumulative"),
        accessor: "till_mrng_food",
        disableSortBy: true,
        Cell: (props) => (
          <div style={{ textAlign: "center", fontWeight: props.row.original.sno === "Total" ? "bold" : "normal" }}>
            {props.row.original.till_mrng_food !== null && props.row.original.till_mrng_food !== undefined 
              ? props.row.original.till_mrng_food 
              : "NA"}
          </div>
        )
      },
      {
        Header: t("fireckreport.dinner_cumulative"),
        accessor: "till_eveng_food",
        disableSortBy: true,
        Cell: (props) => (
          <div style={{ textAlign: "center", fontWeight: props.row.original.sno === "Total" ? "bold" : "normal" }}>
            {props.row.original.till_eveng_food !== null && props.row.original.till_eveng_food !== undefined 
              ? props.row.original.till_eveng_food 
              : "NA"}
          </div>
        )
      },
      {
        Header: t("fireckreport.total_cumulative"),
        accessor: "till_today_total",
        disableSortBy: true,
        Cell: (props) => (
          <div style={{ textAlign: "center", fontWeight: props.row.original.sno === "Total" ? "bold" : "normal" }}>
            {props.row.original.till_today_total !== null && props.row.original.till_today_total !== undefined 
              ? props.row.original.till_today_total 
              : "NA"}
          </div>
        )
      },
    {
      Header: t("usertableheaders.action"),
      disableSortBy: true,
      Cell: (props) => {

        console.log("PROPS",props.row.original);
        const [isdelete, setIsdelete] = useState(false);
        const [selectedId, setSelectedId] = useState(null);

        if (props.row.original.sno === "Total") {
          return null;
        }
      
          const deletePopUp = (id) => {
            setSelectedId(id);
            setIsdelete(!isdelete);
          };
        const deleteTableDataById = async () => {
          try {
            const response = await deleteDataById(deletFireCommunityKitchenReport, selectedId);
            if (response?.status === "success") {
              toast.success(
                response.msg,
                {
                  position: toast.POSITION.TOP_CENTER,
                  autoClose: 2000,
                }
              ); 
              await getDailyReportData()
            } else {
              toast.error(
                response.msg || t("delete_failure_message"),
                {
                  position: toast.POSITION.TOP_CENTER,
                  autoClose: 2000,
                }
              );
            }
          } 
          // catch (error) {
          //   toast.error(t("delete_error_message"), {
          //     position: toast.POSITION.TOP_CENTER,
          //     autoClose: 2000,
          //   });
          //   console.error("Delete error:", error);
          // } 
          finally {
            setIsdelete(false);
            setSelectedId(null);
          }
        };
        // Calculate if the report is older than 3 days
const isOlderThanThreeDays = (reportDate) => {
  const currentDate = new Date();
  const reportDateObj = new Date(reportDate);
  const diffInTime = currentDate.getTime() - reportDateObj.getTime();
  const diffInDays = diffInTime / (1000 * 3600 * 24); // Convert time difference to days
  return diffInDays > 3;
};
  // console.log("ID", props.row.original);
  
const isSuperAdmin = roleName.roleName === process.env.REACT_APP_SUPER_ADMIN;
      const isDisabled = !isSuperAdmin && isOlderThanThreeDays(selectedDate); 
      console.log("Isdisabled",isDisabled);
        return (
          <div className="usertable__actionsicon">
            <span
            className={`icon__position ${isDisabled ? 'disabled' : ''}`} // Add class for disabled icon
              onClick={() => {
                if (!isDisabled) { // Allow click only if not disabled
                  // console.log("FORMATTEDdate",formattedDate);
                const distName = districtData?.find(x => x.id == selectedDistrictId)?.value || '';
                sessionStorage.setItem("date",JSON.stringify(selectedDate));
                sessionStorage.setItem("districtNameValue",JSON.stringify(distName));
                console.log("DISTNAME",distName);
                navigate("/fire-community-kitchen/registration-form", {
                  state: { 
                    data: props.row.original, // Pass data to the next page
                    // date: formattedDate,
                    // districtNameValue: props.row.original.dist_name
                  },
                });
              }
              //   console.log("Human Loss Row Data:", props.row.original); // Log data after navigate
              }}
          >
              <Editicon className={`cursor-pointer me-2 ${isDisabled ? 'disabled_icon' : ''}`} style={{ fontSize: '14px' }} />
            </span>
            {roleName.roleName === process.env.REACT_APP_SUPER_ADMIN && <span className="mx-2" 
               onClick={() => {
                      deletePopUp(props.row.original.id); // Trigger delete action
                 }}>
                <Deleteicon style={{ fontSize: '14px' }} value={props.value}
                    className="cursor-pointer mr-1"/>
              </span>}
            {isdelete && (
              <Confirmpopup
                className="DeltePopup"
                show={isdelete}
                title={t("deletepopuptext")}
                message={t("deletepopupmessage")}
                handleToggle={deletePopUp}
                row={props.row}
                name={props.row.original.name}
                deleteRow={deleteTableDataById}
                cancel={t("cancelbutton")}
                action={t("actionbutton")}
              />
            )}
          </div>
        );
      },
    },
  ],
  [t, roleName]
);

const getTotalRow = (data) => {
  return {
    sno: "Total",
    block_name: "", // This will be hidden as we're merging columns
    today_no_of_kitchens_run: data.reduce((sum, row) => sum + (Number(row.today_no_of_kitchens_run) || 0), 0),
    today_mrng_food: data.reduce((sum, row) => sum + (Number(row.today_mrng_food) || 0), 0),
    today_evng_food: data.reduce((sum, row) => sum + (Number(row.today_evng_food) || 0), 0),
    today_total: data.reduce((sum, row) => sum + (Number(row.today_total) || 0), 0),
    till_mrng_food: data.reduce((sum, row) => sum + (Number(row.till_mrng_food) || 0), 0),
    till_eveng_food: data.reduce((sum, row) => sum + (Number(row.till_eveng_food) || 0), 0),
    till_today_total: data.reduce((sum, row) => sum + (Number(row.till_today_total) || 0), 0)
    // No action property needed as it won't be displayed
  };
};

// Append Total Row at the End
const tableData = React.useMemo(() => {
  if (!dailyReport?.fire_ck?.length) return [];
  return [...dailyReport.fire_ck, getTotalRow(dailyReport.fire_ck)];
}, [dailyReport]);

  return (
    <div class="flood__report--container">
      {popUp && (
        <Confirmpopup
          show={popUp}
          handleToggle={handleTogglePopup}
          title="Confirmation"
          message="Are You sure to clear"
          cancel="No"
          success="Yes"
          onClickSucesshandler={onClickSucesshandler}
        />
      )}
      <Loader
        show={loader}
        type="body"
        stack="vertical"
        message="Loading Data"
      />
      <div className="ps-2 d-flex">
        <div className="me-2 region_type_container">
          <label className="mb-2">{t("floodreport.district")}</label>
          <div>
            <SelectComponent
              id="district_drop"
              value={selectedDistrictId}
              placeholder="All District"
              options={districtData}
              onChange={getDistrictDataByCode}
              getOptionLabel={(option) => option.label}
              getOptionValue={(option) => option.value}
            />
          </div>
        </div>

        {/* <div className="me-2 region_type_container">
          <label className="mb-2">Block</label>
          <SelectComponent
            id="block_drop"
            value={selectedBlockId}
            onChange={setSelectedBlockIdVal}
            placeholder="All Blocks"
            options={blockData}
          />
        </div> */}

        <div>
          <label className="mb-2">{t("floodreport.reported_on")}</label>
          <div className="date_picker_flood_container me-1">
            <DatePicker
              selected={selectedDate}
              maxDate={new Date().toLocaleDateString('en-CA')}
              name="flood_date_picker"
              onChange={(date) => {
                console.log("Selected date:", date);
                setSelectedDate(date);
              }}
              className="form-control date-picker date_picker_flood"
            />
          </div>
        </div>
        <button
          className="btn btn-success ms-2 btn-sm flood__report--button"
          onClick={() => getDailyReportData(null)}>
          {t("floodreport.buttonapply")}
        </button>
        <button
          className="btn btn-success btn-sm flood__report--button"
          onClick={downloadPDF}>
          {t("floodreport.download")}
        </button>
        {/* <button
          className="btn btn-success btn-sm flood__report--button"
          onClick={() => downloadExcel(dailyReport?.dr_cold_wave, tableHeaders)}>
          {"Export"}
        </button> */}
      </div>
      <div className="pe-3" ref={pdfRef}>
        <div className="text-center fw-bold d-none">
          Daily Flood Report Dated on ({getFormattedDate(selectedDate)})
        </div >


        <div className="datatable__container">
          <Table
            data={tableData} // Updated dataset with Total row
            columns={fireCKColumns}
            numberOfRows={7}
            showExport={false}
            showFilter={false}
            showReset={false}
            onExpand={false}
            rowCount={tableData.length}
            headerTableHeadStyle={{ background: "#18a3a0", color: "#FFFFFF", textAlign: "center", verticalAlign: "top" }}
            tableStyle={{
              border: "1px solid  #f0f0f0",
              padding: "2px",
              borderRadius: "12px",
            }}
            getRowProps={(row) => ({
              className: row.original.sno === "Total" ? "total-row" : "",
            })}
          />
        </div>





      </div>
    </div>
  );
};

export default FireCommunityKitchenReportForm;
